import types from "./types";

const initState = {
  additionalInfoCaseListLoading: false,
  additionalInfoCaseCountLoading: false,
  additionalInfoCaseUpdateCountLoading: false,
  additionalInfoCaseList: [],
  additionalInfoCaseCount: 0,
  additionalInfoCaseUpdateCount: 0,
  additionalInfoCount: 0,
  additionalInfoChatCount:0,
};

export default function additionalInfoRequired(state = initState, action) {
  switch (action.type) {
    case types.SET_ADDITIONAL_INFO_CASE_LIST_LOADING:
      return {
        ...state,
        additionalInfoCaseListLoading: action.data,
      };
      
    case types.SET_ADDITIONAL_INFO_CASE_COUNT_LOADING:
      return {
        ...state,
        additionalInfoCaseCountLoading: action.data,
      };
    case types.SET_ADDITIONAL_INFO_CASE_UPDATE_COUNT_LOADING:
      return {
        ...state,
        additionalInfoCaseUpdateCountLoading: action.data,
      };
    case types.SET_ADDITIONAL_INFO_CASE_LIST:
      return {
        ...state,
        additionalInfoCount: action.count,
        additionalInfoCaseList: action.payload,
      };
    case types.SET_ADDITIONAL_INFO_CASE_COUNT:
      return {
        ...state,
        additionalInfoCaseCount: action.payload,
      };
    case types.SET_ADDITIONAL_INFO_CASE_UPDATE_COUNT:
      return {
        ...state,
        additionalInfoCaseUpdateCount: action.payload,
      };
      case types.SET_ADDITIONAL_INFO_CHAT_COUNT:
        return {
          ...state,
          additionalInfoChatCount: action.payload,
        };
    default:
      return state;
  }
}

import types from "./types"
import API from "../../axios/physician/PhysicianApi";
 
const initState = {
    caseQueueLoading: false,
    caseQueue: [],
    caseQueueCountLoading: false,
    caseQueueCount: null,
    sortDirection: 'ascend',
    caseQueueStatusFilter: null,
    sendSMSNotifications: false,
};

export default function otherPhysiciansCases(
    state = initState,
    action,
) {
    let caseQueueCount

    switch (action.type) {
        case types.SET_CASE_QUEUE_LIST_LOADING:
            return {
                ...state,
                caseQueueLoading: action.data,
            };
        case types.SET_CASE_QUEUE_LIST:
            
            let updatedState = { 
                ...state,
                caseQueue: action.data.cases
            }

            if (!state.lastSMSNotificationSent) {
                updatedState.lastSMSNotificationSent = new Date()
            }

            return updatedState;
        case types.SET_CASE_QUEUE_STATUS_FILTER:
            return {
                ...state,
                caseQueueStatusFilter: action.data
            }
        case types.REMOVE_CASE_FROM_QUEUE:
            return {
                ...state,
                caseQueueCount: Number.isInteger(state.caseQueueCount) && state.caseQueueCount > 0 && state.caseQueueCount - 1,
                caseQueue: state.caseQueue?.filter(_case => _case._id !== action.data)
            }
        case types.SET_CASE_QUEUE_COUNT_LOADING:
            return {
                ...state,
                caseQueueCountLoading: action.data
            }
        case types.SET_CASE_QUEUE_COUNT:
            return {
                ...state,
                caseQueueCount: action.data
            }
        case types.SET_SORT_DIRECTION:
            return {
                ...state,
                sortDirection: action.data
            }
        case types.INCREMENT_QUEUE_COUNT:
            caseQueueCount = Number.isInteger(state.caseQueueCount) ? state.caseQueueCount + 1 : 1
            return {
                ...state,
                caseQueueCount
            }
        case types.ADD_CASE_TO_QUEUE: 
            caseQueueCount = Number.isInteger(state.caseQueueCount) ? state.caseQueueCount + 1 : 1
            const update = { ...state, caseQueueCount }

            if ([null, action.data.status].includes(state.caseQueueStatusFilter)) {
                if (state.sortDirection === 'descend') {
                    update.caseQueue = [action.data, ...state.caseQueue]
                } else if (state.caseQueue.length < 50) {
                    update.caseQueue = [...state.caseQueue, action.data]
                } 
            }

            // ADDED CASE PUSHESE THE QUEUE LENGTH TO 10, NOTIFICATOIN THRESHOLD
            // SEND API CALL TO BACKEND TO SEND SMS NOTIFICATION TO PROVIDER
            if (state.caseQueue.length === 9 && state.sendSMSNotifications) {
                try {
                    API.sendQueueSMSNotification()
                    update.lastSMSNotificationSent = new Date()
                } catch (e) {
                    console.log(e)
                }
            }

            return update
        case types.SET_SEND_SMS_NOTIFICATIONS:
            return {
                ...state,
                sendSMSNotifications: action.data
            }
        default:
            return state;
    }
}
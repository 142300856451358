/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Avatar } from "antd";
import { UserOutlined } from "@ant-design/icons";
import HTMLReactParser from "html-react-parser";
import styled from "styled-components";
import { displayTimestamp } from '../../utils/displayTimestamp'

const StyledMessageDiv = styled.div`
  margin: 12px 8px;
  display: flex;
  flex-direction: column;
  min-width: 300px;
  max-width: 48%;
  cursor: pointer;
`;

const StyledMessageText = styled.div`
  color: #000000;
  padding: 0 8px 8px 8px;
  margin-left: 4px;
  overflow-wrap: break-word;
`;

const StyledUserDetails = styled.div`
  display: flex;
  align-items: center;
`;

const StyledName = styled.div`
  display: flex;
  flex-direction: column;
  color: #7bad7e;
  font-size: 16px;
  font-weight: 600;
  line-height: 16px;
  margin: 0 4px;
`;

const StyledDate = styled.div`
  display: inline-block;
  font-size: 12px;
  margin: 0 4px;
`;

const StyledMessageSectionAvatarWrapper = styled.div`
  display: block;
  padding: 8px;
  vertical-align: top;
`;

const StyledMessageSectionHeaderWrapper = styled.div`
  display: block;
  padding: 8px;
  min-height: 40px;
`;

const StyledContentWrapper = styled.div`
  margin-left: 56px;
  display: inline-block;
`;

const StyledContentImageWrapper = styled.div`
  width: inherit;
  text-align: center;
  padding: 8px 8px;
`;

const StyledImage = styled.img`
  width: 68%;
  height: auto;
  cursor: pointer;
`;

const MessageComponent = ({
  avatarUrl,
  name,
  time,
  messageText,
  imageUrl,
  isPatient,
  isAuthor
}) => {


  const messageStyle = {}
  isAuthor && (messageStyle.marginLeft = 'auto')
  messageStyle.backgroundColor = isPatient ? '#fff' : '#f4f4f4'

  return (
    <>
      <StyledMessageDiv style={messageStyle}>
        <StyledUserDetails>
          <StyledMessageSectionAvatarWrapper>
            { avatarUrl ? <Avatar size="large" src={avatarUrl} /> : <Avatar size="large" icon={<UserOutlined />} /> }
          </StyledMessageSectionAvatarWrapper>
          <StyledMessageSectionHeaderWrapper>
            <StyledName>{name}</StyledName>
            <StyledDate>{displayTimestamp(time)}</StyledDate>
          </StyledMessageSectionHeaderWrapper>
        </StyledUserDetails>
        <StyledContentWrapper>
          {imageUrl && (
            <StyledContentImageWrapper>
              <StyledImage src={imageUrl} alt="uploaded-content-view" />
            </StyledContentImageWrapper>
          )}
          {messageText && <StyledMessageText>{HTMLReactParser(messageText)}</StyledMessageText>}
        </StyledContentWrapper>
      </StyledMessageDiv>
    </>
  );
};

export default MessageComponent;

import { StarFilled, StarOutlined } from "@ant-design/icons";
import React, { useState } from "react";
import styled from "styled-components";

import PhysicianApi from "../../../../axios/physician/PhysicianApi";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import physicianDetails from "../../../../redux/physicianDetails";

const StyledWrapper = styled.div`
    font-size: 24px;
    cursor: pointer;
`;

export const BookmarkCase = ({ caseIds, caseId, actions }) => {

    const [ isMarked, setMarked ] = useState(caseIds.includes(caseId));
    const [ bookmarkedCases, setBookmarkCases ] = useState(caseIds.map(id => id));

    const bookmarkCase = () => {
        setMarked(!isMarked);

        const caseIdsSet = new Set(bookmarkedCases);
        caseIdsSet.has(caseId) ? caseIdsSet.delete(caseId) : caseIdsSet.add(caseId);
        const caseIds = Array.from(caseIdsSet);

        setBookmarkCases(caseIds);
        actions.setPhysicianCaseIds(caseIds);
        PhysicianApi.bookmarkCase(caseId, caseIds);
    };

    return (
        <StyledWrapper onClick={ bookmarkCase }>
            { isMarked ?
                <StarFilled style={ { color: '#7BAD7E' } }/> :
                <StarOutlined/>
            }
        </StyledWrapper>
    )
}

const mapDispatchToProps = (dispatch) => {
    const { setPhysicianCaseIds } = physicianDetails.actions;
    return {
        actions: bindActionCreators(
            {
                setPhysicianCaseIds,
            },
            dispatch
        ),
    };
};

export default connect(null, mapDispatchToProps)(BookmarkCase);
  /* eslint-disable react-hooks/exhaustive-deps */
  import React, { useState, useEffect } from "react";
  import moment from 'moment-timezone'

  import { 
    Card, 
    message, 
    Spin, 
    Typography, 
    Row, 
    Col, 
    Table,
    Select,
    Checkbox
  } from "antd";

  import { LoadingOutlined } from "@ant-design/icons";

  import PrescriptionsApi from "../../axios/prescription/PrescriptionsApi";

  import { RxRequestModal } from './modals/RxRequestModal'
  import RxErrorModal from './modals/RxErrorModal'
  import PatientReconciliationModal from './modals/PatientReconcilationModal'

  const { Title } = Typography;
  const { Option } = Select

  const RxRequests = ({ provider }) => {
    const [itemType, setItemType] = useState('request')
    const [items, setItems] = useState(null)
    const [selectedItem, selectItem] = useState(null)
    const [prescription, updatePrescription] = useState(null)
    const [prescriptionIsValid, setPrescriptionIsValid] = useState(false)
    const [requestedMedications, setRequestedMedications] = useState([])
    const [itemsLoading, setItemsLoading] = useState(false);
    const [fetchCompletedRequests, setFetchCompletedRequests] = useState(false)

    useEffect(() => {
      setItemsLoading(true)
      setItems([])
      itemType === 'error'
        ? fetchRxErrors()
        : fetchRxRequests()
      
    }, [itemType, fetchCompletedRequests])

    useEffect(() => {
      if (selectedItem && itemType === 'request') {
        setRequestedMedications(selectedItem.pharmacyRequestedMedications)
        updatePrescription(selectedItem.requestedPrescription || selectedItem.pharmacyRequestedMedications.at(-1))
      }
    }, [selectedItem])


    const fetchRxRequests = () => {
      setItems([])
      PrescriptionsApi.getRxRequests({ status: fetchCompletedRequests ? ['approved', 'replaced'] : ['pending']})
        .then(({ data }) =>  setItems(data.requests.map(request => ({ ...request, key: request._id }))))
        .catch(e => {
          console.error(e)
          message.error('Error fetching rx requests.')
        })
        .finally(() => setItemsLoading(false))
    }

    const fetchRxErrors = () => {
      PrescriptionsApi.getRxErrors()
        .then(({ data }) =>  setItems(data.errors))
        .catch(e => {
          console.error(e)
          message.error('Error fetching rx errors.')
        })
        .finally(() => setItemsLoading(false))
    }

    const columns = [
      {
        title: 'Type',
        key: 'type',
        render: err => <span style={{ textTransform: 'capitalize'}}>{err.type?.toLowerCase() || err.errorType?.toLowerCase()}</span>
      },
      {
        title: 'Case Id',
        dataIndex: 'caseId',
        key: 'caseId',
        render: caseId => caseId || 'Unknown'
      },
      {
        title: 'Patient Name',
        dataIndex: 'patient',
        key: 'patient',
        render: patient => patient ? `${patient?.firstName} ${patient?.lastName}` : 'Unknown',
      },
      {
        title: 'Requested At',
        dataIndex: 'requestedAt',
        render: date => moment(date).format('MM/DD/YYYY hh:mma'),
        key: 'requestedAt'
      }
    ];

    return (
      <div>
        <Spin
          size="large"
          spinning={itemsLoading}
          indicator={<LoadingOutlined spin />}
        >
          <div className="prescription-card-wrapper">
            <Card className="prescription-card-details">
              <Row gutter={24} className="case-patient-details">
                <Col span={24}>
                  <Title level={2}>Prescription Notifications</Title>
                </Col>
              </Row>
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                <Select 
                  defaultValue="error" 
                  onChange={setItemType}
                  value={itemType} 
                  style={{ width: '200px', marginBottom: '15px'}}
                >
                  <Option value="error">Errors</Option>
                  <Option value="request">Pharmacy Requests</Option>
                </Select>
                { itemType === 'request' && <Checkbox onClick={e => setFetchCompletedRequests(e.target.checked)}>Show completed requests</Checkbox> }
              </div>
              
              <Table
                  style={{ textTransform: 'capitalize' }}
                  pagination={{ position: ['none', 'bottom-right'] }}
                  columns={columns}
                  dataSource={items}
                  onRow={ record => {
                    return {
                      onClick: () => selectItem(record)}
                    }
                  }
              />
            </Card>
          </div>
        </Spin>
        { selectedItem && itemType === 'error' && selectedItem.errorType !== 'PATIENT DISCREPANCY ERROR' && (
          <RxErrorModal 
            selectedRxError={selectedItem}
            selectRxError={(selectItem)}
            fetchRxErrors={fetchRxErrors}
          />
        )}
         { selectedItem && itemType === 'error' && selectedItem.errorType === 'PATIENT DISCREPANCY ERROR' && (
          <PatientReconciliationModal 
            selectedRxError={selectedItem}
            selectRxError={(selectItem)}
            fetchRxErrors={fetchRxErrors}
          />
        )}
        { selectedItem && itemType === 'request' && (
          <RxRequestModal 
            provider={provider}
            selectedRxRequest={selectedItem} 
            selectRxRequest={selectItem}
            updatePrescription={updatePrescription}
            prescription={prescription}
            requestedMedications={requestedMedications}
            fetchRxRequests={fetchRxRequests}
            prescriptionIsValid={prescriptionIsValid}
            setPrescriptionIsValid={setPrescriptionIsValid}
          />
        )}
      </div>
    );
  };

  export default RxRequests
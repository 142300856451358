import React from 'react'

import { Select, Input, Button } from 'antd'
import { UserDetails } from "../components/UserDetails";
import { Prescription } from "./prescription";

const RxRequestDetails = ({
    provider,
    selectedRxRequest,
    prescription,
    ptDOBMatch,
    editRequest,
    isPriorAuth,
    priorAuthNumber,
    setPriorAuthNumber,
    reconciliationOptions,
    selectedRxForReconciliation,
    selectRxForReconciliation,
    auth,
    setAuth,
    requestedMedicationChanged
}) => {
    const { originalPrescription, requestedPrescription,  pharmacyRequestedMedications} = selectedRxRequest
    const newPrescription = requestedPrescription || pharmacyRequestedMedications.at(-1)
    const isControlledSubstance = newPrescription?.schedule?.toString !== '0'

    const formatReasonString = str => str[0].toUpperCase() + str.toLowerCase().substring(1)

    const handleSetOTPCode = ({ target }) => {
        setAuth(prev => ({ ...prev, OTP: target.value }))
    }

    const handleUseTFA = () => {
        setAuth(prev => ({ ...prev, useTFA: !auth.useTFA }))
    }

    return (
        <div
            style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
        >
            <div style={{ display: 'flex', flexDirection: 'column', width: '95%', padding: '10px', marginBottom: '20px', background: "#FAFBFC" }}>
                <div style={{ display: 'flex', gap: '40px', justifyContent: 'space-between' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', padding: '0 20px' }}>
                        <span style={{ fontWeight: 'bolder', marginBottom: '5px' }}>Requested Medication(s)</span>
                        {selectedRxRequest.reasons?.map((reason, i) => (
                            <div key={reason.name}>
                                <span style={{ fontSize: '.8rem' }}><span style={{ fontWeight: '600' }}>{`${formatReasonString(reason.name)}`} </span> {reason.note && ': ' + reason.note}</span>
                                {i !== selectedRxRequest.reasons.length - 1 && <br />}
                            </div>
                        ))}
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', width: '20%', textTransform: 'capitalize'}}>
                        <span style={{ fontWeight: 'bolder', marginBottom: '5px' }}>Status</span>
                        <span style={{ fontSize: '.8rem' }}>{selectedRxRequest.status}</span>
                    </div>
                </div>
                <hr
                    style={{
                        height: "1px",
                        width: "95%",
                        backgroundColor: "rgba(0,0,0,.2",
                        border: "none",
                    }}
                />
                <UserDetails
                    type="patient"
                    vertical
                    user={selectedRxRequest.patient}
                    ptDOBMatch={ptDOBMatch}
                    name={selectedRxRequest.patient?.firstName
                            ? `${selectedRxRequest.patient.firstName} ${selectedRxRequest.patient.lastName}`
                            : 'Unavailable'
                    }
                    style={{ minWidth: "33.33%" }}
                />
                <hr
                    style={{
                        height: "1px",
                        width: "95%",
                        backgroundColor: "rgba(0,0,0,.2)",
                        border: "none",
                    }}
                />
                <UserDetails
                    type="provider"
                    user={provider}
                    name={provider?.username || 'Unavailable'}
                    style={{ minWidth: "33.33%" }}
                />
                <hr
                    style={{
                        height: "1px",
                        width: "95%",
                        backgroundColor: "rgba(0,0,0,.2)",
                        border: "none",
                    }}
                />
                <UserDetails
                    type="pharmacy"
                    user={selectedRxRequest?.pharmacy}
                    name={selectedRxRequest?.pharmacy?.name || 'Unavailable'}
                    style={{ minWidth: "33.33%" }}
                />
            </div>
            <div style={{ width: '100%', display: 'flex', padding: '0 50px' }}>
                { originalPrescription && 
                    <Prescription 
                        prescription={originalPrescription} 
                        isEditable={selectedRxRequest.status === 'pending' && !prescription && !isPriorAuth} 
                        editRequest={editRequest} 
                        title="Original Prescription" 
                        isPriorAuth={!prescription && isPriorAuth}
                        priorAuthNumber={priorAuthNumber}
                        setPriorAuthNumber={setPriorAuthNumber}
                    />
                }
                
                {
                    !!reconciliationOptions.length && <>
                        <Prescription 
                            prescription={reconciliationOptions.find(option => option.prescriptionId === JSON.parse(selectedRxForReconciliation).prescriptionId)} 
                            isEditable={selectedRxRequest.status === 'pending' && !prescription && !isPriorAuth} 
                            editRequest={editRequest} 
                            title="Reconciled Prescription" 
                            isPriorAuth={!prescription && isPriorAuth}
                            priorAuthNumber={priorAuthNumber}
                            setPriorAuthNumber={setPriorAuthNumber}
                        >
                            <Select 
                                style={{ width: '50%', marginTop: '5px'}}
                                options={reconciliationOptions.map(option => ({
                                    label: `${option.prescriptionId.substring(3)}-${option.name}`,
                                    value: JSON.stringify(option)
                                }))}
                                value={selectedRxForReconciliation}
                                onChange={selectRxForReconciliation}
                            />
                        </Prescription>
                    </>
                }
                { newPrescription && 
                    <Prescription prescription={prescription} 
                        isEditable={(selectedRxRequest.status === 'pending' && !isPriorAuth) && !(isControlledSubstance && selectedRxRequest.type.toLowerCase() === 'rx change')}
                        editRequest={editRequest} 
                        title="New Prescription" 
                        isPriorAuth={isPriorAuth}
                        priorAuthNumber={priorAuthNumber}
                        setPriorAuthNumber={setPriorAuthNumber}
                    />
                }
            </div>
            { requestedMedicationChanged && isControlledSubstance && (
                     <div style={{ width: '400px', display: 'flex', flexDirection: 'column', marginTop: '30px'}}>
                        <p>Please input your two-factor authentication security code from your DUO mobile application OR send a push notification to DUO</p>
                        <div style={{ display: 'flex', gap: '10px', justifyContent: 'center' }}>
                            { !auth.useTFA && (
                                <span>
                                    TFA Code:
                                    <Input
                                        style={{ width: '75px', margin: '0 10px' }}
                                        maxLength={4}
                                        size="small"
                                        label="TFA"
                                        value={auth.OTP}
                                        onChange={handleSetOTPCode}
                                    />
                                    or
                                </span>
                            )}   
                            <Button type={auth.useTFA ? 'primary' : null} size="small" onClick={handleUseTFA}>{`Request${auth.useTFA ? 'ing' : ''} DUO push`}</Button>
                        </div>
                    </div>
                )}
        </div>
    )
}

export default RxRequestDetails
import React from 'react'
import { Form } from 'antd'

import { 
    CheckSquareOutlined,
    CloseCircleOutlined 
} from '@ant-design/icons'

import { useTreatmentForm } from './hooks/useTreatmentForm'

import { TreatmentFormProvider } from './TreatmentFormContext'

import { TemplateSelect } from './components/TemplateSelect'
import { MedicationSearch } from './components/MedicationSearch'
import { StrengthSelector } from './components/StrengthSelector'
import { QuantityInput } from './components/QuantityInput'
import { DispenseUnitSelector } from './components/DispenseUnitSelector'
import { FormColumnSpacer } from './components/FormColumnSpacer'
import { RefillsInput } from './components/RefillsInput'
import { DaysSupplyInput } from './components/DaysSupplyInput'
import { StartDatePicker } from './components/StartDatePicker'
import { SigTextArea } from './components/SigTextArea'
import { PrescriptionOptions } from './components/PrescriptionOptions'
import { StyledButton } from '../../../../../../Button'

import {
    StyledForm,
    ButtonGroup
} from './Treatment.Form.styles'

export const TreatmentForm = ({
    mode,
    deselectPrescription,
    bypassValidation,
}) => {

    const {
        form,
        handleFinish,
        handleFinishFailed,
        handleFieldsChange,
        templates,
        selectedTemplate,
        handleSelectTemplate,
        selectStrength,
        medicationSearchState,
        fetchMedications,
        selectMedication,
        showPharmacyNotesTextArea,
        setShowPharmacyNotesTextArea
    } = useTreatmentForm({
        deselectPrescription
    })

    const handleCancel = e => {
        e.preventDefault()
        form.resetFields()
        setShowPharmacyNotesTextArea(false)
        deselectPrescription()
    }

    return (
        <TreatmentFormProvider value={{
            form,
            bypassValidation,
            medicationSearchState,
            handleFieldsChange
        }}>
            <StyledForm
                form={form}
                layout="vertical"
                onFinish={handleFinish}
                onFinishFailed={handleFinishFailed}
            >
                {
                    mode === 'add' && (
                        <TemplateSelect
                            templates={templates}
                            selectedTemplate={selectedTemplate}
                            handleSelectTemplate={handleSelectTemplate}
                        />
                    )
                }
                <MedicationSearch
                    fetchMedications={fetchMedications}
                    handleSelectMedication={selectMedication}
                />
                <Form.Item rules={[{ required: true }]}>
                    <StrengthSelector
                        handleSelectStrength={selectStrength}
                    />
                    <FormColumnSpacer />
                    <QuantityInput/>
                </Form.Item>
                <Form.Item>
                    <DispenseUnitSelector/>
                    <FormColumnSpacer />
                    <RefillsInput />
                </Form.Item>
                <Form.Item>
                    <DaysSupplyInput />
                    <FormColumnSpacer />
                    <StartDatePicker/>
                </Form.Item>
                <SigTextArea />
                <PrescriptionOptions
                     showPharmacyNotesTextArea={showPharmacyNotesTextArea}
                     setShowPharmacyNotesTextArea={setShowPharmacyNotesTextArea}
                />
                <Form.Item hidden name="ndc" />
                <Form.Item hidden name="dispensableDrugId" />
                <Form.Item hidden name="ingredients" />
                <Form.Item hidden name="dispenseUnitId" />
                <Form.Item hidden name="schedule" />
                <Form.Item hidden name="_id" />
                <Form.Item hidden name="manual" />
                <ButtonGroup>
                    <StyledButton 
                        size='medium'
                        htmlType="button"
                        Icon={CloseCircleOutlined}
                        color='red'
                        onClick={handleCancel}
                    />
                    <StyledButton 
                        size='medium'
                        htmlType="submit"
                        Icon={CheckSquareOutlined}
                    />
                </ButtonGroup>
            </StyledForm>
        </TreatmentFormProvider>
    )
}
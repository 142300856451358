import types from "./types";
import API from "../../axios/physician/PhysicianApi";
import handleAPIErrorResponse from "../../utils/handleAPIResponseError";

import { message } from "antd";

const setArticleListLoading = (status) => ({
  type: types.SET_ARTICLE_LIST_LOADING,
  data: status,
});
const setArticleList = (payload, count) => ({
  type: types.SET_ARTICLE_LIST,
  payload,
  count,
});

const setViewArticleLoading = (status) => ({
  type: types.SET_VIEW_ARTICLE_LOADING,
  data: status,
});

const setViewArticle = (payload) => ({
  type: types.SET_VIEW_ARTICLE,
  payload,
});

const setCaseCategoryId = (id) => ({
  type: types.SET_CASE_CATEGORY_ID,
  id,
});

function getArticleList(status) {
  return (dispatch) => {
    dispatch(setArticleListLoading(true));
    API.getLmsArticleList(status)
      .then((res) => {
        const { data } = res;
        if (data && data.success) {
          const { payload } = data;
          if (payload.data) {
            const mappedData = payload.data.map((item) => {
              return {
                ...item,
                id: item._id,
                title: item.title,
                articleCategory: item.articleCategory,
                teleMedicineCategory: item.teleMedicineCategory,
                description: item.description,
                teleMedicineCategoryName: item.teleMedicineCategoryName,
                articleCategoryName: item.articleCategoryName,
              };
            });
            const mappedCount = payload.count;
            dispatch(setArticleList(mappedData, mappedCount));
          }
        } else {
          message.warning("Unable to list Articles");
        }
        dispatch(setArticleListLoading(false));
      })
      .catch((error) => {
        dispatch(setArticleList([], 0));
        handleAPIErrorResponse(error, "Error occured while listing Articles");
        dispatch(setArticleListLoading(false));
      });
  };
}

function getViewArticle(id) {
  return (dispatch) => {
    dispatch(setViewArticleLoading(true));
    API.viewLmsArticle(id)
      .then((res) => {
        const { data } = res;
        if (data && data.payload) {
          dispatch(setViewArticle(data.payload));
        } else {
          message.warning("Unable to view article");
        }
        dispatch(setViewArticleLoading(false));
      })
      .catch((error) => {
        handleAPIErrorResponse(error, "Error occured while listing Articles");
        dispatch(setViewArticleLoading(false));
      });
  };
}

const actions = {
  setArticleListLoading,
  getArticleList,
  setArticleList,
  setViewArticleLoading,
  getViewArticle,
  setViewArticle,
  setCaseCategoryId,
};

export default actions;

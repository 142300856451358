import React, { createContext, useContext } from 'react';
import { useDispenseUnits } from '../../../hooks/useDispenseUnits';

const DispenseUnitContext = createContext();

export const useDispenseUnitContext = () => {
    const context = useContext(DispenseUnitContext);
    if (!context) {
        throw new Error("useDispenseUnitContext must be used within a DispenseUnitProvider");
    }
    return context;
}

export const DispenseUnitProvider = ({ children, value }) => {
    const { dispenseUnits, getDispenseUnitById, dispenseUnitsLoading } = useDispenseUnits()
    return (
            <DispenseUnitContext.Provider value={{ dispenseUnits, getDispenseUnitById, dispenseUnitsLoading }}>
                {children}
            </DispenseUnitContext.Provider>
        
    );
};

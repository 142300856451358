import types from "./types";
import API from "../../axios/physician/PhysicianApi";
import handleAPIErrorResponse from "../../utils/handleAPIResponseError";

const setOtherPhysiciansCaseCountLoading = (status) => ({
    type: types.SET_OTHER_PHYSIANS_CASE_COUNT_LOADING,
    data: status,
});

const setOtherPhysiciansCaseListLoading = (status) => ({
    type: types.SET_OTHER_PHYSICIANS_CASE_LIST_LOADING,
    data: status,
});

const setOtherPhysiciansCaseList = cases => ({
    type: types.SET_OTHER_PHYSICIANS_CASE_LIST,
    data: cases
})

const setOtherPhysiciansCaseCount = count => ({
    type: types.SET_OTHER_PHYSICIANS_CASE_COUNT,
    data: count
})

const removeCaseFromOPC = caseId => ({
    type: types.REMOVE_CASE_FROM_OPC,
    data: caseId
})

function getOtherPhysiciansCases(params) {
    return (dispatch) => {
        dispatch(setOtherPhysiciansCaseListLoading(true));
        API.getOtherPhysiciansCases(params).then(
            (res) => {
                if (!res.data?.success) throw new Error()
                dispatch(setOtherPhysiciansCaseList(res.data.cases))
                dispatch(setOtherPhysiciansCaseCount(res.data.count))
            })
            .catch(error => handleAPIErrorResponse(error, "Unable to update other physicians' cases"))
            .finally(() => dispatch(setOtherPhysiciansCaseListLoading(false)))
    };
}

function getOtherPhysiciansCaseCount(params) {
    return (dispatch) => {
        dispatch(setOtherPhysiciansCaseCountLoading(true));
        API.getOtherPhysiciansCaseCount(params).then(
            res => {
                const { data } = res;
                if (!data?.success) throw new Error()
                dispatch(setOtherPhysiciansCaseCount(data.count))
            })
            .catch(error => handleAPIErrorResponse(error, "Unable to get other physicians' case count"))
            .finally(() => dispatch(setOtherPhysiciansCaseCountLoading(false)))
    };
}

const actions = {
    getOtherPhysiciansCases,
    getOtherPhysiciansCaseCount,
    removeCaseFromOPC
};

export default actions;

import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Form } from 'antd'

import moment from 'moment-timezone'

import { usePrescriptionTemplates } from '../../../../hooks/usePrescriptionTemplates'
import { useMedicationSearch } from './useMedicationSearch'

import prescribeMedicine from '../../../../../../../../../../redux/prescribeMedicine'

import { useDispenseUnitContext } from '../../../../context/DispenseUniteContext'

export function useTreatmentForm({
    deselectPrescription,
}) {
    const dispatch = useDispatch()
    const [form] = Form.useForm()

    const [showPharmacyNotesTextArea, setShowPharmacyNotesTextArea] = useState(false)

    const { selectedPrescription } = useSelector(state => state.prescribeMedicine)

    const {
        searchTemplates,
        templates,
        selectedTemplate,
        selectTemplate,
        resetTemplateSearch
    } = usePrescriptionTemplates({
        form,
        setShowPharmacyNotesTextArea
    })

    const {
        medicationSearchState,
        selectMedication,
        selectStrength,
        handleSelectTemplate,
        resetMedicationSearch,
        handleResetMedication,
        fetchMedications,
    } = useMedicationSearch({
        form,
        selectTemplate,
        searchTemplates,
    })

    const { getDispenseUnitById } = useDispenseUnitContext()
    
    const handleFieldsChange = async (e) => {
        const clearSigForUpdatedFields = [
            'name',
            'strength',
        ]

        if (clearSigForUpdatedFields.includes(e?.target?.name)) {
            selectTemplate(null)
            form.setFieldsValue({ directions: '', pharmacyNotes: '' })
        }

        if (e?.target?.name === 'providePharmacyNotes' && !e?.target?.checked) {
            form.setFieldsValue({ pharmacyNotes: '' })
        }
    }

    const resetPrescribeFlow = () => {
        form.resetFields()
        resetMedicationSearch()
        resetTemplateSearch()
    }

    const isPrescriptionMetadataValid = prescription => !!prescription.ingredients?.length || !!prescription.dispensableDrugId

    const handleFinish = async (values) => {
        const isRxValid = isPrescriptionMetadataValid(values)

        const newPrescription = {
            ...(selectedPrescription || {}), 
            ...values, 
            valid: isRxValid, 
            mismatch: false, 
            _id: selectedPrescription?._id || Date.now(),

        }
        
        dispatch(prescribeMedicine.actions.saveQueuedPrescription(newPrescription))
        
        setShowPharmacyNotesTextArea(false)
        deselectPrescription()
        resetPrescribeFlow()
    }

    const handleFinishFailed = ({ errorFields, values }) => {
        const mismatches = errorFields.filter(field => field.errors.includes('Mismatch'));
        const nonMismatchErrors = errorFields.filter(field => field.errors.filter(msg => msg !== 'Mismatch').length > 0);
        if (!nonMismatchErrors.length) {

            const newPrescription = {
                ...(selectedPrescription || {}), 
                ...values, 
                valid: !nonMismatchErrors.length, 
                mismatch: mismatches.length > 0, 
                _id: selectedPrescription?._id || Date.now(),
            }

            dispatch(prescribeMedicine.actions.saveQueuedPrescription(newPrescription))

            setShowPharmacyNotesTextArea(false)
            deselectPrescription()
            resetPrescribeFlow()
        }
    }

    useEffect(() => {
        if (selectedPrescription) {
            const prescriptionDispenseUnit = getDispenseUnitById(selectedPrescription.dispenseUnitId)
            form.setFieldsValue({
                ...selectedPrescription,
                quantityUnits: prescriptionDispenseUnit?.Name,
                startDate: moment(selectedPrescription.startDate),
            })

            setShowPharmacyNotesTextArea(selectedPrescription.pharmacyNotes)
        }
        return () => form.resetFields()
    }, [selectedPrescription])

    return { 
        form,
        handleFieldsChange,
        resetPrescribeFlow,
        handleFinish,
        handleFinishFailed,
        handleResetMedication,
        templates,
        selectedTemplate,
        handleSelectTemplate,
        selectStrength,
        medicationSearchState,
        fetchMedications,
        selectMedication,
        showPharmacyNotesTextArea,
        setShowPharmacyNotesTextArea
    };
}

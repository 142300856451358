import React, { useEffect, useContext } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Table, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

import referrals from "../../redux/referrals";

// commons
import { userConstants } from "../../constants";
import { history } from "../../helpers";
import { sorters } from "../../utils/sorters";

import { TablePreferencesContext } from "../../context/TablePreferencesProvider";

const TABLE_NAME = "Referral Cases";

const Referrals = ({ 
  actions, 
  referralCases, 
  referralCasesCount, 
  referralCasesLoading,
}) => {

  const { getTablePreferences } = useContext(
    TablePreferencesContext
  );
  
  const { currentPage, tablePageSize } = getTablePreferences(TABLE_NAME);

  const columns = [
    {
      title: "Case No",
      dataIndex: "caseId",
      key: "caseno",
      sorter: (a, b) => sorters.alphanumeric(a.caseId, b.caseId),
    },
    {
      title: "Referring Physician",
      dataIndex: "physicianName",
      key: "physicianName",
      sorter: (a, b) => sorters.alphanumeric(a.physicianName, b.physicianName),
    },
    {
      title: "Patient",
      dataIndex: "patientName",
      key: "name",
      sorter: (a, b) => sorters.alphanumeric(a.patientName, b.patientName),
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "categoryname",
      sorter: (a, b) => sorters.alphanumeric(a.categoryName, b.categoryName),
    },
  ];

  useEffect(() => {
    actions.getReferralCases()
    // eslint-disable-next-line
  }, []);

  const pushRouteCaseDetails = (caseId) => {
    history.push(
      `/dashboard/${userConstants.USER_PHYSICIAN}/caseview/${caseId}`,
      { from: 'referrals'}
    );
  };

  const handleCaseListClick = (event, record) => {
    event.stopPropagation();
    pushRouteCaseDetails(record.caseId);
  };

  return (
    <div className="consulted-case">
      <div className="waiting-room-case-list-part-heading">
        <div>
          <h1 style={{ marginBottom: "0" }}>Referral Cases</h1>
          <h4>Cases not Prescribed, for Review</h4>
        </div>
      </div>
      <div className="consulted-case-no-of-patient-list-data">
        <Spin
          size="large"
          spinning={referralCasesLoading}
          indicator={<LoadingOutlined spin />}
        >
          <div>
            <Table
              className="wating-room-table"
              columns={columns}
              dataSource={[...referralCases]}
              pagination={{
                total: referralCasesCount,
                showTotal: (total) =>
                  `Total ${total} Referral Case${total > 1 ? "s" : ""}`,
                pageSizeOptions: ["5", "10", "20", "50", "100"],
                showSizeChanger: true,
                current: currentPage,
                pageSize: tablePageSize,
              }}
              scroll={{ x: 900 }}
              onRow={(record, rowIndex) => {
                return {
                  onClick: (event) =>
                    handleCaseListClick(event, record, rowIndex),
                };
              }}
            />
          </div>
        </Spin>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { 
    referralCases, 
    referralCasesCount, 
    referralCasesLoading,
    caseReviewModalVisible
  } =
    state.referrals;

  return {
    referralCases,
    referralCasesCount,
    referralCasesLoading,
    caseReviewModalVisible
  };
};

const mapDispatchToProps = (dispatch) => {
  const { 
    getReferralCases
  } = referrals.actions;
  return {
    actions: bindActionCreators(
      {
        getReferralCases,
      },
      dispatch
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Referrals);
import React from "react";

import { Modal } from "antd";
import PrescriptionTemplateForm from "./PrescriptionTemplateForm";

export const SaveTemplateModal = ({ 
  rxIndexForTemplate,
  setRxIndexForTemplate
}) => {

  const handleCancel = () => setRxIndexForTemplate(null)

  return (
    <Modal
      title="Save Prescription Template"
      visible={rxIndexForTemplate !== null}
      onCancel={handleCancel}
      footer={null}
      destroyOnClose
    >
      <PrescriptionTemplateForm 
        handleCancel={handleCancel}
        rxIndexForTemplate={rxIndexForTemplate}
        setRxIndexForTemplate={setRxIndexForTemplate}
      />
    </Modal>
  );
};
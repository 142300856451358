import Axios from "../Axios";
import config from "../../config";

const { api } = config;

export default class API {
  static getToken() {
    const token = window.localStorage.getItem("token");
    return token;
  }

  static retrieveCaseReport(params, axiosConfig = {}) {
    const token = this.getToken();
    return Axios({
      url: `${api.baseUrl}/v2/reports/case`,
      method: "GET",
      params,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      ...axiosConfig,
    });
  }

  static retrieveConsultReport(params, axiosConfig = {}) {
    const token = this.getToken();
    return Axios({
      url: `${api.baseUrl}/v2/reports/consult-type`,
      method: "GET",
      params,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      ...axiosConfig,
    });
  }


  static retrieveCompletionCounts(params, axiosConfig = {}) {
    const token = this.getToken();
    return Axios({
      url: `${api.baseUrl}/v2/reports/physician-case-completion-counts?`,
      method: "GET",
      params,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      ...axiosConfig,
    });
  }

  static getPhysicianMonthlyReports(params) {
    const token = this.getToken();
    return Axios({
      url: `${api.baseUrl}/v2/physician/reports?reportMonth=${params.reportMonth}&reportYear=${params.reportYear}`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }
}

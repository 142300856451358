import axios from "axios";
import config from "../config";

const { api } = config;

const Axios = axios.create({
  baseURL: api.baseUrl,
});

export default Axios;

import React from "react";
import { Card, Spin, Popconfirm, message } from "antd";

import { history } from "../../../helpers";
import isValidHttpUrl from '../../../utils/isValidHttpUrl';

import {
    VideoCameraFilled,
    AudioFilled,
    LoadingOutlined,
    QuestionCircleOutlined
} from "@ant-design/icons";

import NavaMDLogo from "../../../assets/images/brands/navamd_logo.svg";
import RexMDLogo from "../../../assets/images/brands/rexmd_logo.svg";
import ShapiroLogo from "../../../assets/images/brands/shapiro_logo.svg";

const getBrandImageByName = (name) => {
    switch (name) {
        case 'Rex MD':
            return (<img className='rexmd-logo-small' alt='RexMD brand logo' src={RexMDLogo} />);
        case 'Nava MD':
            return (<img className='navamd-logo-small' alt='NavaMD brand logo' src={NavaMDLogo} />)
        case 'Shapiro MD':
            return (<img className='rexmd-logo-small' alt='ShapiroMD brand logo' src={ShapiroLogo} />)
        default:
            return '';
    }
}

const moment = require("moment");

const WaitingRoom = (props) => {
    const { title, loading, appointments, handleOnClick } = props;

    return (
        <>
            <h1>{title}</h1>
            <Spin
                spinning={loading}
                size="large"
                indicator={<LoadingOutlined spin />}
            >
                <div>
                    {appointments && appointments.length === 0 ? (
                        <div className="waitingroom-container-background">
                            <div className="waitingroom-container-text">
                                <p>0 upcoming appointments</p>
                            </div>
                        </div>
                    ) : (
                        <div className="waiting-room-card-part">
                            <>
                                {appointments.map((appointment) => {
                                    return (
                                        <Card
                                            className="waitingroom-schedule-list-card"
                                            hoverable
                                            onClick={event => handleOnClick(event, appointment.caseId)}
                                        >
                                            <div className="waitingroom-start-time">{moment(appointment.startTime).format('h:mma')}</div>
                                            <div className="waitingroom-schedule-video-audio">
                                                <h3>{appointment.patientName}</h3>
                                            </div>
                                            <p className="waitingroom-schedule-video-audio-casid-p">{appointment.caseId}</p>
                                            <p>{appointment.category}</p>
                                            <p>{appointment.state}</p>
                                            <div className="waitingroom-schedule-video-audio">
                                            <div className="waitingroom-meeting-link-container">
                                                    <Popconfirm
                                                        title={appointment.consultationType === 'audio' ? 'Call patient?' : 'Go to meeting?'}
                                                        icon={<QuestionCircleOutlined style={{ color: '#7bad7b' }} />}
                                                        onClick={e => e.stopPropagation()}
                                                        onCancel={e => e.stopPropagation()}
                                                        onConfirm={e => {
                                                            e.stopPropagation();

                                                            if (appointment.consultationType === 'video') {
                                                                if (isValidHttpUrl(appointment.meetingLink)) {
                                                                    window.open(appointment.meetingLink, "_blank");
                                                                } else {
                                                                    message.error('Invalid meeting link URL')
                                                                }
                                                            } else {
                                                                history.push(`/case-phone-call/${appointment.caseId}`)
                                                            }
                                                        }}
                                                    >
                                                        <div>
                                                        {appointment.consultationType === "audio" ? (<AudioFilled />) : (<VideoCameraFilled />)}
                                                            { /*  eslint-disable-next-line */ }
                                                            <a className="waitingroom-meeting-link" href="#">
                                                                {appointment.consultationType === 'video' ? 'Join meeting' : appointment.patientPhoneNumber}
                                                            </a>
                                                        </div>
                                                    </Popconfirm>
                                                </div>
                                                <div>{appointment.brand && getBrandImageByName(appointment.brand)}</div>
                                            </div>
                                        </Card>
                                    );
                                })}
                            </>
                        </div>
                    )}
                </div>
            </Spin>
        </>
    );
};

export default WaitingRoom;

import React, { useState, useEffect, useContext, useMemo, useCallback } from "react";
import "./RxChangeRequests.css";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { LoadingOutlined, BellOutlined } from "@ant-design/icons";
import { Table, Modal, Spin, Typography, message } from "antd";

// import components
import SearchAndFilterCases from "../../components/SearchAndFilterCases/SearchAndFilterCases";

// import API functions
import PhysicianApi from "../../axios/physician/PhysicianApi";
import rxChange from "../../redux/rxChange";

// import common functions
import { userConstants } from "../../constants";
import { TablePreferencesContext } from '../../context/TablePreferencesProvider';
import { getTextFieldSorter, getDateFieldSorter, getSortOrder } from '../../utils/sorters';
import { dateTimeFormatter } from "../../utils/dateFormatter";
import { createCaseFilterQueryParamMap, createTableQueryParamMap, createTableSortByMap } from "../../utils/createCaseFilterQueryParamMap";
import { CaseFilterMap, CaseQueryParamMap } from "../../constants/CaseFilter"

const { Text } = Typography;

const TABLE_NAME = 'rx change';

const hideFields = Object.freeze([CaseFilterMap.PHYSICIAN_ID]);

const TableDataMap = {
  CASE_ID: CaseFilterMap.CASE_ID,
  PATIENT_NAME: `${CaseFilterMap.PATIENT_FIRST_NAME} ${CaseFilterMap.PATIENT_LAST_NAME}`,
  COMPLETED_AT: 'completedAt',
  CONSULTATION_START: 'consultationStart',
  UNREAD_CHAT_NOTIFICATION: 'unreadChatNotification',
  TELEMEDICINE_CATEGORY_ID: CaseFilterMap.TELEMEDICINE_CATEGORY_ID,
  CREATED_DATE: CaseFilterMap.CREATED_DATE,
}

const TableSortByMap = createTableSortByMap(TableDataMap);

const getTableColumns = (sortOrder, sortBy) => {
  const columns = [
      {
          title: "Case No",
          key: TableDataMap.CASE_ID,
          onCell: () => ({
            onClick: event => event.stopPropagation()
          })
      },
      {
          title: "Patient Name",
          key: TableDataMap.PATIENT_NAME,
      },
      {
          title: "Renewal Submitted",
          key: TableDataMap.COMPLETED_AT,
      },
      {
          title: "Started",
          key: TableDataMap.CONSULTATION_START,
      },
      {
          title: <BellOutlined />,
          key: TableDataMap.UNREAD_CHAT_NOTIFICATION,
      },
      {
          title: "Category",
          key: TableDataMap.TELEMEDICINE_CATEGORY_ID,
      },
      {
          title: "Case Created",
          key: TableDataMap.CREATED_DATE,
      },
  ];

  columns.forEach((c) => {
      c.dataIndex = c.key;
      if ([TableDataMap.CASE_ID].includes(c.key)) {
        return
      }
      c.sortOrder = getSortOrder(sortOrder, sortBy, c.key);
      const isDateField = [TableDataMap.CREATED_DATE, TableDataMap.COMPLETED_AT].includes(c.key);
      c.sorter = isDateField ? getDateFieldSorter(c.key) : getTextFieldSorter(c.key);
  });

  return columns;
}

const defaultPreferences = { currentPage: 1, tablePageSize: 100, sortOrder: 'descend' }

const UpdatePrescribe = (props) => {
  const {
    actions,
    rxChangeCaseList,
    rxChangeCaseCount,
    rxChangeCaseListLoading,
    rxChangeCaseNotification,
    unassignedCaseNotification,
    telemedicineCategoriesList,
    statesList,
  } = props;
  const history = useHistory();
  const [showModal, setShowModal] = useState(false)
  const [
    consultStartConfirmationModal,
    setConsultStartConfirmationModal,
  ] = useState(false);
  const [caseForConsultationStart, setCaseForConsultationStart] = useState({});
  const [modalButtonLoading, setModalButtonLoading] = useState(false);
  const [searchValue, setSearchValue] = useState(null);
  const [caseListFilter, setCaseListFilter] = useState({ status: 'rx change' });
  const { getTablePreferences, updateTablePreferences } = useContext(TablePreferencesContext);
  const { currentPage, tablePageSize, sortOrder, sortBy } = getTablePreferences(TABLE_NAME, defaultPreferences);

  const columns = useMemo(() => getTableColumns(sortOrder, sortBy), [sortOrder, sortBy]);

  const fetchCases = useCallback(() => {
    const newTablePreferences = {
      currentPage,
      tablePageSize,
      sortOrder,
      sortBy: TableSortByMap[sortBy] || sortBy,
    };
    const tableFilter = createTableQueryParamMap(newTablePreferences);
    const caseFilterMap = createCaseFilterQueryParamMap(caseListFilter, hideFields);
    if (searchValue !== null) {
      caseFilterMap[CaseQueryParamMap.SEARCH_TERM] = searchValue;
    }
    actions.getRxChangeCaseList({ ...tableFilter, ...caseFilterMap });
  }, [currentPage, tablePageSize, searchValue, caseListFilter, sortOrder, sortBy, actions]);

  useEffect(() => {
    fetchCases();
  }, [fetchCases, rxChangeCaseNotification, unassignedCaseNotification]);

  const handleConsultStartCancellation = (event) => {
    event.stopPropagation();
    setCaseForConsultationStart({});
    setConsultStartConfirmationModal(false);
    message.warn(
      "Sorry, Please start consultation to move to the follow-up case details"
    );
  };

  const handleConsultationStartError = (messageString) => {
    setModalButtonLoading(false);
    setConsultStartConfirmationModal(false);
    message.error(messageString);
  };

  const handleConsultStartAccept = (event) => {
    event.stopPropagation();
    const data = {
      caseId: caseForConsultationStart.caseId,
      consultationStart: true,
    };
    setModalButtonLoading(true);
    PhysicianApi
      .startConsultation(data)
      .then((res) => {
        if (res.data.success) {
          pushRouteCaseDetails(caseForConsultationStart.caseId);
        } else {
          const errorMessage = res.data?.message || "Unable to Start Consult. Please try again later!";
          handleConsultationStartError(errorMessage);
        }
      })
      .catch((err) => {
        const errorMessage = err?.response?.data?.message || "Error occured while Starting Consultation. Please try again later!";
        handleConsultationStartError(errorMessage);
      });
    };

  const pushRouteCaseDetails = (caseId) => {
    history.push(
      `/dashboard/${userConstants.USER_PHYSICIAN}/caseview/${caseId}`,
      { from: 'updateprescribe' }
    );
  };

  const handleCaseListClick = (event, record) => {
    event.stopPropagation();
    if (!record.consultationStart) {
      setCaseForConsultationStart(record);
      setConsultStartConfirmationModal(true);
    } else {
      pushRouteCaseDetails(record.caseId);
    }
  };

  const handleOk = (filterData) => {
    setCaseListFilter(filterData);
    setShowModal(false);
  };

  const searchCase = (value) => {
    setSearchValue(value);
  };

  const handleCancel = () => {
    setCaseListFilter({});
    setShowModal(false);
  };

  const handleCancelCross = () => {
    setShowModal(false);
  };

  const onChangePage = (page) => {
    updateTablePreferences(TABLE_NAME, { currentPage: page })
  };

const onPageSizeChange = (_, size) => {
  updateTablePreferences(TABLE_NAME, { currentPage: 1, tablePageSize: size })
};

  const onChangeOrder = (_pagination, _filters, sorter) => {
    updateTablePreferences(TABLE_NAME, {
      sortOrder: sorter.order || 'ascend',
      sortBy: sorter.field,
    });
}

const casesForTable = rxChangeCaseList.map((c) => {
    return {
      key: c.caseId,
      rowKey: c.caseId,
      [TableDataMap.CASE_ID]: c.caseId,
      [TableDataMap.PATIENT_NAME]: c.patientName,
      [TableDataMap.COMPLETED_AT]: c.completedAt ? dateTimeFormatter(c.completedAt) : '',
      [TableDataMap.CONSULTATION_START]: c.consultationStart ? 'yes' : 'no',
      [TableDataMap.UNREAD_CHAT_NOTIFICATION]: c.unreadChatNotification,
      [TableDataMap.TELEMEDICINE_CATEGORY_ID]: c.category,
      [TableDataMap.CREATED_DATE]: dateTimeFormatter(c.createdDate),
    }
})

  return (
    <div className="consulted-case">
      <div className="waiting-room-case-list-part-heading">
        <h1>Rx Change Requests</h1>

        <SearchAndFilterCases
          searchCase={searchCase}
          showModal={showModal}
          setShowModal={setShowModal}
          handleCancelCross={handleCancelCross}
          handleCancel={handleCancel}
          handleOk={handleOk}
          telemedicines={telemedicineCategoriesList}
          states={statesList}
          hideFields={hideFields}
        />
      </div>
      <div className="consulted-case-no-of-patient-list-data">
        <Spin
          size="large"
          spinning={rxChangeCaseListLoading}
          indicator={<LoadingOutlined spin />}
        >
          <div>
            <Table
              className="wating-room-table"
              columns={columns}
              dataSource={casesForTable}
              onChange={onChangeOrder}
              pagination={{
                total: rxChangeCaseCount,
                showTotal: (totalCase) => `Total ${totalCase} Cases`,
                current: currentPage,
                pageSize: tablePageSize,
                pageSizeOptions: ["5", "10", "20", "50", "100"],
                showSizeChanger: true,
                onChange: onChangePage,
                onShowSizeChange: onPageSizeChange,
              }}
              scroll={{ x: 900 }}
              onRow={(record, rowIndex) => {
                return {
                  onClick: (event) => {
                    handleCaseListClick(event, record, rowIndex);
                  },
                };
              }}
            />
          </div>
        </Spin>
        <Modal
          title="Please confirm Consultation Start!"
          okText="Start Consultation"
          closable={false}
          maskClosable={false}
          centered
          visible={consultStartConfirmationModal}
          okButtonProps={{
            loading: modalButtonLoading,
          }}
          cancelButtonProps={{
            loading: modalButtonLoading,
          }}
          onOk={(event) => {
            handleConsultStartAccept(event);
          }}
          onCancel={(event) => {
            handleConsultStartCancellation(event);
          }}
        >
          <Text strong>
            You haven't considered this case for Prescription Update yet. Please
            confirm that you are starting the follow-up consult for this case
          </Text>
        </Modal>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { rxChangeCaseList, rxChangeCaseListLoading, rxChangeCaseCount } = state.rxChange;
  const {
    rxChangeCaseNotification,
    unassignedCaseNotification,
  } = state.notifications;
  const { statesList, telemedicineCategoriesList } = state.entities;
  return {
    rxChangeCaseList,
    rxChangeCaseCount,
    rxChangeCaseNotification,
    rxChangeCaseListLoading,
    unassignedCaseNotification,
    telemedicineCategoriesList,
    statesList,
  };
};

const mapDispatchToProps = (dispatch) => {
  const { getRxChangeCaseList } = rxChange.actions;
  return {
    actions: bindActionCreators(
      {
        getRxChangeCaseList,
      },
      dispatch
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UpdatePrescribe);

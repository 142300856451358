import styled from 'styled-components'
import { Form, Spin, Select, AutoComplete } from 'antd'

export const FormContainer = styled.div`
    width: 50%;
    padding: 0 60px;
    display: flex;
    justify-content: center;
`

export const StyledForm = styled(Form)`
    text-transform: capitalize;
    width: 100%;
`

export const StyledFormItem = styled(Form.Item)`
    & label {
        width: 100%;
    }
`

export const HeaderContainer = styled.div`
    display: flex;
    width: 100%;
    justify-content: flex-end;
`
export const Content = styled.div`
    display: flex;
    justify-content: flex-end;
    gap: 5px;
`

export const TitleContainer = styled.div`
    margin-top: ${({ isFirstMedication}) => isFirstMedication && '50px'};
    display: flex;
    justify-content: space-between;
`

export const TemplatesContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 10px 0;
`

export const StyledSpin = styled(Spin)`
    width: 100% !important;
`

export const StyledSelect = styled(Select)`
    & .ant-select-arrow {
        color: inherit;
    }
`

export const StyledAutoComplete = styled(AutoComplete)`
    & .ant-select-arrow {
        color: inherit;
    }
`
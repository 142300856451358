import React from 'react'
import moment from 'moment-timezone'
import { Typography } from 'antd'
import { formatTenDigitPhoneNumber } from '../../../utils/phoneFormatter'

const { Text } = Typography

const Detail = ({ header, children }) => (
    <div style={{ display: 'flex', flexDirection: 'column', width: '100%'}}>
        { header && <Text style={{ marginRight: '10px', fontSize: '.75rem', textTransform: 'capitalize' }}>{header}</Text> }
        { children }
    </div>
)

export const UserDetails = ({ type, user, name, style }) => {
    return (
        <div style={{ 
            display: 'grid', 
            gridTemplateColumns: '20% 22.5% 22.5% 17.5% 17.5%',
            background: '#FAFBFC',
            justifyContent: 'left',
            minWidth: '800px',
            padding: '0 20px',
            ...style
        }}>
    
            <>
                <Detail header={type}>
                    <div style={{ fontWeight: 'bolder', fontSize: '.75rem'}}>
                        {name}
                        { user.deaNumber && <div style={{ fontWeight: 'bolder', fontSize: '.75rem'}}>DEA Number: {user.deaNumber}</div> }
                    </div>
                </Detail>
                { user.address1 && (
                    <Detail header='Address'>
                        <div style={{ fontWeight: 'bolder', fontSize: '.75rem'}}>{user.address1}</div>
                        { user.address2 && <div style={{ maxWidth: '180px', fontWeight: 'bolder', fontSize: '.75rem'}}>{user.address2}</div> }
                        <div style={{ fontWeight: 'bolder', fontSize: '.75rem'}}> {`${user.city}, ${user.stateName || user.state} ${user.zipCode}`}</div>
                    </Detail>
                ) }
                { (user.phone || user.email ) && (
                    <Detail header='Contact Info'>
                        { user.phone && <div style={{ fontWeight: 'bolder', fontSize: '.75rem'}}>{`${formatTenDigitPhoneNumber(user.phone?.replace(/\D/g , ''))}`}</div> }
                        { user.email && <div style={{ fontWeight: 'bolder', fontSize: '.75rem'}}> {user.email}</div> }
                    </Detail>
                )} 
                {
                    user.gender && (
                        <Detail header='Gender/DOB'>
                            <div style={{ fontWeight: 'bolder', fontSize: '.75rem'}}>{`${user.gender.toUpperCase()},  ${moment(user.dob).utc().format('MM-DD-YYYY')}`}</div>
                        </Detail> 
                    )
                }
                {
                    user.heightFt && (
                        <Detail header='Height/Weight'>
                            <div style={{ fontWeight: 'bolder', fontSize: '.75rem'}}>{`${user.heightFt}ft ${user.heightIn}in / ${user.weight}lbs`}</div>
                        </Detail>
                    )
                }
            </>
        </div>
    )
}
import types from "./types";

const initState = {
  completedCaseListLoading: false,
  completedNoRxCaseListLoading: false,
  completedCaseList: [],
  completedNoRxCaseList: [],
  totalCountCompletedNoRx: 0,
  totalCountCompleted: 0,
};

export default function consultedCase(state = initState, action) {
  switch (action.type) {
    case types.SET_COMPLETED_CASE_LIST_LOADING:
      return {
        ...state,
        completedCaseListLoading: action.data,
      };
    case types.SET_COMPLETED_NO_RX_CASE_LIST_LOADING:
      return {
        ...state,
        completedNoRxCaseListLoading: action.data,
      };
    case types.SET_COMPLETED_CASE_LIST:
      return {
        ...state,
        completedCaseList: action.payload,
        totalCountCompleted: action.count,
      };
    case types.SET_COMPLETED_NO_RX_CASE_LIST:
      return {
        ...state,
        completedNoRxCaseList: action.payload,
        totalCountCompletedNoRx: action.count,
      };
    default:
      return state;
  }
}

const types = {
    SET_CASE_QUEUE_LIST_LOADING: 'SET_CASE_QUEUE_LIST_LOADING',
    SET_CASE_QUEUE_LIST: 'SET_CASE_QUEUE_LIST',
    REMOVE_CASE_FROM_QUEUE: 'REMOVE_CASE_FROM_QUEUE',
    SET_CASE_QUEUE_COUNT_LOADING: 'SET_CASE_QUEUE_COUNT_LOADING',
    SET_CASE_QUEUE_COUNT: 'SET_CASE_QUEUE_COUNT',
    SET_SORT_DIRECTION: 'SET_SORT_DIRECTION',
    ADD_CASE_TO_QUEUE: 'ADD_CASE_TO_QUEUE',
    SET_CASE_QUEUE_STATUS_FILTER: 'SET_CASE_QUEUE_STATUS_FILTER',
    INCREMENT_QUEUE_COUNT: 'INCREMENT_QUEUE_COUNT',
    SET_SEND_SMS_NOTIFICATIONS: 'SET_SEND_SMS_NOTIFICATIONS'
};

export default types;
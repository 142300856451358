import React from 'react'
import moment from 'moment-timezone'

import { Card, Typography } from 'antd'
const { Text } = Typography

export const PatientCard = ({ patient, title, actions }) => (
    <Card title={title} bordered={true} style={{ marginBottom: 16, width: '400px' }} actions={actions}>
      <Text strong>Name:</Text>
      <p>{patient.firstName} {patient.middleName || ''} {patient.lastName}</p>
      <Text strong>DOB:</Text>
      <p>{moment(patient.dob).utc().format('MM/DD/YYYY')}</p>
      <Text strong>Address:</Text>
      <p>
        {patient.address1}
        {patient.address2 && <><br/>{patient.address2}</>}
        {<><br/>{`${patient.city}, ${patient.state} ${patient.zipCode}`}</>}
      </p>
      <Text strong>Phone:</Text>
      <p>{patient.primaryPhone || 'n/a'}</p>
      <Text strong>Email:</Text>
      <p>{patient.email || 'n/a'}</p>
      <Text strong>Gender:</Text>
      <p>{patient.gender || 'n/a'}</p>
      <Text strong>Weight (lb):</Text>
      <p>{patient.weight || 'n/a'}</p>
      <Text strong>Height (cm):</Text>
      <p>{patient.height || 'n/a'}</p>
    </Card>
  );
import types from "./types"

const initState = {
    caseListloading: false,
    waitingCasesCount: 0,
    joinedWaitingRoomCaseList: [],
};

export default function waitingRoom(
    state = initState,
    action,
) {
    switch (action.type) {
        case types.SET_LOADING:
            return {
                ...state,
                caseListloading: action.payload,
            };
        case types.SET_WAITING_ROOM_CASE_LIST:
            return {
                ...state,
                waitingCasesCount: action.payload.length,
                joinedWaitingRoomCaseList: action.payload,
            };
        default:
            return state;
    }
}
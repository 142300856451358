import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import consultedCase from "../../redux/consultedCase";

import ConsultedCases from "../../components/ConsultedCases/ConsultedCases";

const { getConsultedCaseList } = consultedCase.actions;

const mapStateToProps = (state) => {
  const {
    completedNoRxCaseListLoading,
    completedNoRxCaseList,
    totalCountCompletedNoRx,
  } = state[consultedCase.name];
  const { statesList, telemedicineCategoriesList } = state.entities;
  return {
    caseListLoading: completedNoRxCaseListLoading,
    consultedCaseList: completedNoRxCaseList,
    totalCount: totalCountCompletedNoRx,
    statesList: statesList,
    telemedicineCategoriesList: telemedicineCategoriesList,
  };
};
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      getConsultedCaseList,
    },
    dispatch
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConsultedCases);

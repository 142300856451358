import React from 'react'
import { Button, Typography } from 'antd'
const { Text } = Typography
 
export const PrescriptionItem = ({ label, value, span = 1, style = {}, vertical=false, isEditable, editRequest }) => {
    return (
        <div style={{ textTransform: 'capitalize', display: 'flex', flexDirection: vertical ? 'column' : 'row', gap: '10px', gridColumn: `span ${span}`, ...style}}>
            <div style={{ color: 'rgba(0,0,0,.65)', fontWeight: '400' }}>{label}</div>
            <Text strong style={{ color: style.color  || 'rgba(0,0,0,1)' }}>{value}</Text>
            {isEditable && <Button type="primary" onClick={editRequest} size="small" style={{ marginLeft: "10px" }}>Edit</Button>}
        </div>
    )
}

    
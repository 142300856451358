import React from 'react'
import { AutoComplete, Form } from 'antd'
import { debounce } from 'lodash'

import { required, isControlledSubstance } from '../validation'

export const MedicationSearch = ({
    selectedStrength,
    fetchMedications,
    handleSelectMedication,
    medicationSearchMatches,
}) => {

    const handleSearch = str => {
        handleSelectMedication(null)
        debounce(fetchMedications, 1000)(str)
    }

    return (
        <Form.Item
            label="Medication Name"
            name="name"
            required
            rules={[
                required,
                isControlledSubstance(selectedStrength)
            ]}
        >
            <AutoComplete
                onSearch={handleSearch}
                onSelect={handleSelectMedication}
                onChange={() => {}}
                placeholder="Search medication name"
                options={medicationSearchMatches?.map(match => ({ label: match.name, value: match.name }))}
            />
        </Form.Item>
    )
}

import styled from 'styled-components'
import { Space } from 'antd'

export const DiffSpace = styled(Space)`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    text-transform: capitalize;
    margin-bottom: 20px;

    & .ant-space-item {
        width: 60%;
    }
`   

export const DiffItemContainer = styled.div`
    width: 100%;
    display: grid;
    align-items: flex-end;
    grid-template-columns: 40% 20% 40%;
    gap: 10px;
`
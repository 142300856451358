import React, { useState, useEffect } from "react";
import styled from "styled-components";

import { Typography, Spin } from "antd";

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
am4core.useTheme(am4themes_animated);

const { Title, Text } = Typography;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  box-shadow: 4px 4px 12px #ccc;
  padding: 20px;
  background: #fff;
`;

const StyledTitle = styled(Title)`
  padding-bottom: 2px;
  border-bottom: 2px solid #eee;
`;

const Chart = styled.div`
  height: 400px;
`;

const ReportClusterChart = ({ title, id, data, loading }) => {
  const [chart, setChart] = useState(null);
  const [target, setTarget] = useState(null);

  useEffect(() => {
    const container = document.getElementById(id);
    if (!target && container) setTarget(container);
  // eslint-disable-next-line
  }, [data]);

  useEffect(
    () => {
      chart?.dispose();
      if (data && document.getElementById(id)) {
        const chart = am4core.create(id, am4charts.XYChart);
        chart.data = data;

        // Create axes
        let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = "type";
        categoryAxis.renderer.grid.template.location = 0;
        categoryAxis.renderer.minGridDistance = 20;
        let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.renderer.minGridDistance = 20;

        // Create series
        let series = chart.series.push(new am4charts.ColumnSeries());
        let series2 = chart.series.push(new am4charts.ColumnSeries());
        let series3 = chart.series.push(new am4charts.ColumnSeries());

        series.dataFields.valueY = "audio";
        series.name = "Audio";

        series2.dataFields.valueY = "video";
        series2.name = "Video";

        series3.dataFields.valueY = "store and forward";
        series3.name = "Store and Forward";

        series.dataFields.categoryX = series2.dataFields.categoryX =   series3.dataFields.categoryX = "type";
        series.columns.template.tooltipText = series2.columns.template.tooltipText = series3.columns.template.tooltipText = "{name}: [bold]{valueY}[/]";
        series.columns.template.width = series2.columns.template.width =  series3.columns.template.width = am4core.percent(100);

        var bullet1 = series.bullets.push(new am4charts.LabelBullet());
        var bullet2 = series2.bullets.push(new am4charts.LabelBullet());
        var bullet3 = series3.bullets.push(new am4charts.LabelBullet());

        bullet1.label.text = bullet2.label.text = bullet3.label.text = "{valueY}";
        bullet1.label.verticalCenter = bullet2.label.verticalCenter = bullet3.label.verticalCenter = "bottom";
        bullet1.label.fontSize = bullet2.label.fontSize = bullet3.label.fontSize = 18;

        chart.legend = new am4charts.Legend();
        chart.legend.position = "bottom";
        chart.legend.maxWidth = 250;
        chart.maskBullets = false;

        setChart(chart);
      }
    },
    // eslint-disable-next-line
    [data, target],
    () => chart?.dispose()
  );

  data = data?.filter(item => item !== undefined)

  return (
    <Container>
      <StyledTitle level={4}>{title}</StyledTitle>
      {loading && <Spin />}
      {!loading && (data?.length > 0 ? <Chart id={id} /> : <Text>No Data</Text>)}
    </Container>
  );
};

export default ReportClusterChart;

import types from "./types";

const initState = {
  rxIndexForTemplate: null,
  referralCasesLoading: false,
  referralCases: [],
  referralCasesCount: 0,
  caseReviewModalVisible: false
};

export default function referrals(state = initState, action) {
  switch (action.type) {
    case types.SET_REFERRAL_CASES_LOADING:
      return {
        ...state,
        referralCasesLoading: action.payload,
      };
    case types.SET_REFERRAL_CASES_COUNT:
      return {
        ...state,
        referralCasesCount: action.payload,
      };
    case types.SET_REFERRAL_CASES:
      return {
        ...state,
        referralCases: action.payload,
      };
    case types.SET_CASE_REVIEW_MODAL_VISIBLE:
      return {
        ...state,
        caseReviewModalVisible: action.payload
      }
    default:
      return state;
  }
}

import React  from 'react';
import { Modal } from 'antd';

import { SelectLocalPatient } from './components/SelectLocalPatient';
import { SelectMergePatient } from './components/SelectMergePatient';
import { ConfirmPatientReconcilation } from './components/ConfirmPatientReconcilation';

import { useReconcilePatient } from './hooks/useReconcilePatient';

import {
  PrimaryTitle,
  SecondaryTitle,
  TirtiaryTitle
} from './components/Titles'

const PatientReconcilationModal = ({ 
  selectedRxError, 
  selectRxError, 
  fetchRxErrors 
}) => {
  
  const {
    selectedLocalPatient,
    mergedPatient,
    selectLocalPatient,
    setMergedPatient,
    handleReconcile,
    handleMergeSelect,
    handleReconcilePatientProperty,
    mergePatientConfirmed,
    setMergedPatientConfirmed,
    selectedMergeSource,
    selectMergeSource
  } = useReconcilePatient({
    selectedRxError,
    selectRxError,
    fetchRxErrors,
  })

  return (
    <Modal
      title={
        !selectedLocalPatient 
          ? <PrimaryTitle /> 
          : !mergePatientConfirmed
            ? <SecondaryTitle /> 
            : <TirtiaryTitle />
      }
      visible
      onCancel={() => selectRxError(null)}
      footer={null}
      width={1100}
    >

      {!selectedLocalPatient 
        ? (
          <SelectLocalPatient
            selectedRxError={selectedRxError}
            selectLocalPatient={selectLocalPatient}
            selectMergePatient={setMergedPatient}
          />
        ) 
        : !mergePatientConfirmed
          ? (
            <SelectMergePatient 
              selectedRxError={selectedRxError}
              selectedLocalPatient={selectedLocalPatient}
              selectLocalPatient={selectLocalPatient}
              mergedPatient={mergedPatient}
              handleMergeSelect={handleMergeSelect}
              handleReconcilePatientProperty={handleReconcilePatientProperty}
              setMergedPatientConfirmed={setMergedPatientConfirmed}
              selectedMergeSource={selectedMergeSource}
              selectMergeSource={selectMergeSource}
            />
          ) : (
            <ConfirmPatientReconcilation
              mergedPatient={mergedPatient}
              setMergedPatientConfirmed={setMergedPatientConfirmed}
              handleReconcile={handleReconcile}
            />
          )
        }
    </Modal>
  );
};

export default PatientReconcilationModal;

import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

import PrescriptionsAPI from '../../../../axios/prescription/PrescriptionsApi'

import { useCaseContext } from '../../context/CaseContext'

import { PrescriptionCard } from '../../../../components/PrescriptionCard'

import {
    Modal,
    Typography,
    Spin,
} from "antd";

const { Title, Text } = Typography

const RxList = styled.div`
    display: flex;
    flex-direction: row;
    gap: 10px;
    overflow: scroll;
`
export const CancelPrescriptionsModal = ({ hide, setConsultationCompleted }) => {
    const [prescriptionHistory, setPrescriptionHistory] = useState(null)
    const [rxLoading, setRxLoading] = useState(false)
    const { _case } = useCaseContext()

    const getPatientPrescriptionHistory = async () => {
        try {
            const { data: { prescriptions } } = await PrescriptionsAPI.getPatientPrescriptionHistory(_case.patientId)
            const activePrescriptions = prescriptions.filter(rx => rx.prescriberStatus?.toLowerCase() !== 'deleted')
            setPrescriptionHistory(activePrescriptions)
        } catch (e) {
            console.log(e)
        } finally {
            setRxLoading(false)
        }
    }

    useEffect(() => {
        getPatientPrescriptionHistory()
    }, [])

    return (
        <Modal
            title={
                <>
                    <Title level={3}>Recent Prescriptions</Title>
                    <Text>If replacing or updating previously written prescriptions please cancel prior prescriptions before proceding</Text>
                </>
            }
            visible={true}
            onOk={setConsultationCompleted}
            onCancel={hide}
            okText='Ok'
            width={900}
        >
            <RxList>
                {rxLoading && <Spin style={{ margin: '0 auto' }} />}
                {!rxLoading &&
                    (prescriptionHistory?.length
                        ? prescriptionHistory
                            .sort((a, b) => new Date(b.signedAt) - new Date(a.signedAt))
                            .map(script => (
                                <PrescriptionCard 
                                    script={script} 
                                    prescriptionHistory={prescriptionHistory}
                                    setPrescriptionHistory={setPrescriptionHistory}
                                />
                            ))
                        : 'No Prescription History')
                }
            </RxList>
        </Modal>
    )
}

import React from 'react'
import moment from 'moment-timezone'
import { Typography, Tooltip } from 'antd'
import { WarningOutlined } from '@ant-design/icons';

import { formatTenDigitPhoneNumber } from '../../../utils/phoneFormatter'

const { Text } = Typography

const WarningIconWithTooltip = ({ style = {} }) => {
    const tooltipText = "Patient date of birth on record does not match pharmacy record. Submitting Rx will update local patient record with new DOB.";
  
    return (
      <Tooltip title={tooltipText}>
        <WarningOutlined style={{ ...style, color: 'red' }} />
      </Tooltip>
    );
  };

const Detail = ({ header, children, vertical, style={} }) => (
    <div style={{ ...style, display: 'flex', flexDirection: vertical ? 'column' : 'row', width: '100%' }}>
        {header && <Text style={{ marginRight: '10px', fontSize: '.75rem', textTransform: 'capitalize' }}>{header}</Text>}
        {children}
    </div>
)

const PatientDetails = ({ user, vertical, ptDOBMatch }) => {
    const dob = moment(user.dob).utc().format('MM-DD-YYYY')
    return (
        <>
            {user.address1 && (
                <Detail header='Address' vertical={vertical}>
                    <div style={{ fontWeight: 'bolder', fontSize: '.75rem' }}>{user.address1}</div>
                    {user.address2 && <div style={{ maxWidth: '180px', fontWeight: 'bolder', fontSize: '.75rem' }}>{user.address2}</div>}
                    <div style={{ fontWeight: 'bolder', fontSize: '.75rem' }}> {`${user.city}, ${user.state} ${user.zipCode}`}</div>
                </Detail>
            )}
            <Detail header='Contact Info' vertical={vertical}>
                <div style={{ fontWeight: 'bolder', fontSize: '.75rem' }}>
                    {user.primaryPhone && <div>{`${formatTenDigitPhoneNumber(user.primaryPhone?.replace(/\D/g, ''))}`}</div>}
                    {user.email && <div> {user.email}</div>}
                    {!user.primaryPhone && !user.email && <div>N/A</div>}
                </div>
            </Detail>
            {
                user.gender && (
                    <Detail header='Gender/DOB' vertical={vertical}>
                        <div style={{ fontWeight: 'bolder', fontSize: '.75rem' }}>
                            <div>{user.gender.toUpperCase()}</div>
                            <div>
                                <span style={!ptDOBMatch ? {color: 'red'} : {}}>{dob}</span>  
                                { !ptDOBMatch && <WarningIconWithTooltip style={{ marginLeft: '5px'}}/> }
                            </div>
                        </div>
                    </Detail>
                )
            }
            <Detail header='Height/Weight' vertical={vertical}>
                {
                    user.height ? (
                        <>
                            <div style={{ fontWeight: 'bolder', fontSize: '.75rem' }}>{`${user.height}in / ${user.weight}lbs`}</div>
                        </>
                    ) : <div style={{ fontWeight: 'bolder', fontSize: '.75rem' }}>N/A</div>
                }
            </Detail>
        </>
    )
}

export const UserDetails = ({ type, user = {}, ptDOBMatch, name, style, vertical }) => {
    return (
        <div style={{
            display: 'grid',
            gridTemplateColumns: '20% 25% 25% 15% 15%',
            background: '#FAFBFC',
            justifyContent: 'left',
            minWidth: '800px',
            padding: '0 20px',
            ...style
        }}>

            <>
                <Detail header={type} vertical={vertical}>
                    <div style={{ fontWeight: 'bolder', fontSize: '.75rem' }}>{name}</div>
                </Detail>
                { type === 'patient' && <PatientDetails user={user} vertical={vertical} ptDOBMatch={ptDOBMatch} /> }
            </>
        </div>
    )
}
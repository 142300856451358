import React from 'react'

import {
    useSelector,
    useDispatch
} from "react-redux";

import {
    PlusOutlined,
    WarningOutlined,
    StopOutlined,
    CheckCircleOutlined,
    EyeOutlined,
} from '@ant-design/icons'

import { StyledButton } from '../../../../../Button'

import { useCaseViewContext } from '../../../../../../context/CaseViewContext'

import {
    CaseStatusEnum,
    PhysicianTagEnum,
    RequestStatusEnum,
} from '../../../../../../../../types/enums'

import prescribeMedicine from '../../../../../../../../redux/prescribeMedicine';

import { Container } from './Actions.styles'

export const Actions = ({
    setActionCaseStatus,
    showTreatmentModal,
    isAssignedProvider,
}) => {
    const dispatch = useDispatch()

    const { 
        caseDetails, 
        activeRequest, 
        provider 
    } = useCaseViewContext()

    const { prescriptions } = useSelector(state => state.prescribeMedicine)

    const caseIsAlreadyCompleted = [CaseStatusEnum.CONSULT_COMPLETE, CaseStatusEnum.CONSULT_COMPLETE_NO_RX].includes(caseDetails?.status)
    const providerNeedsReview = provider?.tags?.includes(PhysicianTagEnum.NEEDS_REVIEW)
    const isMostRecentRequest = caseDetails?.requests?.at(-1)._id === activeRequest?._id   
    
    const hide = !isAssignedProvider ||
        !isMostRecentRequest || 
        (providerNeedsReview && caseDetails?.status === CaseStatusEnum.REFER)
    
    if (hide) return null

    const handleReviewAndSend = () => dispatch(prescribeMedicine.actions.setConsultationCompleted(true))

    const setStatusToAdditionalInfoRequired = () => setActionCaseStatus(CaseStatusEnum.ADDITIONAL_INFO_REQUIRED)
    const setStatusToRefer = () => setActionCaseStatus(CaseStatusEnum.REFER)
    const setStatusToConsultCompleteNoRx = () => setActionCaseStatus(CaseStatusEnum.CONSULT_COMPLETE_NO_RX)

    return (
        <Container>
            <StyledButton 
                tooltip="Add a treatment" 
                Icon={PlusOutlined} 
                borderColor="#7BAD7E" 
                textColor="#7BAD7E" 
                color="white" 
                onClick={showTreatmentModal}
            />
            { 
                !caseIsAlreadyCompleted && (
                    <StyledButton 
                        Icon={WarningOutlined}
                        tooltip='Additional Info Requested'
                        color="orange" 
                        onClick={setStatusToAdditionalInfoRequired}
                    />
                )
            }
            { 
                !caseIsAlreadyCompleted && 
                providerNeedsReview && (
                    <StyledButton 
                        Icon={EyeOutlined} 
                        color="red"
                        tooltip='Needs Review'
                        onClick={setStatusToRefer}
                    />
                ) 
            }
            { 
                !providerNeedsReview && activeRequest?.status !== RequestStatusEnum.COMPLETED_NO_RX && (
                    <StyledButton 
                        Icon={StopOutlined} 
                        color="red"
                        tooltip='Consult Complete, No Rx'
                        onClick={setStatusToConsultCompleteNoRx}
                    /> 
                )
            }
            { 
                (Object.values(prescriptions || {})).length > 0 && (

                    <StyledButton
                        tooltip='Consult Complete, Prescribe'
                        Icon={CheckCircleOutlined} 
                        onClick={handleReviewAndSend}
                    />
                )
            }
        </Container>
    )
}
import { useState, useEffect, useRef, useMemo } from 'react';
import { debounce } from 'lodash'
import { message } from 'antd'

import API from '../../../../axios/prescription/PrescriptionsApi'

export const useSearchMedications = ({
    form,
    selectedTemplate
 }) => {
    const [medicationSearchMatches, setMedicationSearchMatches] = useState([])
    const [medicationSearchLoading, setMedicationSearchLoading] = useState(false)
    const [selectedMedication, selectMedication] = useState(selectedTemplate?.medication || null)
    const [availableMedicationStrengths, setAvailableMedicationStrengths] = useState([]);

    const abortController = useRef(null)

    useEffect(() => {
        !!selectedTemplate && handleLoadTemplate(selectedTemplate.medication?.drugName)
    }, [selectedTemplate])

    const handleLoadTemplate = async drugName => {
        if (drugName) {
            const foundMedications = await fetchMedications(drugName)
            const match = foundMedications.find(medication => medication.name === drugName);
            if (!match) return;
        
            setAvailableMedicationStrengths(match.strengths || [match.strength]);
        }
    }

    async function fetchMedications(searchStr) {
        setMedicationSearchMatches([])

        if (searchStr?.length <= 2) {
            return setMedicationSearchLoading(false);
        }

        try {
            abortController.current?.abort()
            abortController.current = new AbortController()

            setMedicationSearchLoading(true)
            const { data: { medications } } = await API.searchMedications(searchStr, abortController.current?.signal)

            if (abortController.current?.signal.aborted || !medications?.length) {
                return
            }

            setMedicationSearchMatches(medications);
            return medications
        } catch (e) {
            console.error(e);
            message.error('Error fetching medication matches from prescription service');
        } finally {
            setMedicationSearchLoading(false);
        }
    }

    const handleSelectMedication = medicationName => {
        form.resetFields([
            'lexiGenProductId',
            'lexiDrugSynId',
            'lexiSynonymTypeId',
            'ingredients',
            'strength',
            'ndc'
        ])
        
        const medicationMatch = medicationSearchMatches.find(medication => medication.name === medicationName);
        if (!medicationMatch) return;
        
        setAvailableMedicationStrengths(medicationMatch.strengths || [{ ...medicationMatch, strength: 'compound' }]);
    };

    const handleSelectStrength = strength => {
        const medication = availableMedicationStrengths.find(medStrength => medStrength.strength === strength)
        selectMedication({ ...medication, strength: medication.strength || 'compound'})
    }

    const debounceFetchMedications = useMemo(() => debounce(fetchMedications, 1000), []);

    return { 
        fetchMedications: debounceFetchMedications, 
        medicationSearchMatches, 
        medicationSearchLoading,
        selectedMedication, 
        handleSelectMedication,
        handleSelectStrength,
        availableMedicationStrengths,
    }
}
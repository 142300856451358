import React from "react"
import { connect } from 'react-redux'

import { usePrescriptionTemplates } from "../../hooks/usePrescriptionTemplates";

import { useCaseContext } from "../../context/CaseContext";

import { Form, Input, Row, Col, Select, Button, message } from "antd"
import { PlusCircleOutlined } from '@ant-design/icons';
import styled from 'styled-components'
import { useDispenseUnitContext } from "../../context/DispenseUnitContext";
import { useTelemedicineCategoryContext } from "../../context/TelemedicineCategoryContext";
import { validatePositive, greaterThanZero } from "../../../../formValidation";

const { TextArea } = Input

const StyledFormItem = styled(Col)`
  & label {
    text-transform: capitalize;
  }
`

const StyledButtonGroup = styled(Row)`
    width: 100%;
    margin-top: 16px;
    display: flex;
    justify-content: flex-end;
    gap: 8px;

    & button {
        width: 100px;
        background: #fff;
        color: #171c2f;
    }
`

const FormItem = ({ rules, span, label, name, children, hidden=false }) => (
  <StyledFormItem span={span || 12}>
    <Form.Item hidden={hidden} rules={rules} label={label || name} name={name}>
      {children}
    </Form.Item>
  </StyledFormItem>
);

const PrescriptionTemplateForm = ({
  rxIndexForTemplate,
  setRxIndexForTemplate,
  prescriptions,
  handleCancel
}) => {
  const [form] = Form.useForm();
  const { _case } = useCaseContext()
  const { dispenseUnits, getDispenseUnitById } = useDispenseUnitContext()
  const { 
    telemedicineCategories, 
    getTelemedicineCategoryNameById,
    getTelemedicineCategoryIdByName
  } = useTelemedicineCategoryContext()

  const { 
    createTemplate
  } = usePrescriptionTemplates({
    form,
    dispenseUnits,
    getDispenseUnitById,
  })

  const handleCreateTemplate = async template => {
    try {
      const mappedTelemedicineCategories = template.teleMedicineCategories.map(getTelemedicineCategoryIdByName)
      await createTemplate({ ...template, teleMedicineCategoryIds: mappedTelemedicineCategories })
      setRxIndexForTemplate(null)
    } catch (e) {
      console.log(e)
      message.error('Error creating template from prescription')
    }
  }

  const selectOptions = telemedicineCategories.map(category => ({
    label: category.name,
    value: category.name
  }))

  const prescription = prescriptions?.[rxIndexForTemplate] || {}

  return (
      <Form
        form={form}
        initialValues={{ 
          ...prescription, 
          pseudonyms: prescription.requestedMedication.drugName,
          drugName: prescription.name,
          pillCount: prescription.quantity, 
          teleMedicineCategories: [getTelemedicineCategoryNameById(_case.categoryId)],
        }}
        layout="vertical"
        style={{ textTransform: 'capitalize' }}
        validateMessages={{ required: 'required' }}
        onFinish={handleCreateTemplate}
      >
        <Row gutter={16}>
          <FormItem span={24} name="title" label="Template Name">
            <Input required placeholder="Template Name" />
          </FormItem>
          <FormItem span={24} name="drugName" label="Medication">
            <Input disabled/>
          </FormItem>
          <FormItem span={24} name="strength" label="Strength">
            <Input disabled/>
          </FormItem>
          <FormItem
            span={24}
            label="Telemedicine Category"
            name="teleMedicineCategories"
          >
            <Select
              mode="multiple"
              allowClear
              options={selectOptions}
            />
          </FormItem>
          <FormItem span={8} label="Quantity" rules={[{ required: true }, greaterThanZero()]} name="pillCount">
            <Input type="number"/>
          </FormItem>
          <FormItem span={8} name="refills" rules={[{ required: true }, validatePositive()]}>
            <Input type="number"/>
          </FormItem>
          <FormItem span={8} label="Days Supply" rules={[{ required: true }, greaterThanZero()]} name="daysSupply">
            <Input type="number"/>
          </FormItem>
          <FormItem      
            span={24}
            maxLength={150}
            showCount
            placeholder="Limited to 150 characters"
            label="Patient Directions"
            name="directions"
          >
            <TextArea/>
          </FormItem>
          <FormItem span={24} name="pharmacyNotes" label="Notes to Pharmacy">
            <TextArea/>
          </FormItem>
          <Form.Item hidden name="ndc" />
          <Form.Item hidden name="dispensableDrugId" />
          <Form.Item hidden name="ingredients" />
          <Form.Item hidden name="dispenseUnitId" />
          <Form.Item hidden name="schedule" />
          <StyledButtonGroup>
            <Button onClick={handleCancel}>Cancel</Button>
            <Button
              style={{ background: '#7BAD7E', color: '#fff' }}
              icon={ <PlusCircleOutlined />}
              htmlType="submit"
            >
              Save
            </Button>
          </StyledButtonGroup>
        </Row>
        <Form.Item hidden name='dispenseUnitId' />
      </Form>
  );
};

const mapStateToProps = state => {
  const { prescriptions } = state.prescribeMedicine
  return { prescriptions }
}

export default connect(
  mapStateToProps
)(PrescriptionTemplateForm);

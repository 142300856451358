import React from "react";

const ConsultedCaseIcon = () => {
  return (
    <span style={{ marginRight: 10 }} className="icon-div">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="1rem"
        width="1rem"
        viewBox="0 0 23.63 23.63"
      >
        <defs></defs>
        <title>Asset 13</title>
        <g>
          <path d="M13.89,7H9.74V9.74H7v4.15H9.74v2.77h4.15V13.89h2.77V9.74H13.89Zm1.38,4.15V12.5H12.5v2.77H11.12V12.5H8.35V11.12h2.77V8.35H12.5v2.77Z" />
          <path d="M20.17,3.46A11.81,11.81,0,0,0,0,11.81a11.78,11.78,0,0,0,3.38,8.27L.71,23.63H11.81A11.81,11.81,0,0,0,20.17,3.46ZM11.81,22.24H3.49l1.75-2.31-.46-.43a10.43,10.43,0,1,1,7,2.74Z" />
        </g>
      </svg>
    </span>
  );
};

export default ConsultedCaseIcon;

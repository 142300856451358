import React from 'react'
import { Modal, Typography } from 'antd'
import { useHistory } from "react-router-dom";

export const PossessionChangeModal = ({ action, startConsultation, onOk }) => {
    const history = useHistory();

    const modalContent = {
        "already-accepted": {
            title: "Case Already Accepted",
            text: "This case has already been accepted by another provider.",
            okText: "Go Back",
            handler: () => {
                onOk()
                history.goBack()
            }
        },
        "reassigned": {
            title: "Case reassigned",
            text: "This has been reassigned. Taking you back to case queue.",
            okText: "Go Back",
            handler: () => {
                onOk()
                history.goBack()
            }
        },
        "accept": {
            title: "Accept Case",
            text: "Please confirm that you are accepting this case",
            okText: "Ok",
            handler: startConsultation
        }
    }

    return (
        <Modal
            title={modalContent[action].title}
            okText={modalContent[action].okText}
            closable={false}
            maskClosable={false}
            centered
            visible
            cancelButtonProps={{ style: { display: 'none' } }}
            onOk={modalContent[action].handler}
        >
            <Typography.Text strong>
                {modalContent[action].text}
            </Typography.Text>
        </Modal>
    )
}
import React from 'react'
import { Form, Input } from 'antd'

import { required, isPositive } from '../validation'

export const Quantity = ({
    form
}) => {

    const handleFormatQuantity = async ({ target: { value } }) => {
        const truncated = Math.floor(Number(value) * 1000) / 1000
        await form.setFieldsValue({ quantity: truncated.toString() })
    }

    return (
        <Form.Item
            required
            label="Quantity"
            name="quantity"
            style={{ display: 'inline-block', width: 'calc(50% - 12px)' }}
            rules={[
                required,
                isPositive,
            ]}
        >
            <Input type='number' onBlur={handleFormatQuantity} />
        </Form.Item>
    )
}
import { useState, useEffect } from 'react'

export function useStrengthSelector({
    selectedMedication,
}) {
    const [options, setOptions] = useState([])

    useEffect(() => {
        if (selectedMedication) {
            const compileOptions = selectedMedication => {
                if (!selectedMedication) return []
                if (selectedMedication.ingredients) return [{ label: 'Compound', value: 'Compound' }]
                return selectedMedication.strengths?.map(strength => ({ label: strength.strength, value: strength.strength }))
            }

            setOptions(compileOptions(selectedMedication))
            
        } else {
            setOptions([])
        }
    }, [selectedMedication]);

    return { options }
}
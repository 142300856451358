import dayjs from 'dayjs'

const normalizeStrength = str => str?.replace(' ', '').toLowerCase()

const shouldValidate = (requestedMedication, rxComparisonMedications) => rxComparisonMedications?.find(({ name })=> requestedMedication?.drugName === name)

function validateMedicationName(requestedMedication, rxComparisonMedications, selectedMedication) { 
    return function (_, value) {
        const lookupMatch = shouldValidate(requestedMedication, rxComparisonMedications)
        return !value?.toLowerCase().includes(lookupMatch?.drug_name?.toLowerCase()) || selectedMedication?.schedule !== "0"
    }
}

export const validationConditions = (requestedMedication, rxComparisonMedications, selectedMedication) => {
    return {
        name: () => validateMedicationName(requestedMedication, rxComparisonMedications, selectedMedication), 
        strength: (_, value) => requestedMedication && normalizeStrength(value) !== normalizeStrength(requestedMedication.dosage),
        quantity: (_, value) => requestedMedication && value.toString() !== requestedMedication.pillCount.toString(),
        startDate: (field, value) => requestedMedication && !dayjs(value).isSame(dayjs(requestedMedication[field] || dayjs()).format('YYYY-MM-DD'), 'day'),
        schedule: (_, value) => value?.toString() !== "0",
        default: (field, value) =>  requestedMedication && value?.toString() !== requestedMedication[field]?.toString()
    }
}

export const validateField = (requestedMedication, rxComparisonMedications, selectedMedication) => {
    return [
        () => ({
            validator({ field }, value) {
                const validationFn = validationConditions(requestedMedication, rxComparisonMedications, selectedMedication)[field] || validationConditions(requestedMedication).default
                

                if (shouldValidate() && validationFn(field, value)) return Promise.reject(new Error("Mismatch"))
                return Promise.resolve()
            },
            warningOnly: true
        }),
    ]
}

export const validatePositive = () => {
    return {
        validator(_, value) {
            return value >= 0
                ? Promise.resolve()
                : Promise.reject("Must be a postive number.")
        }
    }
}

export const greaterThanZero  = () => {
    return {
        validator(_, value) {
            return value > 0
                ? Promise.resolve()
                : Promise.reject("Must be greater than zero.")
        }
    }
}
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Typography, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { connect } from "react-redux";

// import APIs
import ChatRoomAPI from "../../axios/ChatRoomAPI";

// import components
import ImageUpload from "../../components/ChatComponent/ImageUpload";
import messageStore from "../../redux/message";
import MessageListingComponent from "../../components/MessageListingComponent/MessageListingComponent";
import MessageHeaderComponent from "../../components/MessageHeaderComponent/MessageHeaderComponent";

const { Text } = Typography;

const MessageList = ({
  caseDetails,
  isDifferentPlatform,
  refreshChatList,
  fetchMessageCount,
  enableCaseDetailsView,
}) => {
  const [messageListLoading, setMessageListLoading] = useState(false);
  const [messageList, setMessageList] = useState([]);
  const [notificationCount, setNotificationCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [imageUploadVisibility, setImageUploadVisibility] = useState(false);

  const fetchMessageList = (params) => {
    setMessageListLoading(true);
    ChatRoomAPI.getChatRoomMessages(caseDetails.caseId, params)
      .then((res) => {
        const { data } = res;
        if (data.success && data.payload) {
          const { payload } = data;
          const messageListData = payload;
          const messagesMessageList = messageList.messages;
          const messagesPayload = payload.messages.reverse();

          if (messagesMessageList) {
            messageListData.messages = messagesPayload.concat(
              messagesMessageList
            );
          }

          setMessageList(messageListData);

          setNotificationCount(payload.notificationCount);
        }
        setMessageListLoading(false);
      })
      .catch(() => {
        setMessageList([]);
        setMessageListLoading(false);
      });
  };

  const clearNotification = () => {
    ChatRoomAPI.clearChatRoomNotifications(caseDetails.caseId)
      .then((res) => {
        if (res.data.success) {
          refreshChatList({ caseId: caseDetails.caseId });
          setNotificationCount(0);
        }
      })
      .catch((error) => {
        console.log(
          "=================== Error :: Clear Notifications =====================",
          error
        );
      });
  };

  // handling message viewing
  const handleMessageViewing = () => {
    clearNotification();
  };

  const onImageUploadModalVisibilityChange = () => {
    setImageUploadVisibility(
      (previousVisibilityStatus) => !previousVisibilityStatus
    );
  };

  const handleImageUploadCompletion = (key) => {
    if (key) {
      setMessageList([]);
      setPageNumber(1);
      onImageUploadModalVisibilityChange();
    } else {
      return false;
    }
  };

  // handling message sending
  const handleMessageSending = (data) => {
    ChatRoomAPI.addMessagesToChatRoom(data)
      .then((res) => {
        if (res.data.success) {
          setMessageList([]);
          setPageNumber(1);
        }
      })
      .catch((error) => {
        console.log(
          "=================== Chat Room Error :: Add messages =====================",
          error
        );
      });
  };

  const handleHeaderOptionsClick = (option) => {
    enableCaseDetailsView(caseDetails.caseId);
  };

  useEffect(() => {
    fetchMessageCount();
  }, [notificationCount]);

  useEffect(() => {
    setPageNumber(1);
    setMessageList([]);
  }, [caseDetails]);
  useEffect(() => {
    if (pageNumber === 1 && messageList.length === 0) {
      const params = {
        page: 1,
      };
      if (caseDetails.caseId) {
        fetchMessageList(params);
      } else {
        setMessageList({});
      }
    }
  }, [messageList, pageNumber]);

  const handleLoadMore = () => {
    const params = {
      page: pageNumber + 1,
    };
    setPageNumber(pageNumber + 1);
    fetchMessageList(params);
  };

  return (
    <>
      <Spin
        size="large"
        wrapperClassName="height-hundred"
        spinning={messageListLoading}
        indicator={<LoadingOutlined spin />}
      >
        {Object.keys(caseDetails).length ? (
          <>
            <MessageHeaderComponent
              avatarUrl={caseDetails.photo}
              title={caseDetails.name}
              subTitle={caseDetails.categoryName}
              options={[
                {
                  title: "Case Details",
                  id: "case-details",
                },
              ]}
              onOptionsClick={handleHeaderOptionsClick}
            />
            <MessageListingComponent
              caseId={caseDetails.caseId}
              roomId={caseDetails.caseId}
              authorId={caseDetails.physicianId}
              isPatient={false}
              isDifferentPlatform={isDifferentPlatform}
              messages={messageList}
              handleLoadMore={handleLoadMore}
              pageNumber={pageNumber}
              allowImageUpload
              sendMessage={handleMessageSending}
              handleImageUpload={onImageUploadModalVisibilityChange}
              handleMessageView={handleMessageViewing}
              caseDetails={caseDetails}
              className="message-list-height-background"
            />
            {imageUploadVisibility && (
              <ImageUpload
                isPatient={false}
                isDifferentPlatform={isDifferentPlatform}
                roomId={caseDetails.caseId}
                authorId={caseDetails.physicianId}
                viewModal={imageUploadVisibility}
                closeModal={onImageUploadModalVisibilityChange}
                handleFileUploadCompletion={handleImageUploadCompletion}
              />
            )}
          </>
        ) : (
          <div className="message-no-chat-selection">
            <Text strong>No Chats selected for listing Messages</Text>
          </div>
        )}
      </Spin>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  fetchMessageCount: () => {
    dispatch(messageStore.actions.getMessage());
  },
});

export default connect(null, mapDispatchToProps)(MessageList);

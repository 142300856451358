import React, { useState, useEffect, useRef } from "react";

const Participant = ({ participant,mute,type,name}) => {
  const [videoTracks, setVideoTracks] = useState([]);
  const [audioTracks, setAudioTracks] = useState([]);
  const [username,setUserName]=useState();

  const videoRef = useRef();
  const audioRef = useRef();

  const trackpubsToTracks = (trackMap) =>
    Array.from(trackMap.values())
      .map((publication) => publication.track)
      .filter((track) => track !== null);
      
  useEffect(()=>{
    setUserName(name);    
  },[name])

  useEffect(() => {

    setVideoTracks(trackpubsToTracks(participant.videoTracks));
    setAudioTracks(trackpubsToTracks(participant.audioTracks));

    const trackSubscribed = (track) => {
      if (track.kind === "video") {
        setVideoTracks((videoTracks) => [...videoTracks, track]);
      } else if (track.kind === "audio") {
        setAudioTracks((audioTracks) => [...audioTracks, track]);
      }
    };

    const trackUnsubscribed = (track) => {
      if (track.kind === "video") {
        setVideoTracks((videoTracks) => videoTracks.filter((v) => v !== track));
      } else if (track.kind === "audio") {
        setAudioTracks((audioTracks) => audioTracks.filter((a) => a !== track));
      }
    };

    participant.on("trackSubscribed", trackSubscribed);
    participant.on("trackUnsubscribed", trackUnsubscribed);

    return () => {
      setVideoTracks([]);
      setAudioTracks([]);
      participant.removeAllListeners();
    };
  }, [participant]);

  useEffect(() => {
    const videoTrack = videoTracks[0];

    if (videoTrack) {
      videoTrack.attach(videoRef.current);


      return () => videoTrack.detach();
    }
  }, [videoTracks]);

  useEffect(() => {
    const audioTrack = audioTracks[0];
    if (audioTrack) {
      audioTrack.attach(audioRef.current);  
      return () => {
        audioTrack.detach();
      };
    }
  }, [audioTracks]);

  useEffect(() => {
    const audioTrack = audioTracks[0];
    if (audioTrack) {
      audioTrack.attach(audioRef.current); 
      if(mute==="mute"){
        audioTrack.disable();
      } 
      if(mute==="unmute"){
        audioTrack.enable();  
      }    
      return () => {
        audioTrack.detach();
      };
    }
  }, [mute,audioTracks]);

 //error occureence on mute and audio tracks


 

  return (
    <div className="participant">
    {type==="audio" && <div className="participant-Audio-section-inner"><div className="participant-Audio-section"><h1>{username}</h1></div></div>}  
    {type==="video" && <video ref={videoRef} autoPlay={true} />}       
      	<div>
	  <h1>{username}</h1>
	  <audio ref={audioRef} autoPlay={true} />
	  </div>
    </div>
  );
};

export default Participant;

/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useRouteMatch } from 'react-router-dom';
import {
  Drawer,
  Typography,
  PageHeader,
  Button,
} from "antd";
import { ArrowRightOutlined } from "@ant-design/icons";

// import CSS
import "./Messages.css";

// import APIs
import { userConstants } from "../../constants";
import { history } from "../../helpers";

//import components
import CaseDetailsAccordion from "../../components/CaseDetailsAccordion/CaseDetailsAccordion";

const { Title } = Typography;

const CaseDetailsDraw = ({
  caseId,
  caseDetailsDrawCollapse,
  handleCaseDetailsDrawClose,
}) => {
  const isInCall = useRouteMatch({ path: ['/case-phone-call/:id', '/consultation/:id'] });

  const handleCaseDetailsSidebarCollapseChange = () => {
    handleCaseDetailsDrawClose();
  };

  const onGotoDetailsClick = () => {
    history.push(
      `/dashboard/${userConstants.USER_PHYSICIAN}/caseview/${caseId}`
    );
  };

  return (
    <Drawer
      visible={caseDetailsDrawCollapse}
      className="message-case-details-draw"
      title={
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
          <PageHeader
            className="case-details-draw-page-header"
            onBack={false}
            title={<Title level={3}>Case</Title>}
          />
          <Button type="primary" size="small" onClick={onGotoDetailsClick}>Go to Chart</Button>
        </div>
        
      }
      closable
      maskClosable
      placement="right"
      onClose={handleCaseDetailsSidebarCollapseChange}
      getContainer={false}
      destroyOnClose
    >
      <CaseDetailsAccordion caseId={caseId} />
    </Drawer>
  );
};

export default CaseDetailsDraw;

import styled from 'styled-components'

export const PrescribeMedicineContainer = styled.div`
    width: 100%;
    padding: 0px;
`

export const MedicationGroup = styled.div`
    background: #fff;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 30px;
    width: 100%;
    box-shadow: 1px 10px 95px -13px rgba(0,0,0,0.31);
`

export const ButtonGroup = styled.div`
    display: flex;
    justify-content: flex-end;
    gap: 10px;
`

export const MedicationGroupContainer = styled.div`
    width: 100%;
    padding: 80px 0px;
    display: flex;
    flex-direction: column;
`
import { useEffect, useRef } from 'react';
import { message } from 'antd';


/**
 * @typedef RequestWithDisclaimerConfig
 * @property {() => Promise<import('axios').AxiosResponse>} initiateRequest
 * @property {(res: import('axios').AxiosResponse) => Promise<any>} onSuccess
 * @property {(err: import('axios').AxiosError) => Promise<any>} onError
 * @property {(res: import('axios').AxiosResponse) => Promise<any>} checkNeedsDisclaimer
 * @property {String} disclaimer
 * 
 * @param {RequestWithDisclaimerConfig} config
 */
export const useRequestWithDisclaimer = ({ initiateRequest, onSuccess, onError, checkNeedsDisclaimer, disclaimer }) => {
  const messageRef = useRef(null);

  useEffect(() => {
    return () => {
      messageRef.current?.();
    }
  }, [])

  return () => initiateRequest()
    .then((res) => {
      if (checkNeedsDisclaimer(res)) {
        messageRef.current = message.warn({ content: disclaimer, duration: 0 });
      }
      return onSuccess(res);
    })
    .catch(onError)
}
import React from "react";
import { useSelector } from "react-redux";
import { Tooltip } from "antd";
import { StyledSmallSpin, StyledSpin } from "./StyledComponents";
import { PhysicianTagEnum } from "../../types/enums"

const CountDisplay = ({ isLoading, count }) => {
    return (
      <div>
        {isLoading ? <StyledSmallSpin /> : <>{`${count} total cases in global queue`}</>}
      </div>
    )
}

const Title = () => {
    const caseQueueCount = useSelector(state => state.caseQueue.caseQueueCount);
    const caseQueueCountLoading = useSelector(state => state.caseQueue.caseQueueCountLoading);
    const physicianDetails = useSelector(state => state.physicianDetails.physicianDetails);

    const isLeadPhysician = physicianDetails?.tags?.includes(PhysicianTagEnum.LEAD_PHYSICIAN)

    return (
        <span style={{ marginBottom: '10px', fontWeight: '500', color: 'rgb(124, 124, 124)'}}>
          <h1 style={{ marginBottom: '5px', lineHeight: '40px'}}>
            Case Queue
            <Tooltip title="Watching for new cases">
              <StyledSpin/>
            </Tooltip>
          </h1>
          {isLeadPhysician && <CountDisplay isLoading={caseQueueCountLoading} count={caseQueueCount} />}
        </span>
    )
}

export default Title;

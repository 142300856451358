import { useState, useEffect } from 'react'

export function useStrength({
    form,
    selectedMedication,
    setMedicationState
}) {
    const [options, setOptions] = useState([])

    useEffect(() => {
        if (selectedMedication) {
            const compileOptions = selectedMedication => {
                if (!selectedMedication) return ['Name-specified']
                if (selectedMedication.ingredients) return [{ label: 'Compound', value: 'Compound' }]
                return selectedMedication.strengths?.map(strength => ({ label: strength.strength, value: strength.strength }))
            }

            setOptions(compileOptions(selectedMedication))

            const selectedStrength = selectedMedication.strengths
                ? selectedMedication.strengths[0]
                : { ...selectedMedication }
    
            setMedicationState(prev => {
                return { 
                    ...prev,
                    selectedStrength
                }
            })

            form.setFieldsValue({ strength: selectedStrength.strength, ndc: selectedStrength.ndc, quantityUnits: selectedStrength.doseForm ||  selectedStrength.dispenseUnit })
            form.validateFields(['strength'])
        }
    }, [selectedMedication]);

    return { options }
}
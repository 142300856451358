import types from "./types"

const initState = {
    tags: ['abc', 'def', 'ghi']
}

export default function caseTags(state = initState, action) {
    switch(action.type) {
        case types.SET_CASE_TAGS:
            const tags = action.payload.map(tagObj => tagObj.tag)
            return {
                ...state,
                tags
            }
        default:
            return state
    }
}
import React from 'react'
import { Input, Select, Spin, Typography, Radio, message } from 'antd'
import { SearchOutlined } from '@ant-design/icons';
import styled from 'styled-components'

import { Template } from "./components/Template"
import { EditModal } from "./components/EditModal"

import { TelemedicineCategoryProvider } from '../context/TelemedicineCategoryContext'

const { Text } = Typography

const TemplatesContainer = styled.div`
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    min-height: 500px;
    padding: 10px;
`

const TemplateSearch = ({ 
    user,
    telemedicineCategories=[], 
    templateFilters, 
    setTemplateFilters,
}) => {

    const handleUpdateTemplateFilters = update => {
        const filters = { ...templateFilters }
        setTemplateFilters({ ...filters, ...update })
    }

    return (
        <TelemedicineCategoryProvider>
            <div style={{ 
            display: 'flex', 
            flexWrap: 'wrap', 
            justifyContent: 'space-between', 
            alignItems: 'center', 
            gap: '10px',
            marginBottom: '20px',
            height: '100%'
        }}>
            <Radio.Group 
                value={templateFilters.owner}
                onChange={e => handleUpdateTemplateFilters({ owner: e.target.value })}
            >
                <Radio.Button value={user?._id}>Mine</Radio.Button>
                <Radio.Button value={'admin'}>Admin</Radio.Button>
            </Radio.Group>
            <Input
                type="text"
                style={{ width: '500px', margin: '10px 0'}}
                placeholder="Search prescription template names.."
                prefix={<SearchOutlined style={{ color: '#e2e7e9'}}/>}
                onChange={e => handleUpdateTemplateFilters({ search: e.target.value })}
            />
            <div>
                <Text>Category:</Text>
                <Select 
                    allowClear
                    name="categoryIds"
                    style={{ width: '225px', marginLeft: '10px'}} 
                    placeholder="Telemedicine Category"
                    onChange={val => handleUpdateTemplateFilters({ categoryIds: val })}
                >
                    { telemedicineCategories.map(category => (
                        <Select.Option value={category._id}>{category.name}</Select.Option>
                    ))}
                </Select>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', gap: '10px'}}>
                <Text>Sort:</Text>
                <Select style={{ width: '140px'}} value={templateFilters.sortBy} onChange={val => handleUpdateTemplateFilters({ sortBy: val })}>
                    <Select.Option value="title">Template Title</Select.Option>
                    <Select.Option value="medication.drugName">Medication Name</Select.Option>
                    <Select.Option value="createdAt">Created Date</Select.Option>
                    <Select.Option value="updatedAt">Last Updated</Select.Option>
                </Select>
                <Select style={{ width: '80px'}} value={templateFilters.sortDirection} onChange={val => handleUpdateTemplateFilters({ sortDirection: val})}>
                    <Select.Option value="asc">Asc</Select.Option>
                    <Select.Option value="desc">Desc</Select.Option>
                </Select>
            </div>
        </div>
        </TelemedicineCategoryProvider>
    )
}

export const PrescriptionTemplates = ({ 
    user,
    templates, 
    telemedicineCategories,
    selectedTemplate,
    loading,
    selectTemplate,
    editTemplate, 
    deleteTemplate,
    handleFinish,
    templateFilters,
    setTemplateFilters,
}) => {
    
    const handleDeleteTemplate = async templateId => {
        if(window.confirm("Are you sure you wish to delete this template?")) {
            try {
                await deleteTemplate(templateId)
                handleFinish()
            } catch (e) {
                message.error(e)
            }
        }
    }

    return (
        <Spin spinning={loading}>
            <TemplateSearch 
                user={user}
                telemedicineCategories={telemedicineCategories} 
                templateFilters={templateFilters} 
                setTemplateFilters={setTemplateFilters}
            />
                <TemplatesContainer>
            

                    { templates
                        .filter(template => template.type === 'prescription')
                        .map(template => (
                            <Template 
                                key={template._id} 
                                user={user}
                                template={template} 
                                selectTemplate={selectTemplate}
                                handleDeleteTemplate={handleDeleteTemplate}
                                templateFilters={templateFilters}
                            />
                        )
                    )}
                                

                </TemplatesContainer>
            <EditModal 
                selectedTemplate={selectedTemplate}
                telemedicineCategories={telemedicineCategories}
                editTemplate={editTemplate}
                handleFinish={handleFinish}
            />
        </Spin>
    )
}


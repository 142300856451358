/* eslint-disable react-hooks/exhaustive-deps */
//import packages
import React, { useState } from "react";
import {
  Modal,
  Form,
  Row,
  Col,
  Input,
  Upload,
  Button,
  message,
  Spin,
} from "antd";
import { InboxOutlined, LoadingOutlined } from "@ant-design/icons";

//import API functions
import ChatRoomApi from "../../axios/ChatRoomAPI";

const { Dragger } = Upload;

const ImageUpload = ({
  isPatient,
  roomId,
  authorId,
  viewModal,
  closeModal,
  handleFileUploadCompletion,
  isDifferentPlatform,
}) => {
  const [description, setDescription] = useState(null);
  const [fileList, setFileList] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleModalClose = () => {
    closeModal(false);
  };

  const layout = {
    labelCol: {
      span: 12,
    },
    wrapperCol: {
      span: 24,
    },
  };

  const handleImageUploadClick = () => {
    setLoading(true);
    const formData = new FormData();
    fileList.forEach((file) => {
      formData.append("file", file);
    });
    formData.append("description", description);
    formData.append("chatRoomId", roomId);
    formData.append("author", authorId);
    formData.append("isPatient", isPatient);
    formData.append("iswheel", isDifferentPlatform);

    ChatRoomApi.uploadChatImage(formData)
      .then((res) => {
        const { data } = res;
        setLoading(false);
        if (res.status === 200 && data && data.success) {
          message.success("Successfully uploaded image");
          const { payload } = data;
          if (payload) {
            handleFileUploadCompletion(payload.key, description);
          }
        } else {
          message.warning("Unable upload image please try again!");
        }
      })
      .catch((error) => {
        setLoading(false);
        message.error(
          "Error occured while uploading image, Please try after a while!"
        );
      });
  };

  const fileUploadProps = {
    onRemove: (file) => {
      setFileList((prevFileList) => {
        const index = prevFileList.indexOf(file);
        const newFileList = prevFileList.slice();
        newFileList.splice(index, 1);
        return newFileList;
      });
    },
    beforeUpload: (file) => {
      setFileList((previousFileList) => {
        return [...previousFileList, file];
      });
      return false;
    },
    fileList,
    multiple: false,
    accept: "image/png, image/jpeg",
  };

  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };

  return (
    <Modal
      title="Upload Image File"
      visible={viewModal}
      footer={null}
      onCancel={handleModalClose}
      width={720}
      destroyOnClose
      maskClosable={false}
    >
      <Spin
        size="large"
        spinning={loading}
        indicator={<LoadingOutlined spin />}
      >
        <Form {...layout} layout={"vertical"} name="chat-image-upload">
          <Row gutter={24}>
            <Col span={24}>
              <Form.Item label="Image Description">
                <Input size={"large"} onChange={handleDescriptionChange} />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item label="Upload image">
                <Dragger
                  {...fileUploadProps}
                  disabled={!description}
                  className="chat-image-upload-dragger"
                >
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                  <p className="ant-upload-text">
                    Click or drag the image file to this area to upload
                  </p>
                  <p className="ant-upload-hint">
                    Support for a single file upload. Please also add a
                    description for image to be viewed in the chat
                  </p>
                </Dragger>
              </Form.Item>
            </Col>
            <Col className="chat-image-upload-button-wrapper" span={24}>
              <Form.Item wrapperCol={{ ...layout.wrapperCol }}>
                <Button
                  type="primary"
                  size={"large"}
                  className="chat-image-upload-button"
                  disabled={!(description && fileList.length)}
                  onClick={handleImageUploadClick}
                >
                  Submit
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Spin>
    </Modal>
  );
};

export default ImageUpload;

import React from 'react'
import { Form, Button, Input, Typography } from 'antd'

const REQUIRED = {
    required: true,
    message: 'Required'
}

const FOUR_DIGIT_NUMBER = {
    validator: (_, value) => {
        if (!value) {
            return Promise.reject(new Error('Input is required'));
        }
        if (value.length !== 4) {
            return Promise.reject(new Error('Input must be exactly 4 digits long'));
        }
        if (!/^\d{4}$/.test(value)) {
            return Promise.reject(new Error('Input must be all digits (0-9)'));
        }
        return Promise.resolve();
    },
    message: 'Please enter 4 digits'
}

const PIN_VALIDATOR = {
    validator: (_, value) => {
        if (value && value === "1234") {
            return Promise.reject(new Error('PIN cannot be "1234"'));
        }
        if (/(\d)\1{3}/.test(value)) {
            return Promise.reject(new Error('PIN cannot have 4 repeating numbers'));
        }
        return Promise.resolve();
    }
}


export const SetPIN = ({
    handleSubmit
}) => {
    const [form] = Form.useForm()

    return (
        <div>
            <Typography.Title level={3}>PIN</Typography.Title>
            <Typography.Text>
                Please provide a 4-digit PIN to be used when prescribing. Your PIN must not contain four repeating numbers or be "1234".
            </Typography.Text>

            <Form
                form={form}
                onFinish={handleSubmit}
                layout="vertical"
            >
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                    <Form.Item
                        label="PIN"
                        style={{ width: '200px' }}
                        name='PIN'
                        rules={[REQUIRED, FOUR_DIGIT_NUMBER, PIN_VALIDATOR]}
                    >
                        <Input type='password' maxLength={4} />
                    </Form.Item>
                    <Form.Item
                        label="Verify PIN"
                        style={{ width: '200px' }}
                        name='confirm'
                        rules={[REQUIRED, ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (!value || getFieldValue('PIN') === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error('The two PINs do not match!'));
                            },
                        })]}>
                        <Input type='password' maxLength={4}/>
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType='submit'>Set PIN</Button>
                    </Form.Item>
                </div>
            </Form>
        </div>
    )
}
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import prescribeMedicine from '../../../../../../redux/prescribeMedicine'

import { Modal, Button, Spin, Form, message } from 'antd'
import { PharmacySearch } from './forms/PharmacySearch'
import { usePharmacy } from '../../hooks/usePharmacy'
import { useCaseContext } from '../../../../context/CaseContext'

const ChangePharmacyModal = ({ 
    showChangePharmacyModal,
    setShowChangePharmacyModal,
    actions
}) => {
    const { _case } = useCaseContext()
    const [form] = Form.useForm()

    const {
        pharmacySearchResults,
        setPharmacySearchResults,
        pharmaciesLoading,
        getPharmacyDetails,
        searchPharmacies,
        handleSelectPharmacy,
        handleCancelSearch
    } = usePharmacy({ 
        _case, 
        setShowChangePharmacyModal,
        ...actions
    })

    const handleSearch = async () => {
        const formVals = form.getFieldsValue()
        const filteredVals = {}
        
        try {
            Object.entries(formVals).forEach(([key, val]) => {
                if (val) filteredVals[key] = val
            })

            await searchPharmacies(filteredVals)
        } catch (e) {
            console.log(e)
            message.error('Error fetching pharmacy search results')
        }
        
    }

    return (
        <Modal
            visible={showChangePharmacyModal}
            title="Change Pharmacy"
            onCancel={handleCancelSearch}
            footer={[
                <Button key="cancel" onClick={handleCancelSearch}>Cancel</Button>,
                <Button type="primary" key="search" onClick={handleSearch}>Search</Button>,
            ]}
      >
        <Spin spinning={pharmaciesLoading}>
            <PharmacySearch 
                form={form}
                pharmacySearchResults={pharmacySearchResults}
                setPharmacySearchResults={setPharmacySearchResults}
                handleSelectPharmacy={handleSelectPharmacy}
            />
        </Spin>
      </Modal>
    )
}


const mapDispatchToProps = (dispatch) => {
    const { setPharmacy } = prescribeMedicine.actions;
  
    return {
      actions: bindActionCreators(
        { setPharmacy },
        dispatch
      ),
    };
  };

export default connect(null, mapDispatchToProps)(ChangePharmacyModal)
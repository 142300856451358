import types from "./types"

const initState = {
    rxErrors: [],
    rxRequests: [],
    isLoading: false
};

export default function rxRenewal(
    state = initState,
    action,
) {
    switch (action.type) {
        case types.SET_IS_LOADING:
            return {
                ...state,
                isLoading: action.payload
            };
        case types.SET_RX_REQUESTS:
            return {
                ...state,
                rxRequests: action.payload
            };
        case types.SET_RX_ERRORS:
            return {
                ...state,
                rxErrors: action.payload,
            };
        default:
            return state;
    }
}
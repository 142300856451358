import actions from "./actions";
import reducer from "./reducer";

const incompleteCases = {
  actions,
  reducer,
  name: "incompleteCases",
};

export default incompleteCases;

import { useState, useEffect, useRef } from 'react';
import { message } from 'antd'

import PrescriptionsApi from '../../../../../axios/prescription/PrescriptionsApi'

export const usePharmacy = ({ 
    _case, 
    setPharmacy, 
    setShowChangePharmacyModal 
}) => {
    const [pharmacySearchResults, setPharmacySearchResults] = useState([])
    const [pharmaciesLoading, setPharmaciesLoading] = useState(false)

    const abortController = useRef(null)

    const getPharmacyDetails = async pharmacyId => {
        try {
            const { data: { pharmacy } } = await PrescriptionsApi.getPharmacybyId(pharmacyId)
            return pharmacy
        } catch (e) {
            throw new Error('Error fetching pharmacy')
        }
    }

    const searchPharmacies = params => {
        setPharmaciesLoading(true)
        setPharmacySearchResults([])

        abortController.current?.abort()
        abortController.current = new AbortController()

        try {
            PrescriptionsApi.searchPharmacies(params)
                .then(({ data: { pharmacies }, config: { body } }) => {
                    if (!abortController.current?.signal || !body?.signal.aborted) {
                         setPharmacySearchResults(pharmacies)
                         setPharmaciesLoading(false)
                    }
                })
        } catch (e) {
            setPharmaciesLoading(false)
            throw new Error('Error fetching patient proximity pharmacies')
        }
    }

    const handleSelectPharmacy = async name  => {
        const newPharmacy = pharmacySearchResults.find(pharmacy => pharmacy.name === name)
        setPharmacy(newPharmacy)
        setPharmacySearchResults([])
        setShowChangePharmacyModal(false)
    }

    const handleCancelSearch = () => {
        setPharmacySearchResults([])
        setShowChangePharmacyModal(false)
    }

    useEffect(() => {
        if (_case) {    
            setPharmaciesLoading(true)
            getPharmacyDetails(_case.pharmacyId)
                .then(setPharmacy)
                .catch(() => message.error('Error fetching default pharmacy'))
                .finally(() => setPharmaciesLoading(false))
        }
    }, [_case]) 

    return { 
        pharmacySearchResults,
        setPharmacySearchResults,
        pharmaciesLoading,
        getPharmacyDetails,
        searchPharmacies,
        handleSelectPharmacy,
        handleCancelSearch
    }
}
import types from "./types"

const initState = {
    rxRenewalCaseCountLoading: false,
    rxRenewalCaseCount: 0,
};

export default function rxRenewal(
    state = initState,
    action,
) {
    switch (action.type) {
        case types.SET_RX_RENEWAL_CASE_COUNT_LOADING:
            return {
                ...state,
                rxRenewalCaseCountLoading: action.data,
            };
        case types.SET_RX_RENEWAL_CASE_COUNT:
            return {
                ...state,
                rxRenewalCaseCount: action.payload,
            };
        default:
            return state;
    }
}
import { 
    useState, 
    useEffect 
} from 'react'

import { 
    Skeleton, 
    message 
} from 'antd'

import PrescriptionsAPI from '../../../../../../../../axios/prescription/PrescriptionsApi'

import { useCaseViewContext } from '../../../../../../context/CaseViewContext'

import { CancelPrescriptionModal } from './CancelPrescription.Modal';
import { Prescription } from './Prescription'

export const PrescribedTreatments = () => {
    const { caseDetails, activeRequest } = useCaseViewContext()
    const [rxHistory, setRxHistory] = useState(null)
    const [selectedPrescriptionForCancelation, selectPrescriptionForCancelation] = useState(null)

    const getPatientPrescriptionHistory = async () => {
        const activeRequestIndex = caseDetails.requests.map(request => request._id).indexOf(activeRequest._id) || 0
        const nextRequest = caseDetails.requests[activeRequestIndex + 1]

        const rxWindow = {
            startDate: activeRequest.createdAt,
            ...(nextRequest?.createdAt && { endDate: nextRequest.createdAt })
        }

        try {
            const { data: { prescriptions } } = await PrescriptionsAPI.getPatientPrescriptionHistory(caseDetails?.patientId, rxWindow)
            const prescribedMedicationsForRxRequest = prescriptions.filter(rx => rx.prescriberStatus?.toLowerCase() !== 'deleted')
            setRxHistory(prescribedMedicationsForRxRequest)
        } catch (e) {
            setRxHistory([])
            message.error('Error fetching patient Rx history')
            console.log(e)
        }
    }

    const handleCancelPrescription = async prescriptionId => {
        try {
            const { responseCode } = await PrescriptionsAPI.cancelPrescription(prescriptionId)
            if (!responseCode === '200') throw new Error('Error cancelling rx request prescription')

            const updatedRxHistory = rxHistory.map(rx => {
                if (rx.prescriptionId !== prescriptionId)
                    return rx
                return { ...rx, medicationStatus: 'CancelPending' }
            })

            setRxHistory(updatedRxHistory)
            selectPrescriptionForCancelation(null)
        } catch (e) {
            console.error(e)
            message.error('Error cancelling rx request prescription')
        }
    }

    useEffect(() => {
        if (caseDetails && activeRequest) {
            getPatientPrescriptionHistory()
        }
    }, [caseDetails, activeRequest])

    if (!rxHistory || rxHistory.length === 0) {
        return (
            <div>
                <p>No Prescribed Medications</p>
            </div>
        )
    }

    return (
        <Skeleton loading={!rxHistory}>
            {
                (rxHistory || [])
                    .sort((a, b) => new Date(b.signedAt) - new Date(a.signedAt))
                    .map(prescription => (
                    <Prescription
                        key={prescription.prescriptionId}
                        prescription={prescription}
                        selectPrescriptionForCancelation={selectPrescriptionForCancelation}
                    />
                ))
            }
            {selectedPrescriptionForCancelation &&
                <CancelPrescriptionModal
                    prescription={selectedPrescriptionForCancelation}
                    deselectRx = {() => selectPrescriptionForCancelation(null)}
                    cancelPrescription={handleCancelPrescription}
                />
            }
        </Skeleton>
    )
}
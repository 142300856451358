import React from 'react'
import { useSelector } from 'react-redux'
import { Modal } from 'antd'

import { PharmacySearchForm } from './PharmacySearchForm'
import { VeritasSpinner } from '../../../../../../components/VeritasSpinner'

import { usePharmacy } from './usePharmacy'

export const ChangePharmacyModal = () => {
    
    const { 
        handleCancelSearch,
        ...rest
    } = usePharmacy()

    const { 
        showChangePharmacyModal, 
        pharmaciesLoading, 
    } = useSelector(state => state.prescribeMedicine)

    return (
        <Modal
            visible={showChangePharmacyModal}
            title="Change Pharmacy"
            onCancel={handleCancelSearch}
            footer={null}
      >
        <VeritasSpinner spinning={pharmaciesLoading}>
            <PharmacySearchForm {...rest}/>
        </VeritasSpinner>
      </Modal>
    )
}
import React from 'react'

import { Typography } from 'antd'
import moment from 'moment-timezone'

const { Text } = Typography

const ContainerStyle = {
    display: 'flex',
    width: '30%',
    'flex-direction': 'column',
    'margin-bottom': '30px',
    justifyContent: 'center',
    background: '#ffffff',
    padding: '10px 30px',
    'box-shadow': '1px 10px 95px -13px rgba(0,0,0,0.31)'
}

export const Patient = ({ patient }) => {
    if (!patient) return null
    return (
        <div style={ContainerStyle}>
            <Text style={{ fontWeight: 'bolder'}}>Patient</Text>
            <Text>{patient.firstName} {patient.middleName || ''} {patient.lastName}</Text>
            { patient.dob && <Text>DOB: {moment(patient.dob).utc().format('MM/DD/YYYY')}</Text> }
        </div>
    )
}
/* eslint-disable no-template-curly-in-string */
/* eslint-disable react-hooks/exhaustive-deps */
//import packages
import React from "react";
import { Typography, Row, Col, Form } from "antd";

// import general fuctions and API function
import { dateOfBirthFormatter } from "../../utils/dateFormatter";

const { Text } = Typography;

const GeneralUpdateViewComponent = ({ title, properties, data }) => {
  const propertyKeyMapping = {
    firstName: "First Name",
    lastName: "Last Name",
    dob: "DOB",
    age: "Age",
    address1: "Address Line 1",
    address2: "Address Line 2",
    phone: "Phone",
    city: "City",
    email: "Email",
    state: "State",
    zipCode: "Zip Code",
  };
  return (
    <section>
      <h3 className="primary-font-color">
        <b>{title ? title : "General Patient Details"}</b>
      </h3>
      <Form name="patient-general-details">
        <Row>
          {properties.map((eachProperty) => (
            <Col xs={24} sm={24} md={24} lg={24} xl={24} key={eachProperty}>
              <Form.Item label={propertyKeyMapping[eachProperty]}>
                {eachProperty === "dob" ? (
                  <Text className="primary-font-color" strong>
                    {data && data[eachProperty]
                      ? dateOfBirthFormatter(data[eachProperty])
                      : ""}
                  </Text>
                ) : (
                  <Text className="primary-font-color" strong>
                    {data && data[eachProperty] ? data[eachProperty] : ""}
                  </Text>
                )}
              </Form.Item>
            </Col>
          ))}
        </Row>
      </Form>
    </section>
  );
};

export default GeneralUpdateViewComponent;

import React from 'react'
import { PatientCard } from '../PatientCard'
import { Button } from 'antd'

import {
    ConfirmPatientReconcilationContainer,
    StyledBackButton
} from './styles'

export const ConfirmPatientReconcilation = ({
    mergedPatient,
    handleReconcile,
    setMergedPatientConfirmed
}) => {
    return (
        <ConfirmPatientReconcilationContainer>
          <PatientCard
            title="Confirm Selection"
            patient={mergedPatient}
            actions={[
              <Button
                type="primary"
                onClick={handleReconcile}
              >
                Consolodate
              </Button>
            ]}
          />
          <StyledBackButton onClick={() => setMergedPatientConfirmed(false)}>Back to Merge</StyledBackButton>
        </ConfirmPatientReconcilationContainer>
    )
}
import jwtDecode from 'jwt-decode';

const decodeToken = (token) => {
    let decodedToken = {};
    try {
        decodedToken = jwtDecode(token);
    } catch (error) {
        console.log("=========================== token decoding error ==========================", error);
        return {};
    }
    return decodedToken;
}

export default decodeToken;
const types = {
    SET_PENDING_PRESCRIPTION_CASE_NOTIFICATION: 'SET_PENDING_PRESCRIPTION_CASE_NOTIFICATION',
    SET_AUDIO_VIDEO_CASE_NOTIFICATION: 'SET_AUDIO_VIDEO_CASE_NOTIFICATION',
    SET_RX_CHANGE_CASE_NOTIFICATION: 'SET_RX_CHANGE_CASE_NOTIFICATION',
    SET_ADDITIONAL_INFO_CASE_UPDATE_NOTIFICATION: 'SET_ADDITIONAL_INFO_CASE_UPDATE_NOTIFICATION',
    SET_CASE_MESSAGE_NOTIFICATION: 'SET_CASE_MESSAGE_NOTIFICATION',
    SET_UNASSIGNED_CASE_NOTIFICATION: 'SET_UNASSIGNED_CASE_NOTIFICATION',
    SET_RX_REQUEST_NOTIFICATION: 'SET_RX_REQUEST_NOTIFICATION',
    CLEAR_RX_REQUEST_NOTIFICATION: 'CLEAR_RX_REQUEST_NOTIFICATION'
};

export default types; 
import Axios from "./Axios";
import config from "../config";

const { api } = config;

export default class API {
  static getToken() {
    const token = window.localStorage.getItem("token");
    return token;
  }

  static getLegalAgreement() {
    const token = this.getToken();
    return Axios({
      url: `${api.baseUrl}/identity-verification/legal-agreement`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  static acceptLegalAgreement(data) {
    const token = this.getToken();
    return Axios({
      url: `${api.baseUrl}/identity-verification/legal-agreement`,
      method: "POST",
      data,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  static getIDPDisclaimer() {
    const token = this.getToken();
    return Axios({
      url: `${api.baseUrl}/identity-verification/idp-disclaimer`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  static acceptIDPDisclaimer(idpDisclaimerId) {
    const token = this.getToken();
    return Axios({
      url: `${api.baseUrl}/identity-verification/idp-disclaimer`,
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: { idpDisclaimerId }
    });
  }

  static provideIdentity(data) {
    const token = this.getToken();
    return Axios({
      url: `${api.baseUrl}/identity-verification/verify`,
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data
    });
  }

  static proveIdentity(data) {
    const token = this.getToken();
    return Axios({
      url: `${api.baseUrl}/identity-verification/prove`,
      method: "POST",
      data,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  static initializeTFAActivation() {
    const token = this.getToken();
    return Axios({
      url: `${api.baseUrl}/identity-verification/initialize-activate-tfa`,
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  static requestTFAActivation(data) {
    const token = this.getToken();
    return Axios({
      url: `${api.baseUrl}/identity-verification/request-activate-tfa`,
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data
    });
  }

  static activateTFA(data) {
    const token = this.getToken();
    return Axios({
      url: `${api.baseUrl}/identity-verification/activate-tfa`,
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data
    });
  }

  static setPIN(data) {
    const token = this.getToken();
    return Axios({
      url: `${api.baseUrl}/identity-verification/set-pin`,
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data
    });
  }
}
import React from "react";
import { Tooltip } from "antd";
import {
  ReloadOutlined,
  PhoneOutlined,
  MedicineBoxOutlined,
  SwapOutlined,
  ExperimentOutlined
} from "@ant-design/icons";
import { StyledVideoIcon } from "./StyledComponents";
import { CaseStatusEnum } from "../../types/enums"

const StatusIcon = ({ status }) => {
  switch(status) {
    case CaseStatusEnum.PENDING_SCHEDULE:
      return (
        <Tooltip title="Sync Case">
          <PhoneOutlined style={{ fontSize: '18px'}}/> / <StyledVideoIcon style={{ fontSize: '18px'}} />
        </Tooltip>
      )
    case CaseStatusEnum.RX_RENEWAL:
      return (
        <Tooltip title="Rx Renewal">
          <ReloadOutlined style={{ fontSize: '20px'}}/>
        </Tooltip>
      )
    case CaseStatusEnum.RX_CHANGE:
      return (
        <Tooltip title="Rx Change">
          <SwapOutlined style={{ fontSize: '20px'}}/>
        </Tooltip>
      )
      case CaseStatusEnum.PENDING_LABS:
        return (
          <Tooltip title="Labs">
            <ExperimentOutlined style={{ fontSize: '20px'}}/>
          </Tooltip>
        )
    case CaseStatusEnum.PENDING_PRESCRIPTION:
    case CaseStatusEnum.CASE_CREATED:
    default:
      return (
        <Tooltip title="New Async Case">
          <MedicineBoxOutlined style={{ fontSize: '20px'}}/>
        </Tooltip>
      )
  }
}

export default StatusIcon;

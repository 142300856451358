import React, { useState } from 'react'
import { Skeleton, Empty, Radio, Badge } from 'antd'
import { PlusOutlined } from '@ant-design/icons'

import { StyledButton } from '../../../Button'
import { ChartingEntryModal } from './ChartingEntry.Modal'
import { ProviderNoteModal } from './ProviderNote.Modal'
import { ChartingEntry } from './ChartingEntry'
import { ProviderNote } from './ProviderNote'

import { useProviderNotes } from './hooks/useProviderNotes'
import { useCharting } from './hooks/useCharting'

import { useCaseViewContext } from '../../../../context/CaseViewContext'

import { Container } from "../../../../Styles"
import { Header } from "./styles"

export const Charting = () => {
    const { caseDetails } = useCaseViewContext()

    const [state, setState] = useState({
        contentType: 'charting',
        selectedItem: null,
        modalMode: null
    })

    const onContentTypeChange = ({ target }) => {
        setState(prev => ({
            ...prev,
            contentType: target.value
        }))
    }

    const onModalModeChange = modalMode => {
        setState(prev => ({
            ...prev,
            modalMode
        }))
    }

    const onSelect = selectedItem => {
        setState(prev => ({
            ...prev,
            selectedItem,
            modalMode: 'edit'
        }))
    }

    const onCancel = () => {
        setState(prev => ({
            ...prev,
            modalMode: null,
            selectedItem: null
        }))
    }

    const charting = useCharting({ state, setState })
    const notes = useProviderNotes({ state, setState })

    const FormComponent = state.contentType === 'charting'
        ? ChartingEntryModal
        : ProviderNoteModal

    const ItemComponent = state.contentType === 'charting'
        ? ChartingEntry
        : ProviderNote

    const itemProps = {
        onSelect,
        onEdit: state.contentType === 'charting'
            ? null
            : notes.editProviderNote,
        onDelete: state.contentType === 'charting'
            ? charting.deleteChartingEntry
            : notes.deleteProviderNote
    }

    const items = state.contentType === 'charting'
        ? charting.chartingState.entries
        : notes.notesState.entries

    const hook = state.contentType === 'charting'
        ? charting
        : notes

    return (
        <Container>
            <Header>
                <Radio.Group
                    onChange={onContentTypeChange}
                    value={state.contentType}
                >
                    <Radio.Button value={'charting'}>Charting</Radio.Button>
                    <Badge count={notes.unreadCount}>
                        <Radio.Button
                            button
                            value={'notes'}
                            onClick={notes.clearUnreadNotesCount}
                        >
                            Notes
                        </Radio.Button>
                    </Badge>
                </Radio.Group>
                <StyledButton
                    Icon={PlusOutlined}
                    borderColor="#7BAD7E"
                    textColor="#7BAD7E"
                    color="white"
                    tooltip='Add Note'
                    onClick={() => onModalModeChange('add')}
                />
            </Header>
            <Skeleton loading={!caseDetails} paragraph={{ rows: 6 }}>
                {!items.length && <Empty />}
                {
                    items.map(item => (
                        <ItemComponent
                            item={item}
                            {...itemProps}
                        />
                    ))
                }
            </Skeleton>
            <FormComponent
                {...state}
                {...hook}
                onCancel={onCancel}
            />
        </Container>
    )
}
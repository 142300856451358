import React, { useEffect, useContext, useMemo, useRef, useCallback } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { bindActionCreators } from "redux";
import { Table, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import incompleteCases from "../../redux/incompleteCases";
import { TablePreferencesContext } from "../../context/TablePreferencesProvider";
import MessagesNavigator from "../../components/MessagesNavigator";
import { dateTimeFormatter } from "../../utils/dateFormatter";
import { CaseFilterMap } from "../../constants/CaseFilter"
import { getDateFieldSorter, getTextFieldSorter, getSortOrder } from '../../utils/sorters';
import { createTableQueryParamMap, createTableSortByMap } from "../../utils/createCaseFilterQueryParamMap";
import { userConstants } from "../../constants";


const TABLE_NAME = "incomplete cases";

const TableDataMap = {
  CASE_ID: CaseFilterMap.CASE_ID,
  PATIENT_NAME: `${CaseFilterMap.PATIENT_FIRST_NAME} ${CaseFilterMap.PATIENT_LAST_NAME}`,
  TELEMEDICINE_CATEGORY_ID: CaseFilterMap.TELEMEDICINE_CATEGORY_ID,
  UPDATED_DATE: CaseFilterMap.UPDATED_DATE,
  STATUS: CaseFilterMap.STATUS,
  UNREAD_CHAT_NOTIFICATION: 'unreadChatNotification',
}

const TableSortByMap = createTableSortByMap(TableDataMap);

const getTableColumns = (sortOrder, sortBy, navigateToMessages) => {
  const columns = [
    {
      title: "Case No",
      key: TableDataMap.CASE_ID,
    },
    {
      title: "Patient",
      key: TableDataMap.PATIENT_NAME,
    },
    {
      title: "Category",
      key: TableDataMap.TELEMEDICINE_CATEGORY_ID,
    },
    {
      title: "Last Updated",
      key: TableDataMap.UPDATED_DATE,
    },
    {
      title: "Status",
      key: TableDataMap.STATUS,
    },
    {
      width: 100,
      key: TableDataMap.UNREAD_CHAT_NOTIFICATION,
      render: (_case) => (
        <MessagesNavigator
          caseId={_case[TableDataMap.CASE_ID]}
          count={_case[TableDataMap.UNREAD_CHAT_NOTIFICATION]}
          navigateToMessages={navigateToMessages}
        />
      ),
    },
  ];

  columns.forEach((c) => {
    if([TableDataMap.UNREAD_CHAT_NOTIFICATION].includes(c.key)) {
      return;
    }
    c.dataIndex = c.key;
    if ([TableDataMap.CASE_ID].includes(c.key)) {
      return
    }
    c.sortOrder = getSortOrder(sortOrder, sortBy, c.key);
    const isDateField = [TableDataMap.CREATED_DATE].includes(c.key);
    c.sorter = isDateField ? getDateFieldSorter(c.key) : getTextFieldSorter(c.key);
  });

  return columns;
}

const IncompleteCases = (props) => {
  const {
    actions,
    incompleteCases,
    incompleteCasesCount,
    incompleteCasesLoading,
  } = props;
  const history = useHistory();
  const { getTablePreferences, updateTablePreferences } = useContext(TablePreferencesContext);
  const { currentPage, tablePageSize, sortOrder, sortBy } = getTablePreferences(TABLE_NAME);

  const fetchCases = useCallback(() => {
    const newTablePreferences = {
      currentPage,
      tablePageSize,
      sortOrder,
      sortBy: TableSortByMap[sortBy] || sortBy,
    };
    const tableFilterMap = createTableQueryParamMap(newTablePreferences);
    actions.getIncompleteCases(tableFilterMap);
  }, [actions, currentPage, tablePageSize, sortOrder, sortBy]);

  useEffect(() => {
    fetchCases();
  }, [fetchCases]);

  const navigateToMessages = useRef((caseId) => {
    history.push(`/dashboard/${userConstants.USER_PHYSICIAN}/messages`);
    actions.setChatCaseId(caseId);
  });

  const columns = useMemo(() => {
    return getTableColumns(sortOrder, sortBy, navigateToMessages.current);
  }, [sortOrder, sortBy]);

  const pushRouteCaseDetails = (caseId) => {
    history.push(
      `/dashboard/${userConstants.USER_PHYSICIAN}/caseview/${caseId}`,
      { from: history.location.pathname.split('/').pop() }
    );
  };

  const handleCaseListClick = (event, record) => {
    event.stopPropagation();
    pushRouteCaseDetails(record[TableDataMap.CASE_ID]);
  };

  const onChangePage = (page) => {
    updateTablePreferences(TABLE_NAME, { currentPage: page })
  };

  const onPageSizeChange = (_, size) => {
    updateTablePreferences(TABLE_NAME, { currentPage: 1, tablePageSize: size })
  };

  const onChangeOrder = (_pagination, _filters, sorter) => {
    updateTablePreferences(TABLE_NAME, {
      sortOrder: sorter.order || 'ascend',
      sortBy: sorter.field,
    });
  }

  const casesForTable = incompleteCases.map((c) => {
    return {
      key: c.caseId,
      rowKey: c.caseId,
      [TableDataMap.CASE_ID]: c.caseId,
      [TableDataMap.PATIENT_NAME]: c.patientName,
      [TableDataMap.TELEMEDICINE_CATEGORY_ID]: c.category,
      [TableDataMap.UPDATED_DATE]: dateTimeFormatter(c.updatedDate),
      [TableDataMap.STATUS]: c.status,
      [TableDataMap.UNREAD_CHAT_NOTIFICATION]: c.unreadChatNotification,
    }
  })

  return (
    <div className="consulted-case">
      <div className="waiting-room-case-list-part-heading">
        <div>
          <h1 style={{ marginBottom: "0" }}>Incomplete Cases</h1>
          <h4>No action taken within 24 hours of starting a consultation</h4>
        </div>
      </div>
      <div className="consulted-case-no-of-patient-list-data">
        <Spin
          size="large"
          spinning={incompleteCasesLoading}
          indicator={<LoadingOutlined spin />}
        >
          <div>
            <Table
              className="wating-room-table"
              columns={columns}
              onChange={onChangeOrder}
              dataSource={casesForTable}
              pagination={{
                total: incompleteCasesCount,
                showTotal: (total) =>
                  `Total ${total} Incomplete Case${total > 1 ? "s" : ""}`,
                pageSizeOptions: ["5", "10", "20", "50", "100"],
                showSizeChanger: true,
                current: currentPage,
                pageSize: tablePageSize,
                onChange: onChangePage,
                onShowSizeChange: onPageSizeChange,
              }}
              scroll={{ x: 900 }}
              onRow={(record) => ({
                onClick: (event) => handleCaseListClick(event, record),
              })}
            />
          </div>
        </Spin>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { incompleteCases, incompleteCasesCount, incompleteCasesLoading } =
    state.incompleteCases;

  return {
    incompleteCases,
    incompleteCasesCount,
    incompleteCasesLoading,
  };
};

const mapDispatchToProps = (dispatch) => {
  const { getIncompleteCases } = incompleteCases.actions;
  return {
    actions: bindActionCreators(
      {
        getIncompleteCases,
      },
      dispatch
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(IncompleteCases);

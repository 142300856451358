import Axios from "../Axios";
import config from "../../config";

const { api } = config;

export default class LabsApi {
  static getToken() {
    const token = window.localStorage.getItem("token");
    return token;
  }

  static fetchLabOrderIframe(caseId) {
    const token = this.getToken();
    return Axios({
      url: `${api.baseUrl}/labs/order`,
      params: { caseId },
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
  }

  static fetchResourcePdf({ resourceType, resourceId }) {
    return new Promise((resolve, reject) => {
        const token = this.getToken();
      
        const xhr = new XMLHttpRequest();
        xhr.open('GET', `${config.api.baseUrl}/labs/${resourceType}s/${resourceId}`);
        xhr.onreadystatechange = handler;
        xhr.responseType = 'blob';
        xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        xhr.send();

        function handler() {
          if (this.readyState === this.DONE) {
            if (this.status === 200) {
              resolve({ data: URL.createObjectURL(this.response) })
            } else {
              reject('Error fetching document')
            }
          }
        }
    })
  }
}

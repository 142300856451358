import React from 'react'
import { Form, Input } from 'antd'
import { required } from '../validation'

export const Sig = () => {
    const MAX_CHARACTER_COUNT = 150
    return (
        <Form.Item
            label='Sig'
            name="directions"
            rules={[required]}
        >
            <Input.TextArea
                maxLength={MAX_CHARACTER_COUNT}
                showCount
                placeholder={`Limited to ${MAX_CHARACTER_COUNT} characters...`}
            />
        </Form.Item>
    )
}
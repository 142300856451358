import React, { useState, useEffect } from "react";
import { Modal, Button, Spin, message, Input } from "antd";
import RxRequestDetails from "../components/RxRequestDetails"
import { EditRxRequestForm } from "../forms/EditRxRequestForm";
import DenyRequestForm from "../forms/DenyRequestForm"
import moment from 'moment-timezone'
import { WarningOutlined } from "@ant-design/icons";

import PrescriptionsApi from "../../../axios/prescription/PrescriptionsApi";
import { DispenseUnitProvider } from "../context/DispenseUnitContext";


export const RxRequestModal = ({
    provider,
    selectedRxRequest,
    selectRxRequest,
    updatePrescription,
    prescription,
    fetchRxRequests,
    requestedMedications,
    prescriptionIsValid,
    setPrescriptionIsValid
}) => {
    const [current, setCurrent] = useState(0);
    const [requestedMedicationChanged, setRequestedMedicationChanged] = useState(false)
    const [noteToPharmacy, setNoteToPharmacy] = useState(null)
    const [priorAuthNumber, setPriorAuthNumber] = useState(null)
    const [loading, setLoading] = useState(false)
    const [denialReason, setDenialReason] = useState(null)
    const [comment, setComment] = useState(null)
    const [selectedRxForReconciliation, selectRxForReconciliation] = useState(null)
    
    const [auth, setAuth] = useState({
        useTFA: false,
        PIN: null,
        OTP: null
    })

    const { originalPrescription, patient, reconciliationOptions } = selectedRxRequest

    let schedule

    // this should be prescription.ingredients.length but schedule prop not being sent so falling back on orginalPrescription
    if (originalPrescription?.ingredients?.length) {
        const ingredientSchedules = originalPrescription.ingredients.map(ingredient => ingredient.schedule)
        schedule = Math.max(...ingredientSchedules)
    } else {
        // this should be prescription.schedule but schedule prop not being sent so falling back on orginalPrescription
        schedule = Number(originalPrescription?.schedule) || null
    }

    const isControlledSubstance = 
        (schedule && schedule.toString()) !== "0" ||
        originalPrescription?.ingredients?.some(ingredient => ingredient?.schedule && ingredient?.schedule.toString() !== "0")

    useEffect(() => {
        if (!!selectedRxRequest?.reconciliationOptions?.length) {
            selectRxForReconciliation(JSON.stringify(selectedRxRequest?.reconciliationOptions?.[0]))
        }
    }, [selectedRxRequest])

    const isPtDOBMatch = () => {
        const formattedDate = date => `${new Date(date).getUTCFullYear()}-${new Date(date).getUTCMonth()}-${new Date(date).getUTCDate()}`;
        const ptRxDOB = originalPrescription?.externalMetadata?.patient.dob
        const ptPharmacyDOB = patient.dob
        return !ptRxDOB || formattedDate(ptRxDOB) === formattedDate(ptPharmacyDOB)
    }

    const handleCancelPrescription = async () => {
        const { updatedPrescription } = selectedRxRequest

        try {
            setLoading(true)
            const { responseCode } = await PrescriptionsApi.cancelPrescription(updatedPrescription._id)
            if (!responseCode === '200') throw new Error('Error cancelling rx request prescription')
            selectRxRequest(null)
            fetchRxRequests()
        } catch (e) {
            console.error(e)
            message.error('Error cancelling rx request prescription')
        } finally {
            setLoading(false)
        }
    }

    const generatePayload = action => {
        if (action === 'deny') return { denialReason, comment }
        const { originalPrescription, pharmacyRequestedMedications, type } = selectedRxRequest

        if (type === 'rx change') {
            if (!requestedMedicationChanged) return { selectedMedicationId: pharmacyRequestedMedications[0].prescriptionId }
            return { newPrescription: prescription } 
        }

        if (isControlledSubstance) {
            return { newPrescription: prescription }
        }

        if (requestedMedicationChanged) {
            if (prescription?.ndc !== originalPrescription?.ndc) return { newPrescription: prescription }
            return { refills: prescription.refills }
        }
        return {}
    } 

    const handleResponse = async action => {
        const { 
            originalPrescription, 
            rxRequestId, 
            patient, 
            pharmacyRequestedMedications, 
            prescriberMetadata, 
            type 
        } = selectedRxRequest

        const isTheraputicInterchange = prescriberMetadata.rxChangeType?.toLowerCase() ==='therapeutic interchange'

        const isReplaceRequest = 
            action !== 'deny' &&
            type === 'rx renewal' &&
            (
                isControlledSubstance || 
                (
                    !isTheraputicInterchange && 
                    requestedMedicationChanged && 
                    originalPrescription && 
                    prescription.ndc && 
                    prescription.ndc !== originalPrescription?.ndc
                )
            )
        
        if (action !== 'deny' && isControlledSubstance) {

            // this should be prescription.schedule but schedule prop not being sent so falling back on orginalPrescription

            switch(Number(originalPrescription.schedule)) {
                case 1:
                    if (Number(prescription.refills) > 1) {
                        throw new Error('Schedule 1 drugs cannot be refilled')
                    }
                case 2:
                    if (Number(prescription.refills) > 5) {
                        throw new Error('Schedule 2 drugs cannot be refilled more than 5 times')
                    }
                case 3:
                    if (moment(prescription.startDate).isAfter(moment(prescription.startDate).add(6, 'months'))) {
                        throw new Error('Controlled substances prescriptions must start within the next six months')
                    }
                    if (Number(prescription.daysSupply) > 90) {
                        throw new Error('Controlled substance prescriptions cannot include a days supply value of greater than 90 days')
                    }
                    break
                default:
                    throw new Error('Controlled subtance medication not handled')
            }


            // if (Number(prescription.schedule) <= 2) {
            //     if (Number(prescription.refills) > 1) {
            //         throw new Error('Schedule 1 and 2 drugs cannot be refilled')
            //     }
            // }

            // if (Number(prescription.schedule) === 3) {
            //     if (moment(prescription.startDate).isAfter(moment(prescription.startDate).add(6, 'months'))) {
            //         throw new Error('Controlled substances prescriptions must start within the next six months')
            //     }
    
            //     if (Number(prescription.daysSupply) > 90) {
            //         throw new Error('Controlled substance prescriptions cannot include a days supply value of greater than 90 days')
            //     }
            // }

            // if (Number(prescription.refills) > 5) throw new Error('Schedule 1 drugs cannot be refilled more than 5 times')
        }


        
        const payload = generatePayload(action)
        noteToPharmacy?.length && (payload.comment = noteToPharmacy)

        if (priorAuthNumber) {
            payload.priorAuthNumber = priorAuthNumber
            payload.selectedMedicationId = pharmacyRequestedMedications[0].prescriptionId
        }

        const requestBody = { 
            requestId: rxRequestId, 
            action: isReplaceRequest ? 'replace' : action,
            ...payload,
            ...auth
        }

        if (selectedRxForReconciliation) {
            requestBody.referencedPrescription = JSON.parse(selectedRxForReconciliation)
        }

        try {
            setLoading(true)
       
            const { responseCode } = await PrescriptionsApi.approveOrDenyRxRequest(requestBody)

            if (!responseCode === '200') throw new Error('Error responding to prescription request')

            if (!isPtDOBMatch() && originalPrescription?.externalMetadata?.patient._id) {
                const { responseCode } = await PrescriptionsApi.updatePatient(originalPrescription?.externalMetadata?.patient._id, { dob: patient.dob })
                if (!responseCode === '200') throw new Error('Error updating patient record with new DOB')
            }

            selectRxRequest(null)
            setRequestedMedicationChanged(false)
            setCurrent(0)
            fetchRxRequests()

        } catch (e) {
                console.error(e)
                message.error(e.response?.data?.message || 'Error handling rx request')
        } finally {
            // TODO: update redux store with new correct request count
            setLoading(false)
        }
    }

    const handleCancel = () => {
        selectRxRequest(null)
        setRequestedMedicationChanged(false)
        setCurrent(0)
    }

    const handleUpdatePrescription = () => {
        setRequestedMedicationChanged(true)
        setCurrent(0)
    }

    const handleSetPin = ({ target }) => {
        setAuth(prev => ({ ...prev, PIN: target.value }))
    }

    const ModalTitle = () => {
        let title = selectedRxRequest.type 
        let warning = null

        if (selectedRxRequest.prescriberMetadata?.rxChangeType) {
            title += ` - ${selectedRxRequest.prescriberMetadata.rxChangeType}`
        }

        if (selectedRxRequest.prescriberMetadata?.rxChangeSubType) {
            title += `, ${selectedRxRequest.prescriberMetadata.rxChangeSubType}`
        }
        
        if (selectedRxRequest.type.toLowerCase() === 'rx change' && prescription.schedule.toString() === "0") {
            warning = (
                <span style={{ color: 'red', fontSize: '12px', textTransform: 'none' }}>
                    <WarningOutlined style={{ marginRight: '5px'}}/>
                    Controlled substances cannot be prescribed or changed via an Rx Change request. Please submit new prescription.
                </span>
            )
        }

        return <div style={{ textTransform: 'capitalize', display: 'flex', flexDirection: 'column'}}>{title}{warning}</div>
    }

    const handleEditRequest = () => {
        setCurrent(1)
        setPrescriptionIsValid(false)
    }
    
    const isPriorAuth = selectedRxRequest.prescriberMetadata?.rxChangeType?.toLowerCase() === 'prior authorization'
    const disableOK = 
        (isPriorAuth && !!priorAuthNumber?.toString().length === false) 
        || auth.PIN?.length !== 4
        || (isControlledSubstance && requestedMedicationChanged && !auth.useTFA && !auth.OTP)
        || (isControlledSubstance && selectedRxRequest.type.toLowerCase() === 'rx change')

    const modalFooter = selectedRxRequest.status !== 'pending'
        ? [<Button style={{ textTransform: 'capitalize' }} type="danger" onClick={handleCancelPrescription}>{`Cancel ${selectedRxRequest.status} Prescription`}</Button> ]
        : [
            current === 0 && provider.pinSet && <Input value={auth.PIN} maxLength={4} style={{ width: 100, marginRight: '15px' }} placeholder="PIN" onChange={handleSetPin}/>,
            current === 0 && <Button disabled={loading} key="deny" type="danger"onClick={() => setCurrent(2)}>Deny</Button>,
            current === 0 && <Button disabled={disableOK} key="approve" type="primary" onClick={() => handleResponse('approve')}>Approve</Button>, 
            current === 1 && <Button key="ok" type="primary" disabled={!prescriptionIsValid || loading} onClick={handleUpdatePrescription}>Ok</Button>,
            current === 2 && <Button disabled={!denialReason} key="ok" type="primary" onClick={() => handleResponse('deny')}>Ok</Button> 
        ]

    return (
        <DispenseUnitProvider>
            <Modal
                visible={!!selectedRxRequest}
                title={<ModalTitle />}
                onCancel={handleCancel}
                width={current === 0 ? 1500 : 500}
                footer={modalFooter}
            >
                <Spin spinning={loading}>
                { current === 0 && 
                    <RxRequestDetails 
                        provider={provider}
                        selectedRxRequest={selectedRxRequest} 
                        prescription={prescription}
                        ptDOBMatch={isPtDOBMatch()}
                        editRequest={handleEditRequest }
                        setNoteToPharmacy={setNoteToPharmacy}
                        isPriorAuth={isPriorAuth}
                        priorAuthNumber={priorAuthNumber}
                        setPriorAuthNumber={setPriorAuthNumber}
                        reconciliationOptions={reconciliationOptions}
                        selectedRxForReconciliation={selectedRxForReconciliation}
                        selectRxForReconciliation={selectRxForReconciliation}
                        auth={auth}
                        setAuth={setAuth}
                        requestedMedicationChanged={requestedMedicationChanged}
                    />
                }
                { current === 1 &&
                    <EditRxRequestForm 
                        prescription={prescription}
                        updatePrescription={updatePrescription}
                        requestedMedications={requestedMedications}
                        setPrescriptionIsValid={setPrescriptionIsValid}
                        setRequestedMedicationChanged={setRequestedMedicationChanged}
                    />
                }
                { current === 2 &&
                    <DenyRequestForm 
                        setComment={setComment}
                        setDenialReason={setDenialReason}
                    />
                }
                </Spin>
            </Modal>
        </DispenseUnitProvider>
    );
};
import { useState, useEffect, useMemo } from 'react'
import { debounce } from 'lodash'
import { message } from 'antd'

import TemplatesAPI from "../../../axios/templates/TemplateAPI"
import APIHelper from '../../../axios/apiHelper';

export function useTemplates(type, user) {
    const [loading, setLoading] = useState(false)
    const [selectedTemplate, selectTemplate] = useState(null)
    const [templates, setTemplates] = useState([])
    const [telemedicineCategories, setTelemedicineCategories] = useState([])
    const [caseStatuses, setCaseStatues] = useState([])

    const [templateFilters, setTemplateFilters] = useState({
        owner: user?._id,
        categoryIds: null,
        status: null,
        search: null,
        sortBy: 'title',
        sortDirection: 'asc'
    });

    useEffect(() => {
        try {   
            APIHelper.fetchLookUp('tele-medicine-category')
                .then(({ data }) => setTelemedicineCategories(data.payload))
            APIHelper.fetchLookUp('case-status')
                .then(({ data }) => setCaseStatues(data.payload))
        } catch (e) {
            console.error(e)
        }
    }, [])

    const searchTemplates = async ({ ...params }) => {
        setLoading(true)
        setTemplates([])

        if (type === 'prescription') delete params.caseStatus

        try {
            const { data: { templates } } = await TemplatesAPI.getMany(params)

            if (!templates?.length) return setTemplates([])

            const mappedTemplates = templates.map(template => {
                const additions = {
                    teleMedicineCategories: template.teleMedicineCategoryIds?.map(category => category.name).join(', '),
                    ...template.statusId && { caseStatus:template.statusId.name}
                }

                Object.assign(template, additions)
                return template
            })

            setTemplates(mappedTemplates)
        } catch (e) {
            console.log(e)
        } finally {
            setLoading(false)
        }
    }

    const debouncedSearchTemplates = useMemo(() => debounce(params => searchTemplates({ ...params }), 1000), [])

    const createTemplate = async ({ 
        title, 
        pharmacyNotes, 
        directions, 
        pseudonyms,
        teleMedicineCategoryIds,
        ...medication 
    }) => {
        try {
            const payload = {
                title,
                pharmacyNotes,
                directions,
                pseudonyms,
                teleMedicineCategoryIds,
                medication
            }

            await TemplatesAPI.create(type, payload);
            message.success('Template successfully created')
            resetTemplates()
        } catch (e) {
            throw new Error(e)
        }
    }

    const editTemplate = async (
        templateId, { 
            title, 
            pharmacyNotes, 
            directions, 
            teleMedicineCategoryIds,
            ...medication 
        }
    ) => {
        setTemplates([])
        try {
            const payload = {
                templateId,
                title,
                pharmacyNotes,
                directions,
                teleMedicineCategoryIds,
                medication
            }

            await TemplatesAPI.update(templateId, payload);
            resetTemplates()
        } catch (e) {
            console.error(e)
            throw new Error('Error editing template')
        }
    }

    const deleteTemplate = async templateId => {
        try {
            await TemplatesAPI.deleteOne(templateId);
            resetTemplates()
        } catch (e) {
            console.error(e)
            throw new Error(`Error deleting template`)
        }
    }

    const resetTemplates = () => {
        setTemplateFilters({
            owner: user?._id,
            categoryIds: null,
            status: null,
            search: null,
            sortBy: 'title',
            sortDirection: 'asc'
          })
        setTemplates([])
    }

    useEffect(() => {
        setTemplates([])
        searchTemplates({type, ...templateFilters})
    }, [templateFilters, debouncedSearchTemplates])

    useEffect(() => {
        resetTemplates()
    }, [type])

    return {
        selectTemplate,
        setTemplates,
        createTemplate,
        editTemplate,
        deleteTemplate,
        setTemplateFilters,
        searchTemplates: debouncedSearchTemplates,
        telemedicineCategories,
        caseStatuses,
        type,
        selectedTemplate,
        loading,
        templates,
        templateFilters
    };
}

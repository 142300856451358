import React, { useState, useEffect } from "react";
import styled from "styled-components";

import { Typography, Spin } from "antd";

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
am4core.useTheme(am4themes_animated);

const { Title, Text } = Typography;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  box-shadow: 4px 4px 12px #ccc;
  padding: 20px;
  background: #fff;
`;

const StyledTitle = styled(Title)`
  padding-bottom: 2px;
  border-bottom: 2px solid #eee;
`;

const Chart = styled.div`
  height: 350px;
`;

const ReportPieChart = ({
  title,
  id,
  data,
  fields,
  value,
  category,
  legendPosition,
  loading
}) => {
  const [chart, setChart] = useState(null);
  const [target, setTarget] = useState(null);

  const mapData = () => {
    return Object.entries(data)
      .filter(([k, _]) => fields.includes(k))
      .map(([k, v]) => ({ status: k, cases: v }));
  };

  const findTarget = () => {
    const container = document.getElementById(id);
    if (container !== target) setTarget(container);
    else setTimeout(findTarget, 500);
  };

  // eslint-disable-next-line
  useEffect(findTarget, [data]);

  useEffect(
    () => {
      chart?.dispose();
      if (data && target) {
        const mappedData = mapData();
        const chart = am4core.create(id, am4charts.PieChart);
        chart.data = mappedData;

        let pieSeries = chart.series.push(new am4charts.PieSeries());
        pieSeries.dataFields.value = value;
        pieSeries.dataFields.category = category;
        pieSeries.ticks.template.disabled = true;
        pieSeries.alignLabels = false;
        pieSeries.labels.template.text =
          "{value.percent.formatNumber('#.0')}% ({value})";
        pieSeries.legendSettings.valueText =
          "{value.percent.formatNumber('#.0')}% ({value})";
        pieSeries.labels.template.radius = am4core.percent(-75);
        pieSeries.labels.template.fill = am4core.color("white");
        pieSeries.labels.template.relativeRotation = 90;

        chart.legend = new am4charts.Legend();
        chart.legend.position = legendPosition;
        chart.legend.valueLabels.template.paddingLeft = 30;
        chart.legend.maxWidth = undefined;

        setChart(chart);
      }
    },
    // eslint-disable-next-line
    [data, target],
    () => chart?.dispose()
  );

  return (
    <Container>
      <StyledTitle level={4}>{title}</StyledTitle>
      {loading && <Spin />}
      {data && Object.keys(data).length > 0 ? (
        <Chart id={id} style={{ textTransform: "capitalize" }} />
      ) : (
        !loading && !data?.length && <Text>No Data</Text>
      )}
    </Container>
  );
};

export default ReportPieChart;

import types from "./types"

const initState = {
    scheduledCaseListLoading: false,
    scheduledCaseCountLoading: false,
    scheduledCaseList: [],
    scheduledCaseCount: 0,
};

export default function scheduledCases(
    state = initState,
    action,
) {
    switch (action.type) {
        case types.SET_SCHEDULED_CASE_LIST_LOADING:
            return {
                ...state,
                scheduledCaseListLoading: action.data,
            };
        case types.SET_SCHEDULED_CASE_COUNT_LOADING:
            return {
                ...state,
                scheduledCaseCountLoading: action.data,
            };
        case types.SET_SCHEDULED_CASE_LIST:
            return {
                ...state,
                scheduledCaseList: action.payload,
            };
        case types.SET_SCHEDULED_CASE_COUNT:
            return {
                ...state,
                scheduledCaseCount: action.payload,
            };
        default:
            return state;
    }
}
import React, { useState, useEffect } from "react";
import { Button, Form, Spin, Input, Modal } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

const { TextArea } = Input;

const EditPhysicianNoteComponent = ({
  noteEditLoading,
  editingNote,
  editNoteModalVisible,
  handleEditNoteSubmit,
  handleEditNoteCancel,
}) => {
  const [form] = Form.useForm();
  const [editNoteFields, setEditNoteFields] = useState([
    {
      name: ["title"],
      value: "",
    },
    {
      name: ["note"],
      value: "",
    },
  ]);

  const onEditNoteSubmit = (value) => {
    handleEditNoteSubmit(value);
  };

  const onEditNoteCancel = () => {
    handleEditNoteCancel();
  };

  const handleModalClose = () => {
    form.resetFields();
  };


  const initEditFormValues = () => {
    if (editingNote && Object.keys(editingNote).length) {
      const { title, content } = editingNote;
      setEditNoteFields([
        { name: ["title"], value: title || "" },
        { name: ["note"], value: content || "" },
      ]);
    }
  };

  useEffect(initEditFormValues, [editingNote]);

  return (
    <Modal
      title={"Edit Physician Note"}
      visible={editNoteModalVisible}
      onOk={""}
      onCancel={""}
      footer={null}
      closable={false}
      destroyOnClose={true}
      afterClose={handleModalClose}
    >
      <Spin spinning={noteEditLoading} indicator={<LoadingOutlined />}>
        <Form
          form={form}
          layout={"vertical"}
          onFinish={(value) => onEditNoteSubmit(value)}
          fields={editNoteFields}
          onFieldsChange={(changedFields, allFields) => {
            setEditNoteFields(allFields);
          }}
        >
          <Form.Item
            label="Title"
            name="title"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Note"
            name="content"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <TextArea autoSize={{ minRows: 6, maxRows: 10 }} />
          </Form.Item>
          <Form.Item className="template-message-form-item-button">
            <Button size={"large"} onClick={onEditNoteCancel}>
              Cancel
            </Button>
            <Button htmlType="submit" size="large" type="primary">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
};

export default EditPhysicianNoteComponent;

import React from 'react'
import { Form, Select } from 'antd'

import { useStrength } from './useStrength'
import { required } from '../../validation'

export const Strength = ({
    form,
    selectedMedication,
    selectedStrength,
    setMedicationState,
    handleSelectStrength,
}) => {

    const {
        options
    } = useStrength({
        form,
        selectedMedication,
        selectedStrength,
        setMedicationState
    })

    return (
        <Form.Item
            label="Strength"
            name="strength"
            value={selectedStrength?.strength}
            style={{ display: 'inline-block', width: 'calc(50% - 12px)' }}
        >
            <Select 
                options={options} 
                onChange={handleSelectStrength}
            /> 
        </Form.Item>
    )
}
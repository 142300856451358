import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from 'moment-timezone'
import { Editor } from '@tinymce/tinymce-react';
import styled from 'styled-components'

import {
  Form,
  Input,
  Button,
  Radio,
  Upload,
  Select,
  message,
  Spin,
  Checkbox,
  Tooltip
} from "antd";

import { 
  PlusOutlined, 
  FormOutlined, 
  LoadingOutlined, 
  InfoCircleOutlined 
} from "@ant-design/icons";

import ImageCropperModal from './ImageCropperModal';

import PhysicianApi from "../../../../axios/physician/PhysicianApi";
import api from "../../../../axios/apiHelper";

import { useUserConfig } from "../../../../hooks/useUserConfig";

import config from '../../../../config';
import physicianDetails from "../../../../redux/physicianDetails";

import "./PhysicianAccount.css";

const { apiKeys } = config;

const SettingsContainer = styled.div`
  display: flex;
  gap: 40px;
  margin: 20px auto 20px 2.5%;
`

const PhysicianAccount = ({ actions }) => {
  const { Option } = Select;
  const [form] = Form.useForm();
  const [userData, setUserData] = useState();
  const [DOB, setDOB] = useState();
  const [profileUrl, setProfileUrl] = useState();
  const [updatePic, setUpdatePic] = useState();
  const [fetchstates, setFetchstates] = useState();
  const [children, setChildren] = useState();
  const [loading, setLoading] = useState(true);
  const [edited, setEdited] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [fetchDoctorCredentials, setFetchDoctorCredentials] = useState();
  const [otpVia, setOtpVia] = useState();
  const [isCropping, setIsCropping] = useState(false);
  const [croppingImgSrc, setCroppingImgSrc] = useState();
  const [bio, setBio] = useState('');
  const [queueSMS, setQueueSMS] = useState(false)
  const [config, 
    {
      toggleUseExperimentalFeatures, 
      toggleUseLegacyFeatures
    }
  ] = useUserConfig();

  useEffect(() => {
    const responseCredential = [];

    api.fetchLookUp("state").then((res) => {
      const responseState = res.data.payload.map((state) => ({
        ...state,
        key: state._id,
        value: state.name,
        text: state.name,
      }));

      setFetchstates(responseState);
    });

    api.fetchLookUp("credential").then((res) => {
      res.data.payload.map((eachCredential) => {
        return responseCredential.push({
          key: eachCredential._id,
          value: eachCredential.name,
          text: eachCredential.name,
        });
      });
      setFetchDoctorCredentials(responseCredential);
    });

    PhysicianApi.physicianData()
      .then(({ data }) => {
        const { payload } = data 
        
        
        form.setFieldsValue({
          firstName: payload.firstName,
          lastName: payload.lastName,
          gender: payload.gender,
          cellphone: payload.phone,
          credential: payload.credential,
          deaNumber: payload.deaNumber
        });

        const providerDOB = moment(payload.DOB, "YYYY-MM-DD").format("DD-MM-YYYY");
        
        setDOB(providerDOB);
        setUserData(payload);
        setProfileUrl(`${payload.profilePic1}?v=${Date.now()}`);
        setBio(payload.bio);
        setQueueSMS(payload.preferences?.sendQueueSMSNotifications)
      }).catch(err => {
        console.error(err)
        message.error('Error updating provider info.')
      }).finally(() => {
        setLoading(false)
      })
  }, []);

  useEffect(() => {
    if (fetchstates) {
      const selectValue = fetchstates.map((item) => {
        return (
          <Option value={item.key} key={item.key}>
            {item.value}
          </Option>
        );
      });
      setChildren(selectValue);
    }
  }, [fetchstates]);

  useEffect(() => {
    if (fetchstates && userData) {
      form.setFieldsValue({ licensedStates: userData.licensedStates });
    }
  }, [fetchstates, userData]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (edited === true) {
      PhysicianApi.physicianData().then((res) => {
        setUserData(res.data.payload);
        setProfileUrl(`${res.data.payload.profilePic1}?v=${Date.now()}`);

        var dateString = res.data.payload.DOB;
        var momentObj = moment(dateString, "YYYY-MM-DD");
        var momentString = momentObj.format("DD-MM-YYYY");
        setDOB(momentString);
      });
      setEdited(false);
    }
  }, [edited]);

  function onFormLayoutChange(value) {
    const model = {};

    if (updatePic) {
      model["profilePic1"] = updatePic;
    }

    if (value.firstName) {
      model["firstName"] = value.firstName;
    }
    if (value.lastName) {
      model["lastName"] = value.lastName;
    }

    if (value.gender) {
      model["gender"] = value.gender;
    }

    if (value.credential) {
      model["credential"] = value.credential;
    }

    if (value.licensedStates) {
      model['licensedStates'] = value.licensedStates;
    }

    model["physicianId"] = userData._id;
    model['bio'] = bio;
    model.sendQueueSMSNotifications = queueSMS
    model.deaNumber = value.deaNumber

    setButtonLoading(true);
    updatePhysician(model, userData._id);
  }

  const updatePhysician = (data, id) => {
    PhysicianApi.physicianUpdate(data, id)
      .then(({ data }) => {
        setLoading(false);
        if (data?.success === true) {
          setEdited(true);
          message.success("Sucessfully Updated");
          actions.mapAndSetPhysicianDetails(data.physician)
          setButtonLoading(false);
        } else {
          message.error(data?.message || 'Error updating physician');
          setButtonLoading(false);
        }
      })
      .catch((error) => {
        console.log(error)
        setLoading(false);
        const { response } = error;
        if (response.status === 409) {
          message.error(response.data.message);
        } else {
          const { data } = response;
          if (data && data.message) {
            message.error(data.message);
          } else {
            message.error("Unable to update details,Please try again!");
          }
        }
        setButtonLoading(false);
      });
  };

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div className="ant-upload-text">Upload</div>
    </div>
  );

  function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  function beforeUpload(file) {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }
    return isJpgOrPng && isLt2M;
  }

  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const handleChange = (info) => {
    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }

    if (info.file.status === "done") {
      // Get this url from response in real world.
      getBase64(
        info.file.originFileObj,
        (profileUrl) => {
          setIsCropping(true);
          setCroppingImgSrc(profileUrl)
        },
        setLoading(false)
      );
    }
  };

  const onChangeAuthentication = (e) => {
    const { value } = e.target;
    const data = {};
    if (value === "email") {
      data["enableEmailAuthentication"] = true;
      data["enablePhoneAuthentication"] = false;
    } else if (value === "phone") {
      data["enablePhoneAuthentication"] = true;
      data["enableEmailAuthentication"] = false;
    } else if (value === "remove") {
      data["enableEmailAuthentication"] = false;
      data["enablePhoneAuthentication"] = false;
    }

    setLoading(true);
    updatePhysician(data, userData._id);
  };

  useEffect(() => {
    if (userData) {
      if (userData.enableEmailAuthentication) {
        setOtpVia("email");
      } else if (userData.enablePhoneAuthentication) {
        setOtpVia("phone");
      } else {
        setOtpVia("remove");
      }
    }
  }, [userData]);
  return (
    <>
      <Spin spinning={loading}>
        <div className="myaccount-physician">
          <h1>Profile</h1>
          <Upload
            name="avatar"
            listType="picture-card"
            className="myaccount-profile-pic"
            showUploadList={false}
            customRequest={dummyRequest}
            beforeUpload={beforeUpload}
            onChange={handleChange}
          >
            {profileUrl ? (
              loading ? (
                <div>
                  <LoadingOutlined />
                </div>
              ) : (
                <div className="myaccount-profile-pic-inner-section">
                  <img
                    src={profileUrl}
                    alt="avatar"
                    style={{
                      width: "150px",
                      height: "150px",
                      borderRadius: "50%",
                    }}
                  />
                  <div className="myaccount-profile-pic-edit">
                    <FormOutlined />
                  </div>
                </div>
              )
            ) : (
              uploadButton
            )}
          </Upload>
          <hr></hr>
          <h2 className="myaccount-personal-information-heading">
            Settings
          </h2>
          <SettingsContainer>
            <span>
            <Checkbox 
              checked={queueSMS} 
              disabled={!userData?.phone} 
              onChange={e => setQueueSMS(e.target.checked)}
            >
              SMS Queue Notifications
          </Checkbox>
          <Tooltip title="SMS messages will be sent whenever your available cases queue crosses a 10-case threshold">
            <InfoCircleOutlined />
          </Tooltip>
            </span>
            
          <Checkbox 
              checked={config.useExperimentalFeatures} 
              onChange={toggleUseExperimentalFeatures}
            >
              Use Experimental Features
          </Checkbox>
          <Checkbox 
              checked={config.useLegacyFeatures} 
              onChange={toggleUseLegacyFeatures}
          >
              Use Legacy Features
          </Checkbox>
          </SettingsContainer>
          <hr></hr>

          <h2 className="myaccount-personal-information-heading">
            Personal Information
          </h2>
          <SettingsContainer>
          <Form
            layout="vertical"
            onFinish={onFormLayoutChange}
            form={form}
            size="large"
            labelAlign="left"
            >
            <div className="myaccount-form-flex-name">
              <Form.Item
                name="credential"
                label="Credential"
                className="register-form-credential"
              >
                <Select
                  placeholder="Select"
                  getPopupContainer={(trigger) => trigger.parentElement}
                >
                  {fetchDoctorCredentials &&
                    fetchDoctorCredentials.map((eachCredential) => {
                      return (
                        <Select.Option
                          key={eachCredential.key}
                          value={eachCredential.value}
                        >
                          {eachCredential.value}
                        </Select.Option>
                      );
                    })}
                </Select>
              </Form.Item>
              <Form.Item label="First Name" name="firstName">
                <Input />
              </Form.Item>
              <Form.Item label="Last Name" name="lastName">
                <Input />
              </Form.Item>
            </div>
            <div className="register-form-flex">
              <Form.Item label="Email" className="physician-account-width-form">
                <Input
                  placeholder="Email"
                  value={userData && userData.email}
                  disabled={true}
                />
              </Form.Item>
              <Form.Item label="DEA Number" name='deaNumber' className="physician-account-width-form">
                <Input
                  placeholder="DEA Number"
                  defaultValue={userData?.deaNumber}
                />
              </Form.Item>
              <Form.Item
                label="Licensed States"
                name="licensedStates"
                className="physician-account-width-form"
              >
                <Select
                  mode="multiple"
                  getPopupContainer={(trigger) => trigger.parentElement}
                  style={{ width: "100%" }}
                >
                  {children}
                </Select>
              </Form.Item>
            </div>

            <div className="register-form-flex">
              <Form.Item
                label="Date of Birth"
                className="physician-account-width-form"
              >
                <Input
                  placeholder="input placeholder"
                  value={DOB}
                  disabled={true}
                />
              </Form.Item>
              <Form.Item
                name="gender"
                label="Gender"
                className="physician-account-width-form"
              >
                <Radio.Group
                  value={userData && userData.gender}
                >
                  <Radio.Button value="male">Male</Radio.Button>
                  <Radio.Button value="female">Female</Radio.Button>
                  <Radio.Button value="other">Other</Radio.Button>
                </Radio.Group>
              </Form.Item>
            </div>
            <div className="register-form-flex">
              <Form.Item label="employment status">
                <Input
                  placeholder="input placeholder"
                  value={userData && userData.employmentStatus}
                  disabled={true}
                />
              </Form.Item>

              <Form.Item name="cellphone" label="cell-phone">
                <Input placeholder="input placeholder" disabled={true} />
              </Form.Item>
            </div>
            <div style={{ marginBottom: 20 }}>
              <div className='ant-form-item-label ant-form-item-label-left'>
                <label title='Bio'>Bio</label>
              </div>
              <Editor
                apiKey={apiKeys.tinyEditorKey}
                init={{
                  height: 350,
                  toolbar: true,
                  menubar: false,
                  statusbar: false,
                }}
                value={bio}
                onEditorChange={value => setBio(value)}
              />
            </div>
            <Form.Item style={{ width: "100%" }}>
              <Button
                type="primary"
                size="large"
                htmlType="submit"
                loading={buttonLoading}
                className="myaccount-save-button"
              >
                Save Changes
              </Button>
            </Form.Item>
          </Form>
          </SettingsContainer>
       
        </div>

        <div className="myaccount-physician">
          <h1>Select authentication method</h1>
          <Radio.Group
            onChange={onChangeAuthentication}
            value={otpVia && otpVia}
          >
            <Radio value={"email"}>E-mail</Radio>
            <Radio value={"phone"}>Phone</Radio>
            <Radio value={"remove"}>No-authentication</Radio>
          </Radio.Group>
        </div>
      </Spin>
      <ImageCropperModal 
        isVisible={isCropping} 
        setIsVisible={setIsCropping} 
        imgSrc={croppingImgSrc} 
        setImgSrc={(img) => {
          setUpdatePic(img);
          setProfileUrl(URL.createObjectURL(img));
        }} 
      />
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  const { mapAndSetPhysicianDetails } = physicianDetails.actions;

  return {
    actions: bindActionCreators(
      { mapAndSetPhysicianDetails },
      dispatch
    ),
  };
};

export default connect(
  null,
  mapDispatchToProps
)(PhysicianAccount);

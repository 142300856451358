import actions from "./actions";
import reducer from "./reducer";

const caseTickets = {
  actions,
  reducer,
  name: "caseTickets",
};

export default caseTickets;

import { useState, useEffect, useRef, useMemo } from 'react';
import { debounce } from 'lodash'
import { message } from 'antd'

import PrescriptionsApi from '../axios/prescription/PrescriptionsApi'

export const usePharmacy = ({
    pharmacyId,
    selectPharmacy
}) => {
    const [pharmacySearchResults, setPharmacySearchResults] = useState([])
    const [pharmaciesLoading, setPharmaciesLoading] = useState(false)
    const [isNewDefaultPharmacy, setIsNewDefaultPharmacy] = useState(false)
    const [newDefaultPharmacyLoading, setNewDefaultPharmacyLoading] = useState(false)

    const abortController = useRef(null)

    const getPharmacyDetails = async pharmacyId => {
        try {
            const { data: { pharmacy } } = await PrescriptionsApi.getPharmacybyId(pharmacyId)
            return pharmacy
        } catch (e) {
            throw new Error('Error fetching pharmacy')
        }
    }

    const changePharmacy = async (pharmacy, patientId) => {
        selectPharmacy(pharmacy)

        if (isNewDefaultPharmacy) {
            setNewDefaultPharmacyLoading(true)
            try {
                const { data } = await PrescriptionsApi.updatePatientDefaultPharmacy(patientId, pharmacy.PharmacyId)
                return !data?.error
            } catch (e) {
                throw new Error('Error updating patient default pharmacy')
            } finally {
                setNewDefaultPharmacyLoading(false)
            }
        } 

        return false
    }

    const clearSearchResults = () => setPharmacySearchResults([])

    const searchPharmacies = useMemo(() => debounce(params => {
        setPharmaciesLoading(true)
        setPharmacySearchResults([])

        abortController.current?.abort()
        abortController.current = new AbortController()

        try {
            PrescriptionsApi.searchPharmacies(params)
                .then(({ data: { pharmacies }, config: { body } }) => {
                    if (!abortController.current?.signal || !body?.signal.aborted) {
                         setPharmacySearchResults(pharmacies)
                         setPharmaciesLoading(false)
                    }
                })
        } catch (e) {
            setPharmaciesLoading(false)
            throw new Error('Error fetching patient proximity pharmacies')
        }
    }, 1000), [])

    useEffect(() => {
        if (pharmacyId) {    
            setPharmaciesLoading(true)
            getPharmacyDetails(pharmacyId)
                .then(selectPharmacy)
                .catch(() => message.error('Error fetching default pharmacy'))
                .finally(() => setPharmaciesLoading(false))
        }
    }, [pharmacyId]) 

    return { 
        isNewDefaultPharmacy,
        pharmacySearchResults,
        pharmaciesLoading,
        newDefaultPharmacyLoading,
        changePharmacy, 
        getPharmacyDetails,
        clearSearchResults,
        setIsNewDefaultPharmacy,
        searchPharmacies
    }
}
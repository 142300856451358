/* eslint-disable no-template-curly-in-string */
/* eslint-disable react-hooks/exhaustive-deps */
//import packages
import React from "react";
import { Form, Input, Button, Row, Col, Modal, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

//import CSS
import "./ForgetPasswordComponent.css";

const ForgetPasswordComponent = ({
  modalVisible,
  loading,
  setModalVisible,
  handlePasswordResetRequest,
}) => {
  const forgetPasswordFormLayout = {
    labelCol: {
      span: 24,
    },
    wrapperCol: {
      span: 24,
    },
  };

  const forgetPasswordValidateMessages = {
    required: "Please enter your email address",
    types: {
      email: "Email address is not valid!",
    },
  };

  const onForgetPasswordFinish = (values) => {
    const { forgetPassword } = values;
    handlePasswordResetRequest({
      ...forgetPassword,
    });
  };

  const handleForgetPasswordModalCancel = () => {
    setModalVisible(false);
  };

  return (
    <Modal
      visible={modalVisible}
      title="Find Your Account"
      maskClosable={false}
      closable={false}
      footer={null}
      centered
      destroyOnClose
    >
      <Spin spinning={loading} size="large" indicator={<LoadingOutlined />}>
        <Form
          {...forgetPasswordFormLayout}
          layout={"vertical"}
          name="forget-password-form"
          onFinish={onForgetPasswordFinish}
          validateMessages={forgetPasswordValidateMessages}
        >
          <Row gutter={24}>
            <Col span={24}>
              <Form.Item
                name={["forgetPassword", "email"]}
                label="Please enter your email address to search for your account."
                rules={[
                  {
                    required: true,
                    type: "email",
                  },
                ]}
              >
                <Input placeholder="Enter Email Id" />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                className="forget-password-form-item-wrapper"
                wrapperCol={{ ...forgetPasswordFormLayout.wrapperCol }}
              >
                <Button
                  className="forget-password-form-button"
                  type="secondary"
                  size="large"
                  onClick={handleForgetPasswordModalCancel}
                >
                  Cancel
                </Button>
                <Button
                  className="forget-password-form-button"
                  type="primary"
                  htmlType="submit"
                  size="large"
                >
                  Submit
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Spin>
    </Modal>
  );
};

export default ForgetPasswordComponent;

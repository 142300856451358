import React from 'react';
import { Table, Modal } from 'antd';
import { TemplateTypeEnum } from '../../types/enums';

export const showVariableList = (templateType) => {

  const visitTemplateVariables = [
    { variable: 'prescription.name', description: 'Medication Name' },
    { variable: 'prescription.pillCount', description: 'Medication Quanity' },
    { variable: 'prescription.quantityUnits', description: 'Medication Quantity Units' },
    { variable: 'prescription.daysSupply', description: 'Prescription Days Supply' },
    { variable: 'prescription.refills', description: 'Prescription Refills' },
    { variable: 'prescription.strength', description: 'Medication Strength' },
  ]

  const templateVariables = [
    { variable: 'case._id', description: 'Case ID' },
    { variable: 'patient.firstName', description: "Patient's First Name" },
    { variable: 'patient.lastName', description: "Patient's Last Name" },
    { variable: 'physician.firstName', description: "Assigned Physician's First Name" },
    { variable: 'physician.lastName', description: "Assigned Physician's Last Name" },
  ]

  const renderTemplateVariables = () => {
    if (templateType === TemplateTypeEnum.VISIT) {
      return visitTemplateVariables
    }
    return templateVariables
  }

  return (
    Modal.info({
      title: 'Template Variables',
      centered: true,
      maskClosable: true,
      icon: null,
      width: '50vw',
      content: (
        <>
          <div style={{ marginBottom: 20 }}>
            <p style={{ marginBottom: 30 }}>Add variables to templates by wrapping them with two sets of curly braces.</p>
            <p>Example: Hello <em><strong>&#123;&#123;patient.firstName&#125;&#125; &#123;&#123;patient.lastName&#125;&#125;</strong></em></p>
            <p>Result: Hello <em><strong>John Doe</strong></em></p>
          </div>
          <Table
            columns={[
              { dataIndex: 'variable', title: 'Variable' },
              { dataIndex: 'description', title: 'Description' },
            ]}
            pagination={false}
            dataSource={renderTemplateVariables()}
          />
        </>
      )
     })
  )
}
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { message, Modal, Typography } from "antd";
import qs from 'querystring'
import PhysicianApi from "../../axios/physician/PhysicianApi";
import { userConstants } from "../../constants";
import { PhysicianTagEnum } from "../../types/enums"

const { Text } = Typography;

const AcceptModal = ({ openModal, setOpenModal, caseForConsultationStart, setCaseForConsultationStart, fetchCaseQueue, TableDataMap }) => {
    const history = useHistory();
    const physicianDetails = useSelector(state => state.physicianDetails.physicianDetails);
    const [modalButtonLoading, setModalButtonLoading] = useState(false);
    const [acceptAsLead, setAcceptAsLead] = useState(false)

    const isLeadPhysician = physicianDetails?.tags?.includes(PhysicianTagEnum.LEAD_PHYSICIAN)

    const handleConsultationStartError = (messageString) => {
      setModalButtonLoading(false);
      setAcceptAsLead(false)
      setOpenModal(false);
      message.error(messageString);
    };

    const handleConsultStartCancellation = (event) => {
      event.stopPropagation();
      setCaseForConsultationStart({});
      setAcceptAsLead(false)
      setOpenModal(false);
      message.warn("Sorry, Please accept case to move to the case details");
    };

    const pushRouteCaseDetails = ({ caseId, requestId }) => {
      const path = `/dashboard/${userConstants.USER_PHYSICIAN}/caseview/${caseId}`;
      const search = requestId ? qs.stringify({ requestId }) : '';
      const from = history.location.pathname.split('/').pop() ;
      history.push(`${path}?${search}`, { from });
    };

    const handleConsultStartAccept = (event) => {
      event.stopPropagation();
      setModalButtonLoading(true);
      const caseId = caseForConsultationStart[TableDataMap.CASE_ID];
      const requestId = caseForConsultationStart[TableDataMap.REQUEST_ID];

      PhysicianApi
        .startConsultation({
          caseId,
          consultationStart: true,
          acceptAsLead
        })
        .then(({ data }) => {
          if (data?.success) {
            return pushRouteCaseDetails({ caseId, requestId });
          }
          const errorMessage = data?.message || "Unable to Start Consult. Please try again later!"
          handleConsultationStartError(errorMessage)
        })
        .catch(({ response }) => {
          const errorMessage = response?.data?.message || "Error occured while Starting Consultation. Please try again later!"
          handleConsultationStartError(errorMessage);
          fetchCaseQueue()
        })
        .finally(() => setModalButtonLoading(false))
    };

    return (
        <Modal
          title="Accept Case"
          okText="Ok"
          closable={false}
          maskClosable={false}
          centered
          visible={openModal}
          okButtonProps={{ loading: modalButtonLoading }}
          cancelButtonProps={{ loading: modalButtonLoading }}
          onOk={handleConsultStartAccept}
          onCancel={handleConsultStartCancellation}
        >
          <Text strong>Please confirm that you are accepting this case</Text>
        </Modal>
    )
}

export default AcceptModal;

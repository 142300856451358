import { useState, useEffect, useRef, useMemo } from 'react';
import { debounce } from 'lodash'
import { message } from 'antd'

import PrescriptionsApi from '../../../axios/prescription/PrescriptionsApi'

export const useAllergies = patientId => {
    const [patientAllergies, setPatientAllergies] = useState([])
    const [newAllergyQueue, setNewAllergyQueue] = useState([])
    const [patientAllergiesLoading, setPatientAllergiesLoading] = useState(false)
    const [allergySearchTerm, setAllergySearchTerm] = useState(null)
    const [allergySearchResults, setAllergySearchResults] = useState([])
    const [allergySearchResultsLoading, setAllergySearchResultsLoading] = useState(false)
    const [allergyAdditionErrors, setAllergyAdditionErrors] = useState([])

    const abortController = useRef(null)

    const getPatientAllergies = async patientId => {
        try {
            setPatientAllergiesLoading(true)
            const { data: { allergies } } =  await PrescriptionsApi.getPatientAllergies(patientId)
            setPatientAllergies(allergies?.data)
        } catch (e) {
            console.log(e)
            setPatientAllergies(null)
        } finally {
            setPatientAllergiesLoading(false)
        }
    }

    const addAllergyToQueue = allergy => {
        if (!newAllergyQueue.find(queueAllergy => queueAllergy.displayName === allergy.displayName)) {
            setAllergySearchTerm('')
            setAllergySearchResults([])
            setNewAllergyQueue([...newAllergyQueue, allergy])
        }
    }

    const removeAllergyFromQueue = allergy => {
        setNewAllergyQueue(newAllergyQueue.filter(queueAllergy => queueAllergy.displayName !== allergy.displayName))
    }

    const searchAllergies = useMemo(() => debounce(async q => {
        // need to filter out allergies already on patient
        if (q.length < 3) return setAllergySearchResults([])
        setAllergySearchResultsLoading(true)

        abortController.current?.abort()
        abortController.current = new AbortController()

        try {
            const { data: { allergies }, config: { body } } = await PrescriptionsApi.searchAllergies(q)
            const { data } = allergies
            if (!abortController.current?.signal || !body?.signal.aborted) {
                setAllergySearchResults(data)
           }
        } catch (e) {
            console.log(e)
            message.error('Error searching allergies')
        } finally {
            setAllergySearchResultsLoading(false)
        }
    }, 500), [])

    const addAllergiesToPatient = async newAllergies => {
        try {
            const { data: { allergies, errors } } = await PrescriptionsApi.addAllergiesToPatient(newAllergies)
            setPatientAllergies(allergies?.data)
            setAllergyAdditionErrors(errors)
        } catch (e) {
            console.log(e)
            message.error('Error searching allergies')
        }
    }

    const resetAllergies = () => setNewAllergyQueue([])

    useEffect(() => {
        if (patientId) {
            getPatientAllergies(patientId)
        }
    }, [patientId])


    useEffect(() => {
        if (allergySearchTerm) {
            searchAllergies(allergySearchTerm)
        }
    // eslint-disable-next-line
    }, [allergySearchTerm])


    return { 
        getPatientAllergies,
        setAllergySearchTerm,
        addAllergiesToPatient,
        addAllergyToQueue, 
        removeAllergyFromQueue,
        resetAllergies,
        allergySearchTerm,
        patientAllergies,
        newAllergyQueue,
        patientAllergiesLoading,
        allergySearchResults,
        allergySearchResultsLoading,
        allergyAdditionErrors
    }
}
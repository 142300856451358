import { useState, useEffect } from 'react';
import { message } from 'antd'

import IdentityVerificationApi from '../../../../axios/IdentityVerificationApi'

export const useIdentityVerification = ({ 
    form, 
    onFinish,
    idVerified,
    pinSet,
}) => {    
    const [validation, setValidation] = useState({
        loading: false,
        currentStep: idVerified
            ? pinSet ? 6 : 5 
            : 0, 
        attested: false, 
        legalAgreement: null,
        idpDisclaimer: null,
        idpDisclaimerId: null,
        identityValidated: false, 
        challengeQuestionsValidated: false,
        duoSMSAccepted: false 
    })

    const stepForward  = () => setValidation(prev => ({ ...prev, currentStep: prev.currentStep + 1}))
    const stepBack = () => setValidation(prev => ({ ...prev, currentStep: prev.currentStep > 0 ? prev.currentStep - 1 : 0}))

    const [challengeQuestionsAndAnswers, setChallengeQuestionsAndAnswers] = useState({ sessionId: null, questions: [], answers: []})

    const handleAttestation = isAttested => setValidation(prev => ({ ...prev, attested: isAttested }))
    
    const handleValidateIdentityForm = () => {
        form.validateFields()
            .then(() => setValidation(prev => ({ ...prev, identityValidated: true })))
            .catch(() => setValidation(prev => ({ ...prev, identityValidated: false })))
    }

    const fetchLegalAgreement = async () => {
        setValidation(prev => ({
            ...prev,
            loading: true
        }))

        try {
            const { data } = await IdentityVerificationApi.getLegalAgreement()

            setValidation(prev => ({
                ...prev,
                legalAgreement: data.data,
            }))
            
        } catch (e) {
            console.error(e)
            message.error('Error fetching IDP Disclaimer')
        } finally {
            setValidation(prev => ({
                ...prev,
                loading: false
            }))
        }
    }

    const handleAcceptLegalAgreement = async ({ agreementId, nameSigned }) => {
            
        setValidation(prev => ({
            ...prev,
            loading: true
        }))

        try {
            await IdentityVerificationApi.acceptLegalAgreement({ agreementId, nameSigned })
            await fetchIdpDisclaimer()
            if (agreementId === 2) stepForward()
        } catch (e) {
            console.error(e)
            message.error(e.response?.data?.message || 'Error accepting legal agreement')
        } finally {
            setValidation(prev => ({
                ...prev,
                loading: false
            }))
        }
    }


    const fetchIdpDisclaimer = async () => {

        setValidation(prev => ({
            ...prev,
            loading: true
        }))

        try {
            const { data } = await IdentityVerificationApi.getIDPDisclaimer()
            
            setValidation(prev => ({
                ...prev,
                idpDisclaimer: data.data.idpDisclaimerText,
                idpDisclaimerId: data.data.idpDisclaimerId
            }))
            
        } catch (e) {
            console.error(e)
            message.error('Error fetching IDP Disclaimer')
        } finally {
            setValidation(prev => ({
                ...prev,
                loading: false
            }))
        }
    }

    const handleAcceptIdpDisclaimer = async () => {
        if (validation.attested) {
            
        setValidation(prev => ({
            ...prev,
            loading: true
        }))
            try {
                await IdentityVerificationApi.acceptIDPDisclaimer(validation.idpDisclaimerId)
                stepForward()
            } catch (e) {
                if (e.response?.data?.message === 'Clinician has already signed disclaimer.') {
                    stepForward()
                } else {
                    console.error(e)    
                    message.error(e.response?.data?.message || 'Error accepting IDP Disclaimer')
                }
            } finally {
                setValidation(prev => ({
                    ...prev,
                    loading: false
                }))
            }
        }
    }

    const handleSubmitIdentityForm = async identity => {
        setValidation(prev => ({
            ...prev,
            loading: true
        }))
        try {
            const { data } = await IdentityVerificationApi.provideIdentity(identity)
            setChallengeQuestionsAndAnswers(data?.data)
            stepForward()
            if (!data.data.questions) stepForward()
        } catch (e) {
            message.error(e.response?.data?.message || 'Error submitting identity for verification')
        } finally {
            setValidation(prev => ({
                ...prev,
                loading: false
            }))
        }
    }

    const handleSubmitChallengeQuestionResponses = async challengeResponses => {
        setValidation(prev => ({
            ...prev,
            loading: true
        }))
        const answers = Object.values(challengeResponses)
        try {
            await IdentityVerificationApi.proveIdentity({ sessionId: challengeQuestionsAndAnswers.sessionId, answers })
            stepForward()
        } catch (e) {
            console.error(e)
            message.error(e.response?.data?.message || 'Error verifying identity')
        } finally {
            setValidation(prev => ({
                ...prev,
                loading: false
            }))
        }
    }

    const setPIN = async PIN => {
        setValidation(prev => ({
            ...prev,
            loading: true
        }))

        try {
            await IdentityVerificationApi.setPIN({ PIN })
            stepForward()
        } catch (e) {
            if (e.response?.data?.message?.toLowerCase().includes('pin already set')) {
                message.error('PIN previously set. To change PIN, please contact your Administrator.')
                stepForward()
            } else {
                console.error(e)
                message.error(e.response?.data?.message || 'Error setting PIN')
            }
        } finally {
            setValidation(prev => ({
                ...prev,
                loading: false
            }))
        }
    }

    const handleAcceptDUOSMS = smsAccepted => setValidation(prev => ({ ...prev, duoSMSAccepted: smsAccepted }))

    const initializeTFAActivation = async () => {
        setValidation(prev => ({
            ...prev,
            loading: true
        }))

        try {
            await IdentityVerificationApi.initializeTFAActivation()
            stepForward()
        } catch (e) {
            if (e.response?.data?.message === 'TFA already initialized') {
                stepForward()
            } else {
                console.error(e)
                message.error(e.response?.data?.message || 'Error initializing TFA')
            }
        } finally {
            setValidation(prev => ({
                ...prev,
                loading: false
            }))
        }
    }

    const requestTFAActivation = async data => {
        setValidation(prev => ({
            ...prev,
            loading: true
        }))

        try {
            await IdentityVerificationApi.requestTFAActivation(data)
            stepForward()
        } catch (e) {
            console.error(e)
            message.error(e.response?.data?.message || 'Error requesting TFA activation')
        } finally {
            setValidation(prev => ({
                ...prev,
                loading: false
            }))
        }
    }

    const activateTFA = async data => {
        setValidation(prev => ({
            ...prev,
            loading: true
        }))

        try {
            await IdentityVerificationApi.activateTFA(data)
             message.success('Succesfully completed identity verification.')
             onFinish()
        } catch (e) {
            console.error(e)
            message.error(e.response?.data?.message || 'Error activating TFA')
        } finally {
            setValidation(prev => ({
                ...prev,
                loading: false
            }))
        }
    }

    useEffect(() => {
        fetchLegalAgreement()
    }, []) 

    return { 
        loading: validation.loading,
        currentStep: validation.currentStep,
        idpDisclaimer: validation.idpDisclaimer,
        validation,
        challengeQuestionsAndAnswers,
        setCurrentStep: validation.currentStep,
        stepForward,
        stepBack,
        fetchLegalAgreement,
        handleAcceptLegalAgreement,
        handleAttestation,
        handleValidateIdentityForm,
        handleAcceptIdpDisclaimer,
        handleSubmitIdentityForm,
        handleSubmitChallengeQuestionResponses,
        setPIN,
        handleAcceptDUOSMS,
        initializeTFAActivation,
        requestTFAActivation,
        activateTFA
    }
}
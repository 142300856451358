import React from 'react'
import { Button, Typography } from 'antd'

export const InitiateTFAActivation = ({ handleSubmit }) => {
    return (
            <div style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: '10px'
            }}>
                <Typography.Text>Please continue with activating Two-Factor Authentication. There will be additional steps that follow.</Typography.Text>
                <Button type="primary" onClick={handleSubmit}>Activate TFA</Button>
            </div>
    )
}
import React, { useState, useContext, useEffect, useCallback, useMemo } from "react";
import "../AdditionalInfoRequired/AdditionalInfoRequired.css";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Table, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { capitalize } from "lodash";

import socket from "../../axios/socket";
import { history } from "../../helpers";
import { userConstants } from "../../constants";
import { CaseFilterMap, CaseQueryParamMap } from "../../constants/CaseFilter";
import { dateTimeFormatter } from "../../utils/dateFormatter";
import { getTextFieldSorter, getDateFieldSorter, getSortOrder } from '../../utils/sorters';
import { createCaseFilterQueryParamMap, createTableQueryParamMap, createTableSortByMap } from "../../utils/createCaseFilterQueryParamMap";

import SearchAndFilterCases from "../../components/SearchAndFilterCases/SearchAndFilterCases";
import CaseUpdateComparisonComponent from "../../components/CaseUpdateComparisonComponent/CaseUpdateComparisonComponent";
import otherPhysiciansCases from "../../redux/otherPhysiciansCases"
import { TablePreferencesContext } from '../../context/TablePreferencesProvider';

const hideFields = Object.freeze([CaseFilterMap.STATE_ID, CaseFilterMap.TYPE]);

const TABLE_NAME = "Other Physicians' Cases";

const TableDataMap = {
  CASE_ID: CaseFilterMap.CASE_ID,
  PATIENT_NAME: `${CaseFilterMap.PATIENT_FIRST_NAME} ${CaseFilterMap.PATIENT_LAST_NAME}`,
  TELEMEDICINE_CATEGORY_ID: CaseFilterMap.TELEMEDICINE_CATEGORY_ID,
  PHYSICIAN_ID: CaseFilterMap.PHYSICIAN_ID,
  CREATED_DATE: CaseFilterMap.CREATED_DATE,
  UPDATED_DATE: CaseFilterMap.UPDATED_DATE,
  STATUS: CaseFilterMap.STATUS,
}

const TableSortByMap = createTableSortByMap(TableDataMap);

const createTableColumns = (sortOrder, sortBy) => {
  const columns = [
    {
      title: "Case No",
      key: TableDataMap.CASE_ID,
    },
    {
      title: "Patient Name",
      key: TableDataMap.PATIENT_NAME,
    },
    {
      title: "Assigned Physician",
      key: TableDataMap.PHYSICIAN_ID,
    },
    {
      title: "Category Name",
      key: TableDataMap.TELEMEDICINE_CATEGORY_ID,
    },
    {
      title: "Created Date",
      key: TableDataMap.CREATED_DATE,
    },
    {
      title: "Updated Date",
      key: TableDataMap.UPDATED_DATE,
    },
    {
      title: "Status",
      key: TableDataMap.STATUS,
    },
  ];

  columns.forEach((c) => {
    c.dataIndex = c.key;
    if (c.key === TableDataMap.CASE_ID) {
      return
    }
    c.sortOrder = getSortOrder(sortOrder, sortBy, c.key);
    const isDateField = [TableDataMap.CREATED_DATE].includes(c.key);
    c.sorter = isDateField ? getDateFieldSorter(c.key) : getTextFieldSorter(c.key);
  });

  return columns;
};

const OtherPhysiciansCases = (props) => {
  const [showModal, setShowModal] = useState(false)
  const [caseDetailsComparisonId, setCaseDetailsComparisonId] = useState(null);
  const [viewCaseDetailsComparisonModal, setViewCaseDetailsComparisonModal] = useState(false);
  const [searchValue, setSearchValue] = useState(null);
  const [caseListFilter, setCaseListFilter] = useState({});
  const { getTablePreferences, updateTablePreferences } = useContext(TablePreferencesContext);
  const tablePreferences = getTablePreferences(TABLE_NAME);

  const { currentPage, tablePageSize, sortOrder, sortBy } = tablePreferences;

  const columns = useMemo(() => createTableColumns(sortOrder, sortBy), [sortOrder, sortBy]);

  const {
    actions,
    statesList,
    otherPhysiciansCaseList,
    telemedicineCategoriesList,
    otherPhysiciansCaseListLoading,
    otherPhysiciansCaseCount,
  } = props;

  const closeCaseDetailsComparisonModal = () => {
    setCaseDetailsComparisonId(null);
    setViewCaseDetailsComparisonModal(false);
  };

  const completeClearNotificationAndCaseListingUpdate = () => {
    setCaseDetailsComparisonId(null);
    setViewCaseDetailsComparisonModal(false);
  };

  const fetchCases = useCallback(() => {
    const newTablePreferences = {
      currentPage,
      tablePageSize,
      sortOrder,
      sortBy: TableSortByMap[sortBy] || sortBy,
    };
    const tableFilter = createTableQueryParamMap(newTablePreferences);
    const caseFilterMap = createCaseFilterQueryParamMap(caseListFilter, hideFields);
    if (searchValue !== null) {
      caseFilterMap[CaseQueryParamMap.SEARCH_TERM] = searchValue
    }

    actions.getOtherPhysiciansCases({ ...caseFilterMap, ...tableFilter });
  }, [actions, currentPage, tablePageSize, sortOrder, searchValue, caseListFilter, sortBy]);

  useEffect(() => {
    socket.connect()
    socket.on("OPCCaseAccepted", ({ caseId }) => actions.removeCaseFromOPC(caseId))
    return () => socket.removeAllListeners(["OPCCaseAccepted"])
  }, [actions])

  useEffect(() => {
    fetchCases();
  }, [fetchCases]);

  const pushRouteCaseDetails = (caseId) => {
    history.push(
      `/dashboard/${userConstants.USER_PHYSICIAN}/caseview/${caseId}`,
      { from: 'other-physicians-cases' }
    );
  };

  const handleCaseListClick = (event, record) => {
    event.stopPropagation();
    pushRouteCaseDetails(record[TableDataMap.CASE_ID]);
  };

  const handleIntialTableCount = () => {
    updateTablePreferences(TABLE_NAME, { currentPage: 1, tablePageSize: 100 })
  };

  const handleOk = (filterData) => {
    setCaseListFilter(filterData);
    setShowModal(false);
    handleIntialTableCount();
  };

  const searchCase = (value) => {
    setSearchValue(value);
    handleIntialTableCount();
  };

  const handleCancel = () => {
    setCaseListFilter({});
    setShowModal(false);
    handleIntialTableCount();
  };

  const handleCancelCross = () => {
    setShowModal(false);
  };

  const onChangePage = (page) => {
    updateTablePreferences(TABLE_NAME, { currentPage: page })
  };

  const onPageSizeChange = (_, size) => {
    updateTablePreferences(TABLE_NAME, { currentPage: 1, tablePageSize: size })
  };

  const onChangeOrder = (_pagination, _filters, sorter) => {
    updateTablePreferences(TABLE_NAME, {
      sortOrder: sorter.order || 'ascend',
      sortBy: sorter.field,
    });
  }

  const casesForTable = otherPhysiciansCaseList.map((c) => {
    return {
      key: c._id,
      rowKey: c._id,
      [TableDataMap.CASE_ID]: c._id,
      [TableDataMap.PATIENT_NAME]: `${c.patientData?.firstName} ${c.patientData?.lastName}`,
      [TableDataMap.PHYSICIAN_ID]: c.physicianData?.name,
      [TableDataMap.TELEMEDICINE_CATEGORY_ID]: c.categoryData?.name,
      [TableDataMap.CREATED_DATE]: dateTimeFormatter(c.createdDate),
      [TableDataMap.UPDATED_DATE]: c.updatedDate ? dateTimeFormatter(c.updatedDate) : '',
      [TableDataMap.STATUS]: c.status.split(' ').map(capitalize).join(' '),
    }
  })

  return (
    <div className="consulted-case">
      <div className="waiting-room-case-list-part-heading">
        <h1>Other Physicians' Cases</h1>
        <SearchAndFilterCases
          searchCase={searchCase}
          showModal={showModal}
          setShowModal={setShowModal}
          handleCancelCross={handleCancelCross}
          handleCancel={handleCancel}
          handleOk={handleOk}
          telemedicines={telemedicineCategoriesList}
          states={statesList}
          hideFields={hideFields}
        />
      </div>
      <div className="consulted-case-no-of-patient-list-data">
        <Spin
          size="large"
          spinning={otherPhysiciansCaseListLoading}
          indicator={<LoadingOutlined spin />}
        >
          <div>
            <Table
              columns={columns}
              onChange={onChangeOrder}
              pagination={{
                total: otherPhysiciansCaseCount,
                showTotal: () => <>Total {otherPhysiciansCaseCount || <Spin />} cases</>,
                current: currentPage,
                pageSize: tablePageSize,
                pageSizeOptions: ["5", "10", "20", "50", "100"],
                showSizeChanger: true,
                onChange: onChangePage,
                onShowSizeChange: onPageSizeChange,
              }}
              dataSource={casesForTable}
              scroll={{ x: 900 }}
              onRow={(record, rowIndex) => {
                return {
                  onClick: (event) => {
                    handleCaseListClick(event, record, rowIndex);
                  },
                };
              }}
            />
          </div>
        </Spin>
      </div>
      <CaseUpdateComparisonComponent
        modalVisible={viewCaseDetailsComparisonModal}
        caseId={caseDetailsComparisonId}
        setModalVisible={closeCaseDetailsComparisonModal}
        clearCaseNotificationCallback={
          completeClearNotificationAndCaseListingUpdate
        }
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  const {
    otherPhysiciansCaseListLoading,
    otherPhysiciansCaseCount,
    otherPhysiciansCaseList,
  } = state.otherPhysiciansCases;
  const { statesList, telemedicineCategoriesList } = state.entities;
  return {
    otherPhysiciansCaseListLoading,
    otherPhysiciansCaseCount,
    otherPhysiciansCaseList,
    statesList,
    telemedicineCategoriesList,
  };
};

const mapDispatchToProps = (dispatch) => {
  const { getOtherPhysiciansCases, removeCaseFromOPC } = otherPhysiciansCases.actions;
  return {
    actions: bindActionCreators(
      {
        getOtherPhysiciansCases,
        removeCaseFromOPC
      },
      dispatch
    ),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OtherPhysiciansCases);

import React, { useState, useEffect, useCallback } from 'react';
import Room from './components/Room';
import './VideoCall.css';
import api from '../../axios/physician/PhysicianApi';
import { useParams } from "react-router-dom";
import { history } from "../../helpers";
import { message, Spin } from 'antd';
import useLocalStorage from '../../hooks/useLocalStorage';
import PatientApi from '../../axios/patient/PatientApi';


const VideoCall = () => {
    const [enterRoom, setEnterRoom] = useState(false);
    const [physicianName, setPhysicianname, removePhysicianName] = useLocalStorage("roomPhyscianName");
    const [patientName, setPatientName, removePatientName] = useLocalStorage("roomPatientName");
    const [roomName, setRoomName, removeRoomName] = useLocalStorage("roomName", "");
    const [token, setToken, removeToken] = useLocalStorage("roomToken", "");
    const [consultationType, setConsultationType, removeConsultationType] = useLocalStorage("roomConsultationType");
    let { id } = useParams();


    const clearRoomData = () => {
        removePhysicianName();
        removePatientName();
        removeRoomName();
        removeToken();
        removeConsultationType();
        setEnterRoom(false);
    }


    const handleLogout = useCallback(event => {
        if (window.localStorage.getItem('type') === "physician") {
            setToken('');

        }


        if (window.localStorage.getItem('caseId') !== null) {
            history.push(`/case/scheduled-call/${window.localStorage.getItem('caseId')}`)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    function getInitials(string) {
        let names = string.split(' '),
            initials = names[0].substring(0, 1).toUpperCase();

        if (names.length > 1) {
            initials += names[names.length - 1].substring(0, 1).toUpperCase();
        }
        return initials;
    };


    const isPatient = () => { return window.localStorage.getItem("caseId") !== null; }

    useEffect(() => {
        if (token && roomName) {
          setEnterRoom(true);
          message.success("connected to Room For Consultation")
        } else {
          if (isPatient()) {
            PatientApi.gotToRoom().then(res => {
                new Promise(function (resolve, reject) {
                    setTimeout(() => resolve(1), 1000); // (*)          
                }).then(function () {
                    const patientNameshort = getInitials(res.data.patientName);
                    const physicianNameshort = getInitials(res.data.physicianName);
    
                    window.localStorage.setItem("roomToken", res.data.token);
                    window.localStorage.setItem("roomName", res.data.room);
                    window.localStorage.setItem("roomPhyscianName", physicianNameshort);
                    window.localStorage.setItem("roomPatientName", patientNameshort);
                    window.localStorage.setItem("roomConsultationType", res.data.consultationType);
    
                    setToken(res.data.token);
                    setRoomName(res.data.room);
                    setPatientName(patientNameshort);
                    setPhysicianname(physicianNameshort);
                    setConsultationType(res.data.consultationType);
                    setEnterRoom(true);
                    message.success("connected to Room For Consultation")
                });
            }).catch((error) => {
                const { response } = error;
                if (response) {
                    const { data } = response;
                    if (data && data.message) {
                        message.error(data.message);
                    } else {
                        message.error("Unable Connect to room, Please Check your Network Connection and reload");
                    }
                } else {
                    message.error("Unable Connect to room, Please Check your Network Connection and reload");
                }
            });
          } else {
            api.gotoRoom(id).then(res => {
                new Promise(function (resolve, reject) {
                    setTimeout(() => resolve(1), 1000); // (*)          
                }).then(function () {
                    const patientNameshort = getInitials(res.data.patientName);
                    const physicianNameshort = getInitials(res.data.physicianName);
    
                    window.localStorage.setItem("roomToken", res.data.token);
                    window.localStorage.setItem("roomName", res.data.room);
                    window.localStorage.setItem("roomPhyscianName", physicianNameshort);
                    window.localStorage.setItem("roomPatientName", patientNameshort);
                    window.localStorage.setItem("roomConsultationType", res.data.consultationType);
    
                    setToken(res.data.token);
                    setRoomName(res.data.room);
                    setPatientName(patientNameshort);
                    setPhysicianname(physicianNameshort);
                    setConsultationType(res.data.consultationType);
                    setEnterRoom(true);
                    message.success("connected to Room For Consultation")
                });
            }).catch((error) => {
                const { response } = error;
                if (response) {
                    const { data } = response;
                    if (data && data.message) {
                        message.error(data.message);
                    } else {
                        message.error("Unable Connect to room, Please Check your Network Connection and reload");
                    }
                } else {
                    message.error("Unable Connect to room, Please Check your Network Connection and reload");
                }
            });
          }
        }
        // eslint-disable-next-line
      }, [])

    return (
        <>
            <Spin spinning={!token} tip={enterRoom ? "Please wait while Disconnecting the room..." : "Please wait while Connecting to room..."}>
                {!token && <div style={{ height: "1000px" }} />}
            </Spin>
            <Room roomName={roomName} token={token} handleLogout={handleLogout} consulationType={consultationType} physicianName={physicianName} patientName={patientName} clearRoomData={clearRoomData} />
        </>
    )
}

export default VideoCall;
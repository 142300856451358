import React, { useEffect } from 'react'
import moment from 'moment-timezone'

import { 
    Form, 
    Input, 
    Spin,
} from 'antd'

import styled from 'styled-components'

import { useSearchMedications } from '../../../../hooks/useSearchMedications'

// CONTEXT
import { useDispenseUnitContext } from '../../context/DispenseUnitContext'

// COMPONENTS
import { MedicationSearch } from './components/MedicationSearch'
import { EffectiveDate } from './components/EffectiveDate'
import { Strength } from './components/Strength'
import { Quantity } from './components/Quantity'
import { DispenseUnits } from './components/DispenseUnits'
import { Refills } from './components/Refills'
import { DaysSupply } from './components/DaysSupply'
import { Sig } from './components/Sig'

const { TextArea } = Input

const StyledSpin = styled(Spin)`
    width: 100% !important;
`

const FormColumnSpacer = () => <span style={{ display: 'inline-block', width: '24px', lineHeight: '32px', textAlign: 'center' }}></span>

export const EditRxRequestForm = ({ 
    prescription,
    updatePrescription,
    setPrescriptionIsValid
}) => {
    const [form] = Form.useForm()
    const { dispenseUnits, getDispenseUnitById, dispenseUnitsLoading } = useDispenseUnitContext()

    const handleFieldsChange = async () => {
        form.validateFields()
            .then(() => {
                setPrescriptionIsValid(true)
                updatePrescription({ ...prescription, ...form.getFieldsValue() })
            })
    }
    
    const {
        fetchMedications,
        handleSelectMedication,
        handleSelectStrength,
        medicationSearchMatches,
        selectedMedication,
        selectedStrength,
        medicationSearchLoading,
    } = useSearchMedications({
        form,
        handleFieldsChange,
    })

    const startDateIsInPast = moment(prescription.startDate).isBefore(moment())

    useEffect(() => {
        if (startDateIsInPast) {
            form.setFieldsValue({ startDate: moment() })
            handleFieldsChange()
        }
    }, [])
    
    return (
        <StyledSpin spinning={medicationSearchLoading || dispenseUnitsLoading}>
            <Form
                form={form}
                initialValues={{ 
                    refills: 1,
                    daysSupply: 30,
                    ...prescription, 
                    startDate: !prescription.startDate || startDateIsInPast ? moment() : moment(prescription.startDate),
                    quantityUnits: getDispenseUnitById(prescription.dispenseUnitId)?.Singular,
                }}
                onChange={handleFieldsChange}
                layout="vertical"
                style={{ textTransform: 'capitalize' }}
            >
                    <MedicationSearch
                        selectedMedication={selectedMedication}
                        selectedStrength={selectedStrength}
                        fetchMedications={fetchMedications}
                        handleSelectMedication={handleSelectMedication}
                        medicationSearchMatches={medicationSearchMatches}
                        prescription={prescription}
                    />
                <Form.Item rules={[{ required: true }]}>
                    <Strength
                        form={form}
                        selectedMedication={selectedMedication}
                        setMedicationState={handleSelectMedication}
                        handleSelectStrength={handleSelectStrength}
                    />
                    <FormColumnSpacer />
                    <Quantity form={form} />
                </Form.Item>
                <Form.Item>
                    <DispenseUnits
                        form={form}
                        selectedStrength={selectedStrength}
                        dispenseUnits={dispenseUnits}
                        handleFieldsChange={handleFieldsChange}
                    />
                    <FormColumnSpacer />
                    <Refills/>
                </Form.Item>
                <Form.Item>
                    <DaysSupply/>
                    <FormColumnSpacer />
                    <EffectiveDate 
                        prescription={prescription} 
                        handleFieldsChange={handleFieldsChange}
                    />
                </Form.Item>
                <Sig />
                <Form.Item
                    label='Pharmacy Notes'
                    name='pharmacyNotes'
                >
                    <TextArea
                        showCount
                        maxLength={210}
                        placeholder="Limited to 210 characters..."
                    />
                </Form.Item>
                <Form.Item hidden name='brandName' />
                <Form.Item hidden name='genericName' />
                <Form.Item hidden name='ndc' />
                <Form.Item hidden name='dispensableDrugId' />
                <Form.Item hidden name='route' />
                <Form.Item hidden name='dispenseUnitId' />
                <Form.Item hidden name='valid' />
                <Form.Item hidden name='patientId' />
                <Form.Item hidden name='schedule' />
                <Form.Item hidden name='quantityUnits' />
            </Form>
        </StyledSpin>
    )
}
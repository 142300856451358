import Axios from "../Axios";
import config from "../../config";

const { api } = config;

export default class PatientApi {
  static getToken() {
    const token = window.localStorage.getItem("token");
    return token;
  }

  static fetchAppointments(caseId) {
    const token = this.getToken();
    return Axios({
      url: `${api.baseUrl}/patients/cases/${caseId}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
  }

  static gotToRoom() {
    const token = this.getToken();
    return Axios({
      url: `${api.baseUrl}/patients/video/token/`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  static getPatientDetails(patientId) {
    const token = this.getToken();
    return Axios({
        url: `${api.baseUrl}/v2/patients/${patientId}`,
        method: "GET",
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
}

  static getCaseHistory(patientId) {
      const token = this.getToken();
      return Axios({
          url: `${api.baseUrl}/v2/patients/${patientId}/history`,
          method: "GET",
          headers: {
              Authorization: `Bearer ${token}`,
          },
      });
  }
}

import React, { useState, memo } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Form } from 'antd'
import moment from 'moment-timezone'

// STATE
import prescribeMedicine from "../../../../redux/prescribeMedicine"

// CONTEXT
import { PrescriptionFormProvider } from './context/prescriptionFormContext'
import { useDispenseUnitContext } from '../../context/DispenseUnitContext'

// HOOKS
import { useSearchMedications } from './hooks/useSearchMedications'
import { usePrescriptionTemplates } from '../../hooks/usePrescriptionTemplates'

// COMPONENTS
import { FormColumnSpacer } from '../FormColumnSpacer'
import DispenseUnitSelector from './components/DispenseUnitSelector'
import { PrescriptionOptions } from './components/PrescriptionOptions'
import MedicationSearch from './components/MedicationSearch'
import StrengthSelector from './components/StrengthSelector'
import DaysSupplyInput from './components/DaysSupplyInput'
import { MemoizedTemplateSelect } from './components/TemplateSelect'
import QuantityInput from './components/QuantityInput'
import RefillsInput from './components/RefillsInput'
import { SigTextArea } from './components/SigTextArea'
import FormHeader from './components/FormHeader'
import { FormTitle } from './components/FormTitle'
import { StartDatePicker } from './components/StartDatePicker'

// STYLES
import { FormContainer, StyledForm, StyledSpin } from './styles'

// CONSTANTS
import { RESET_VALUES } from './data/constants'

const PrescriptionForm = ({
    isFirstMedication,
    handleCreateTemplate,
    prescription,
    telemedicineCategoryId,
    bypassValidation,
    actions
}) => {
    const [form] = Form.useForm()
    const [showPharmacyNotesTextArea, setShowPharmacyNotesTextArea] = useState(false)
    const { dispenseUnits, getDispenseUnitById, dispenseUnitsLoading } = useDispenseUnitContext()

    const isPrescriptionMetadataValid = prescription => !!prescription.ingredients?.length || !!prescription.dispensableDrugId

    const validateRx = async () => {
        try {
            await form.validateFields()
            const values = await form.getFieldsValue()
            const isRxValid = isPrescriptionMetadataValid(values)
            actions.saveQueuedPrescription({ ...values, valid: isRxValid, mismatch: false, _id: prescription._id })
            form.setFieldsValue({ mismatch: false })
        } catch ({ errorFields, values, ...rest }) {
            const mismatches = errorFields.filter(field => field.errors.includes('Mismatch'));
            const nonMismatchErrors = errorFields.filter(field => field.errors.filter(msg => msg !== 'Mismatch').length > 0);
            form.setFieldsValue({ mismatch: mismatches.length > 0 })
            actions.saveQueuedPrescription({  ...values, valid: !nonMismatchErrors.length, mismatch: mismatches.length > 0, _id: prescription._id })
        }
    }

    const {
        selectTemplate,
        selectedTemplate,
        templatesLoading,
        templates,
        handleSelectTemplate,
        templateOwnerType,
        selectTemplateOwnerType,
    } = usePrescriptionTemplates({
        form,
        prescription,
        dispenseUnits,
        getDispenseUnitById,
        validateRx,
        setShowPharmacyNotesTextArea,
        ...actions
    })

    const {
        fetchMedications,
        handleSelectMedication,
        handleSelectStrength,
        medicationSearchMatches,
        selectedMedication,
        selectedStrength,
        medicationSearchLoading,
        setMedicationState,
        handleResetMedication,
    } = useSearchMedications({
        form,
        prescription,
        selectedTemplate,
        selectTemplate,
        templatesLoading,
        validateRx,
        ...actions
    })


    const handleFieldsChange = async (e) => {
        const clearSigForUpdatedFields = [
            'name',
            'strength',
        ]

        if (clearSigForUpdatedFields.includes(e?.target.name)) {
            selectTemplate(null)
            form.setFieldsValue({ directions: '', pharmacyNotes: '' })
        }

        validateRx(prescription)
    }

    const handleResetForm = () => {
        form.resetFields()
        selectTemplate(null)
        actions.clearPrescription(prescription._id)
        form.setFieldsValue(RESET_VALUES)
        handleResetMedication()
    }

    const _handleCreateTemplate = index => {
        const values = form.getFieldsValue()
        actions.saveQueuedPrescription({ ...values,  _id: prescription._id })
        handleCreateTemplate(index)
    }

    return (
        <FormContainer style={{ width: '50%', padding: '10px 60px' }}>
            <PrescriptionFormProvider value={{
                form,
            }}>
                <StyledSpin spinning={medicationSearchLoading || dispenseUnitsLoading || templatesLoading}>
                    <StyledForm
                        form={form}
                        layout="vertical"
                        onChange={handleFieldsChange}
                        initialValues={{
                            daysSupply: prescription.daysSupply || 30,
                            startDate: moment(),
                            noSubstitutions: prescription.requestedMedication?.DAW
                        }}
                    >
                        <FormTitle isFirstMedication={isFirstMedication} />
                        <FormHeader
                            prescription={prescription}
                            handleResetForm={handleResetForm}
                            handleCreateTemplate={_handleCreateTemplate}
                        />
                        <MemoizedTemplateSelect
                            loading={templatesLoading}
                            prescription={prescription}
                            templates={templates}
                            selectedTemplate={selectedTemplate}
                            handleSelectTemplate={handleSelectTemplate}
                            templateOwnerType={templateOwnerType}
                            selectTemplateOwnerType={selectTemplateOwnerType}
                        />
                        <MedicationSearch
                            form={form}
                            selectedMedication={selectedMedication}
                            selectedStrength={selectedStrength}
                            fetchMedications={fetchMedications}
                            handleSelectMedication={handleSelectMedication}
                            medicationSearchMatches={medicationSearchMatches}
                            prescription={prescription}
                            telemedicineCategoryId={telemedicineCategoryId}
                            bypassValidation={bypassValidation}
                        />
                        <Form.Item rules={[{ required: true }]}>
                            <StrengthSelector
                                prescription={prescription}
                                selectedMedication={selectedMedication}
                                selectedStrength={selectedStrength}
                                setMedicationState={setMedicationState}
                                handleSelectStrength={handleSelectStrength}
                                telemedicineCategoryId={telemedicineCategoryId}
                                bypassValidation={bypassValidation}
                            />
                            <FormColumnSpacer />
                            <QuantityInput 
                                prescription={prescription} 
                                telemedicineCategoryId={telemedicineCategoryId}
                                bypassValidation={bypassValidation}
                            />
                        </Form.Item>
                        <Form.Item>
                            <DispenseUnitSelector
                                prescription={prescription}
                                selectedStrength={selectedStrength}
                                dispenseUnits={dispenseUnits}
                                handleFieldsChange={handleFieldsChange}
                            />
                            <FormColumnSpacer />
                            <RefillsInput 
                                prescription={prescription} 
                                form={form}
                                telemedicineCategoryId={telemedicineCategoryId}
                                bypassValidation={bypassValidation}
                            />
                        </Form.Item>
                        <Form.Item>
                            <DaysSupplyInput 
                                prescription={prescription} 
                                telemedicineCategoryId={telemedicineCategoryId}
                                form={form}
                                bypassValidation={bypassValidation}
                            />
                            <FormColumnSpacer />
                            <StartDatePicker form={form} onChange={handleFieldsChange} />
                        </Form.Item>
                        <SigTextArea onChange={handleFieldsChange}/>
                        <PrescriptionOptions
                            onChange={handleFieldsChange}
                            showPharmacyNotesTextArea={showPharmacyNotesTextArea}
                            setShowPharmacyNotesTextArea={setShowPharmacyNotesTextArea}
                        />
                        <Form.Item hidden name="ndc" />
                        <Form.Item hidden name="dispensableDrugId" />
                        <Form.Item hidden name="ingredients" />
                        <Form.Item hidden name="dispenseUnitId" />
                        <Form.Item hidden name="schedule" />
                    </StyledForm>
                </StyledSpin>
            </PrescriptionFormProvider>
        </FormContainer>
    )
}

const mapStateToProps = (state) => {
    const {
        consultationCompleted,
        allPrescriptionsValid,
        selectedPharmacy,
        showChangePharmacyModal,
        prescriptions,
        rxComparisonMedications,
        rxComparisonMedicationsLoading,
        dispenseUnits,
    } = state.prescribeMedicine;

    return {
        consultationCompleted,
        allPrescriptionsValid,
        selectedPharmacy,
        showChangePharmacyModal,
        prescriptions,
        rxComparisonMedications,
        rxComparisonMedicationsLoading,
        dispenseUnits
    };
};

const mapDispatchToProps = (dispatch) => {
    const {
        clearQueuedPrescription,
        saveQueuedPrescription,
        setAllPrescriptionsValid,
        resetPrescriptionQueue,
        setNewPharmacyForRx,
    } = prescribeMedicine.actions;
    return {
        actions: bindActionCreators(
            {
                clearQueuedPrescription,
                saveQueuedPrescription,
                setAllPrescriptionsValid,
                resetPrescriptionQueue,
                setNewPharmacyForRx,
            },
            dispatch
        ),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(memo(PrescriptionForm))
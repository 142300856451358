export const safeToLower = (str) => str?.toLowerCase();

export const findMeicationByName = (name, meds) =>
  meds.find(med => safeToLower(med.name) === safeToLower(name));

export const findMedicationByNdc = (medications, ndc) =>
  medications.find(medication =>
    medication.strengths?.find(strength =>
      strength?.ndc?.toString() === ndc?.toString()
    )
  );

export const findMedicationByName = (medications, lookupName) =>
  medications.find(medication => safeToLower(medication.name) === safeToLower(lookupName));

export const getDefaultMedication = (medications) =>
  medications?.[0]?.strengths ? medications[0].strengths[0] : medications[0];

export const normalizeStrengthString = (requestedStrength, str) => {
  if (parseInt(requestedStrength).toString() === requestedStrength?.toString()) {
    return str?.replace(/\D/g,'');
  }
  return str.replace(' ', '').toLowerCase()
}

import { useState, useEffect } from 'react'

export function useDispenseUnitSelector({
    dispenseUnits,
}) {
    const [options, setOptions] = useState([])

    useEffect(() => {
        if (dispenseUnits?.length) {
            setOptions(dispenseUnits.map(unit => (
                {
                    value: unit.StandardDispenseUnitTypeID,
                    label: unit.Name,
                }
            )))
        }
    }, [dispenseUnits])

    return { options }
}
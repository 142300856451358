import React, { useEffect } from 'react'

import { DiffContainer } from '../DiffContainer'

import { 
  SelectMergePatientContainer,
  StyledBackButton ,
  StyledConfirmButton
} from './styles'

export const SelectMergePatient = ({
    selectedRxError,
    selectedLocalPatient,
    selectLocalPatient,
    mergedPatient,
    handleReconcilePatientProperty,
    setMergedPatientConfirmed,
    selectedMergeSource,
    selectMergeSource
}) => {

    const handleInitialReconcilation = () => {
      if (selectedMergeSource === 'rtl')
        for (let [k,v] of Object.entries(selectedLocalPatient)) {
          if (!v && selectedRxError?.prescriberPatientRecord?.[k]) {
            handleReconcilePatientProperty({ [k]: selectedRxError?.prescriberPatientRecord?.[k]})
          }
        }
      else {
        for (let [k,v] of Object.entries(selectedLocalPatient)) {
          if (v && !selectedRxError?.prescriberPatientRecord?.[k]) {
            handleReconcilePatientProperty({ [k]: v})
          }
        }
      }
    }

    useEffect(() => {
      handleInitialReconcilation()
    }, [selectedMergeSource])

    return (
        <SelectMergePatientContainer>
          <DiffContainer 
            localPatient={selectedLocalPatient}
            prescriberPatient={selectedRxError?.prescriberPatientRecord}
            mergedPatient={mergedPatient}
            handleReconcilePatientProperty={handleReconcilePatientProperty}
            selectedMergeSource={selectedMergeSource}
            selectMergeSource={selectMergeSource}
          />
          <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between'}}>
            <StyledBackButton onClick={() => selectLocalPatient(null)}>Back to Patient Select</StyledBackButton>
            <StyledConfirmButton type='primary' onClick={() => setMergedPatientConfirmed(true)}>Confirm Reconcilation</StyledConfirmButton>
          </div>
          
        </SelectMergePatientContainer>
    )
}
import React from 'react'
import { connect } from "react-redux"
import { bindActionCreators } from 'redux'

import { Tooltip, Button } from 'antd'
import { DeleteOutlined, SaveOutlined } from '@ant-design/icons';

// import { usePrescribeContext } from '../../../context/ProviderContext'
import ClearPrefill from '../../../../../components/ClearPrefilledForm';

import prescribeMedicine from "../../../../../redux/prescribeMedicine";

import { HeaderContainer, Content } from '../styles';

const FormHeader = ({
    prescription,
    handleResetForm,
    actions 
}) => {
    return (
        <HeaderContainer>
            <Content>
                { !prescription.requestedMedication && <ClearPrefill handleClick={handleResetForm} /> }
                { !prescription.requestedMedication && (
                    <Tooltip title="Remove">
                        <Button
                            onClick={() => actions.removePrescriptionFromQueue(prescription._id)}
                            size='small'
                            style={{ fontWeight: '600' }}
                            icon={<DeleteOutlined />}
                        />
                    </Tooltip>
                )}
            </Content>
        </HeaderContainer>
    )
}

const mapDispatchToProps = (dispatch) => {
    const { removePrescriptionFromQueue } = prescribeMedicine.actions;
    return {
        actions: bindActionCreators(
            { removePrescriptionFromQueue },
            dispatch
        ),
    };
};


export default connect(null, mapDispatchToProps)(FormHeader)
import styled from 'styled-components'
import { Button } from 'antd'

export const ConfirmPatientReconcilationContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`

export const StyledBackButton = styled(Button)`
    align-self: flex-start
`
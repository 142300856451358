import { useCallback } from 'react';
import { message } from 'antd'

import PhysicianApi from '../../../axios/physician/PhysicianApi'

export const useCaseDetails = () => {

    const fetchCaseDetails = useCallback(async caseId => {
        if (!caseId) return

        try {
            const { data: { payload } } = await PhysicianApi.getCaseDetailsById(caseId)
            return payload
        } catch (e) {
            console.log(e)
            message.error("Unable to fetch caseDetails")
        }
    }, [])

    return { fetchCaseDetails }
}
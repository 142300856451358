import { createStore, applyMiddleware, combineReducers, compose } from "redux";
import thunkMiddleware from "redux-thunk";
import { createLogger } from "redux-logger";
import config from "../config";

import registration from "./registration";
import authentication from "./authentication";
import waitingRoom from "./waitingRoom";
import message from "./message";
import notifications from "./notifications";
import pendingPrescription from "./pendingPrescription";
import scheduledCases from "./scheduledCases";
import additionalInfoRequired from "./additionalInfoRequired";
import caseTickets from "./caseTickets";
import rxChange from "./rxChange";
import rxRenewal from "./rxRenewals";
import consultedCase from "./consultedCase";
import physicianDetails from "./physicianDetails";
import entities from "./entities";
import articles from "./articles";
import reports from "./reports";
import caseTags from "./caseTags";
import incompleteCases from "./incompleteCases";
import referrals from "./referrals";
import otherPhysiciansCases from "./otherPhysiciansCases";
import caseQueue from "./caseQueue"
import rxRequests from "./rxRequests"
import prescribeMedicine from "./prescribeMedicine"

const appReducer = combineReducers({
  [registration.name]: registration.reducer,
  [authentication.name]: authentication.reducer,
  [waitingRoom.name]: waitingRoom.reducer,
  [message.name]: message.reducer,
  [notifications.name]: notifications.reducer,
  [pendingPrescription.name]: pendingPrescription.reducer,
  [scheduledCases.name]: scheduledCases.reducer,
  [additionalInfoRequired.name]: additionalInfoRequired.reducer,
  [rxChange.name]: rxChange.reducer,
  [rxRenewal.name]: rxRenewal.reducer,
  [consultedCase.name]: consultedCase.reducer,
  [physicianDetails.name]: physicianDetails.reducer,
  [entities.name]: entities.reducer,
  [articles.name]: articles.reducer,
  [caseTickets.name]: caseTickets.reducer,
  [reports.name]: reports.reducer,
  [caseTags.name]: caseTags.reducer,
  [incompleteCases.name]: incompleteCases.reducer,
  [referrals.name]: referrals.reducer,
  [otherPhysiciansCases.name]: otherPhysiciansCases.reducer,
  [caseQueue.name]: caseQueue.reducer,
  [rxRequests.name]: rxRequests.reducer,
  [prescribeMedicine.name]: prescribeMedicine.reducer
});

const middlewares = [thunkMiddleware];

const rootReducer = (state, action) => {
  // when a logout action is dispatched it will reset redux state
  if (action.type === "USERS_LOGOUT") {
    state = undefined;
  }
  return appReducer(state, action);
};

// if (config.appEnv !== "prod") {
//   const loggerMiddleware = createLogger();
//   middlewares.push(loggerMiddleware);
// }

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
export const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(...middlewares))
);

import React from 'react'
import styled from 'styled-components'

import {
    Form,
    Typography,
    Space,
    Radio,
} from "antd"

const { Text } = Typography

const StyledRadio = styled(Radio)`
    & span.ant-radio > span {
        background: none;
    }
`

const PrescriptionMismatchCheck = ({ isRxMismatch }) => {
    if (!isRxMismatch) return null
    return (
        <div>
            <Text 
                strong 
                style={{ display: 'block', marginBottom: '10px'}}
            >
                Prescription Adjustment Reason
            </Text>
            <Text>
                We noticed there might be some variation between what was requested by the patient
                and what was prescribed. Please select a reason below:
            </Text>
            <Form.Item 
                label=""
                defaultValue="patient"
                name="adjustmentReason" 
                style={{ marginTop: '20px'}}
            >
                <Radio.Group defaultValue={null}>
                    <Space direction="vertical">
                        <StyledRadio value={null}>No Adjustment</StyledRadio>
                        <StyledRadio value="Rx Adjustment by Dr">Rx Adjustment by Provider</StyledRadio>
                        <StyledRadio value="Rx Adjustment by Patient" checked>Rx Adjustment by Patient</StyledRadio>
                    </Space>
                </Radio.Group>
            </Form.Item>
        </div>
    )
}

export default PrescriptionMismatchCheck
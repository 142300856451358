import React, { useState,useEffect } from 'react'
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Handlebars from 'handlebars';
import { Select, Input, Typography, Button } from 'antd'

import { TemplateTypeEnum } from '../../../../types/enums'
import TemplatesApi from '../../../../axios/templates/TemplateAPI'

import { showVariableList } from './showVariableList'

import prescribeMedicine from '../../../../redux/prescribeMedicine';


const { Title } = Typography
const { TextArea } = Input 

const Charting = ({ 
    telemedicineCategoryId, 
    prescription,
    visitNote,
    actions,
}) => {
    const [availableTemplates, setAvailableTemplates] = useState([])
    const [selectedTemplate, selectTemplate] = useState(null)

    const compileHandlebars = (str) => {
        try {
            const handlebarsTemplate = Handlebars.compile(str);
            return handlebarsTemplate({ prescription })
        } catch (e) {
            throw new Error()
        }
    }

    const handleSelectTemplate = (message) => {
        selectTemplate(message)
        actions.setVisitNote(compileHandlebars(message))
    }

    const handleTextChange = ({ target }) => {
        selectTemplate(target.value)

        try {
            actions.setVisitNote(compileHandlebars(target.value))
        } catch (e) {
            actions.setVisitNote(compileHandlebars('Template contains errors. Please edit template.'))
        }
    }

    const resetVisitNote = () => {
        selectTemplate(null)
        actions.setVisitNote('')
    }

    useEffect(() => {
        TemplatesApi.getMany({
            type: TemplateTypeEnum.VISIT,
            teleMedicineCategoryIds: [telemedicineCategoryId]
        })
        .then(({ data: { templates } }) => setAvailableTemplates(templates))
    }, [])

    useEffect(() => {
        if (prescription) {
            actions.setVisitNote(compileHandlebars(selectedTemplate || ''))
        }
    }, [prescription])

    return (
        <div style={{ 
            display: 'flex',
            flexDirection: 'column',
            gap: '12px',
            margin: '30px 0 30px 60px', 
            padding: '20px',
            background: '#f4f7f8'
        }}
        >
            <div>
                <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '5px'}}>
                    <Title style={{ fontSize: '15px'}}>Visit Note</Title>
                    <Button type="primary" size="small" onClick={resetVisitNote}>Clear Note</Button>
                </div>

                <Title style={{ fontSize: '12px'}}>Template Name</Title>

                <Select 
                    style={{ width: '100%'}}
                    placeholder="Select a template"
                    options={availableTemplates.map(template => ({
                        value: template.message,
                        label: template.title
                    }))}
                    onSelect={handleSelectTemplate}
                />
            </div>
            <div>
                <Title style={{ fontSize: '12px'}}>Template</Title>
                <TextArea style={{ height: '100px'}} value={selectedTemplate} onChange={handleTextChange}/>
            </div>
            <div>
                <Title style={{ fontSize: '12px'}}>Rendered message</Title>
                <div style={{ background: '#fff', color: 'rgba(0, 0, 0, 0.65)', padding: '10px'}}>
                    { visitNote }
                </div>
            </div>
            <Button 
                type="primary" 
                size="small" 
                style={{ width: '180px'}}
                onClick={showVariableList}
            >
                Template Variables
            </Button>
        </div>
    )
}   

const mapStateToProps = (state) => {
    const {  
        visitNote,
    } = state.prescribeMedicine;
    return {
        visitNote,
    };
};

const mapDispatchToProps = (dispatch) => {
    const { 
        setVisitNote,
    } = prescribeMedicine.actions;
    return {
      actions: bindActionCreators(
        {
            setVisitNote,
        },
        dispatch
      ),
    };
  };

  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(Charting);
const types = {
    SET_CLIENTS_LIST_LOADING: 'SET_CLIENTS_LIST_LOADING',
    SET_CLIENTS_LIST: 'SET_CLIENTS_LIST',
    SET_STATES_LIST_LOADING: 'SET_STATES_LIST_LOADING',
    SET_STATES_LIST: 'SET_STATES_LIST',
    SET_TELEMEDICINE_CATEGORY_LIST_LOADING: 'SET_TELEMEDICINE_CATEGORY_LIST_LOADING',
    SET_TELEMEDICINE_CATEGORY_LIST: 'SET_TELEMEDICINE_CATEGORY_LIST',
    SET_CREDENTIALS_LIST_LOADING: 'SET_CREDENTIALS_LIST_LOADING',
    SET_CREDENTIALS_LIST: 'SET_CREDENTIALS_LIST',
    SET_ARTICLE_CATEGORY_LIST_LOADING:'SET_ARTICLE_CATEGORY_LIST_LOADING',
    SET_ARTICLE_CATEGORY_LIST:'SET_ARTICLE_CATEGORY_LIST',
};

export default types; 
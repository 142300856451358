import types from "./types";
import handleAPIErrorResponse from "../../utils/handleAPIResponseError";
import APIHelper from "../../axios/apiHelper";

import { message } from "antd";

const setClientsListLoading = (status) => ({
  type: types.SET_CLIENTS_LIST_LOADING,
  data: status,
});

const setClientsList = (payload) => ({
  type: types.SET_CLIENTS_LIST,
  payload,
});

const setStatesListLoading = (status) => ({
  type: types.SET_STATES_LIST_LOADING,
  data: status,
});

const setStatesList = (payload) => ({
  type: types.SET_STATES_LIST,
  payload,
});

const setTelemedicineCategoriesListLoading = (status) => ({
  type: types.SET_TELEMEDICINE_CATEGORY_LIST_LOADING,
  data: status,
});

const setTelemedicineCategoriesList = (payload) => ({
  type: types.SET_TELEMEDICINE_CATEGORY_LIST,
  payload,
});

const setCredentialsListLoading = (status) => ({
  type: types.SET_CREDENTIALS_LIST_LOADING,
  data: status,
});

const setCredentialsList = (payload) => ({
  type: types.SET_CREDENTIALS_LIST,
  payload,
});

const setArticleCategoryListLoading = (status) => ({
  type: types.SET_ARTICLE_CATEGORY_LIST_LOADING,
  data: status,
});

const setArticleCategoryList = (payload) => ({
  type: types.SET_ARTICLE_CATEGORY_LIST,
  payload,
});

function getClientsList() {
  return (dispatch) => {
    dispatch(setClientsListLoading(true));
    APIHelper.fetchLookUp("client")
      .then((res) => {
        const { data } = res;
        if (data && data.success === true) {
          const { payload } = data;
          dispatch(setClientsList(payload));
        } else {
          message.warning("Unable to fetch clients");
        }
        dispatch(setClientsListLoading(false));
      })
      .catch((error) => {
        handleAPIErrorResponse(error, "Error occured while fetching clients");
        dispatch(setClientsListLoading(false));
      });
  };
}

function getStatesList() {
  return (dispatch) => {
    dispatch(setStatesListLoading(true));
    APIHelper.fetchLookUp("state")
      .then((res) => {
        const { data } = res;
        if (data && data.success === true) {
          const { payload } = data;
          dispatch(setStatesList(payload));
        } else {
          message.warning("Unable to fetch states");
        }
        dispatch(setStatesListLoading(false));
      })
      .catch((error) => {
        handleAPIErrorResponse(error, "Error occured while fetching states");
        dispatch(setStatesListLoading(false));
      });
  };
}

function getTelemedicineCategoriesList() {
  return (dispatch) => {
    dispatch(setTelemedicineCategoriesListLoading(true));
    APIHelper.fetchLookUp("tele-medicine-category")
      .then((res) => {
        const { data } = res;
        if (data && data.success === true) {
          const { payload } = data;
          dispatch(setTelemedicineCategoriesList(payload));
        } else {
          message.warning("Unable to fetch telemedicine categories");
        }
        dispatch(setTelemedicineCategoriesListLoading(false));
      })
      .catch((error) => {
        handleAPIErrorResponse(
          error,
          "Error occured while fetching telemedicine categories"
        );
        dispatch(setTelemedicineCategoriesListLoading(false));
      });
  };
}

function getCredentialsList() {
  return (dispatch) => {
    dispatch(setCredentialsListLoading(true));
    APIHelper.fetchLookUp("credential")
      .then((res) => {
        const { data } = res;
        if (data && data.success === true) {
          const { payload } = data;
          dispatch(setCredentialsList(payload));
        } else {
          message.warning("Unable to fetch credentials");
        }
        dispatch(setCredentialsListLoading(false));
      })
      .catch((error) => {
        handleAPIErrorResponse(
          error,
          "Error occured while fetching credentials"
        );
        dispatch(setCredentialsListLoading(false));
      });
  };
}

function getArticleCategoryList() {
  return (dispatch) => {
    dispatch(setArticleCategoryListLoading(true));
    APIHelper.fetchLookUp("article-category")
      .then((res) => {
        const { data } = res;
        if (data && data.success === true) {
          const { payload } = data;
          dispatch(setArticleCategoryList(payload));
        } else {
          message.warning("Unable to fetch credentials");
        }
        dispatch(setArticleCategoryListLoading(false));
      })
      .catch((error) => {
        handleAPIErrorResponse(
          error,
          "Error occured while fetching credentials"
        );
        dispatch(setArticleCategoryListLoading(false));
      });
  };
}

const actions = {
  setClientsListLoading,
  setClientsList,
  setStatesListLoading,
  setStatesList,
  setTelemedicineCategoriesListLoading,
  setTelemedicineCategoriesList,
  setCredentialsListLoading,
  setCredentialsList,
  setArticleCategoryListLoading,
  setArticleCategoryList,
  getClientsList,
  getStatesList,
  getTelemedicineCategoriesList,
  getCredentialsList,
  getArticleCategoryList,
};

export default actions;

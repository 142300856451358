import React from 'react';
import { Menu } from 'antd';
import styled from 'styled-components'

import { useToggleMenu } from "../../../context/MenuProvider";

import MainMenu from './MainMenu';

const Collapse = styled.div`
  width: 280px;
  height: 100vh;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;

  position: absolute;
  z-index: 1;
  left: ${({ dataCollapsed }) => dataCollapsed ? '-280px' : '0'};

  transition: left 0.3s;

  & .ant-menu {
    width: 100%;
  }
`


export const Sidebar = () => {

  const { isMenuOpen } = useToggleMenu();

  return (
      <Collapse dataCollapsed={!isMenuOpen}>
        <Menu
          theme="dark"
          mode="vertical"
          inlineCollapsed={!isMenuOpen}
        >
          <MainMenu data-collapsed={!isMenuOpen} />
        </Menu>
      </Collapse>
  );
};
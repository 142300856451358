/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Select,
  Typography,
  Spin,
  Button,
  Drawer,
  Divider,
  Tooltip,
  message,
} from "antd";
import { LoadingOutlined, MenuOutlined, PlusOutlined, CaretUpOutlined, CaretDownOutlined } from "@ant-design/icons";
import { bindActionCreators } from "redux";

// import CSS
import "./Messages.css";

// import APIs
import ChatRoomAPI from "../../axios/ChatRoomAPI";
import PhysicianApi from "../../axios/physician/PhysicianApi";

//import components
import ChatList from "./ChatList";
import MessageList from "./MessageList";
import CaseDetailsDraw from "./CaseDetailsDraw";
import { connect } from "react-redux";
import messageStore from "../../redux/message";

const { Text } = Typography;

const Messages = (props) => {
  const { chatCaseId, actions } = props;
  const [chatListLoading, setChatListLoading] = useState(false);
  const [chatListDrawCollapse, setChatListDrawCollapse] = useState(false);
  const [caseDetailsDrawCollapse, setCaseDetailsDrawCollapse] = useState(false);
  const [caseIdForCaseDetails, setCaseIdForCaseDetails] = useState(null);
  const [caseDetails, setCaseDetails] = useState({});
  const [caseList, setCaseList] = useState([]);
  const [chatList, setChatList] = useState([]);
  const [totalCountCase, setTotalCountCase] = useState();
  const [indexCaseList, setIndexCaseList] = useState(1);
  const [caseListLoading, setCaseListLoading] = useState(false);
  const [totalCountChatList, setTotalCountChatList] = useState();
  const [indexChatList, setIndexChatList] = useState(1);
  const [caseInChatSelected, setCaseInChatSelected] = useState(false);
  const [selectedCase, setSelectedCase] = useState();
  const [caseSearchTerm, setCaseSearchTerm] = useState("");
  const [sortOrder, setSortOrder] = useState('newest');

  const isWheel = () => {
    if (window.localStorage.getItem("role") === "Wheel") {
      return true;
    } else {
      return false;
    }
  };

  const fetchChatList = (params) => {
    setChatListLoading(true);
    ChatRoomAPI.getChatList(params)
      .then((res) => {
        if (res.data.success === true && res.data.payload.chat) {
          const chatListData = chatList;
          setChatList(chatListData.concat(res.data.payload.chat));
          setTotalCountChatList(res.data.payload.totalCount);
          setChatListLoading(false);
        } else {
          setChatList([]);
          setChatListLoading(false);
        }
      })
      .catch(() => {
        setChatList([]);
        setChatListLoading(false);
      });
  };

  const fetchCaseList = (params) => {
    setCaseListLoading(true);
    PhysicianApi.getCaseList(params)
      .then((res) => {
        if (res.data.success === true && res.data.payload.data) {
          const caseListData = caseList;
          setCaseList(caseListData.concat(res.data.payload.data));
          setTotalCountCase(res.data.payload.count);
        }
        setCaseListLoading(false);
      })
      .catch(() => {
        setCaseList([]);
        setCaseListLoading(false);
      });
  };

  const surgicalChatListUpdate = ({ caseId }) => {
    setTotalCountChatList(totalCountChatList - 1);
    setChatList(chatList.map((thread) => {
      return { 
        ...thread, 
        count: thread.caseId === caseId 
          ? 0 
          : thread.count 
        };
    }));
  }


  useEffect(() => {
    if (!chatCaseId) {    
      if(caseList.length===0){ 
      if (indexCaseList === 1) {
        const caseListParams = {
          index: 1,
          "case-count": 25,
        };
        fetchCaseList(caseListParams);
      }
      }
      if (indexChatList === 1 && !caseInChatSelected) {
        if(chatList.length===0){
        const chatListParams = {
          page: 1,
          limit: 25,
        };
        fetchChatList(chatListParams);
      }
      }
    } else if (!caseInChatSelected) {
      handleNavigatedChatCase();
      if (indexCaseList === 1) {
        const caseListParams = {
          index: 1,
          "case-count": 25,
        };
        fetchCaseList(caseListParams);
      }
    }
  }, [caseInChatSelected]);

  useEffect(() => {
    if (caseInChatSelected) {
      if (chatList.length === 0 && caseList.length === 0) {
        handleSelectClear();
      }
    }
  }, [chatList, caseList]);

  const handleSelectClear = () => {
    const caseListParams = {
      index: 1,
      "case-count": 10 ,
    };
    fetchCaseList(caseListParams);
    setIndexCaseList(1);
    setIndexChatList(1);
    setCaseSearchTerm("");

    const chatListParams = {
      page: 1,
      limit: 10,
    };
    fetchChatList(chatListParams);
    actions.setChatCaseId();
  };

  const handleChatSidebarCollapseChange = () => {
    setChatListDrawCollapse((previousState) => !previousState);
  };

  const handleCaseDetailsSidebarCollapseChange = () => {
    setCaseDetailsDrawCollapse((previousState) => !previousState);
  };

  const handleCaseDetailsView = (caseId) => {
    setCaseIdForCaseDetails(caseId);
    setCaseDetailsDrawCollapse((previousState) => !previousState);
  };

  const handleCaseChangeAndAdd = (value) => {
    if (value) {
      const selectedCaseFromDropdown = JSON.parse(value);
      // create a chat data object
      const chatNeedsToBeSelected = {
        caseId: selectedCaseFromDropdown.caseId,
        count: selectedCaseFromDropdown.unReadChatCount,
        date: "",
        name: selectedCaseFromDropdown.patientName,
        patientId: selectedCaseFromDropdown.patientId,
        physicianId: selectedCaseFromDropdown.physicianId,
        photo: selectedCaseFromDropdown.patientPhoto,
      };
      setChatList([chatNeedsToBeSelected]);
      setTotalCountChatList(1);
      setCaseDetails(chatNeedsToBeSelected);
      const { caseId } = chatNeedsToBeSelected;
      setSelectedCase(value);
      actions.setChatCaseId(caseId);
    } else {
      setChatList([]);
      setCaseList([]);
      setCaseInChatSelected(true);
      setSelectedCase();
    }
  };

  const handleChatSelection = (data) => {
    setCaseDetails(data);
  };

  function handleNavigatedChatCase() {
    if (chatCaseId) {
      const params = {
        "case-id": chatCaseId,
      };
      setChatListLoading(true);
      setCaseListLoading(true);
      PhysicianApi.getCaseList(params)
        .then((res) => {
          const { data } = res;
          setChatListLoading(false);
          setCaseListLoading(false);
          if (!data.success) return message.error("Unable to fetch case, please try again!");
          if (data.payload.data[0]) {
            const checkChatNavigatedCaseList = data.payload.data[0];
            const selectNavigatedChatCase = {
              caseId: checkChatNavigatedCaseList.caseId,
              count: checkChatNavigatedCaseList.unReadChatCount,
              date: "",
              name: checkChatNavigatedCaseList.patientName,
              patientId: checkChatNavigatedCaseList.patientId,
              physicianId: checkChatNavigatedCaseList.physicianId,
              photo: checkChatNavigatedCaseList.patientPhoto,
            };
            setChatList([selectNavigatedChatCase]);
            setCaseDetails(selectNavigatedChatCase);
          } 
        })
        .catch(() => {
          setChatListLoading(false);
          setCaseListLoading(false);
          message.error("unable to fetch case , please try again!");
        });
    }
  }
  const handleLoadCaseList = () => {
    const caseListParams = {
      index: indexCaseList + 1,
      "case-count": 25,
      "search-term": caseSearchTerm,
    };
    setIndexCaseList(indexCaseList + 1);
    fetchCaseList(caseListParams);
  };

  const handleLoadChatList = () => {
    const chatListParams = {
      page: indexChatList + 1,
      limit: 25,
    };
    setIndexChatList(indexChatList + 1);
    fetchChatList(chatListParams);
  };

  const handleSearch = (val) => {
    setCaseSearchTerm(val);
  };

  const toggleSortChatList = () => {
    if (sortOrder === 'oldest') {
      setSortOrder('newest');
    }
    else {
      setSortOrder('oldest');
    }
  }

  useEffect(() => {
    if (sortOrder === 'oldest') {
      setChatList([...chatList.sort((roomA, roomB) => new Date(roomB.date) - new Date(roomA.date))]);
    }
    else {
      setChatList([...chatList.sort((roomA, roomB) => new Date(roomA.date) - new Date(roomB.date))]);
    }
  }, [sortOrder])

  return (
    <div className="message-wrapper">
      <Drawer
        visible={chatListDrawCollapse}
        className="message-chat-list-draw"
        closable
        maskClosable
        placement="left"
        onClose={handleChatSidebarCollapseChange}
        getContainer={false}
      >
        <Spin
          size="large"
          spinning={chatListLoading}
          indicator={<LoadingOutlined spin />}
        >
          {chatList.length ? (
            <>
              <ChatList
                caseDetails={caseDetails}
                chatList={chatList}
                chatSelection={handleChatSelection}
              />
              {totalCountChatList > chatList.length && (
                <div>
                  <Divider style={{ margin: "4px 0" }} />
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Button
                      type="primary"
                      size="large"
                      style={{
                        padding: "8px",
                        display: "block",
                        cursor: "pointer",
                      }}
                      loading={chatListLoading}
                      onClick={handleLoadChatList}
                    >
                      <PlusOutlined /> Load More
                    </Button>
                  </div>
                </div>
              )}
            </>
          ) : (
            <div className="message-no-chat-selection bg-white-color border-color">
              <Text strong>No Chats yet</Text>
            </div>
          )}
        </Spin>
      </Drawer>

      <Row gutter={[8, 8]}>
        <Col xs={3} sm={3} md={2} lg={0} xl={0}>
          <Button
            icon={<MenuOutlined />}
            className="message-chat-list-box"
            size="large"
            onClick={handleChatSidebarCollapseChange}
          />
        </Col>
        <Col xs={21} sm={21} md={22} lg={24} xl={24}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Tooltip  title={`${sortOrder} first`}>
              <Button onClick={toggleSortChatList} icon={sortOrder === 'oldest' ? <CaretDownOutlined /> : <CaretUpOutlined />} style={{ height: 40, width: 40 }} />
            </Tooltip>
            <Select
              placeholder="Find case in loaded list"
              showSearch
              allowClear={true}
              size="large"
              value={selectedCase}
              onSearch={handleSearch}
              className="message-search-case-dropdown"
              onChange={handleCaseChangeAndAdd}
              getPopupContainer={(trigger) => trigger.parentElement}
              dropdownRender={(menu) => (
                <div>
                  <Spin
                    spinning={caseListLoading}
                    indicator={<LoadingOutlined spin />}
                  >
                    {menu}

                    {totalCountCase > caseList.length && caseSearchTerm === "" && (
                      <>
                        <Divider style={{ margin: "4px 0" }} />
                        <div
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <Button
                            type="primary"
                            size="large"
                            style={{
                              padding: "8px",
                              display: "block",
                              cursor: "pointer",
                            }}
                            onClick={handleLoadCaseList}
                          >
                            <PlusOutlined /> Load More Case
                          </Button>
                        </div>
                      </>
                    )}
                  </Spin>
                </div>
              )}
            >
              {caseList.map((eachCase) => (
                <Select.Option
                  key={eachCase.caseId}
                  value={JSON.stringify(eachCase)}
                >
                  {`${eachCase.category} : ${eachCase.patientName}`}
                </Select.Option>
              ))}
            </Select>
            <Button onClick={() => handleCaseChangeAndAdd()} style={{ height: 40 }}>
              Clear Selections
            </Button>
          </div>
        </Col>
        <Col
          xs={0}
          sm={0}
          md={0}
          lg={8}
          xl={8}
          className="message-chat-list-wrapper"
          style={{ backgroundColor: 'none'}}
        >
          <Spin
            size="large"
            spinning={chatListLoading}
            indicator={<LoadingOutlined spin />}
          >
            {chatList.length ? (
              <div>
                <ChatList
                  caseDetails={caseDetails}
                  chatList={chatList}
                  chatSelection={handleChatSelection}
                />

                {totalCountChatList > chatList.length && (
                  <div>
                    <Divider style={{ margin: "4px 0" }} />
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <Button
                        type="primary"
                        size="large"
                        style={{
                          padding: "8px",
                          display: "block",
                          cursor: "pointer",
                        }}
                        loading={chatListLoading}
                        onClick={handleLoadChatList}
                      >
                        <PlusOutlined /> Load More Chats
                      </Button>
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <div className="message-no-chat-selection bg-white-color border-color">
                <Text strong>No Chats yet</Text>
              </div>
            )}
          </Spin>
        </Col>
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={16}
          xl={16}
          className="message-chat-list-wrapper"
        >
          <MessageList
            caseDetails={caseDetails}
            isDifferentPlatform={isWheel()}
            refreshChatList={surgicalChatListUpdate}
            enableCaseDetailsView={handleCaseDetailsView}
          />
        </Col>
      </Row>
      <CaseDetailsDraw
        caseId={caseIdForCaseDetails}
        caseDetailsDrawCollapse={caseDetailsDrawCollapse}
        handleCaseDetailsDrawClose={handleCaseDetailsSidebarCollapseChange}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  const { chatCaseId } = state.message;
  return { chatCaseId };
};

const mapDispatchToProps = (dispatch) => {
  const { setChatCaseId } = messageStore.actions;
  return {
    actions: bindActionCreators(
      {
        setChatCaseId,
      },
      dispatch
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Messages);

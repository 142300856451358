/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Avatar, Button } from "antd";

//import CSS
import "./MessageHeaderComponent.css";

const MessageHeaderComponent = ({
  avatarUrl,
  title,
  subTitle,
  options,
  onOptionsClick,
}) => {
  
  const handleOptionClick = (event, option) => {
    onOptionsClick(option);
  };

  return (
    <div className="message-header-wrapper">
      <div>
        {avatarUrl && (
            <div className="message-header-section-wrapper .message-header-avatar">
                <Avatar size={56} src={avatarUrl} />
            </div>
        )}
        <div className="message-header-section-wrapper message-header-title-wrapper">
          <div className="message-header-subtitle">{subTitle}</div>
          <div className="message-header-title">{title}</div>
        </div>
      </div>
      <Button className='message-case-details-btn' type='primary' onClick={(event) => handleOptionClick(event, "case-details")}>Case Details</Button>
    </div>
  );
};

export default MessageHeaderComponent;

import React, { createContext, useContext } from 'react';

const ProviderContext = createContext();

export const useProviderContext = () => {
    const context = useContext(ProviderContext);
    if (!context) {
        throw new Error("usePrescribeContext must be used within a ProviderProvider");
    }
    return context;
}

export const ProviderProvider = ({ children, value }) => {
    return (
            <ProviderContext.Provider value={value}>
                {children}
            </ProviderContext.Provider>
        
    );
};

import * as React from 'react';
import lockIcon from "../assets/images/svg/lock-icon.svg"
const SafeConnection = () => {
    return (
        <div className="safe-connection">
           <img className="lock-icon" alt="lock-icon" src={lockIcon} />
            <p className="lock-icon-text"><b>Your connection is safe</b></p>
        </div>
    )
}
export default SafeConnection;
import types from "./types";
import API from "../../axios/physician/PhysicianApi";
import handleAPIErrorResponse from "../../utils/handleAPIResponseError";

import { message } from "antd";

const setRxRenewalCaseCountLoading = (status) => ({
    type: types.SET_RX_RENEWAL_CASE_COUNT_LOADING,
    data: status,
});

const setRxRenewalCaseCount = (payload) => ({
    type: types.SET_RX_RENEWAL_CASE_COUNT,
    payload
})

function getRxRenewalCaseCount() {
    return (dispatch) => {
        dispatch(setRxRenewalCaseCountLoading(true));
        API.getRxRenewalRequestsCount().then(
            (res) => {
                const { data } = res;
                if (data) {
                    const count = data.data?.count;
                    if (count) {
                        dispatch(setRxRenewalCaseCount(count))
                    }
                } else {
                    message.warning("Unable to update Rx Renewal case count");
                }
                dispatch(setRxRenewalCaseCountLoading(false));
            }).catch(
                (error) => {
                    handleAPIErrorResponse(error, "Error occurred while trying to load case count for Rx Renewals");
                    dispatch(setRxRenewalCaseCountLoading(false));
                });
    };
}

const actions = {
    setRxRenewalCaseCountLoading,
    setRxRenewalCaseCount,
    getRxRenewalCaseCount,
};

export default actions;
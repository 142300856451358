import types from "./types";

const initState = {
  clientsListLoading: false,
  clientsList: [],
  statesListLoading: false,
  statesList: [],
  telemedicineCategoriesListLoading: false,
  telemedicineCategoriesList: [],
  credentialsListLoading: false,
  credentialsList: [],
  articleCategoryListLoading: false,
  articleCategoryList: [],
};

export default function entities(state = initState, action) {
  switch (action.type) {
    case types.SET_CLIENTS_LIST_LOADING:
      return {
        ...state,
        clientsListLoading: action.data,
      };
    case types.SET_CLIENTS_LIST:
      return {
        ...state,
        clientsList: action.payload,
      };
    case types.SET_STATES_LIST_LOADING:
      return {
        ...state,
        statesListLoading: action.data,
      };
    case types.SET_STATES_LIST:
      return {
        ...state,
        statesList: action.payload,
      };
    case types.SET_TELEMEDICINE_CATEGORY_LIST_LOADING:
      return {
        ...state,
        telemedicineCategoriesListLoading: action.data,
      };
    case types.SET_TELEMEDICINE_CATEGORY_LIST:
      return {
        ...state,
        telemedicineCategoriesList: action.payload,
      };
    case types.SET_CREDENTIALS_LIST_LOADING:
      return {
        ...state,
        credentialsListLoading: action.data,
      };
    case types.SET_CREDENTIALS_LIST:
      return {
        ...state,
        credentialsList: action.payload,
      };
    case types.SET_ARTICLE_CATEGORY_LIST_LOADING:
      return {
        ...state,
        articleCategoryListLoading: action.data,
      };
    case types.SET_ARTICLE_CATEGORY_LIST:
      return {
        ...state,
        articleCategoryList: action.payload,
      };
    default:
      return state;
  }
}

import React from 'react'
import { Form, Typography, Checkbox, Input, DatePicker, Button, Select, Popover, AutoComplete } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons';

import { states } from '../../../utils/USStates'

const { Title, Text } = Typography

const REQUIRED = { 
    required: true,
    message: 'Required' 
}

export const ProvideIdentityForm = ({ 
    form, 
    handleSubmit,
    handleCancel, 
}) => {

    const stateOptions = states.map(state => ({ label: state.name, value: state.name }))
    const licenseStateOptions = states.map(state => ({ label: state.abbreviation, value: state.abbreviation }))


    return (
        <Form
            form={form}
            layout="vertical"
            style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '20px'
            }}
            initialValues={{
                phoneType: 'mobile',
                mobileConfirm: true
            }}
            onFinish={handleSubmit}
        >
            <div>
                <Title level={3}>Identity Verification</Title>
                <Text>To complete the identity proofing process, please enter the following information. Providing specific 
                    information such as a driver's license number is highly recommended as it will increase the pass rate. 
                    Sensitive personal information, including social security numbers, credit card numbers, and driver's 
                    license numbers are used solely for Experinan identity proofing purposes and are not retained by DoseSpot
                     or LifeMD
                </Text>
            </div>
            <div>
                <Form.Item 
                    name='firstName' 
                    label="Legal First Name" 
                    rules={[REQUIRED]}
                >
                    <Input type='text'></Input>
                </Form.Item>
                <Form.Item 
                    name='lastName' 
                    label="Legal Last Name" 
                    rules={[REQUIRED]}
                >
                    <Input type='text'></Input>
                </Form.Item>
                <Form.Item 
                    name='address' 
                    label="Home Address" 
                    rules={[REQUIRED]}
                >
                    <Input type='text'></Input>
                </Form.Item>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Form.Item 
                        name='city' 
                        label="City" 
                        rules={[REQUIRED]}
                    >
                        <Input type='text'></Input>
                    </Form.Item>
                    <Form.Item
                        style={{ width: '180px' }} 
                        name='state' 
                        label="State" 
                        rules={[REQUIRED]}
                    >
                        <AutoComplete options={stateOptions} filterOption/>
                    </Form.Item>
                    <Form.Item 
                        style={{ width: '120px' }} 
                        name='zipCode' 
                        label="Postal Code" 
                        rules={[REQUIRED]}
                    >
                        <Input type='text'></Input>
                    </Form.Item>
                </div>
                <div style={{ display: 'flex', gap: '5px', justifyContent: 'space-between' }}>
                    <Form.Item 
                        name='dob' 
                        label="Date of Birth" 
                        rules={[REQUIRED]}
                    >
                        <DatePicker style={{ width: '100%' }} />
                    </Form.Item>
                    <Form.Item 
                        style={{ width: "180px"}} 
                        name='phoneType' 
                        label="Phone Type" 
                        rules={[REQUIRED]}
                    >
                        <Select>
                            <Select.Option value="mobile">Mobile</Select.Option>
                            <Select.Option value="home">Home</Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item 
                        style={{ width: "170px"}} 
                        name='phoneNumber' 
                        label="Phone (personal)" 
                        rules={[
                            REQUIRED,
                            { pattern: /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/, message: 'Invalid phone number'}
                        ]}
                    >
                        <Input type='text'></Input>
                    </Form.Item>
                </div>
                <p><strong>If you would like to receive a One Time Password to expedite the identification process please use your personal mobile phone number above.</strong></p>
                <div style={{ display: 'flex', marginBottom: '15px'}}>
                    <Form.Item name='mobileConfirm' valuePropName="checked">
                        <Checkbox style={{ marginRight: '5px' }} />
                    </Form.Item>
                    <Text style={{ marginTop: '3px'}}><strong>This is a mobile number. By checking this box, you agree to receive a text message from Experean containing only One Time Passwords. Mobile carrier text message rates may apply.</strong></Text>
                </div>
                <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around' }}>
                    <Form.Item 
                        style={{ width: '49%' }}
                        name='ssn' 
                        label="Social Security Number"  
                        rules={[REQUIRED]}
                    >
                        <Input type='text'></Input>
                    </Form.Item>
                    <Form.Item 
                        style={{ width: '49%' }}
                        name='creditCardNumber' 
                        label={<>Credit Card Number<Popover content="(No Debit Cards)"><ExclamationCircleOutlined style={{ marginLeft: '5px', color: 'red'}}/></Popover></>}
                        rules={[REQUIRED]}
                    >
                    <Input type='text'></Input>
                </Form.Item>
                </div>
                <div style={{ background: "#eee", padding: '5px 10px', marginBottom: '10px'}}>
                    <Text><strong>Driver's License (Optional but recommended)</strong></Text>
                    <div style={{ display: 'flex', justifyContent: 'space-around', marginTop: '7px' }}>
                    <Form.Item 
                            style={{ width: '49%' }}
                            name='driversLicenseNumber' 
                            label="Number"
                        >
                            <Input type='text'></Input>
                        </Form.Item>
                    <Form.Item
                            style={{ width: '49%' }} 
                            name='driversLicenseState' 
                            label="State" 
                        >
                            <AutoComplete options={licenseStateOptions} />
                        </Form.Item>
                    </div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between'}}>
                    <Button onClick={handleCancel}>Cancel</Button>
                    <Form.Item>
                        <Button type="primary" htmlType="submit">Next</Button>
                    </Form.Item>    
                </div>  
            </div>
        </Form>
    )
}
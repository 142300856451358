import React from 'react';

import { 
    useParams, 
    useLocation, 
} from "react-router-dom";

import { Patient } from "./components/Patient"
import { Request } from "./components/Request"
import { Messaging } from "./components/Messaging"
import { PossessionChangeModal } from './components/PossessionChange.Modal'

import { useCaseDetails } from "../../hooks/useCaseDetails"
import { useCoupledScroll } from "./useCoupledScroll";

import { CaseViewProvider } from './context/CaseViewContext';

import { View } from './Styles'

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
};

export const CaseView = ({
    physicianDetails,
}) => {

    const query = useQuery();
    const { id } = useParams();
    const requestId = query.get('requestId');

    const { 
        caseDetails, 
        fetchCaseDetails,
        setCaseDetails,
        startConsultation,
        selfAssignCase,
        possessionChangeAction, 
        setPossessionChangeAction,
        showLabOrderModal,
        setShowLabOrderModal
    } = useCaseDetails(id, physicianDetails, requestId)

    const {
        PatientRef,
        RequestRef,
    } = useCoupledScroll();


    const activeRequest = requestId === 'initial'
        ? caseDetails?.requests[0]
        : caseDetails?.requests.find(request => request._id === requestId) || caseDetails?.requests.at(-1)

    const patient = {
        ...caseDetails?.patient,
        heightFt: caseDetails?.heightFt,
        heightIn: caseDetails?.heightIn,
        heightCm: caseDetails && Math.floor((caseDetails?.heightFt * 12 + caseDetails?.heightIn) * 2.54),
        weight: caseDetails?.weight,
        weightKg: Math.floor(caseDetails?.weight * 0.453592)
    }

    return (
        <CaseViewProvider value={{ 
            caseDetails, 
            fetchCaseDetails,
            setCaseDetails, 
            activeRequest, 
            provider: physicianDetails, 
            patient,
            startConsultation,
            selfAssignCase,
            showLabOrderModal,
            setShowLabOrderModal 
        }}>
            <View>
                <Patient patientRef={PatientRef}/>
                <Request requestRef={RequestRef}/>
                <Messaging/>
            </View>
            { 
                possessionChangeAction && (
                    <PossessionChangeModal
                        action={possessionChangeAction}
                        startConsultation={startConsultation}
                        onOk={() => setPossessionChangeAction(null)}
                    /> 
                )
            }
        </CaseViewProvider>
    )
}

import { useState, useRef, useEffect } from 'react';
import { message } from 'antd'
import { debounce } from 'lodash'

import PrescriptionsAPI from '../../../../../axios/prescription/PrescriptionsApi'

export const useSearchMedications = ({
    form,
    prescription,
    selectedTemplate,
    selectTemplate,
    templatesLoading,
    saveQueuedPrescription,
    validateRx,
}) => {
    const [medicationSearchMatches, setMedicationSearchMatches] = useState([])
    const [medicationSearchLoading, setMedicationSearchLoading] = useState(false)
    const [initialLoad, setInitialLoad] = useState(true)

    const [{ selectedMedication, selectedStrength }, setMedicationState] = useState({
        selectedMedication: null,
        selectedStrength: null
    })

    const abortController = useRef(null)

    const clearHiddenFormValues = () => {
        form.resetFields([
            'ndc',
            'dispensableDrugId',
            'ingredients',
            'dispenseUnitId',
            'schedule'
        ])
    }

    const resetQueuedPrescription = () => {
        saveQueuedPrescription({ valid: false, ndc: null, ingredients: [],  _id: prescription._id })
    }

    const handleResetMedication = () => {
        setMedicationState({
            selectedMedication: null,
            selectedStrength: null
        })
    }

    const fetchMedications = async searchStr => {

        if (abortController.current) {
            abortController.current.abort();
        }

        abortController.current = new AbortController()
        const { signal } = abortController.current;

        setMedicationSearchLoading(true)
        selectTemplate(null)
        setMedicationSearchMatches([])
        handleSelectMedication(null)
        clearHiddenFormValues()
        resetQueuedPrescription()
        form.setFieldsValue({ directions: '', pharmacyNotes: '' })

        if (!searchStr || searchStr?.length <= 2) {
            return setMedicationSearchLoading(false)
        }

        const abbreviatedSearch = searchStr.split(' ')[0]

        try {
            const { data: { medications } } = await PrescriptionsAPI.searchMedications(abbreviatedSearch, abortController.current?.signal)
            if (signal.aborted) return
            setMedicationSearchMatches(medications)
        } catch (e) {
            console.error(e);
            message.error('Error fetching medication matches from prescription service');
        } finally {
            setMedicationSearchLoading(false);
        }
    }

    const handleSelectMedication = medicationName => {
        clearHiddenFormValues()
        resetQueuedPrescription()

        const medicationMatch = medicationSearchMatches.find(({ name }) => name === medicationName);
        if (!medicationMatch) return handleResetMedication()

        const isCompound = !!medicationMatch.prescribingDefaults

        const initialStrength = medicationMatch.strengths?.[0] || medicationMatch

        setMedicationState(({ 
            selectedMedication: medicationMatch, 
            selectedStrength: initialStrength 
        }))
        
        form.setFieldsValue({
            ...isCompound && { ...medicationMatch.prescribingDefaults, strength: 'Compound' },
            ...initialStrength, 
            quantityUnits: initialStrength.doseForm || initialStrength.dispenseUnit || 'Compound',
            directions: '', 
            pharmacyNotes: ''
        })

        validateRx()
    }

    const handleSelectStrength = strength => {
        clearHiddenFormValues()
        resetQueuedPrescription()

        const availableStrengths = selectedMedication?.strengths || [selectedMedication]
        const selectedStrength = availableStrengths?.find(medStrength => medStrength.strength === strength)

        setMedicationState(prev => ({ ...prev, selectedStrength }))
        
        form.setFieldsValue({ 
            ...selectedStrength, 
            quantityUnits: selectedStrength.doseForm || selectedStrength.dispenseUnit || 'Compound',
            directions: '', 
            pharmacyNotes: ''
        })

        validateRx()
    }

    useEffect(() => {
        if (initialLoad && !selectedTemplate && !templatesLoading && prescription.requestedMedication) {
            const { drugName, pillCount, daysSupply, refills } = prescription.requestedMedication
            form.setFieldsValue({ name: drugName, quantity: pillCount, daysSupply, refills })
            fetchMedications(drugName)
        }
        setInitialLoad(false)

    }, [templatesLoading, selectedTemplate, prescription.requestedMedication])

    useEffect(() => {
        if (medicationSearchMatches?.length && prescription?.requestedMedication) {
            const foundMatch = medicationSearchMatches.find(medication => {
                if (!medication.strengths && !medication.strength) return false

                const medicationStrengths = medication.strengths?.filter(strength => !!strength.strength) || [medication]
                return medicationStrengths.find(strength => {
                    const sanitizedStrength = strength.strength?.replace(' ', '').toLowerCase()
                    const sanitizedRequestedMedicationStrength = prescription.requestedMedication.dosage?.replace(' ', '').toLowerCase()
                    
                    const match = (
                        sanitizedStrength?.includes(sanitizedRequestedMedicationStrength) ||
                        sanitizedRequestedMedicationStrength?.includes(sanitizedStrength)
                    )

                    if (match) {
                        setMedicationState({ 
                            selectedMedication: medication, 
                            selectedStrength: strength 
                        })

                        form.setFieldsValue({ 
                            ...strength, 
                            quantityUnits: strength.doseForm || strength.dispenseUnit || 'Compound'
                        })

                        return true
                    }

                    return false
                })
            })

            if (!foundMatch){
                const medication = medicationSearchMatches.find(medication => !!medication.strengths?.length || !!medication.strength ||  !!medication.ingredients)
                if (medication) {
                    const strengths = medication.strengths || [medication]

                    const initialStrength = strengths.find(strength => !!strength.strength)
                    
                    if (initialStrength) {
                        
                        setMedicationState({ 
                            selectedMedication: medication, 
                            selectedStrength: initialStrength 
                        })

                        form.setFieldsValue({ 
                            ...initialStrength, 
                            name: initialStrength.displayName, 
                            quantityUnits: initialStrength.doseForm || initialStrength.dispenseUnit || 'Compound'
                        })
                    }
                }
            }

            validateRx()
        }
    }, [medicationSearchMatches])


    return {
        fetchMedications: debounce(fetchMedications, 1000),
        medicationSearchMatches,
        medicationSearchLoading,
        selectedMedication,
        selectedStrength,
        handleSelectMedication,
        handleSelectStrength,
        setMedicationState,
        handleResetMedication
    }
}
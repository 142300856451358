import React from 'react'
import { Form, Input } from 'antd'
import { required, isGreaterThanZero } from '../validation'

export const DaysSupply = () => {
    return (
        <Form.Item
            required
            label="Days Supply"
            name="daysSupply"
            style={{ display: 'inline-block', width: 'calc(50% - 12px)' }}
            rules={[
                required,
                isGreaterThanZero,
            ]}
        >
            <Input type='number' />
        </Form.Item>
    )
}
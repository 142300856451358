import Axios from './Axios'

export default class CaseTagApi {
    static getToken() {
        return window.localStorage.getItem("token");
    }

    static getCaseTags() {
        const token = this.getToken()
        return Axios({
            url: '/case-tags',
            method: "GET",
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
    }

    static addCaseTag(data) {
        const token = this.getToken()
        return Axios({
            url: '/case-tags',
            method: "POST",
            data,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            }
        })
    }

    static removeCaseTag(data) {
        const token = this.getToken()
        return Axios({
            url: '/case-tags',
            method: "DELETE",
            data,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            }
        })
    }
}
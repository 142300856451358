const types = {
    SET_ADDITIONAL_INFO_CASE_LIST_LOADING: 'SET_ADDITIONAL_INFO_CASE_LIST_LOADING',
    SET_ADDITIONAL_INFO_CASE_LIST: 'SET_ADDITIONAL_INFO_CASE_LIST',
    SET_ADDITIONAL_INFO_CASE_COUNT_LOADING: 'SET_ADDITIONAL_INFO_CASE_COUNT_LOADING',
    SET_ADDITIONAL_INFO_CASE_COUNT: 'SET_ADDITIONAL_INFO_CASE_COUNT',
    SET_ADDITIONAL_INFO_CASE_UPDATE_COUNT_LOADING: 'SET_ADDITIONAL_INFO_CASE_UPDATE_COUNT_LOADING',
    SET_ADDITIONAL_INFO_CASE_UPDATE_COUNT: 'SET_ADDITIONAL_INFO_CASE_UPDATE_COUNT',
    SET_ADDITIONAL_INFO_CHAT_COUNT:`SET_ADDITIONAL_INFO_CHAT_COUNT`
};

export default types;
import React from 'react'
import { connect } from 'react-redux'
import { Typography } from 'antd'

import { formatTenDigitPhoneNumber } from '../../../../utils/phoneFormatter'

const { Text } = Typography

const PharmacyDetails = ({ pharmacy }) => {
    if (!pharmacy) return <Text>None selected</Text>
    return (
        <>
            <Text>{pharmacy?.name}</Text>
            <Text>{pharmacy.address1}</Text>
            <Text>{`${pharmacy.city}, ${pharmacy.state} ${pharmacy.zipCode}`}</Text>
            <Text>{formatTenDigitPhoneNumber(pharmacy?.phoneOrFax)}</Text>
        </>
    )
}

const mapStateToProps = (state) => {
    const { pharmacy } = state.prescribeMedicine;
    return { pharmacy };
};

export default connect(mapStateToProps)(PharmacyDetails)


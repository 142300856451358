import React from 'react'
import { connect } from 'react-redux'
import { Form } from 'antd'

import { useStrengthSelector } from './useStrengthSelector'
import { required, isMismatch } from '../../validation'
import { usePrescriptionFormContext } from '../../context/prescriptionFormContext'
import { StyledSelect } from '../../styles'

const StrengthSelector = ({
    prescription,
    telemedicineCategoryId,
    selectedMedication,
    selectedStrength,
    setMedicationState,
    handleSelectStrength,
    APMedicineDictionary,
    bypassValidation
}) => {
    const { form } = usePrescriptionFormContext()

    const {
        options
    } = useStrengthSelector({
        form,
        selectedMedication,
        selectedStrength,
        setMedicationState
    })

    return (
        <Form.Item
            required
            label="Strength"
            name="strength"
            value={selectedStrength?.strength}
            style={{ display: 'inline-block', width: 'calc(50% - 12px)' }}
            rules={[
                required,
                isMismatch(APMedicineDictionary, prescription?.requestedMedication, telemedicineCategoryId)
            ]}
        >
            <StyledSelect 
                options={options} 
                onSelect={handleSelectStrength}
            /> 
        </Form.Item>
    )
}

const mapStateToProps = (state) => {
    const {
        APMedicineDictionary
    } = state.prescribeMedicine;
    return {
        APMedicineDictionary
    };
};


export default connect(mapStateToProps)(StrengthSelector)

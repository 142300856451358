import types from "./types";

const initState = {
  caseTicketListLoading: false,
  caseTicketList: [],
  caseTicketCount: 0,
  caseTicketNotificationCount: 0,
};

export default function caseTickets(state = initState, action) {
  switch (action.type) {
    case types.SET_CASE_TICKETS_LIST_LOADING:
      return {
        ...state,
        caseTicketListLoading: action.data,
      };
    case types.SET_CASE_TICKETS_LIST:
      return {
        ...state,
        caseTicketCount: action.count,
        caseTicketList: action.payload,
      };
    case types.SET_CASE_TICKET_NOTIFICATION_COUNT:
      return {
        ...state,
        caseTicketNotificationCount: action.count,
      };

    default:
      return state;
  }
}

import React from 'react'
import { Typography } from 'antd'
import { TitleContainer } from '../styles'

export const FormTitle = ({ isFirstMedication }) => (
    <TitleContainer isFirstMedication={isFirstMedication}>
        <Typography.Title
            strong
            level={4}
            style={{ marginBottom: '40px' }}>
            {isFirstMedication && 'Prescribe Medicine'}
        </Typography.Title>
    </TitleContainer>
)
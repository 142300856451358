import React, { useState } from "react";
import { connect } from "react-redux";
import { Form, Input, Button, Spin, message } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { bindActionCreators } from "redux";

// import APIs and functions
import PhysicianApi from "../../../../axios/physician/PhysicianApi";
import authentication from "../../../../redux/authentication";

//import components
import ForgetPasswordCompent from "../../../../components/ForgetPasswordComponent/ForgetPasswordComponent";

function LoginPhysician(props) {
  const { actions } = props;
  const [disable, setDisable] = useState(false);
  const [spinning, setSpinning] = useState(false);
  const [formLoading, setFormLoading] = useState(false);
  const [forgetPasswordModalVisible, setForgetPasswordModalVisible] = useState(
    false
  );
  const onFinish = (values) => {
    setDisable(true);
    setSpinning(true);
    actions.login(values.username, values.password, submitComplete);
  };
  const submitComplete = () => {
    setDisable(false);
    setSpinning(false);
  };

  const onForgetPasswordFinish = (values) => {
    setFormLoading(true);
    PhysicianApi.passwordResetRequest({
      ...values,
    })
      .then((res) => {
        const { data } = res;
        if (data && data.success) {
          message.success(
            "Successfully identified your account and initiated password reset! Reset link has been sent to your Email"
          );
          setForgetPasswordModalVisible(false);
        } else {
          if (data && data.message) {
            message.error(data.message);
          } else {
            message.error(
              "Failed to find you account! Please check your email"
            );
          }
        }
        setFormLoading(false);
      })
      .catch(() => {
        message.error(
          "Failed to find your account! Please check your email or try again later"
        );
        setFormLoading(false);
      });
  };

  const handleForgetPasswordLink = () => {
    setForgetPasswordModalVisible(true);
  };

  const closeResetPasswordModal = () => {
    setForgetPasswordModalVisible(false);
  };

  return (
    <div>
      <div className="login-selection-part-login-signup">
        <p>Login</p>
      </div>

      <Form name="basic" layout="vertical" size="large" onFinish={onFinish}>
        <Form.Item
          name="username"
          rules={[
            {
              type: "email",
              message: "The input is not valid E-mail!",
            },
            {
              required: true,
              message: "Please input your E-mail!",
            },
          ]}
        >
          <Input placeholder="Email ID" />
        </Form.Item>

        <Form.Item
          name="password"
          rules={[
            {
              required: true,
              message: "Please input your password!",
            },
          ]}
        >
          <Input.Password placeholder="Password" />
        </Form.Item>

        <Form.Item>
          <Spin spinning={spinning} indicator={<LoadingOutlined />}>
            <Button
              type="primary"
              htmlType="submit"
              style={{ width: "100%" }}
              disabled={disable}
            >
              <b>LOGIN</b>
            </Button>
          </Spin>
        </Form.Item>

        <Form.Item className="wrapper-text-align">
          <Button
            className="user-button-link"
            type="link"
            onClick={handleForgetPasswordLink}
          >
            Forgotten password?
          </Button>
        </Form.Item>
      </Form>
      <ForgetPasswordCompent
        modalVisible={forgetPasswordModalVisible}
        loading={formLoading}
        setModalVisible={closeResetPasswordModal}
        handlePasswordResetRequest={onForgetPasswordFinish}
      />
    </div>
  );
}

const mapStateToProps = (state) => {
  const { loggingIn } = state.authentication;
  return { loggingIn };
};

const mapDispatchToProps = (dispatch) => {
  const { login } = authentication.actions;
  return {
    actions: bindActionCreators(
      {
        login,
      },
      dispatch
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginPhysician);

import types from "./types";

const initState = {
  incompleteCasesLoading: false,
  incompleteCases: [],
  incompleteCasesCount: 0,
};

export default function incompleteCases(state = initState, action) {
  switch (action.type) {
    case types.SET_INCOMPLETE_CASES_LOADING:
      return {
        ...state,
        incompleteCasesLoading: action.payload,
      };
    case types.SET_INCOMPLETE_CASES_COUNT:
      return {
        ...state,
        incompleteCasesCount: action.payload,
      };
    case types.SET_INCOMPLETE_CASES:
      return {
        ...state,
        incompleteCases: action.payload,
      };
    default:
      return state;
  }
}

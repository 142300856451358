import types from "./types";
import API from "../../axios/physician/PhysicianApi";
import handleAPIErrorResponse from "../../utils/handleAPIResponseError";

import { message } from "antd";

const setRxChangeCaseListLoading = (status) => ({
    type: types.SET_RX_CHANGE_CASE_LIST_LOADING,
    data: status,
});

const setRxChangeCaseList = (payload) => ({
    type: types.SET_RX_CHANGE_CASE_LIST,
    payload
});

const setRxChangeCaseCountLoading = (status) => ({
    type: types.SET_RX_CHANGE_CASE_COUNT_LOADING,
    data: status,
});

const setRxChangeCaseCount = (payload) => ({
    type: types.SET_RX_CHANGE_CASE_COUNT,
    payload
})

function getRxChangeCaseList(status) {
    return (dispatch) => {
        dispatch(setRxChangeCaseListLoading(true));
        API.getCaseList(status).then(
            (res) => {
                if (res.data?.success) {
                    if (res.data?.payload) {
                        dispatch(setRxChangeCaseCount(res.data.payload.count))
                        dispatch(setRxChangeCaseList(res.data.payload.data))
                    }
                } else {
                    message.warning("Unable to update cases which requires Rx Change");
                }
            }).catch(
                (error) => {
                    handleAPIErrorResponse(error, "Error occured while updating cases which requires Rx Change");
                }).finally(() => {
                    dispatch(setRxChangeCaseListLoading(false));
                });
    };
}

function getRxChangeCaseCount(status) {
    return (dispatch) => {
        dispatch(setRxChangeCaseCountLoading(true));
        API.getCaseCount(status).then(
            (res) => {
                const { data } = res;
                if (data && data.success) {
                    const { payload } = data;
                    if (payload) {
                        const { caseCount } = payload;
                        dispatch(setRxChangeCaseCount(caseCount))
                    }
                } else {
                    message.warning("Unable to Rx Change Cases");
                }
                dispatch(setRxChangeCaseCountLoading(false));
            }).catch(
                (error) => {
                    handleAPIErrorResponse(error, "Error occured while updating cases which requires Rx Change Cases");
                    dispatch(setRxChangeCaseCountLoading(false));
                });
    };
}

const actions = {
    setRxChangeCaseListLoading,
    setRxChangeCaseList,
    setRxChangeCaseCountLoading,
    setRxChangeCaseCount,
    getRxChangeCaseList,
    getRxChangeCaseCount,
};

export default actions;

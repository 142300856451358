import React, { createContext, useContext } from 'react';

const PrescriptionFormContext = createContext();

export const usePrescriptionFormContext = () => {
    const context = useContext(PrescriptionFormContext);
    if (!context) {
        throw new Error("usePrescriptionFormContext must be used within a PrescriptionFormProvider");
    }
    return context;
}

export const PrescriptionFormProvider = ({ children, value }) => {
    return (
            <PrescriptionFormContext.Provider value={value}>
                {children}
            </PrescriptionFormContext.Provider>
        
    );
};

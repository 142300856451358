import React from "react";
import { Button, Form, Spin, Input, Modal } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

const { TextArea } = Input;

const AddPhysicianNoteComponent = ({
  noteAddLoading,
  addNoteModalVisible,
  handleAddNoteSubmit,
  handleAddNoteCancel,
}) => {
  const [form] = Form.useForm();

  const onAddNoteSubmit = (value) => {
    handleAddNoteSubmit(value);
  };

  const onAddNoteCancel = () => {
    handleAddNoteCancel();
  };
  const handleModalClose = () => {
    form.resetFields();
  };

  return (
    <Modal
      title={"Add Physician Note"}
      visible={addNoteModalVisible}
      onOk={""}
      onCancel={""}
      footer={null}
      closable={false}
      destroyOnClose={true}
      afterClose={handleModalClose}
    >
      <Spin spinning={noteAddLoading} indicator={<LoadingOutlined />}>
        <Form
          form={form}
          layout={"vertical"}
          onFinish={(value) => onAddNoteSubmit(value)}
        >
          <Form.Item
            label="Title"
            name="title"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Note"
            name="note"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <TextArea autoSize={{ minRows: 6, maxRows: 10 }} />
          </Form.Item>
          <Form.Item className="template-message-form-item-button">
            <Button size={"large"} onClick={onAddNoteCancel}>
              Cancel
            </Button>
            <Button htmlType="submit" size="large" type="primary">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
};

export default AddPhysicianNoteComponent;

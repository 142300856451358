import React, { useState, useContext, useEffect, useMemo, useRef, useCallback } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { bindActionCreators } from "redux";
import { Table, Button, Spin, Badge } from "antd";
import { BellFilled, LoadingOutlined } from "@ant-design/icons";
import messageStore from "../../redux/message";
import additionalInfoRequired from "../../redux/additionalInfoRequired";
import { TablePreferencesContext } from '../../context/TablePreferencesProvider';
import CaseUpdateComparisonComponent from "../../components/CaseUpdateComparisonComponent/CaseUpdateComparisonComponent";
import SearchAndFilterCases from "../../components/SearchAndFilterCases/SearchAndFilterCases";
import MessagesNavigator from "../../components/MessagesNavigator";
import { getDateFieldSorter, getTextFieldSorter, getSortOrder } from '../../utils/sorters';
import { dateTimeFormatter } from "../../utils/dateFormatter";
import { createCaseFilterQueryParamMap, createTableQueryParamMap, createTableSortByMap } from "../../utils/createCaseFilterQueryParamMap";
import { CaseFilterMap, CaseQueryParamMap } from "../../constants/CaseFilter"
import { userConstants } from "../../constants";
import { CaseStatusEnum } from "../../types/enums";

const TABLE_NAME = 'additional info';

const hideFields = Object.freeze([CaseFilterMap.PHYSICIAN_ID, CaseFilterMap.STATUS]);

const TableDataMap = {
  TELEMEDICINE_CATEGORY_ID: CaseFilterMap.TELEMEDICINE_CATEGORY_ID,
  CASE_ID: CaseFilterMap.CASE_ID,
  CREATED_DATE: CaseFilterMap.CREATED_DATE,
  STATUS: CaseFilterMap.STATUS,
  PATIENT_NAME: `${CaseFilterMap.PATIENT_FIRST_NAME} ${CaseFilterMap.PATIENT_LAST_NAME}`,
  LAST_MESSAGE_TO_PATIENT_AT: 'lastMessageToPatientAt',
  READ_NOTIFICATIONS: 'readNotifications',
  UNREAD_CHAT_NOTIFICATION: 'unreadChatNotification',
}

const TableSortByMap = createTableSortByMap(TableDataMap);

const ReadNotifications = (props) => {
  return (
    <>
      {props.case?.[TableDataMap.READ_NOTIFICATIONS] === false ? (
        <Badge dot>
          <Button
            shape="circle"
            type="ghost"
            icon={<BellFilled />}
            onClick={(event) => {
              event.stopPropagation();
              props.viewUpdatesInCase(props.case.caseId);
            }}
          />
        </Badge>
      ) : (
        <>NA</>
      )}
    </>
  )
}

const getTableColumns = (sortOrder, sortBy, navigateToMessages, viewUpdatesInCase) => {
  const columns = [
    {
      title: "Case No",
      key: TableDataMap.CASE_ID,
    },
    {
      title: "Patient Name",
      key: TableDataMap.PATIENT_NAME,
    },
    {
      title: "Category Name",
      key: TableDataMap.TELEMEDICINE_CATEGORY_ID,
    },
    {
      title: "Created Date",
      dataIndex: TableDataMap.CREATED_DATE,
      key: TableDataMap.CREATED_DATE,
    },
    {
      title: "Case Update Details",
      key: TableDataMap.READ_NOTIFICATIONS,
      render: (_case) => <ReadNotifications case={_case} viewUpdatesInCase={viewUpdatesInCase} />,
      width: 160,
    },
    {
      title: "Last Message At",
      key: TableDataMap.LAST_MESSAGE_TO_PATIENT_AT,
      width: 160,
    },
    {
      title: "Messages",
      key: TableDataMap.UNREAD_CHAT_NOTIFICATION,
      render: (_case) => (
        <MessagesNavigator
          caseId={_case[TableDataMap.CASE_ID]}
          count={_case[TableDataMap.UNREAD_CHAT_NOTIFICATION]}
          navigateToMessages={navigateToMessages}
        />
      ),
      width: 160,
    },
  ];

  columns.forEach((c) => {
    if([TableDataMap.UNREAD_CHAT_NOTIFICATION].includes(c.key)) {
      return;
    }
    c.dataIndex = c.key;
    if ([TableDataMap.CASE_ID].includes(c.key)) {
      return
    }
    c.sortOrder = getSortOrder(sortOrder, sortBy, c.key);
    const isDateField = [TableDataMap.CREATED_DATE, TableDataMap.LAST_MESSAGE_TO_PATIENT_AT].includes(c.key);
    c.sorter = isDateField ? getDateFieldSorter(c.key) : getTextFieldSorter(c.key);
  });

  return columns;
}

const defaultPreferences = { currentPage: 1, tablePageSize: 100, sortOrder: 'descend' }

const AdditionalInfoRequired = (props) => {
  const history = useHistory();
  const [showModal, setShowModal] = useState(false);
  const [caseDetailsComparisonId, setCaseDetailsComparisonId] = useState(null);
  const [viewCaseDetailsComparisonModal, setViewCaseDetailsComparisonModal] = useState(false);
  const [searchValue, setSearchValue] = useState(null);
  const [caseListFilter, setCaseListFilter] = useState({});
  const { getTablePreferences, updateTablePreferences } = useContext(TablePreferencesContext);
  const { currentPage, tablePageSize, sortOrder, sortBy } = getTablePreferences(TABLE_NAME, defaultPreferences);

  const {
    actions,
    statesList,
    additionalInfoCaseList,
    telemedicineCategoriesList,
    additionalInfoCaseListLoading,
    additionalInfoCount,
    additionalInfoChatCount,
    additionalInfoCaseUpdateCount,
  } = props;


  const navigateToMessages = useRef((caseId) => {
    history.push(`/dashboard/${userConstants.USER_PHYSICIAN}/messages`);
    actions.setChatCaseId(caseId);
  });

  const viewUpdatesInCase = useRef((caseId) => {
    setCaseDetailsComparisonId(caseId);
    setViewCaseDetailsComparisonModal(true);
  });

  const columns = useMemo(() => {
    return getTableColumns(sortOrder, sortBy, navigateToMessages.current, viewUpdatesInCase.current);
  }, [sortOrder, sortBy]);

  const closeCaseDetailsComparisonModal = () => {
    setCaseDetailsComparisonId(null);
    setViewCaseDetailsComparisonModal(false);
  };

  const completeClearNotificationAndCaseListingUpdate = () => {
    setCaseDetailsComparisonId(null);
    setViewCaseDetailsComparisonModal(false);
  };

  const fetchCases = useCallback(() => {
    const newTablePreferences = {
      currentPage,
      tablePageSize,
      sortOrder,
      sortBy: TableSortByMap[sortBy] || sortBy,
    };
    const tableFilterMap = createTableQueryParamMap(newTablePreferences);
    const caseFilterMap = createCaseFilterQueryParamMap(caseListFilter, hideFields);
    caseFilterMap[CaseFilterMap.STATUS] = CaseStatusEnum.ADDITIONAL_INFO_REQUIRED;
    caseFilterMap[CaseFilterMap.ADDITIONAL_STATUS] = `${CaseStatusEnum.INVALID_FACE_PHOTO},${CaseStatusEnum.INVALID_PHOTO_ID}`;
    if (searchValue !== null) {
      caseFilterMap[CaseQueryParamMap.SEARCH_TERM] = searchValue;
    }


    actions.getAdditionalInfoCaseList({ ...tableFilterMap, ...caseFilterMap });
  }, [searchValue, caseListFilter, currentPage, tablePageSize, sortOrder, actions, sortBy]);

  useEffect(fetchCases, [
    additionalInfoChatCount,
    additionalInfoCaseUpdateCount,
    fetchCases,
  ]);

  const pushRouteCaseDetails = (caseId) => {
    history.push(
      `/dashboard/${userConstants.USER_PHYSICIAN}/caseview/${caseId}`,
      { from: history.location.pathname.split('/').pop() }
    );
  };

  const handleCaseListClick = (event, record) => {
    event.stopPropagation();
    pushRouteCaseDetails(record.caseId);
  };

  const handleOk = (filterData) => {
    setCaseListFilter(filterData);
    setShowModal(false);
  };

  const searchCase = (value) => {
    setSearchValue(value);
  };

  const handleCancel = () => {
    setCaseListFilter({});
    setShowModal(false);
  };

  const handleCancelCross = () => {
    setShowModal(false);
  };

  const onChangePage = (page) => {
    updateTablePreferences(TABLE_NAME, { currentPage: page })
  };

  const onPageSizeChange = (_, size) => {
    updateTablePreferences(TABLE_NAME, { currentPage: 1, tablePageSize: size })
  };

  const onChangeOrder = (_pagination, _filters, sorter) => {
    updateTablePreferences(TABLE_NAME, {
      sortOrder: sorter.order || 'ascend',
      sortBy: sorter.field,
    });
  }

  const casesForTable = additionalInfoCaseList.map((c) => {
    return {
      key: c.caseId,
      rowKey: c.caseId,
      [TableDataMap.CASE_ID]: c.caseId,
      [TableDataMap.PATIENT_NAME]: c.patientName,
      [TableDataMap.TELEMEDICINE_CATEGORY_ID]: c.category,
      [TableDataMap.CREATED_DATE]: dateTimeFormatter(c.createdDate),
      [TableDataMap.LAST_MESSAGE_TO_PATIENT_AT]: c.lastMessageToPatientAt ? dateTimeFormatter(c.lastMessageToPatientAt) : '',
      [TableDataMap.READ_NOTIFICATIONS]: c.readNotifications,
      [TableDataMap.UNREAD_CHAT_NOTIFICATION]: c.unreadChatNotification,
    }
  })

  return (
    <div className="consulted-case">
      <div className="waiting-room-case-list-part-heading">
        <h1>Additional Info Required</h1>
        <SearchAndFilterCases
          searchCase={searchCase}
          showModal={showModal}
          setShowModal={setShowModal}
          handleCancelCross={handleCancelCross}
          handleCancel={handleCancel}
          handleOk={handleOk}
          telemedicines={telemedicineCategoriesList}
          states={statesList}
          hideFields={hideFields}
        />
      </div>
      <div className="consulted-case-no-of-patient-list-data">
        <Spin
          size="large"
          spinning={additionalInfoCaseListLoading}
          indicator={<LoadingOutlined spin />}
        >
          <div>
            <Table
              columns={columns}
              onChange={onChangeOrder}
              pagination={{
                total: additionalInfoCount,
                showTotal: () => `Total ${additionalInfoCount} cases`,
                current: currentPage,
                pageSize: tablePageSize,
                pageSizeOptions: ["5", "10", "20", "50", "100"],
                showSizeChanger: true,
                onChange: onChangePage,
                onShowSizeChange: onPageSizeChange,
              }}
              dataSource={casesForTable}
              scroll={{ x: 900 }}
              onRow={(record, rowIndex) => {
                return {
                  onClick: (event) => {
                    handleCaseListClick(event, record, rowIndex);
                  },
                };
              }}
            />
          </div>
        </Spin>
      </div>
      <CaseUpdateComparisonComponent
        modalVisible={viewCaseDetailsComparisonModal}
        caseId={caseDetailsComparisonId}
        setModalVisible={closeCaseDetailsComparisonModal}
        clearCaseNotificationCallback={
          completeClearNotificationAndCaseListingUpdate
        }
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  const {
    additionalInfoCaseList,
    additionalInfoCaseListLoading,
    additionalInfoCount,
    additionalInfoChatCount,
    additionalInfoCaseUpdateCount,
  } = state.additionalInfoRequired;
  const { statesList, telemedicineCategoriesList } = state.entities;
  return {
    additionalInfoCaseList,
    additionalInfoCaseListLoading,
    statesList,
    telemedicineCategoriesList,
    additionalInfoCount,
    additionalInfoChatCount,
    additionalInfoCaseUpdateCount,
  };
};

const mapDispatchToProps = (dispatch) => {
  const { getAdditionalInfoCaseList } = additionalInfoRequired.actions;
  const { setChatCaseId } = messageStore.actions;
  return {
    actions: bindActionCreators(
      {
        getAdditionalInfoCaseList,
        setChatCaseId,
      },
      dispatch
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdditionalInfoRequired);

import styled from 'styled-components'

export const SelectLocalPatientContainer = styled.div`
    display: flex;
    justify-content: space-around;
`

export const LocalPatientOptionsScroll = styled.div`
    overflow-y: auto;
    max-height: 680px;
`
import React from 'react'
import { connect } from 'react-redux'
import { Form, Input } from 'antd'

import { required, isPositive, isMismatch } from '../validation'
import { usePrescriptionFormContext } from '../context/prescriptionFormContext'

const QuantityInput = ({
    prescription,
    telemedicineCategoryId,
    APMedicineDictionary
}) => {
    const { form } = usePrescriptionFormContext()

    const handleFormatQuantity = async ({ target: { value } }) => {
        const truncated = Math.floor(Number(value) * 1000) / 1000
        await form.setFieldsValue({ quantity: truncated.toString() })
    }

    return (
        <Form.Item
            required
            label="Quantity"
            name="quantity"
            style={{ display: 'inline-block', width: 'calc(50% - 12px)' }}
            rules={[
                required,
                isPositive,
                isMismatch(APMedicineDictionary, prescription?.requestedMedication, telemedicineCategoryId)
            ]}
        >
            <Input type='number' onBlur={handleFormatQuantity} />
        </Form.Item>
    )
}

const mapStateToProps = (state) => {
    const {
        APMedicineDictionary
    } = state.prescribeMedicine;
    return {
        APMedicineDictionary
    };
};

export default connect(mapStateToProps)(QuantityInput)
import React from 'react'
import { connect } from 'react-redux'
import { Form, Input } from 'antd'

import { required, isMismatch, isPositive } from '../validation'


const RefillsInput = ({
    form,
    prescription,
    telemedicineCategoryId,
    APMedicineDictionary
}) => {


    const controlledSubstanceValidation = {
        validator: (_, value) => {
            const schedule = form.getFieldValue('schedule');
        
            if (schedule) {
                if (Number(schedule) === 2 && Number(value) !== 0) return Promise.reject(new Error('Schedule 2 drugs cannot be refilled'));
                if (Number(schedule) > 2 && Number(value) > 5)  return Promise.reject(new Error(`Schedule ${schedule} drugs cannot be refilled more than 5 times`));
            }

            return Promise.resolve()
        }
    }


    return (
        <Form.Item
            required
            label="Refills"
            name="refills"
            style={{ display: 'inline-block', width: 'calc(50% - 12px)' }}
            rules={[
                required,
                isPositive,
                isMismatch(APMedicineDictionary, prescription.requestedMedication, telemedicineCategoryId),
                controlledSubstanceValidation
            ]}
        >
            <Input type='number' />
        </Form.Item>
    )
}

const mapStateToProps = (state) => {
    const {
        APMedicineDictionary
    } = state.prescribeMedicine;
    return {
        APMedicineDictionary
    };
};

export default connect(mapStateToProps)(RefillsInput)
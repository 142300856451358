const config = {
  api: {
    baseUrl: `${process.env.REACT_APP_E_PRESCRIBE_API_URL}`,
  },
  webSocket: {
    baseUrl: `${process.env.REACT_APP_E_PRESCRIBE_WS_URL}`,
    baseUrltwo: `${process.env.REACT_APP_E_PRESCRIBE_WS_TWO_URL}`,
  },
  appEnv: `${process.env.REACT_APP_ENV}`,
  apiKeys: {
    tinyEditorKey: `${process.env.REACT_APP_E_PRESCRIBE_TINY_EDITOR_API_KEY}`,
  },
  showReportingCharts: false,
  rxMismatchComparisonWindow: `${process.env.REACT_APP_RX_COMPARISON_EXPIRATION_WINDOW}`,
  completedCaseCheckDeadlineInHours: `${process.env.REACT_APP_COMPLETED_CASE_CHECK_DEADLINE_IN_HOURS}`,
  showNewReportingDashboard: `${process.env.REACT_APP_SHOW_NEW_REPORTING_DASHBOARD}`,
  MARKER_PROJECT_ID: process.env.REACT_APP_MARKER_PROJECT_ID,
  LATEST_SHA: process.env.REACT_APP_LATEST_SHA,
  USE_HEISENBERG: process.env.REACT_APP_USE_HEISENBERG,
  allowIdVerification: `${process.env.REACT_APP_ALLOW_ID_VERIFICATION}`
};

export default config;

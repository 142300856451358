import actions from "./actions";
import reducer from "./reducer";

const caseTags = {
  actions,
  reducer,
  name: "caseTags",
};

export default caseTags;

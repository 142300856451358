import React, { useState, useEffect } from 'react'

import { 
    Form, 
    Select, 
    Input, 
    message
} from 'antd'

import RequiredFormField from '../../../components/RequiredFormField'
import PrescriptionsAPI from "../../../axios/prescription/PrescriptionsApi"

const { TextArea } = Input

const DenyRequestForm = ({
    setDenialReason,
    setComment
}) => {
    const [denialCodes, setDenialCodes] = useState(null)

    useEffect(() => {
        PrescriptionsAPI.getRxRequestDenialCodes()
            .then(({ data }) => setDenialCodes(data.denialCodeDict))
            .catch(e => message.error('Error fetching denial codes'))
    }, [])

    return (
        <Form
            layout="vertical"
            style={{ textTransform: 'capitalize' }}
            validateMessages={{ required: 'required' }}
        >
            <RequiredFormField
                label={
                    <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                        <>Denial Reason</>
                    </div>
                }
                name="reason"
                style={{ width: '100%'}}
                rules={[{ required: true }]}
                required
            >
                <Select
                    showSearch
                    placeholder='Denial Reason'
                    name="denialReason"
                    style={{ color: 'black'}}
                    onChange={setDenialReason}
                    options={
                        denialCodes && Object.entries(denialCodes).map(([key, val]) => {
                            return {
                                key,
                                value: val,
                                label: key,
                            }
                        })
                    }
                >
                </Select>
            </RequiredFormField>
            <Form.Item
                label='Comment'
                name='comment'
            >
                <TextArea placeholder="Comments" onChange={e => setComment(e.target.value)}/>
            </Form.Item>
        </Form>
    )
}

export default DenyRequestForm
import Axios from "../Axios"
import config from "../../config";

const { api } = config;

export default class API {
    static getToken() {
      const token = window.localStorage.getItem("token");
      return token;
    }

    static completeConsultation(caseId, data) {
        const token = this.getToken();
        return Axios({
            url: `${api.baseUrl}/cases/${caseId}/complete-consultation`,
            data,
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
    }

    static registerPDMPView(caseId, resource) {
        const token = this.getToken();
        return Axios({
          url: `${api.baseUrl}/cases/${caseId}/register-pdmp-view`,
            method: "POST",
            data: { resource },
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
    }
}

import React from "react";
import { Row, Col, Typography, Form, Tabs, Alert } from "antd";
import styled from 'styled-components'
import moment from 'moment-timezone'
const { Title, Text } = Typography;
const { TabPane } = Tabs;

const StyledTabs = styled(Tabs)`
  & .ant-tabs-bar.ant-tabs-top-bar {
    padding-top: 0;
  }

  & .ant-tabs-tabpane {
    display: flex;
  }
`

const CaseRequestedMedicines = ({ caseDetails = {}, typeFilters, statusFilters,  ...props }) => {
  const typeFiltersSet = new Set(typeFilters || ['initial', 'rx change', 'rx renewal']);
  const statusFiltersSet = new Set(statusFilters || []);

  const requests = caseDetails?.requests?.filter(({ requestType, status }) => {
    return (typeFiltersSet.has(requestType)) &&
           (!statusFiltersSet.size || statusFiltersSet.has(status))
  }).sort((reqA, reqB) => new Date(reqB.createdAt) - new Date(reqA.createdAt));

  const brandedMedicineRequested = null;
    // ?.toLowerCase()
    // .includes("branded");

  return (
    <Row className="case-details-row-wrapper">
      <Title className="sub-content-color" level={4}>
        Requested Medicine
      </Title>
      <Col span={24}>
        {requests?.length ? (
          <StyledTabs defaultActiveKey="0">
            {requests.map((request, i) => (
              <TabPane
                key={i}
                tab={`${request.requestType.toUpperCase()} ${moment(request.createdAt).format("(MM/DD/YYYY)")}${request.status ? ` ● ${request.status}` : ''}`}
                style={{ display: "flex", flexDirection: "column" }}
              >
                <Tabs defaultActiveKey="0">
                  {request.medicines.map((medicine, j) => (
                    <TabPane tab={medicine.drugName} key={j}>
                      <Col span={24}>
                        <Row>
                          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                            <Row>
                              <Form.Item label="Drug Name">
                                <Text strong className="sub-content-color">
                                  {medicine.drugName}
                                </Text>
                              </Form.Item>
                            </Row>
                            <Row>
                              <Form.Item label="Dosage">
                                <Text strong className="sub-content-color">
                                  {medicine.dosage}
                                </Text>
                              </Form.Item>
                            </Row>
                            <Row>
                              <Form.Item label="Dispense Type">
                                <Text strong className="sub-content-color">
                                  {medicine.dispense}
                                </Text>
                              </Form.Item>
                            </Row>
                            <Row>
                              <Form.Item label="Refills">
                                <Text strong className="sub-content-color">
                                  {medicine.refills}
                                </Text>
                              </Form.Item>
                            </Row>
                            <Row>
                              <Form.Item label="Days Supply">
                                <Text strong className="sub-content-color">
                                  {medicine.daysSupply}
                                </Text>
                              </Form.Item>
                            </Row>
                          </Col>
                          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                            <Row>
                              <Form.Item label="Quantity">
                                <Text strong className="sub-content-color">
                                  {medicine.pillCount}
                                </Text>
                              </Form.Item>
                            </Row>
                            <Row>
                              <Form.Item label="NDC">
                                <Text strong className="sub-content-color">
                                  {medicine.ndc}
                                </Text>
                              </Form.Item>
                            </Row>
                            {request.note && (
                              <Row>
                                <Form.Item
                                  label='Admin Note'
                                >
                                  <Text
                                    strong
                                    className="sub-content-color"
                                  >
                                    {request.note}
                                  </Text>
                                </Form.Item>
                              </Row>
                            )}
                            {request.reason && 
                              <Row>
                                <Form.Item
                                  label='Reason Note'
                                >
                                  <Text
                                    strong
                                    className="sub-content-color"
                                  >
                                    {request.reason}
                                  </Text>
                                </Form.Item>
                              </Row>
                            }
                            {request.openEndedRequest &&
                              Object.keys(request.openEndedRequest).map(
                                (itemKey) => (
                                  <Row>
                                    <Form.Item
                                      label={itemKey}
                                      style={{ textTransform: "capitalize" }}
                                    >
                                      <Text
                                        strong
                                        className="sub-content-color"
                                      >
                                        {caseDetails.openEndedRequest[itemKey]}
                                      </Text>
                                    </Form.Item>
                                  </Row>
                                )
                              )}
                          </Col>
                        </Row>
                        {brandedMedicineRequested && (
                          <Row>
                            <Alert
                              description="Please make sure you select the branded version of the medication, and not the generic one."
                              type="error"
                            />
                          </Row>
                        )}
                      </Col>
                    </TabPane>
                  ))}
                </Tabs>
              </TabPane>
            ))}
          </StyledTabs>
        ) : (
          <Col className="align-text-center" span={24}>
            <Text>Requested medicines unavailable</Text>
          </Col>
        )}
      </Col>
    </Row>
  );
};

export default CaseRequestedMedicines;

import Axios from "./Axios";

export default class TicketApi {
  static getToken() {
    const token = window.localStorage.getItem("token");
    return token;
  }

  static getTicketChat(params) {
    const token = this.getToken();
    return Axios({
      url: `/ticket/fetch-chat-ticket`,
      method: "GET",
      params: params,
      headers: {        
        Authorization: `Bearer ${token}`,
      },
    });
  }

  static sendChatMessage(data) {
    const token = this.getToken();
    return Axios({
      url: `/ticket/chat-ticket`,
      method: "POST",
      data: data,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
  }
}

import React from 'react'
import { Modal, Typography, Spin, Form } from 'antd'

import { LegalAgreement } from './LegalAgreement'
import { Attestation } from './Attestation'
import { ProvideIdentityForm } from './ProvideIdentityForm'
import { ChallengeQuestions } from './ChallengeQuestions'
import { IdentityVerificationResult } from './IdentityVerificationResult'
import { SetPIN } from './setPIN'
import { InitiateTFAActivation } from './InitiateTFAActivation'
import { DUOMobileActivationRequest } from './DUOMobileActivationRequest'
import { DUOMobileActivation  } from './DUOMobileActivation'
import { IdentityVerificationProcessComplete } from './IdentityVerificationProcessComplete'
import { useIdentityVerification } from './hooks/useIdentityVerification'

const { Title } = Typography

export const VerifyIdentity = ({ 
    idVerified,
    pinSet,
    setShowIdentityVerificationFlow,
    visible,
    onFinish 
}) => {
    const [form] = Form.useForm() 

    const {
        validation,
        stepForward,
        stepBack,
        handleAcceptLegalAgreement,
        handleAttestation,
        handleAcceptIdpDisclaimer,
        handleSubmitIdentityForm,
        handleSubmitChallengeQuestionResponses,
        setPIN,
        initializeTFAActivation,
        handleAcceptDUOSMS,
        requestTFAActivation,
        activateTFA,
        challengeQuestionsAndAnswers,
    } = useIdentityVerification({ form, onFinish, idVerified, pinSet })
    
    const handleCancel = () => setShowIdentityVerificationFlow(false)

    return (
        <Modal
            width={550}
            title={<Title level={3}>Provider Identity Verification</Title>}
            visible={visible}
            footer={null}
            closable={false}
        >
            <Spin spinning={validation.loading}>
            <div style={{
                maxHeight: '650px',
                overflowY: 'scroll'
            }}>
                {validation.currentStep === 0 && (
                    <LegalAgreement
                        validation={validation}
                        handleSubmit={handleAcceptLegalAgreement}
                        handleCancel={handleCancel}
                    />
                )}
                {validation.currentStep === 1 && (
                    <Attestation
                        validation={validation}
                        validate={handleAttestation}
                        submitDisabled={!validation.attested}
                        handleSubmit={handleAcceptIdpDisclaimer}
                        handleCancel={handleCancel}
                    />
                )}
                {validation.currentStep === 2 && (
                    <ProvideIdentityForm
                        form={form}
                        handleCancel={handleCancel}
                        handleSubmit={handleSubmitIdentityForm}
                    />
                )}
                {validation.currentStep === 3 && (
                    <ChallengeQuestions
                        validation={validation}
                        challengeQuestionsAndAnswers={challengeQuestionsAndAnswers}
                        submitDisabled={!challengeQuestionsAndAnswers?.length}
                        handleSubmit={handleSubmitChallengeQuestionResponses}
                    />
                )}
                { validation.currentStep === 4 && <IdentityVerificationResult stepForward={stepForward}/> }
                { validation.currentStep === 5 && <SetPIN handleSubmit={setPIN}/> }
                { validation.currentStep === 6 && <InitiateTFAActivation handleSubmit={initializeTFAActivation} />}

                { validation.currentStep === 7 && (
                    <DUOMobileActivationRequest 
                        validation={validation} 
                        validate={handleAcceptDUOSMS}
                        handleSubmit={requestTFAActivation}
                    />
                )}

                { validation.currentStep === 8 && <DUOMobileActivation handleSubmit={activateTFA} stepBack={stepBack}/> }      
                { validation.currentStep === 9 && <IdentityVerificationProcessComplete handleFinsh={onFinish} />}
            </div>
            </Spin>
            
        </Modal>
    )
}
import { useState, useEffect } from 'react'

export function useDispenseUnitSelector({
    form,
    prescription,
    dispenseUnits,
    selectedStrength,
    saveQueuedPrescription
}) {
    const [options, setOptions] = useState([])

    useEffect(() => {
        if (dispenseUnits?.length) {
            setOptions(dispenseUnits.map(unit => (
                {
                    value: unit.StandardDispenseUnitTypeID,
                    label: unit.Name,
                }
            )))
        }
    }, [dispenseUnits])

    useEffect(() => {
        if (selectedStrength && prescription._id) {
            const dispenseUnitId = selectedStrength.ingredients
                ? selectedStrength.prescribingDefaults?.dispenseUnitId
                : selectedStrength.dispenseUnitId

            form.setFieldsValue({ dispenseUnitId })
            saveQueuedPrescription({ _id: prescription._id, dispenseUnitId })
        }
    }, [form, selectedStrength])

    return { options }
}
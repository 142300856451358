import { useState, useRef, useCallback } from 'react'
import { message } from 'antd'
import { debounce } from 'lodash'
import moment from 'moment-timezone'

import PrescriptionsAPI from '../../../../../../../../../../axios/prescription/PrescriptionsApi'

import { useDispenseUnitContext } from '../../../../context/DispenseUniteContext'

export const useMedicationSearch = ({ 
    form,
    selectTemplate,
    searchTemplates, 
}) => {
    const { dispenseUnits, getDispenseUnitById } = useDispenseUnitContext()

    const [medicationSearchState, setMedicationSearchState] = useState({
        matches: [],
        loading: false,
        selectedMedication: null,
        selectedStrength: null
    })

    const abortController = useRef(null)

    const clearHiddenFormValues = () => {
        form.resetFields([
            'ndc',
            'dispensableDrugId',
            'ingredients',
            'dispenseUnitId',
            'schedule'
        ])
    }

    const handleResetMedication = () => {
        setMedicationSearchState(prev => ({
            ...prev,
            selectedMedication: null,
            selectedStrength: null
        }))
    }

    const fetchMedications = useCallback(async searchStr => {

        if (!searchStr || searchStr?.length <= 2) {

            if (abortController.current) {
                abortController.current.abort();
            }

            return setMedicationSearchState(prev => ({
                ...prev,
                matches: [],
                loading: false
            }))
        }

        if (abortController.current) {
            abortController.current.abort();
        }

        abortController.current = new AbortController()
        const { signal } = abortController.current;

        selectTemplate(null)

        setMedicationSearchState(prev => ({
            ...prev,
            loading: true,
            matches: [],
            selectedMedication: null,
        }))

        clearHiddenFormValues()

        const abbreviatedSearch = searchStr.split(' ')[0]

        try {
            const { data: { medications } } = await PrescriptionsAPI.searchMedications(abbreviatedSearch, abortController.current?.signal)
            if (signal.aborted) return

            setMedicationSearchState(prev => ({
                ...prev,
                matches: medications
            }))
        } catch (e) {
            const errorMessage = e.response?.data?.message || e.message
            message.error(`Error fetching medication matches: ${errorMessage}`);
        } finally {
            setMedicationSearchState(prev => ({
                ...prev,
                loading: false
            }))
        }
    }, [])

    const selectMedication = useCallback(async medicationName => {
        clearHiddenFormValues()

        const medicationMatch = medicationSearchState.matches.find(({ name }) => name === medicationName);
                
        if (!medicationMatch) {
            return handleResetMedication()
        }

        setMedicationSearchState(prev => ({
            ...prev,
            selectedMedication: medicationMatch
        }))

        const dispenseUnit = dispenseUnits?.find(unit => unit.StandardDispenseUnitTypeID?.toString() === medicationMatch.dispenseUnitId?.toString())
        const isCompound = !!medicationMatch.prescribingDefaults

        const newFormValues = {
            quantityUnits: dispenseUnit?.Name,
            directions: '', 
            pharmacyNotes: '',
            strength: null,
            quantity: null,
            daysSupply: null,
            refills: null,
        }

        if (isCompound) {
            newFormValues.strength = 'Compound'
            Object.assign(newFormValues, medicationMatch.prescribingDefaults || {})
        }

        form.setFieldsValue(newFormValues)
    
        const templateSearchFilters = { 
            ownerId: null, 
            drugName: medicationMatch.name,
            strength: '0mg', // to override strength requirement on search
            DAW: false,
        }

        await searchTemplates(templateSearchFilters)
    }, [dispenseUnits, medicationSearchState.matches])

    const selectStrength = useCallback(async strength => {
        clearHiddenFormValues()

        const availableStrengths = medicationSearchState.selectedMedication?.strengths || [medicationSearchState.selectedMedication]
        const selectedStrength = availableStrengths?.find(medStrength => medStrength.strength === strength)

        setMedicationSearchState(prev => ({
            ...prev,
            selectedStrength
        }))

        const newFormValues = {
            ...selectedStrength,
            quantityUnits: selectedStrength.doseForm || selectedStrength.dispenseUnit || 'Compound',
            directions: '', 
            pharmacyNotes: ''
        }
        
        form.setFieldsValue(newFormValues)

        const searchParams = {
            absoluteFilters: { 
                drugName: medicationSearchState.selectedMedication.name 
            }, 
            rankedFilters: { strength }
        }

        await searchTemplates(searchParams)
    }, [medicationSearchState.selectedMedication])

    const handleSelectTemplate = (template) => {

        const { 
            medication, 
            pharmacyNotes,
            DAW,
        } = template

        const { 
            refills, 
            daysSupply, 
            pillCount,
            dispenseUnitId,
            drugName
        } = medication

        const dispenseUnit = getDispenseUnitById(dispenseUnitId)
        
        form.setFieldsValue({ 
            ...template, 
            ...medication,
            refills, 
            daysSupply, 
            pillCount,
            dispenseUnitId,
            drugName,
            quantityUnits: dispenseUnit?.Name,
            quantity: pillCount,
            noSubstitutions: !!DAW,
            providePharmacyNotes: !!pharmacyNotes,
            startDate: moment()
        })
        
        selectTemplate(template)
    }

    const resetMedicationSearch = () => {
        setMedicationSearchState(prev => ({
            ...prev,
            matches: [],
            selectedMedication: null,
            selectedStrength: null
        }))
    }

    return {
        medicationSearchState,
        selectMedication,
        selectStrength,
        handleSelectTemplate,
        resetMedicationSearch,
        handleResetMedication,
        fetchMedications: debounce(fetchMedications, 1000),
    }
}
//TODO: implement sorting
//display the case allocated to physician

import React, { useState, useEffect, useContext } from "react";
import "./SyncCaseList.css";
import { Table, Tabs, Spin, Popconfirm, message } from "antd";

import { history } from "../../helpers";
import { userConstants } from "../../constants";
import { LoadingOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import isValidHttpUrl from '../../utils/isValidHttpUrl';

import PhysicianApi from "../../axios/physician/PhysicianApi";

// assets
import NavaMDLogo from "../../assets/images/brands/navamd_logo.svg";
import RexMDLogo from "../../assets/images/brands/rexmd_logo.svg";
import ShapiroLogo from "../../assets/images/brands/shapiro_logo.svg";
import WaitingRoom from "./WaitingRoom/WaitingRoom";
import { TablePreferencesContext } from '../../context/TablePreferencesProvider';

const { TabPane } = Tabs;
const moment = require("moment");

const ACTIVE = 'ACTIVE';
const MISSED = 'MISSED';

const getBrandImageByName = (name) => {
    switch (name) {
        case 'Rex MD':
            return { className: 'rexmd-logo', alt: 'RexMD Brand Logo', url: RexMDLogo };
        case 'Nava MD':
            return { className: 'navamd-logo', alt: 'NavaMD Brand Logo', url: NavaMDLogo };
        case 'Shapiro MD':
            return ShapiroLogo;
        default:
            return '';
    }
}

const transformAppointment = (syncCase) => {
    const {
        patient,
        patientPhoneNumber,
        consultationType,
        brand,
        category,
        state,
        appointment,
        meetingLink,
        caseId
    } = syncCase

    return {
        "caseId": caseId,
        "patient": patient,
        "patientPhoneNumber": patientPhoneNumber,
        "consultationType": consultationType,
        "brand": brand,
        "category": category,
        "state": state,
        "startTime": appointment?.startTime || syncCase.status,
        "meetingLink": meetingLink,
    }
}


const TABLE_NAME = 'sync cases';

const SyncCaseList = (props) => {
    const [isLoading, setIsLoading] = useState(false);
    const [waitingRoom, setWaitingRoom] = useState([]);
    const [activeSyncCaseList, setActiveSyncCaseList] = useState([]);
    const [missedSyncCaseList, setMissedSyncCaseList] = useState([]);
    const [haveFetchedMissedSyncCaseList, setHaveFetchedMissedSyncCaseList] = useState(false);
    // toggle table data source between active and missed sync caselist
    const [tabKey, setTabKey] = useState(ACTIVE);
    const { getTablePreferences, updateTablePreferences } = useContext(TablePreferencesContext);
    const { currentPage, tablePageSize, dateSortOrder } = getTablePreferences(TABLE_NAME, { currentPage: 1, tablePageSize: 100, dateSortOrder: 'descend' });

    const getAppointmentsWithinMinutes = (sortedCaseList, minutes = 15) => {
        const appointments = [];
        const floor = moment().subtract({ minutes: minutes });
        const ceiling = moment().add({ minutes: minutes });

        // assume caselist is sorted
        for (let i = 0; i < sortedCaseList.length; i++) {
            let startTime = sortedCaseList[i].appointment?.startTime;
            if (!startTime) continue;

            if (moment(startTime).isBetween(floor, ceiling)) {
                appointments.push(sortedCaseList[i]);
            } else {
                return appointments;
            }
        }

        return appointments;
    }

    const fetchMissedSyncCaseList = () => {
        return new Promise((resolve, reject) => {
            PhysicianApi.getSyncCaseList({ active: false })
                .then(res => {
                    const { data } = res;
                    if (data?.payload) {
                        resolve(data?.payload);
                    } else {
                        reject('missing payload')
                    }
                });
        });
    }

    const fetchActiveSyncCaseList = () => {
        return new Promise((resolve, reject) => {
            PhysicianApi.getSyncCaseList({ active: true })
                .then(res => {
                    const { data } = res;
                    if (data?.payload) {
                        resolve(data?.payload);
                    } else {
                        reject('missing payload')
                    }
                });
        });
    }

    const columns = [
        {
            title: "Patient",
            dataIndex: "patient",
            key: "patient",
            sorter: (a, b) => a.patient.localeCompare(b.patient)
        },
        {
            title: "Type",
            dataIndex: "consultationType",
            key: "consultationType",
            sorter: (a, b) => a.consultationType.localeCompare(b.consultationType)
        },
        {
            title: "Brand",
            dataIndex: "brand",
            key: "brand",
            sorter: (a, b) => a.brand.localeCompare(b.brand),
            render: (name) => {
                const brandImage = getBrandImageByName(name);
                return (<img className={brandImage.className} alt={brandImage.alt} src={brandImage.url} />)
            }
        },
        {
            title: "Category",
            dataIndex: "category",
            key: "category",
            sorter: (a, b) => a.category.localeCompare(b.category),
        },
        {
            title: "State",
            dataIndex: "state",
            key: "state",
            sorter: (a, b) => a.state.localeCompare(b.state),
        },
        {
            title: "Appointment time",
            dataIndex: "startTime",
            key: "startTime",
            sortOrder: dateSortOrder,
            sorter: (a, b) => new Date(b.startTime) - new Date(a.startTime),
            render: startTime => (moment(startTime).isValid() ? `${moment(startTime).format("L")} - ${moment(startTime).format("LT")}` : startTime)
        },
        {
            title: "Meeting link",
            dataIndex: "meetingLink",
            key: "meetingLink",
            disableClickEventBubbling: true,
            render: (meetingLink, record) => {
                const isVideo = record.consultationType === 'video';
                const value = isVideo ? "Join meeting" : record.patientPhoneNumber;
                return (
                    <div
                        className="fill-cell"
                        onClick={e => e.stopPropagation()}
                    >
                        <Popconfirm
                            title={record.consultationType === 'audio' ? 'Call patient?' : 'Go to meeting?'}
                            icon={<QuestionCircleOutlined style={{ color: '#7bad7b' }} />}
                            onClick={e => e.stopPropagation()}
                            onConfirm={e => {
                                e.stopPropagation();
                                if (isVideo) {
                                    if (isValidHttpUrl(meetingLink)) {
                                        window.open(meetingLink, "_blank");
                                    } else {
                                        message.error('Invalid meeting link URL')
                                    }
                                } else {
                                    history.push(`/case-phone-call/${record.caseId}`)
                                }
                            }}
                            onCancel={e => {
                                e.stopPropagation();
                            }}
                        >
                            { /*  eslint-disable-next-line */ }
                            <a className="waitingroom-meeting-link" href="#">{value}</a>
                        </Popconfirm>
                    </div>
                );
            }
        }
    ];

    const handleCaseDetailsClick = (event, caseId) => {
        // TODO handle else
        if (caseId) {
            history.push(`/dashboard/${userConstants.USER_PHYSICIAN}/caseview/${caseId}`);
        }
    };

    const handleTabChange = (value) => {
        setTabKey(value);

        if (value === MISSED && !haveFetchedMissedSyncCaseList) {
            setIsLoading(true);
            fetchMissedSyncCaseList()
                .then(data => {
                    setMissedSyncCaseList(data.map(transformAppointment));
                    setIsLoading(false);
                    message.success("Missed sync cases have been updated");
                })
                .catch(() => message.error("Encountered an error while trying to load missed sync cases"))
                .finally(() => {
                    setIsLoading(false);
                    setHaveFetchedMissedSyncCaseList(true);
                });
        }
    }

    // hooks
    useEffect(() => {
        setIsLoading(true);
        fetchActiveSyncCaseList()
            .then(data => {
                const appointments = getAppointmentsWithinMinutes(data);
                setWaitingRoom(appointments.map(transformAppointment));
                return data;
            }).then(results => {
                setActiveSyncCaseList(results.map(transformAppointment));
                setIsLoading(false);
                message.success("Active sync cases have been updated");
            })
            .catch(e => {
                console.log(e)
                message.error("Encountered an error while trying to load active sync cases")
            })
            .finally(() => setIsLoading(false));
    }, []);

    return (
        <>
            <div className="waiting-room">
                <h1>Sync cases</h1>
                <div className="waiting-room-no-of-patient-list-data">
                    <Spin
                        spinning={isLoading}
                        size="large"
                        indicator={<LoadingOutlined spin />}
                    >
                        <Tabs defaultActiveKey="1" onChange={handleTabChange}>
                            <TabPane tab="Active" key={ACTIVE} />
                            <TabPane tab="Missed" key={MISSED} />
                        </Tabs>
                        <br />
                        <Table
                            style={{ textTransform: 'capitalize'}}
                            className="wating-room-table"
                            columns={columns}
                            dataSource={tabKey === ACTIVE ? activeSyncCaseList : missedSyncCaseList}
                            onChange={(pagination, filters, sorter) => { updateTablePreferences(TABLE_NAME, { dateSortOrder: sorter.order || 'descend' }) }}
                            pagination={{
                                current: currentPage,
                                pageSize: tablePageSize,
                                pageSizeOptions: ['10', '20', '50', '100'],
                                onShowSizeChange: (_, pageSize) => updateTablePreferences(TABLE_NAME, { currentPage: 1, tablePageSize: pageSize }),
                                onChange: (page, pageSize) => updateTablePreferences(TABLE_NAME, { currentPage: page, tablePageSize: pageSize })
                            }}
                            scroll={{ x: 900 }}
                            size="large"
                            onRow={(record, rowIndex) => {
                                return { onClick: (event) => {
                                    // prevent going to case details when clicking on column with meeting link
                                    if (event.target.querySelector('.fill-cell') == null) {
                                        handleCaseDetailsClick(event, record?.caseId, rowIndex);
                                    }
                                }};
                            }}
                        />
                    </Spin>
                </div>
            </div>
            <div className="waitingroom-container">
                <WaitingRoom
                    title='Upcoming Appointments'
                    loading={isLoading}
                    appointments={waitingRoom}
                    handleOnClick={(e, caseId) => handleCaseDetailsClick(e, caseId)}
                />
            </div>
        </>
    );
};

export default SyncCaseList;

import React from 'react'

export const RequestedMedications = ({
    background,
    color,
    medications = [],
}) => {
    return (
        <div style={{
            width: '100%',
            padding: '20px',
            marginBottom: '20px',
            background: background || 'rgba(123, 173, 126, .15)',
            color: '#000',
            borderRadius: '5px',
            display: 'flex',
            flexDirection: 'column',
            gap: '5px'
        }}
        >
            <div style={{ width: '100%', display: 'flex' }}>
                <div style={{ display: 'flex', width: '100%' }}>
                    <div style={{ width: 'calc(100% - 100px)' }}>
                        <strong style={{ color: color || '#7BAD7E', fontSize: '1.1rem' }}>Requested Medications </strong>
                    </div>
                </div>
            </div>
            {
                medications.map((medication) => (
                    <div style={{ fontWeight: 'bold' }} key={medication.name || medication.drugName}>
                        <span style={{ fontSize: '1.1rem'}}>{medication.drugName || medication.name} {medication.dosage}</span>
                        <div style={{ display: 'flex', gap: '10px', fontWeight: 'bold' }} >
                        <span>{medication.pillCount} {medication.quantityUnits ? `${medication.quantityUnits}(s)` : 'units'},</span>
                        <span>{medication.refills} Refills,</span>
                        <span>{medication.daysSupply} Days Supply</span>
                        { medication.DAW && <span>NO SUBSTITUTIONS</span> }
                        </div>
                    </div>
                ))
            }
        </div>
    )
}
import { useEffect } from "react";
import { message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import reports from "../redux/reports"
import socket from "../axios/socket";
import { SocketEventEnum, REPORT_TYPE } from "../types/enums";

const { GET_CASE_REPORT, GET_CONSULT_TYPE_REPORT, GET_COMPLETION_COUNTS_REPORT } = SocketEventEnum

export const useReportsWithSockets = () => {
    const dispatch = useDispatch();
    const userId = useSelector((state) => state.physicianDetails.physicianDetails?.userId);
    const monthlyCaseReport = useSelector((state) => state.reports.monthlyCaseReport);
    const consultReport = useSelector((state) => state.reports.consultReport);
    const completionCounts = useSelector((state) => state.reports.completionCounts);
    const caseReportRequestId = useSelector((state) => state.reports.caseReportRequestId);
    const consultReportRequestId = useSelector((state) => state.reports.consultReportRequestId);
    const completionCountsRequestId = useSelector((state) => state.reports.completionCountsRequestId);

    const hasReportData = !!(monthlyCaseReport && consultReport && completionCounts)

    useEffect(() => {
      if (hasReportData) {
        message.success("Report is ready");
      }
    }, [hasReportData])

    const getUserEventName = (eventName) => `${eventName}-${userId}`
    const caseReportEventName = getUserEventName(GET_CASE_REPORT)
    const consultReportEventName = getUserEventName(GET_CONSULT_TYPE_REPORT)
    const completionCountsEventName = getUserEventName(GET_COMPLETION_COUNTS_REPORT)

    useEffect(() => {
        socket.on(caseReportEventName, (response) => {
          if (response.reportType !== REPORT_TYPE.PHYSICIAN_MONTHLY) {
            return; //avoid socket conflict with same reportAPI
          }
          if (Number(response.requestId) !== caseReportRequestId) {
            console.log(`Outdated response for ${GET_CASE_REPORT}>>>>>`, { response, currentRequestId: caseReportRequestId });
            return;
          }

          console.log(`${caseReportEventName} socket response=`, response)
          dispatch(reports.actions.incrementCaseReportRequestId());
          const data = response.payload?.data
          if (!data) {
            dispatch(reports.actions.setErrorCaseReport());
            message.error("Error occured while fetching monthly open cases report");
            return
          }
          dispatch(reports.actions.setSuccessCaseReport(data[0] || []));
        })

        return () => {
          socket.removeAllListeners([
            caseReportEventName,
          ]);
        };
      }, [
        dispatch,
        caseReportEventName,
        caseReportRequestId,
      ]);

      useEffect(() => {
          socket.on(consultReportEventName, (response) => {
            if (response.reportType !== REPORT_TYPE.PHYSICIAN_MONTHLY) {
              return; //avoid socket conflict with same reportAPI
            }
            if (Number(response.requestId) !== consultReportRequestId) {
              console.log(`Outdated response for ${GET_CONSULT_TYPE_REPORT}>>>>>`, { response, currentRequestId: consultReportRequestId });
              return;
            }

            console.log(`${consultReportEventName} socket response=`, response)
            dispatch(reports.actions.incrementConsultReportRequestId());
            if (!response.reports) {
              dispatch(reports.actions.setErrorConsultReport());
              message.error("Error occured while fetching monthly consult report");
              return;
            };
            const { newConsultTypeReport, openConsultTypeReport } = response.reports
            let newConsults, openConsults

            if (newConsultTypeReport && newConsultTypeReport.length) {
              newConsults = { type: "New Cases" };
              newConsultTypeReport.forEach((consult) => {
                newConsults[consult._id] = consult.cases;
              });
            }

            if (openConsultTypeReport && openConsultTypeReport.length) {
              openConsults = { type: "Open Cases" };
              openConsultTypeReport.forEach((consult) => {
                openConsults[consult._id] = consult.cases;
              });
            }

            dispatch(reports.actions.setSuccessConsultReport([newConsults, openConsults]))
          })

          return () => {
            socket.removeAllListeners([
              consultReportEventName,
            ]);
          };
        }, [
          dispatch,
          consultReportEventName,
          consultReportRequestId,
        ]);

        useEffect(() => {
            socket.on(completionCountsEventName, (response) => {
              if (response.reportType !== REPORT_TYPE.PHYSICIAN_MONTHLY) {
                return; //avoid socket conflict with same reportAPI
              }
              if (Number(response.requestId) !== completionCountsRequestId) {
                console.log(`Outdated response for ${GET_COMPLETION_COUNTS_REPORT}>>>>>`, { response, currentRequestId: completionCountsRequestId });
                return;
              }

              console.log(`${completionCountsEventName} socket response=`, response)
              dispatch(reports.actions.incrementCompletionCountsRequestId());
              const report = response.payload?.data?.[0]
              if (!report) {
                dispatch(reports.actions.setErrorCompletionCounts());
                message.error("Error occured while fetching monthly completion counts");
                return;
              }

              dispatch(reports.actions.setSuccessCompletionCounts(report))
            })

            return () => {
              socket.removeAllListeners([
                completionCountsEventName,
              ]);
            };
          }, [
            dispatch,
            completionCountsEventName,
            completionCountsRequestId,
          ]);

    useEffect(() => {
      return () => {
        socket.close();
      }
    }, [])
}

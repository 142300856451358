import React, { useState, useEffect } from 'react'
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { PhysicianTagEnum } from '../../../../types/enums'

import prescribeMedicine from '../../../../redux/prescribeMedicine';

import {
    Modal,
    Steps,
    Typography,
    Button,
    Form,
    message
} from "antd"

import { history } from "../../../../helpers";

import CompleteConsultationForm from './components/CompleteConsultationForm'
import ReviewPrescriptions from './components/ReviewPrescriptions';

import { useCaseContext } from '../../context/CaseContext';
import { useProviderContext } from '../../context/ProviderContext';

const { Title } = Typography

const CompleteConsultationModal = ({
    prescriptions,
    visitNote,
    pharmacy,
    isNewDefaultPharmacy,
    consultationCompleted,
    providerPin,
    useTFA,
    providerOTPCode,
    readyToComplete,
    physicianDetails,
    requireVisitNote,
    actions
}) => {
    const { provider } = useProviderContext()
    const wasPrescribed = prescriptions && !!Object.values(prescriptions)
    const { _case } = useCaseContext()
    const [form] = Form.useForm()
    const [current, setCurrent] = useState(0);

    const hasControlledSubstance = prescriptions && Object.values(prescriptions)?.find(medication => medication.schedule && medication.schedule !== "0")

    useEffect(() => {
        if (prescriptions) {
            setCurrent(0)
            actions.evaluateReadyToComplete(physicianDetails)
        } else setCurrent(1)
    }, [prescriptions])

    const handleSubmit = async () => {
        if (requireVisitNote && !visitNote) {
            message.error('Please provide a visit note.')
            return
        }

        const vals = form.getFieldsValue(true)

        let patientExplanation

        if (vals["Patient Explanation Message"]) {
            patientExplanation = vals["Patient Explanation Message"]

            if (vals["Extra Message Template"]) {
                patientExplanation += `\n\n${vals["Extra Message Template"]}`
            }
        }

        const payload = {
            prescriptions: Object.values(prescriptions),
            visitNote,
            needsReview: provider?.tags?.includes(PhysicianTagEnum.NEEDS_REVIEW),
            isNewDefaultPharmacy,
            pharmacy,
            pin: providerPin,
            useTFA,
            providerOTPCode,
            patientExplanation,
            ...vals
        }

        try {
            await actions.completeConsultation(_case._id, payload)
            message.success('Consultation completed successfully.')
            wasPrescribed
            ? history.push(`/dashboard/physician/caseview/${_case._id}`)
            : window.location.reload()
        } catch (e) {
            console.log(e)
            // check for auth error here. don't reload. offer provider additional attempt to correct pin or tfa
            message.error(`Error completing consultation: ${e.message}`)
        }
    }

    const handleCancel = () => {
        setCurrent(0)
        actions.setProviderPin(null)
        actions.setConsultationCompleted(false)
    }

    const initialFooter = [
        <Button key="cancel" onClick={handleCancel}>Cancel</Button>,
        <Button
            key="next"
            type="primary"
            onClick={() => setCurrent(current + 1)}
            disabled={!readyToComplete}
        >
            Next
        </Button>
    ]

    const completionFooter = [
        <Button key="back" onClick={() => setCurrent(current - 1)}>Back</Button>,
        <Button key="complete" onClick={handleSubmit} type="primary">Complete Consultation</Button>,
    ]

    const ModalTitle = () => (
        <Title level={3}>
            Complete Consultation {wasPrescribed ? 'With' : 'Without'} Prescription{prescriptions?.length > 1 ? 's' : ''}
        </Title>
    )

    return (
        <Modal
            title={<ModalTitle/>}
            visible={consultationCompleted}
            onOk={handleSubmit}
            onCancel={handleCancel}
            okText='Complete'
            width={900}
            footer={current === 0 ? initialFooter : completionFooter}
        >
            { current === 0 && (
                <ReviewPrescriptions hasControlledSubstance={hasControlledSubstance}/>
            )}
            { current === 1 && form && <CompleteConsultationForm form={form}/>}
            <div style={{
                width: '100%',
                margin: '30px auto 0 auto',
                background: '#FAFBFC',
                padding: '30px 120px'
            }}>
                <Steps current={current}>
                    <Steps.Step title={`Review Prescription${prescriptions?.length > 1 ? 's' : ''}`} />
                    <Steps.Step title="Complete Consultation" />
                </Steps>
            </div>
        </Modal>
    )
}

const mapStateToProps = (state) => {
    const {
        prescriptions,
        prescribed,
        pharmacy,
        consultationCompleted,
        isNewDefaultPharmacy,
        readyToComplete,
        useTFA,
        providerOTPCode,
        providerPin,
        visitNote
    } = state.prescribeMedicine;

    const {
        physicianDetails,
    } = state.physicianDetails

    return {
        prescriptions,
        prescribed,
        pharmacy,
        consultationCompleted,
        isNewDefaultPharmacy,
        readyToComplete,
        useTFA,
        providerOTPCode,
        providerPin,
        physicianDetails,
        visitNote
    };
  };

const mapDispatchToProps = (dispatch) => {
    const {
        setConsultationCompleted,
        completeConsultation,
        evaluateReadyToComplete,
        setProviderPin
    } = prescribeMedicine.actions;
    return {
      actions: bindActionCreators(
        {
            setConsultationCompleted,
            completeConsultation,
            evaluateReadyToComplete,
            setProviderPin
        },
        dispatch
      ),
    };
  };

export default connect(mapStateToProps, mapDispatchToProps)(CompleteConsultationModal);

import types from "./types";

const initState = {
  articleListLoading: false,
  articleList: [],
  articleListCount: 0,
  viewArticleLoading: false,
  viewArticle: [],
};

export default function articles(state = initState, action) {
  switch (action.type) {
    case types.SET_ARTICLE_LIST_LOADING:
      return {
        ...state,
        articleListLoading: action.data,
      };
    case types.SET_ARTICLE_LIST:
      return {
        ...state,
        articleList: action.payload,
        articleListCount: action.count,
      };
    case types.SET_VIEW_ARTICLE_LOADING:
      return {
        ...state,
        viewArticleLoading: action.data,
      };

    case types.SET_VIEW_ARTICLE:
      return {
        ...state,
        viewArticle: action.payload,
      };
    case types.SET_CASE_CATEGORY_ID:
      return {
        ...state,
        caseCategoryId: action.id,
      };
    default:
      return state;
  }
}

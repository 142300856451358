import types from "./types";
import API from "../../axios/apiHelper";
import { history } from "../../helpers";
import { message } from "antd";

const setLoginRequest = (user) => ({ type: types.LOGIN_REQUEST, user });
const setLoginSuccess = (user) => ({ type: types.LOGIN_SUCCESS, user });
const setLoginFailure = (error) => ({ type: types.LOGIN_FAILURE, error });
const setUserLogout = () => ({ type: types.LOGOUT, data: {} });

function login(email, password, submitComplete) {
  return (dispatch) => {
    dispatch(setLoginRequest({ email }));
    const data = {
      email: email,
      password: password,
    };
    API.login(data).then(
      (user) => {
        const { data } = user;
        if (data && data.payload) {
          const { payload } = data;

          if (payload.sendVia) {
            message.success(payload.sendVia);
            history.push("/verify-otp");
            window.localStorage.setItem("_id", payload._id);
            submitComplete();
          } else {
            message.error("E-mail or Password is wrong");
            submitComplete();
          }
        } else if (user.data.token) {
          dispatch(setLoginSuccess(user));

          new Promise(function (resolve, reject) {
            setTimeout(() => resolve(1), 1000);
          })
            .then(function () {
              window.localStorage.setItem("token", user.data.token);
              window.localStorage.setItem("status", user.status);
              window.localStorage.setItem("statustext", user.statusText);
              window.localStorage.setItem("type", user.data.type);
              window.localStorage.setItem("useremail", user.data.userEmail);
              window.localStorage.setItem("profilepic", user.data.profilePic1);
              window.localStorage.setItem("isWheel", user.data.isWheel);
              if (user.data.isWheel) {
                window.localStorage.setItem("role", "Wheel");
              } else {
                window.localStorage.setItem("role", "Normal");
              }
            })
            .then(function () {
              history.push("/dashboard");
            })
            .then(function () {
              message.success("successfully logged");
            });

          submitComplete();
        } else {
          message.error("E-mail or Password is wrong");
          submitComplete();
        }
      },
      (error) => {
        message.error("Invalid User Or Check your Network");
        dispatch(setLoginFailure(error));
        submitComplete();
      }
    );
  };
}

function verfiyAuthenticationOtp(data, verifyComplete) {
  return (dispatch) => {
    API.verfiyAuthenticationCode(data)
      .then((user) => {
        verifyComplete();
        if (user.data.token) {
          dispatch(setLoginSuccess(user));
          new Promise(function (resolve, reject) {
            setTimeout(() => resolve(1), 1000);
          })
            .then(function () {
              window.localStorage.setItem("token", user.data.token);
              window.localStorage.setItem("status", user.status);
              window.localStorage.setItem("statustext", user.statusText);
              window.localStorage.setItem("type", user.data.type);
              window.localStorage.setItem("useremail", user.data.userEmail);
              window.localStorage.setItem("profilepic", user.data.profilePic1);
              window.localStorage.setItem("isWheel", user.data.isWheel);
              if (user.data.isWheel) {
                window.localStorage.setItem("role", "Wheel");
              } else {
                window.localStorage.setItem("role", "Normal");
              }
            })
            .then(function () {
              history.push("/dashboard");
            })
            .then(function () {
              message.success("successfully logged");
            });
        } else {
          verifyComplete();
          message.error("Unable to verify the OTP");
        }
      })
      .catch((error) => {
        verifyComplete();
        dispatch(setLoginFailure(error));
        const { response } = error;
        if (response) {
          const { data } = response;
          if (data && data.message) {
            message.error(data.message);
          } else {
            message.error("Unable to verify the OTP");
          }
        } else {
          message.error("Unable to verify the OTP");
        }
      });
  };
}



const actions = {
  login,
  verfiyAuthenticationOtp,
  setLoginFailure,
  setLoginRequest,
  setLoginSuccess,
  setUserLogout,
};

export default actions;

import React from 'react'

import styled from 'styled-components'
import { Typography, Button } from 'antd'

import ChangePharmacyModal from './modals/ChangePharmacyModal';
import PharmacyDetails from './PharmacyDetails';

const { Text } = Typography

const PharmacyContainer = styled.div`
    display: flex;
    justify-content: space-between;
    width: 30%;
    max-width: 450px;
    margin-bottom: 30px;
    background: rgba(250,250,250);
    padding: 10px 14px;
    box-shadow: 1px 10px 95px -13px rgba(0,0,0,0.31);
`

const Header = styled.div`
    display: flex;
    flex-direction: column
`

const StyledButton = styled(Button)`
    font-weight: bolder;
    font-size: 0.8rem;
    height: 30p';
    width: 80px;
`

export const Pharmacy = ({
    showChangePharmacyModal,
    setShowChangePharmacyModal,
}) => {
    return (
        <PharmacyContainer>
            <Header>
                <Text style={{ fontWeight: 'bolder'}}>Pharmacy</Text>
                <PharmacyDetails />
            </Header>
            <StyledButton
                size='small'
                onClick={() => setShowChangePharmacyModal(true)}
            >
                Change
            </StyledButton>
            <ChangePharmacyModal
                showChangePharmacyModal={showChangePharmacyModal}
                setShowChangePharmacyModal={setShowChangePharmacyModal}
            />
        </PharmacyContainer>
    )
}
import { useState, useEffect } from 'react';
import { message } from 'antd'

import PrescriptionsApi from '../../../../../../../../../axios/prescription/PrescriptionsApi'

export const useGetPharmacySpecialtyTypes = () => {
    const [pharmacySpecialtyTypes, setPharmacySpecialtyTypes] = useState([])
    
    const getPharmacySpecialtyTypes = async()  => {
        try {
            const results = (await PrescriptionsApi.getPharmacySpecialtyTypes()).data?.pharmacies
            setPharmacySpecialtyTypes(results)
        } catch (e) {
            message.error('Error fetching pharmacy specialtyTypes')
        }
    }

    useEffect(() => {
        getPharmacySpecialtyTypes()
    }, [])

    return { pharmacySpecialtyTypes }
}
import { useState } from 'react';
import { message } from 'antd'

import API from "../../../../../axios/prescription/PrescriptionsApi"

export const useReconcilePatient = ({
    selectedRxError,
    selectRxError,
    fetchRxErrors,
}) => {
    const [selectedLocalPatient, selectLocalPatient] = useState(null)
    const [mergedPatient, setMergedPatient] = useState(null)
    const [mergePatientConfirmed, setMergedPatientConfirmed] = useState(false)
    const [selectedMergeSource, selectMergeSource] = useState('rtl')

    const handleReconcile = async () => {

        try {
            const payload = {
                prescriberPatientId: selectedRxError?.prescriberPatientRecord.prescriberPatientId,
                internalPatientId: selectedLocalPatient.prescriberPatientId,
                reconciliationType: selectedMergeSource,
                pointToPrescriberPatientId: mergedPatient.prescriberPatientId,
                patient: mergedPatient
            }

            const { data } = await API.reconcilePharmacyPatient(payload)

            if (data?.error) {
                return message.error('Error reconciling pharmacy patient')
            }

            selectLocalPatient(null)
            setMergedPatient(null)
            selectRxError(null)
            fetchRxErrors()
        } catch (e) {
            message.error('Error reconciling pharmacy patient')
        }
    }

    const handleReconcilePatientProperty = async diff => {
        setMergedPatient(prev => ({ ...prev, ...diff }))
    }

    const handleMergeSelect = (patient, source) => {
        setMergedPatient(patient)
        selectMergeSource(source)
    }

    return {
        selectedLocalPatient,
        mergedPatient,
        mergePatientConfirmed,
        selectLocalPatient,
        setMergedPatient,
        handleReconcile,
        handleMergeSelect,
        handleReconcilePatientProperty,
        setMergedPatientConfirmed,
        selectedMergeSource,
        selectMergeSource
    }
}
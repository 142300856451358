import React from "react";
//  <style>
// .a {
//   fill: "#848c94";
// }
// </style>

const ScheduleDayIcon = () => {
  return (
    <span style={{ marginRight: 10 }} className="icon-div">
    <svg xmlns="http://www.w3.org/2000/svg" 
    width="1rem"
    height="1rem"
    viewBox="0 0 20.61 20.61"   
    >
  <defs>

  </defs>
  <title>Asset 15</title>
  <path className="a" d="M18,1.61H16.1v-1a.64.64,0,1,0-1.29,0v1h-9v-1a.64.64,0,0,0-1.29,0v1H2.66A2.66,2.66,0,0,0,0,4.27V18a2.66,2.66,0,0,0,2.66,2.66H18A2.66,2.66,0,0,0,20.61,18V4.27A2.66,2.66,0,0,0,18,1.61ZM2.66,2.9H4.51v.64a.64.64,0,0,0,1.29,0V2.9h9v.64a.64.64,0,0,0,1.29,0V2.9H18a1.37,1.37,0,0,1,1.37,1.37V5.8h-18V4.27A1.37,1.37,0,0,1,2.66,2.9ZM18,19.32H2.66A1.37,1.37,0,0,1,1.29,18V7.08h18V18A1.37,1.37,0,0,1,18,19.32Z"/>
</svg>

    </span>
  )
}

export default ScheduleDayIcon;

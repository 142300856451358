import React, { createContext, useState, useEffect, useContext } from 'react';
import APIHelper from '../../../axios/apiHelper'

const TelemedicineCategoryContext = createContext();

export const useTelemedicineCategoryContext = () => {
    const context = useContext(TelemedicineCategoryContext);
    if (!context) {
        throw new Error("usetelemedicineCategoryContext must be used within a telemedicineCategoryProvider");
    }
    return context;
}

export const TelemedicineCategoryProvider = ({ children, value }) => {
    const [telemedicineCategories, setTelemedicineCategories] = useState([])

    const getTelemedicineCategoryNameById = id => {
        return telemedicineCategories.find(category => category._id === id)?.name
    }

    const getTelemedicineCategoryIdByName = name => {
        return telemedicineCategories.find(category => category.name === name)?._id
    }

    useEffect(() => {
        APIHelper.fetchLookUp('tele-medicine-category')
            .then(({ data }) => setTelemedicineCategories(data.payload))
            .catch(e => console.error(e))
    }, [])

    return (
        <>
            <TelemedicineCategoryContext.Provider value={{ 
                telemedicineCategories,
                getTelemedicineCategoryNameById,
                getTelemedicineCategoryIdByName
            }}>
                {children}
            </TelemedicineCategoryContext.Provider>
        </>
    );
};

import React from 'react'
import { Button, Typography } from 'antd'

export const IdentityVerificationProcessComplete = ({ handleFinish }) => {
    return (
            <div style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: '10px'
            }}>
                <Typography.Text>You have successfully completed identity verification and TFA activation. </Typography.Text>
                <Button type="primary" style={{ width: '100px'}} onClick={handleFinish}>Finish</Button>
            </div>
    )
}
import React from "react";
import PhysicianPasswordChange from "./components/PhysicianPasswordChange/PhysicianPasswordChange";
import PhysicianAccount from "./components/PhysicianAccount/PhysicianAccount";

const ProfileView = () => {
  return (
    <>
      <PhysicianAccount />
      <PhysicianPasswordChange />
    </>
  );
};

export default ProfileView;

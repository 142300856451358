import React from 'react'
import { connect } from 'react-redux'
import { Form, Input } from 'antd'
import { required, isGreaterThanZero, isMismatch } from '../validation'

const DaysSupplyInput = ({
    form,
    prescription,
    telemedicineCategoryId,
    APMedicineDictionary,
    bypassValidation
}) => {

    const controlledSubstanceValidation = {
        validator: async (_, value) => {
            const schedule = form.getFieldValue('schedule');
            if (Number(schedule) === 2 && value > 90) {
                return Promise.reject(new Error("Schedule 2 drugs cannot be prescribed with a days's supply greater than 90"))
            }
            return Promise.resolve()
        }
    };


    return (
        <Form.Item
            required
            label="Days Supply"
            name="daysSupply"
            style={{ display: 'inline-block', width: 'calc(50% - 12px)' }}
            rules={[
                required,
                isGreaterThanZero,
                isMismatch(APMedicineDictionary, prescription?.requestedMedication, telemedicineCategoryId),
                controlledSubstanceValidation
            ]}
        >
            <Input type='number' />
        </Form.Item>
    )
}

const mapStateToProps = (state) => {
    const {
        APMedicineDictionary
    } = state.prescribeMedicine;
    return {
        APMedicineDictionary
    };
};


export default connect(mapStateToProps)(DaysSupplyInput)
import React from 'react'
import { Button } from 'antd'

import { PatientCard } from '../PatientCard'

import {
    SelectLocalPatientContainer,
    LocalPatientOptionsScroll
} from './styles'

export const SelectLocalPatient = ({
    selectedRxError,
    selectLocalPatient,
    selectMergePatient
}) => {

    const handleSelectLocalPatient = patient => {
        selectLocalPatient(patient)
        selectMergePatient(patient)
    }
    
    return (
        <SelectLocalPatientContainer>
            <LocalPatientOptionsScroll>
                {
                    selectedRxError?.savedPatientRecords.map(patient => (
                        <PatientCard
                            title="LifeMD Patient Record"
                            key={`${patient.firstName}-${patient.lastName}`}
                            patient={patient}
                            actions={[
                                <Button
                                    type="primary"
                                    onClick={() => handleSelectLocalPatient(patient)}
                                >
                                    Select
                                </Button>
                            ]}
                        />
                    ))
                }
            </LocalPatientOptionsScroll>
            <PatientCard title="Pharmacy Patient Record" patient={selectedRxError?.prescriberPatientRecord} />
        </SelectLocalPatientContainer>
    )
}
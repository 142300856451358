import React from 'react';
import { Table, Modal } from 'antd';

export const showVariableList = () => {

  const templateVariables = [
    { variable: 'prescription.name', description: 'Medication Name' },
    { variable: 'prescription.pillCount', description: 'Medication Quanity' },
    { variable: 'prescription.quantityUnits', description: 'Medication Quantity Units' },
    { variable: 'prescription.daysSupply', description: 'Prescription Days Supply' },
    { variable: 'prescription.refills', description: 'Prescription Refills' },
    { variable: 'prescription.strength', description: 'Medication Strength' },
  ]

  return (
    Modal.info({
      title: 'Template Variables',
      centered: true,
      maskClosable: true,
      icon: null,
      width: '50vw',
      content: (
        <>
          <div style={{ marginBottom: 20 }}>
            <p style={{ marginBottom: 30 }}>Add variables to templates by wrapping them with two sets of curly braces.</p>
            <p>Example: Administer 
              <em>
                <strong>
                  &#123;&#123;prescription.pillCount&#125;&#125; 
                  &#123;&#123;prescription.quantityUnits&#125;&#125; 
                  &#123;&#123;prescription.name&#125;&#125;
                  because patient has low testosterone.
                </strong>
              </em>
            </p>
          </div>
          <Table
            columns={[
              { dataIndex: 'variable', title: 'Variable' },
              { dataIndex: 'description', title: 'Description' },
            ]}
            pagination={false}
            dataSource={templateVariables}
          />
        </>
      )
     })
  )
}
import types from "./types";
import handleAPIErrorResponse from "../../utils/handleAPIResponseError";
import API from "../../axios/reports/ReportsAPI";
import { REPORT_TYPE } from "../../types/enums";
import moment from 'moment-timezone'
import { message } from 'antd'

const setMonthlyReportDate = (date) => ({
  type: types.SET_MONTHLY_REPORT_DATE,
  payload: date,
});

const setCaseReport = (report) => ({
  type: types.SET_MONTHLY_CASE_REPORT,
  payload: report,
});

const setConsultReport = (report) => ({
  type: types.SET_MONTHLY_CONSULT_REPORT,
  payload: report,
});

const setPhysicianMonthlyReportCases = (report) => ({
  type: types.SET_PHYSICIAN_MONTHLY_REPORT,
  payload: report,
});

const setPhysicianReportMonth = (month) => ({
  type: types.SET_PHYSICIAN_REPORT_MONTH,
  payload: month,
});

const setPhysicianReportYear = (year) => ({
  type: types.SET_PHYSICIAN_REPORT_YEAR,
  payload: year,
});

const setPhysicianCaseCompletionCounts = counts => ({
  type: types.SET_CASE_COMPLETION_COUNTS,
  payload: counts
})

const setIsLoadingCaseReport = (loading) => {
  return {
    type: types.SET_IS_LOADING_CASE_REPORT,
    payload: loading,
  }
}

const setIsLoadingConsultReport = (loading) => {
  return{
    type: types.SET_IS_LOADING_CONSULT_REPORT,
    payload: loading,
  }
}

const setIsLoadingCompletionCounts = (loading) => {
  return {
    type: types.SET_IS_LOADIN_COMPLETION_COUNTS,
    payload: loading,
  }
}

const incrementCaseReportRequestId = () => {
  return {
    type: types.INCREMENT_CASE_REPORT_REQUEST_ID,
  }
}

const incrementConsultReportRequestId = () => {
  return {
    type: types.INCREMENT_CONSULT_REPORT_REQUEST_ID,
  }
}

const incrementCompletionCountsRequestId = () => {
  return {
    type: types.INCREMENT_COMPLETION_COUNTS_REQUEST_ID,
  }
}

const retrieveCaseReport = (date) => {
  return (dispatch, getState) => {
    const { reports, physicianDetails } = getState()
    dispatch(setIsLoadingCaseReport(true));
    API.retrieveCaseReport({
      filterType: "month",
      date,
      physicianId: physicianDetails.physicianDetails.userId,
      requestId: reports.caseReportRequestId,
      reportType: REPORT_TYPE.PHYSICIAN_MONTHLY,
    }, {
      timeout: 10_000, //abort request. response get by Sockets
    });
  };
};

const retrieveConsultReport = (date) => {
  return (dispatch, getState) => {
    const { reports, physicianDetails } = getState()
    dispatch(setIsLoadingConsultReport(true));
    API.retrieveConsultReport({
      date,
      physicianId: physicianDetails.physicianDetails.userId,
      requestId: reports.consultReportRequestId,
      reportType: REPORT_TYPE.PHYSICIAN_MONTHLY,
    }, {
      timeout: 10_000, //abort request. response get by Sockets
    });
  };
};

const retrieveCompletionCounts = (date) => {
  return (dispatch, geState) => {
    const { reports, physicianDetails } = geState()
    dispatch(setIsLoadingCompletionCounts(true));
    setPhysicianMonthlyReportCases([])

    const startDate = moment(date).startOf('month').format('MM-DD-YYYY')
    const endDate = moment(date).endOf('month').format('MM-DD-YYYY')

    API.retrieveCompletionCounts({
      'start-date': startDate,
      'end-date': endDate,
      physicianId: physicianDetails.physicianDetails.userId,
      requestId: reports.completionCountsRequestId,
      reportType: REPORT_TYPE.PHYSICIAN_MONTHLY,
    }, {
      timeout: 10_000, //abort request. response get by Sockets
    });
  };
};

function getPhysicianMonthlyReports(reportMonth, reportYear) {
  return async dispatch => {
      dispatch(setReportsLoading(true));

      try {
          const response = await API.getPhysicianMonthlyReports({ reportMonth, reportYear })
          const { data } = response
          if (data && !data.error) {
              const { report } = data;
              dispatch(setPhysicianMonthlyReportCases(report))
          } else {
              message.warning("Unable to fetch physician monthly report");
          }
      } catch (err) {
          handleAPIErrorResponse(err, "Error occured while fetching physician monthly reports");
      } finally {
          dispatch(setReportsLoading(false));
      }
  };
}

function setReportsLoading(loading) {
  return async dispatch => {
    dispatch({
      type: types.SET_PHYSICIAN_MONTHLY_REPORT_LOADING,
      payload: loading,
    })
  }
}

const setSuccessCaseReport = (data) => {
  return async dispatch => {
    dispatch(setIsLoadingCaseReport(false));
    dispatch(setCaseReport(data))
  }
}

const setSuccessConsultReport = (data) => {
  return async dispatch => {
    dispatch(setIsLoadingConsultReport(false));
    dispatch(setConsultReport(data))
  }
}

const setSuccessCompletionCounts = (data) => {
  return async dispatch => {
    dispatch(setIsLoadingCompletionCounts(false));
    dispatch(setPhysicianCaseCompletionCounts(data))
  }
}

const setErrorCaseReport = () => {
  return async dispatch => {
    dispatch(setIsLoadingCaseReport(false));
    dispatch(setCaseReport(null))
  }
}

const setErrorConsultReport = () => {
  return async dispatch => {
    dispatch(setIsLoadingConsultReport(false));
    dispatch(setConsultReport(null))
  }
}

const setErrorCompletionCounts = () => {
  return async dispatch => {
    dispatch(setIsLoadingCompletionCounts(false));
    dispatch(setPhysicianCaseCompletionCounts(null))
  }
}

const resetData = () => {
  return async dispatch => {
    dispatch(setCaseReport(null))
    dispatch(setConsultReport(null))
    dispatch(setPhysicianCaseCompletionCounts(null))
  }
}

const incrementRequestId = () => {
  return async dispatch => {
    dispatch(incrementCaseReportRequestId())
    dispatch(incrementConsultReportRequestId())
    dispatch(incrementCompletionCountsRequestId())
  }
}

const setIsLoadingReport = (value) => {
  return async dispatch => {
    dispatch(setIsLoadingCaseReport(value))
    dispatch(setIsLoadingConsultReport(value))
    dispatch(setIsLoadingCompletionCounts(value))
  }
}

const cancelRequest = () => {
  return async dispatch => {
    dispatch(setIsLoadingReport(false))
    dispatch(incrementRequestId())
    dispatch(resetData())
  }
}

const actions = {
  setMonthlyReportDate,
  retrieveCaseReport,
  retrieveConsultReport,
  retrieveCompletionCounts,
  getPhysicianMonthlyReports,
  setPhysicianReportMonth,
  setPhysicianReportYear,
  setReportsLoading,
  setSuccessCaseReport,
  setSuccessConsultReport,
  setSuccessCompletionCounts,
  setErrorCaseReport,
  setErrorConsultReport,
  setErrorCompletionCounts,
  incrementCaseReportRequestId,
  incrementConsultReportRequestId,
  incrementCompletionCountsRequestId,
  resetData,
  cancelRequest,
};

export default actions;

import React from "react";

const CaseIcon = () => {
  return (
    <span style={{ marginRight: 10 }} className="icon-div">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1rem"
        height="1rem"
        viewBox="0 0 24 22.59"
      >
        <defs></defs>
        <title>Asset 9</title>
        <g>
          <path d="M12,7.06a5.65,5.65,0,1,0,5.65,5.65A5.65,5.65,0,0,0,12,7.06Zm0,9.88a4.24,4.24,0,1,1,4.24-4.24A4.24,4.24,0,0,1,12,16.94Z" />
          <path d="M12.71,10.59H11.29V12H9.88v1.41h1.41v1.41h1.41V13.41h1.41V12H12.71Z" />
          <path d="M21.18,4.24V2.82H16.94V4.24H15.53V2.06A2.07,2.07,0,0,0,13.47,0H10.53A2.07,2.07,0,0,0,8.47,2.06V4.24H7.06V2.82H2.82V4.24H0V21.18H2.82v1.41H7.06V21.18h9.88v1.41h4.24V21.18H24V4.24Zm-2.83,0h1.42V5.65H18.35ZM9.88,2.06a.65.65,0,0,1,.65-.65h2.93a.65.65,0,0,1,.65.65V4.24H9.88ZM4.24,4.24H5.65V5.65H4.24ZM22.59,19.76H19.76v1.41H18.35V19.76H5.65v1.41H4.24V19.76H1.41V5.65H2.82V7.06H7.06V5.65h9.88V7.06h4.24V5.65h1.41Z" />
        </g>
      </svg>
    </span>
  );
};

export default CaseIcon;

import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Skeleton } from 'antd'
import { EditOutlined } from '@ant-design/icons'

import { ChangePharmacyModal } from './ChangePharmacy.Modal'
import { Item } from "../../../Item"
import { SectionHeader } from '../../../SectionHeader'
import { StyledButton } from '../../../Button'
import { VeritasSpinner } from '../../../../../../components/VeritasSpinner'

import prescribeMedicine from '../../../../../../redux/prescribeMedicine'
import { formatTenDigitPhoneNumber } from '../../../../../../utils/phoneFormatter'

import { Container } from "../../../../Styles"

export const Pharmacy = () => {
    const dispatch = useDispatch()

    const { pharmacy } = useSelector(state => state.prescribeMedicine)

    const handleShowChangePharmacyModal = () => {
        dispatch(prescribeMedicine.actions.setShowChangePharmacyModal(true))
    }

    return (
        <Container>
            <VeritasSpinner spinning={!pharmacy}>
                <Skeleton loading={!pharmacy} paragraph={{ rows: 1 }}>
                    <SectionHeader title="Pharmacy">
                        <StyledButton
                            tooltip='Edit Pharmacy Information'
                            onClick={handleShowChangePharmacyModal} 
                            Icon={EditOutlined}
                        />
                    </SectionHeader>
                </Skeleton>
                <Item label="name" value={<Skeleton loading={!pharmacy} paragraph={{ rows: 0 }}>{pharmacy?.name}</Skeleton>} />
                <Item label="address" style={{ marginBottom: 0 }} value={<Skeleton loading={!pharmacy} paragraph={{ rows: 0 }}>{pharmacy?.address1}</Skeleton>} />
                <Item label="" value={<Skeleton loading={!pharmacy} paragraph={{ rows: 0 }}>{`${pharmacy?.city}, ${pharmacy?.state} ${pharmacy?.zipCode}`}</Skeleton>} />
                <Item label="phone" value={<Skeleton loading={!pharmacy} paragraph={{ rows: 0 }}>{formatTenDigitPhoneNumber(pharmacy?.phoneOrFax)}</Skeleton>} />
            </VeritasSpinner>
            <ChangePharmacyModal/>
        </Container>
    )
}
import { useState, useEffect, useCallback } from 'react';
import { message } from 'antd'

import PrescriptionsApi from '../axios/prescription/PrescriptionsApi'

export const useDispenseUnits = () => {
    const [dispenseUnits, setDispenseUnits] = useState([])
    const [dispenseUnitsLoading, setDispenseUnitsLoading] = useState(false)

    useEffect(() => {
        fetchDispensUnits()
    }, [])

    const fetchDispensUnits = useCallback(() => {
        setDispenseUnitsLoading(true)
        PrescriptionsApi.getAllAvailableDispenseUnits()
            .then(({ data }) => setDispenseUnits(data?.units || []))
            .catch(e => message.error(e.message))
            .finally(() => setDispenseUnitsLoading(false))
    }, [])

    const getDispenseUnitById = unitId => dispenseUnits?.find(unit => unit.StandardDispenseUnitTypeID?.toString() === unitId?.toString())

    return { 
        dispenseUnits, 
        getDispenseUnitById,
        dispenseUnitsLoading
    }
}
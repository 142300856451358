import { useEffect } from 'react';
import { Form } from 'antd';

export function useTemplateForm({ selectedTemplate }) {
  const [form] = Form.useForm();

  useEffect(() => {
    form.resetFields()
    if (selectedTemplate) {
      const { medication, teleMedicineCategoryIds: telemedicineCategories, ...rest } = selectedTemplate
      const mappedTelemedicineCategories = telemedicineCategories?.map(({ name }) => name)
      form.setFieldsValue({ ...medication, ...rest, teleMedicineCategories: mappedTelemedicineCategories });
    }
  }, [selectedTemplate])

  return { form };
}

import types from "./types";
import handleAPIErrorResponse from "../../utils/handleAPIResponseError";
import API from "../../axios/physician/PhysicianApi";

const setMessageCount = (payload) => ({
    type: types.SET_MESSAGE_COUNT,
    payload,
});

const setChatCaseId = (id) => ({
    type: types.SET_CHAT_CASE_ID,
    id,
});

function getMessage() {
    return (dispatch) => {
        API.getMessageCount().then((res) => {
            if (res.data.success) {
                dispatch(setMessageCount(res.data.payload));
            }
        }).catch(
            (error) => {
                handleAPIErrorResponse(error, "Error occured while fetching latest Message Count");
            });;
    };
}

const actions = {
    setMessageCount,
    getMessage,
    setChatCaseId,
};

export default actions; 
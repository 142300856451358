import React, { useState, useEffect, useCallback } from "react";
import "../Articles.css";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useHistory } from "react-router-dom";
import { Card, Pagination, Button, Form, Select, Input, Spin } from "antd";
import { RightOutlined, LoadingOutlined } from "@ant-design/icons";
import { userConstants } from "../../../constants";
import { debounce } from "lodash";

import articles from "../../../redux/articles";
import NoDataIcon from "../../../assets/icons/NoDataIcon";

const { Search } = Input;

const ArticleList = ({
  type,
  valueClinical,
  valueTraining,
  actions,
  articleListLoading,
  articleList,
  articleListCount,
  telemedicineCategoriesList,
  caseCategoryId,
}) => {
  const [form] = Form.useForm();
  const [filterParams, setFilterParams] = useState();
  const [tablePageSize, setTablePageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const [initialProtocol, setInitialProtocol] = useState();
  const [initialTraining, setInitialTraining] = useState();

  let history = useHistory();

  useEffect(() => {
    if (valueTraining) {
      setInitialTraining({
        page: 1,
        limit: 10,
        articleCategory: valueTraining,
      });
    }
  }, [valueTraining]);

  useEffect(() => {
    if (valueClinical) {
      if (caseCategoryId) {
        setInitialProtocol({
          page: 1,
          limit: 10,
          articleCategory: valueClinical,
          teleMedicineCategory: caseCategoryId,
        });
      } else {
        setInitialProtocol({
          page: 1,
          limit: 10,
          articleCategory: valueClinical,
        });
      }
    }
  }, [valueClinical, caseCategoryId]);

  useEffect(() => {
    if (initialProtocol) {
      if (type === "protocol") {
        articleLists(initialProtocol);
      }
    }

    if (initialTraining) {
      if (type === "training") {
        articleLists(initialTraining);
      }
    }
    // eslint-disable-next-line
  }, [type, initialTraining, initialProtocol]);

  const articleLists = (filterValue) => {
    actions.getArticleList(filterValue);
  };

  const filterValue = () => {
    const data = form.getFieldsValue();
    let filter = {
      page: 1,
      limit: 10,
    };
    if (type === "training") {
      filter["articleCategory"] = valueTraining;
    }
    if (type === "protocol") {
      filter["articleCategory"] = valueClinical;
    }
    filter["title"] = data.search;
    if (data.category) {
      filter["teleMedicineCategory"] = data.category;
    } else if (caseCategoryId) {
      filter["teleMedicineCategory"] = caseCategoryId;
    }
    setCurrentPage(1);
    setTablePageSize(10);
    setFilterParams(filter);
    articleLists(filter);
  };

  const debounceId = useCallback(debounce(filterValue, 2000), [valueTraining,valueClinical,]); // eslint-disable-line react-hooks/exhaustive-deps

  const clearFilterChange = () => {
    form.resetFields();
    setCurrentPage(1);
    setTablePageSize(10);
    setFilterParams();
    actions.setCaseCategoryId();

    let filter = {};

    if (type === "protocol") {
      filter = initialProtocol;
      articleLists(filter);
    }

    if (type === "training") {
      filter = initialTraining;
      articleLists(filter);
    }
  };

  const onChangePage = (value, pageSize) => {
    setCurrentPage(value);
    const paramsData = filterParams ? filterParams : {};
    paramsData["page"] = value;
    paramsData["limit"] = pageSize;
    if (type === "training") {
      paramsData["articleCategory"] = valueTraining;
    }
    if (type === "protocol") {
      paramsData["articleCategory"] = valueClinical;
      if (caseCategoryId) {
        paramsData["teleMedicineCategory"] = caseCategoryId;
      }
    }
    articleLists(paramsData);
  };

  const onPageSizeChange = (current, size) => {
    setTablePageSize(size);
    setCurrentPage(1);
    const paramsData = filterParams ? filterParams : {};
    paramsData["page"] = current;
    paramsData["limit"] = size;
    if (type === "training") {
      paramsData["articleCategory"] = valueTraining;
    }
    if (type === "protocol") {
      paramsData["articleCategory"] = valueClinical;
      if (caseCategoryId) {
        paramsData["teleMedicineCategory"] = caseCategoryId;
      }
    }
    articleLists(paramsData);
  };

  return (
    <>
      <Spin
        size="large"
        spinning={articleListLoading}
        indicator={<LoadingOutlined spin />}
      >
        <Form layout="vertical" form={form} size="large">
          <div className="article-list-filter-wrapper">
            <div className="article-list-search-filter">
              <Form.Item name="search" label="Title" rules={[{}]}>
                <Search
                  size={"large"}
                  placeholder={"Search by Title"}
                  onChange={debounceId}
                  onSearch={filterValue}
                />
              </Form.Item>
            </div>
            {type === "protocol" && (
              <div className="article-list-category-filter">
                <Form.Item name="category" label="Category">
                  <Select
                    placeholder="Filter by telemedicine category"
                    defaultValue={caseCategoryId}
                    getPopupContainer={(trigger) => trigger.parentElement}
                    onChange={filterValue}
                  >
                    {telemedicineCategoriesList &&
                      telemedicineCategoriesList.map((data) => (
                        <Select.Option key={data._id} value={data._id}>
                          {data.name}
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>
              </div>
            )}
            <div className="article-list-clear">
              <Form.Item>
                <Button
                  className="article-list-clear-button"
                  type="secondary"
                  onClick={() => clearFilterChange()}
                >
                  Clear
                </Button>
              </Form.Item>
            </div>
          </div>
        </Form>

        {articleList.length !== 0 ? (
          <>
            {type === "protocol" && (
              <div>
                <div className="article-list-data">
                  {articleList.length !== 0 &&
                    articleList.map((item) => (
                      <Card
                        title={item.title}
                        bordered={false}
                        hoverable
                        className="article-card"
                        onClick={() =>
                          history.push(
                            `/dashboard/${userConstants.USER_PHYSICIAN}/article-view/${item._id}`
                          )
                        }
                      >
                        <div className="article-card-description">
                          <p>{item.description}</p>
                        </div>
                        <br></br>
                        <div className="article-card-view">
                          View Protocol <RightOutlined />
                        </div>
                      </Card>
                    ))}
                </div>
              </div>
            )}
            {type === "training" && (
              <div>
                <div className="article-list-data">
                  {articleList.length !== 0 &&
                    articleList.map((item) => (
                      <Card
                        title={item.title}
                        bordered={false}
                        hoverable
                        className="article-card"
                        onClick={() =>
                          history.push(
                            `/dashboard/${userConstants.USER_PHYSICIAN}/article-view/${item._id}`
                          )
                        }
                      >
                        <div className="article-card-description">
                          <p>{item.description}</p>
                        </div>
                        <br></br>
                        <div className="article-card-view">
                          View Training <RightOutlined />
                        </div>
                      </Card>
                    ))}
                </div>
              </div>
            )}
            <Pagination
              className="article-list-pagination"
              total={articleListCount}
              showTotal={() => `Total ${articleListCount} Articles`}
              current={currentPage}
              pageSize={tablePageSize}
              pageSizeOptions={["5", "10", "20", "50", "100"]}
              showSizeChanger={true}
              onChange={onChangePage}
              onShowSizeChange={onPageSizeChange}
              defaultCurrent={6}
            />
          </>
        ) : (
          <div className="no-data-icon-wrapper">
            <NoDataIcon />
            <p className="no-data">No Data</p>
          </div>
        )}
      </Spin>
    </>
  );
};

const mapStateToProps = (state) => {
  const { articleListLoading, articleList, articleListCount, caseCategoryId } =
    state.articles;
  const { telemedicineCategoriesList } = state.entities;

  return {
    articleListLoading,
    articleList,
    articleListCount,
    telemedicineCategoriesList,
    caseCategoryId,
  };
};

const mapDispatchToProps = (dispatch) => {
  const { getArticleList } = articles.actions;
  const { setCaseCategoryId } = articles.actions;
  return {
    actions: bindActionCreators(
      {
        getArticleList,
        setCaseCategoryId,
      },
      dispatch
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ArticleList);

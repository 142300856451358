import React from "react";
import { Modal, message } from "antd";
import { PrescriptionTemplateForm } from "./PrescriptionTemplateForm";

export const EditModal = ({ 
  telemedicineCategories,
  selectedTemplate,
  editTemplate,
  handleFinish
}) => {

  const handleEditTemplate = async values => {
    try {
        await editTemplate(selectedTemplate._id, values)
        handleFinish()
    } catch (e) {
        console.log(e)
        message.error('Error editing prescription template')
    }
}
  
  return (
    <Modal
      title="Edit Medication"
      visible={selectedTemplate}
      onCancel={handleFinish}
      footer={null}
      destroyOnClose
    >
      <PrescriptionTemplateForm 
        telemedicineCategories={telemedicineCategories}
        selectedTemplate={selectedTemplate}
        submit={handleEditTemplate}
        handleFinish={handleFinish}
      />
    </Modal>
  );
};

import types from "./types"

const initState = {
    physicianDetailsLoading: false,
    physicianDetails: null
};

export default function pendingPrescription(
    state = initState,
    action,
) {
    switch (action.type) {
        case types.SET_PHYSICIAN_DETAILS_LOADING:
            return {
                ...state,
                physicianDetailsLoading: action.data,
            };

        case types.SET_PHYSICIAN_BOOKMARKED_CASES:
            return {
                ...state,
                physicianDetails: {
                    ...state.physicianDetails,
                    caseIds: action.payload
                }
            };

        case types.SET_PHYSICIAN_DETAILS:
            return {
                ...state,
                physicianDetails: action.payload,
            };
        default:
            return state;
    }
}
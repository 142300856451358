import React, { useState, useEffect } from "react";
import { Form, Row, Col, Typography, message, Spin, Alert } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import "./PharmacyDetailsComponent.css";
import { usePharmacy } from "../../hooks/usePharmacy"

const { Text, Title } = Typography;

export const PharmacyDetailsComponent = ({
  pharmacyId,
  patientId,
  title,
  className,
  contentSize = "small",
  copyDetails = true,
  primaryColoredTitle = false,
  splitFormDetails = false,
}) => {
  const [pharmacy, selectPharmacy] = useState(null);
  const [receivedError, setReceivedError] = useState("");
  const [loading, setLoading] = useState(false);
  const [formColSize, setFormColSize] = useState(24);
  const { getPharmacyDetails } = usePharmacy({ patientId, pharmacyId, selectPharmacy })

  const ERROR_MESSAGE = "Unable to fetch the requested pharamcy details. Please try again!"

  useEffect(() => {
    if (pharmacyId) {
      setLoading(true)
      getPharmacyDetails(pharmacyId)
        .then(selectPharmacy)
        .catch(e => {
          console.log(e)
          setReceivedError(true)
          message.warning(ERROR_MESSAGE);
        })
        .finally(() => setLoading(false))
    }
  // eslint-disable-next-line
  }, [pharmacyId])


  const setFormColProperties = () => {
    const columnSize = splitFormDetails ? 12 : 24
    setFormColSize(columnSize)
  };

  useEffect(setFormColProperties, [splitFormDetails]);

  return (
    <Spin spinning={loading} size="medium" indicator={<LoadingOutlined />}>
      <div
        name="requested-pharmacy-details"
        className={className ? className : ""}
      >
        <Row className="row-wrapper">
          {title ? (
            <Col span={24}>
              <Title
                className={primaryColoredTitle ? "content-color" : ""}
                level={4}
              >
                {title}
              </Title>
            </Col>
          ) : (
            <></>
          )}
          {
            pharmacy ? (
              <>
                <Col xs={24} sm={24} md={24} lg={formColSize} xl={formColSize}>
                  <Form.Item label="Name" size={contentSize}>
                    <Text
                      strong
                      copyable={copyDetails}
                      className="content-color pharmacy"
                    >
                      {pharmacy.name || "NA"}
                    </Text>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={formColSize} xl={formColSize}>
                  <Form.Item label="Address 1" size={contentSize}>
                    <Text
                      strong
                      copyable={copyDetails}
                      className="content-color pharmacy"
                    >
                      {pharmacy.address1 || "NA"}
                    </Text>
                  </Form.Item>
                </Col>
                { pharmacy?.address2 && (
                  <Col xs={24} sm={24} md={24} lg={formColSize} xl={formColSize}>
                    <Form.Item label="Address 2" size={contentSize}>
                      <Text
                        strong
                        copyable={copyDetails}
                        className="content-color pharmacy"
                      >
                        {pharmacy.address2}
                      </Text>
                    </Form.Item>
                  </Col>
                )}
                <Col xs={24} sm={24} md={24} lg={formColSize} xl={formColSize}>
                  <Form.Item label="City" size={contentSize}>
                    <Text strong className="content-color">
                      { pharmacy.city || "NA" }
                    </Text>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={formColSize} xl={formColSize}>
                  <Form.Item label="State" size={contentSize}>
                    <Text strong className="content-color">
                      { pharmacy.state || "NA" }
                    </Text>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={formColSize} xl={formColSize}>
                  <Form.Item label="Zip Code" size={contentSize}>
                    <Text strong className="content-color">
                      { pharmacy.zipCode || "NA" }
                    </Text>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={formColSize} xl={formColSize}>
                  <Form.Item label="Phone" size={contentSize}>
                    <Text strong className="content-color">
                      { pharmacy.primaryPhone || "NA" }
                    </Text>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={formColSize} xl={formColSize}>
                  <Form.Item label="Pharmacy Id" size={contentSize}>
                    <Text strong className="content-color">
                      { pharmacyId }
                    </Text>
                  </Form.Item>
                </Col>
              </>
            ) : (
            <Col span={24}>
              {receivedError ? (
                <Alert
                  message="Error: Requested Pharmacy details"
                  description={ERROR_MESSAGE}
                  type="error"
                  showIcon
                />
              ) : (
                <p className="align-text-center primary-font-color">
                  No requested pharamcy details available
                </p>
              )}
            </Col>
          )}
        </Row>
      </div>
    </Spin>
  );
};

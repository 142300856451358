import React from 'react'
import { Typography, Checkbox, Button, Spin } from 'antd'

const { Text } = Typography

export const Attestation = ({ 
    validation, 
    validate, 
    submitDisabled, 
    handleSubmit,
    handleCancel
}) => {
    return (
        <Spin spinning={validation.loading}>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                <Text><strong>IDP Agreement</strong></Text>
                <Text>Identity proofing based on certain financial records is required by the DEA for your continued use of this service. DoseSpot contracts with agencies such as Experian to complete the identification process. In some cases, the information you provide may not match the information available to Experian in the electronic records used for verification. If your identity could not be verified, please contact the Experian Help Desk at (800) 831-5614.</Text>
                {validation.idpDisclaimer && (
                    <div style={{ margin: '10px 0'}}>
                        <Checkbox
                            style={{ marginRight: '10px' }}
                            checked={validation.attested}
                            onClick={e => validate(e.target.checked)}
                        />
                        <Text><strong>{validation.idpDisclaimer}</strong></Text>
                    </div>
                )}
                <Button onClick={handleCancel}>Cancel</Button>
                <Button type="primary" disabled={submitDisabled} onClick={handleSubmit}>Next</Button>
            </div>
        </Spin>
    )
}
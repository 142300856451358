import React from 'react'
import { connect } from 'react-redux'

import { RequestedMedication } from './RequestedMedication'
import Charting from './Charting'
import PrescriptionForm from './PrescriptionForm'

// STYLES
import { MedicationGroup } from "../styles"

const Prescriptions = ({
    APMedicineDictionary,
    showChartingTemplate,
    bypassValidation,
    handleCreateTemplate,
    telemedicineCategoryId,
    prescriptions
}) => {
    return (
        <>
            {APMedicineDictionary.length && Object.values(prescriptions || {})?.map((prescription, i) => {
                const isFirstMedication = i === 0
                return (
                    <MedicationGroup key={i}>
                        <div style={{ display: 'flex', flexDirection: 'column', width: '50%'}}>
                            { prescription.requestedMedication && (
                                <RequestedMedication
                                    prescription={prescription}
                                    isFirstMedication={i === 0}
                                />
                            )} {
                                showChartingTemplate && 
                                <Charting prescription={prescription}/>
                            }
                        </div>
                        <PrescriptionForm
                            isFirstMedication={isFirstMedication}
                            prescription={prescription}
                            bypassValidation={bypassValidation}
                            handleCreateTemplate={handleCreateTemplate}
                        />
                    </MedicationGroup>
                )
            })}
        </>                
    )
}

const mapStateToProps = state => {
    const { 
        prescriptions,
        allPrescriptionsValid,
        selectedPharmacy,
        APMedicineDictionary 
    } = state.prescribeMedicine

    return { 
        prescriptions,
        allPrescriptionsValid,
        selectedPharmacy,
        APMedicineDictionary  
    }
}

export default connect(mapStateToProps)(Prescriptions)
import React, { useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from 'moment-timezone'

import { Typography, Row, Col, DatePicker, Tooltip, Button, message, Spin } from "antd";
import { QuestionCircleOutlined, LoadingOutlined } from "@ant-design/icons";
import styled from 'styled-components'

import reports from '../../redux/reports'
import ReportPieChart from "../../components/Reporting/ReportPieChartComponent"
import ReportClusterChart from "../../components/Reporting/ReportClusterChartComponent"
import StatisticCards from "../../components/Reporting/StatisticsCardsComponent"
import { ConsultTypeEnum, CaseStatusEnum } from '../../types/enums'

const {
  RX_RENEWAL,
  RX_CHANGE,
  CONSULT_COMPLETE,
  CONSULT_COMPLETE_NO_RX,
  ADDITIONAL_INFO_REQUIRED,
  INVALID_FACE_PHOTO,
  INVALID_PHOTO_ID
} = CaseStatusEnum

const { STORE_AND_FORWARD } = ConsultTypeEnum

const { Title, Text } = Typography;

const Container = styled.div`
  padding: 42px;
`

const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;

  & h1 {
    margin-bottom: 0;
  }
`

const ReportingTitle = styled.span`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  & span {
    margin-bottom: 5px;
  }
`

const Reports = (props) => {
  const {
    actions,
    reportDate,
    isLoadingReports,
    monthlyCaseReport,
    consultReport,
    completionCounts,
  } = props;

  const [currentReportDate, setCurrentReportDate] = useState(reportDate)

  const hasReports = !!(monthlyCaseReport && consultReport && completionCounts)

  const formattedReportDate = moment(reportDate).format('YYYY-MM')
  const formattedCurrentReportDate = moment(currentReportDate).format('YYYY-MM')

  const currentReportDateTitle = (isLoadingReports
    ? "Loading..."
    : (hasReports && currentReportDate
      ? `Report for ${formattedCurrentReportDate}`
      : "No Date choosen to get Report")
    )

  const isSameDate = hasReports && (formattedCurrentReportDate === formattedReportDate)

  const retrieveReports = () => {
    if (isSameDate) {
      message.success(`Report for ${formattedCurrentReportDate} is ready`);
      return;
    }
    if (!reportDate) {
      return
    }
    actions.resetData()
    actions.retrieveCaseReport(reportDate)
    actions.retrieveConsultReport(reportDate)
    actions.retrieveCompletionCounts(reportDate)
    setCurrentReportDate(reportDate)
  }

  const handleDateChange = (date) => actions.setMonthlyReportDate(moment(date).utc().toDate())

  let completedCases, completedRenewals, completedRxChanges, acceptanceRateData, consultReportCompletions

  if (completionCounts) {
    completedCases = completionCounts?.totals.case
    completedRenewals = completionCounts?.totals[RX_RENEWAL]
    completedRxChanges = completionCounts?.totals[RX_CHANGE]

    acceptanceRateData =
      completionCounts.totals[CONSULT_COMPLETE] &&
      completionCounts.totals[CONSULT_COMPLETE_NO_RX] &&
      completionCounts.totals[CONSULT_COMPLETE] + completionCounts?.totals[CONSULT_COMPLETE_NO_RX] > 0
        ? ({
          [CONSULT_COMPLETE]: completionCounts?.totals[CONSULT_COMPLETE],
          [CONSULT_COMPLETE_NO_RX]: completionCounts?.totals[CONSULT_COMPLETE_NO_RX]
        })
        : {}

    if (completionCounts.totals) {
      consultReportCompletions = { type: 'Completed Cases' }
      completionCounts.totals.audio && (consultReportCompletions.audio = completionCounts.totals.audio)
      completionCounts.totals[STORE_AND_FORWARD] && (consultReportCompletions[STORE_AND_FORWARD] = completionCounts.totals[STORE_AND_FORWARD])
      completionCounts.totals.video && (consultReportCompletions.video = completionCounts.totals.video)
      if (Object.keys(consultReportCompletions).length === 1) {
        consultReportCompletions = undefined
      }
    }
  }

  return (
    <Container className='article-wrapper'>
      <Spin
        size="large"
        spinning={isLoadingReports}
        tip={<span>Continue your Work.<br/> You will be notified when Report is ready</span>}
        indicator={
          <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            <LoadingOutlined />
            <Button style={{ marginTop: 60 }} onClick={actions.cancelRequest} children="Cancel Loading" />
          </div>
        }
      >
        <Row gutter={[16,16]} justify="space-around">
          <Col className="gutter-row" xs={{span: 24}} sm={{ span: 24 }} md={{ span: 18 }} lg={{ span: 20 }} xl={{ span: 20}}>
            <Header>
              <ReportingTitle>
                <Title level={1}>Monthly Reports</Title>
                <Text>*Reporting cutoffs based on Central timezone</Text>
              </ReportingTitle>
              <DatePicker
                onChange={handleDateChange}
                defaultValue={moment(reportDate).utc()}
                picker="month"
                allowClear={false}
                disabledDate={date => date < moment('2022-10-01T00:00:00.000').startOf('month')}
                disabled={isLoadingReports}
              />
            </Header>
          </Col>
        </Row>
        <Row gutter={[16,16]} justify="space-around">
          <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 18 }} lg={{ span: 20 }} xl={{ span: 20 }}>
            <Button
              type="primary"
              size="large"
              style={{ width: "33%" }}
              disabled={isLoadingReports}
              onClick={retrieveReports}
              children={isLoadingReports ? "Loading..." : "Get Report"}
            />
          </Col>
        </Row>
        <Row gutter={[16,16]} justify="space-around" style={{ paddingTop: 16 }}>
          <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 18 }} lg={{ span: 20 }} xl={{ span: 20 }}>
            <ReportingTitle>
              <Title level={3}>{currentReportDateTitle}</Title>
            </ReportingTitle>
          </Col>
        </Row>
        <Row gutter={[16, 16]} wrap justify="space-around">
          <Col className="gutter-row" xs={{span: 24}} sm={{ span: 24 }} md={{ span: 18 }} lg={{ span: 20 }} xl={{ span: 20}}>
              <StatisticCards
                loading={isLoadingReports}
                completedCases={completedCases}
                completedRenewals={completedRenewals}
                completedRxChanges={completedRxChanges}
              />
          </Col>
        </Row>
        <Row gutter={[16, 16]} wrap justify="space-around">
          <Col className="gutter-row" xs={{span: 24}} sm={{ span: 24 }} md={{ span: 18 }} lg={{ span: 20 }} xl={{ span: 20}}>
            <ReportPieChart
              id="acceptanceRate"
              title="New Case Acceptance Rate"
              data={acceptanceRateData}
              loading={isLoadingReports}
              fields={[CONSULT_COMPLETE, CONSULT_COMPLETE_NO_RX]}
              value="cases"
              category="status"
              legendPosition="bottom"
            />
          </Col>
          <Col className="gutter-row" xs={{span: 24}} sm={{ span: 24 }} md={{ span: 18 }} lg={{ span: 20 }} xl={{ span: 20}}>
            <ReportPieChart
              id="openCases"
              title="Open Cases (End of Period)"
              data={monthlyCaseReport}
              loading={isLoadingReports}
              fields={[
                ADDITIONAL_INFO_REQUIRED,
                INVALID_FACE_PHOTO,
                INVALID_PHOTO_ID,
                RX_CHANGE,
                RX_RENEWAL
              ]}
              value="cases"
              category="status"
              legendPosition="left"
            />
          </Col>
          <Col className="gutter-row" xs={{span: 24}} sm={{ span: 24 }} md={{ span: 18 }} lg={{ span: 20 }} xl={{ span: 20}}>
            <ReportClusterChart
              id="consultType"
              title={
                <>
                  Consultation Type
                  <Tooltip
                    overlayStyle={{ maxWidth: '450px' }}
                    overlayInnerStyle={{ color: '#fff !important'}}
                    title={
                      <div style={{ color: '#fff'}}>
                        <strong>New cases:</strong> cases created within the period.
                        <br />
                        <strong>Open cases:</strong> all non-completed cases at the end of the period.
                        <br />
                        <strong>Completed cases:</strong> cases completed within period.
                      </div>
                    }>
                    <span style={{ position: 'relative', bottom: '5px', left: '5px'}}><QuestionCircleOutlined style={{ fontSize: '15px', color: 'rgb(24, 144, 255)'}}/></span>
                  </Tooltip>
                </>
              }
              data={consultReport && [...consultReport, consultReportCompletions ]}
              loading={isLoadingReports}
              value="cases"
              category="type"
            />
          </Col>
        </Row>
      </Spin>
    </Container>
  );
};

const mapStateToProps = (state) => {
  const {
    isLoadingCaseReport,
    isLoadingConsultReport,
    isLoadingCompletionCounts,
    monthlyCaseReport,
    consultReport,
    completionCounts,
    reportDate,
  } = state.reports;

  const {
    physicianDetails
  } = state.physicianDetails

  return {
    isLoadingReports: isLoadingCaseReport || isLoadingConsultReport || isLoadingCompletionCounts,
    monthlyCaseReport,
    consultReport,
    completionCounts,
    reportDate,
    physicianId: physicianDetails?.userId,
  };
};

const mapDispatchToProps = dispatch => {
  const {
    retrieveCaseReport,
    retrieveConsultReport,
    retrieveCompletionCounts,
    setMonthlyReportDate,
    resetData,
    cancelRequest,
  } = reports.actions

  return {
    actions: bindActionCreators(
      {
        retrieveCaseReport,
        retrieveConsultReport,
        retrieveCompletionCounts,
        setMonthlyReportDate,
        resetData,
        cancelRequest,
      },
        dispatch
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Reports);

import React from 'react'
import { Form, DatePicker } from 'antd'
import { required, isGreaterThanZero } from '../validation'

export const EffectiveDate = ({ handleFieldsChange }) => {
    return (
        <Form.Item
            required
            label="Start Date"
            name="startDate"
            style={{ display: 'inline-block', width: 'calc(50% - 12px)' }}
            rules={[
                required,
                isGreaterThanZero,
            ]}
        >
            <DatePicker
                style={{ width: '100%' }}
                format='MM/DD/YYYY'
                onChange={handleFieldsChange}
            />
        </Form.Item>
    )
}
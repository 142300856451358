import React from 'react'
import { Form, Input } from 'antd'

import { required, isPositive } from '../validation'

export const Refills = () => {
    return (
        <Form.Item
            required
            label="Refills"
            name="refills"
            style={{ display: 'inline-block', width: 'calc(50% - 12px)' }}
            rules={[
                required,
                isPositive,
            ]}
        >
            <Input type='number' />
        </Form.Item>
    )
}
import React from 'react'
import { Form, DatePicker } from 'antd'
import moment from 'moment-timezone'
import { required } from '../validation'

export const StartDatePicker = ({ 
    form,
    onChange
}) => {

    const controlledSubstanceValidation = {
        validator: async (_, value) => {
            const schedule = form.getFieldValue('schedule');
            if (Number(schedule) === 2 && moment(value).isAfter(moment().add(6, 'months'))) {
                return Promise.reject(new Error("Schedule 2 drugs cannot be prescribed with a start date greater than 6 months from today's date"))
            }
            return Promise.resolve()
        }
    };


    return (
        <Form.Item
            required
            label="Start Date"
            name="startDate"
            style={{ display: 'inline-block', width: 'calc(50% - 12px)' }}
            rules={[required, controlledSubstanceValidation]}
        >
            <DatePicker
                allowClear={false}
                format='MM/DD/YYYY'
                style={{ width: '100%' }}
                disabledDate={now => now?.isBefore(moment().startOf('day'))}
                onChange={onChange}
            />
        </Form.Item>
        )
    }

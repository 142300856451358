import React, { useEffect, useState } from "react";
import "./PhysicianDashboard.css";
import { Button, Card, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { Link, useRouteMatch } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { userConstants } from "../../../constants";
import PhysicianApi from "../../../axios/physician/PhysicianApi";

import pendingPrescription from "../../../redux/pendingPrescription";
import scheduledCases from "../../../redux/scheduledCases";
import additionalInfoRequired from "../../../redux/additionalInfoRequired";
import rxChange from "../../../redux/rxChange";
import rxRenewal from "../../../redux/rxRenewals";
import incompleteCases from "../../../redux/incompleteCases"
import otherPhysiciansCases from "../../../redux/otherPhysiciansCases"

import { PhysicianTagEnum } from "../../../types/enums"


const PhysicianDashboard = (props) => {
  const {
    caseCountLoading,
    pendingPrescriptionCaseCount,
    scheduledCaseCount,
    scheduledCaseCountLoading,
    additionalInfoCaseCountLoading,
    additionalInfoCaseUpdateCountLoading,
    additionalInfoCaseCount,
    additionalInfoCaseUpdateCount,
    rxChangeCaseCountLoading,
    rxChangeCaseCount,
    rxRenewalCaseCountLoading,
    rxRenewalCaseCount,
    pendingPrescriptionCaseNotification,
    audioVideoCaseNotification,
    rxChangeCaseNotification,
    unassignedCaseNotification,
    otherPhysiciansCaseListLoading,
    otherPhysiciansCaseCount,
    physicianDetails,
    actions,
  } = props;

  const [phyicianName, setPhysicianName] = useState();
  const [physicianCredential, setPhysicianCredential] = useState(null)
  let { url } = useRouteMatch();

  const physicianTags = physicianDetails?.tags?.map(tag => tag.toLowerCase())

  useEffect(() => {
    if (!window.localStorage.getItem("physicianName")) {
      PhysicianApi.physicianData().then(({ data }) => {
        window.localStorage.setItem(
          "physicianName",
          data.payload.firstName + " " + data.payload.lastName
        );

        if (data.payload.credential) {
          window.localStorage.setItem("physicianCredential", data.payload.credential)
          setPhysicianCredential(data.payload.credential)
        }

        setPhysicianName(data.payload.firstName + " " + data.payload.lastName);
      });
    } else {
      setPhysicianName(window.localStorage.getItem("physicianName"));
      setPhysicianCredential(window.localStorage.getItem("physicianCredential"))
    }
  }, []);

  const fetchPendingPrescritionCaseCount = () => {
    const status = "pending prescription";
    actions.getPendingPrescriptionCaseCount({ status });
  };

  // eslint-disable-next-line
  useEffect(fetchPendingPrescritionCaseCount, [
    pendingPrescriptionCaseNotification,
    unassignedCaseNotification,
  ]);

  const fetchScheduledCount = () => {
    const status = "pending audio consult,pending video consult";
    actions.getScheduledCaseCount({ status });
  };

  // eslint-disable-next-line
  useEffect(fetchScheduledCount, [
    audioVideoCaseNotification,
    unassignedCaseNotification,
  ]);

  const fetchAdditionalInfoCaseCount = () => {
    const statusParams = {};
    statusParams["status"] =
      "additional info required,invalid face photo,invalid photo id";
    actions.getAdditionalInfoCaseCount(statusParams);
  };

  // eslint-disable-next-line
  useEffect(fetchAdditionalInfoCaseCount, []);

  const fetchRxChangeCaseCount = () => {
    const status = "rx change";
    actions.getRxChangeCaseCount({ status });
  };

  // eslint-disable-next-line
  useEffect(fetchRxChangeCaseCount, [
    rxChangeCaseNotification,
    unassignedCaseNotification,
  ]);

  const fetchRxRenewalCaseCount = () => {
    const status = "rx renewal";
    actions.getRxRenewalCaseCount({ status });
  };

  const fetchIncompleteCases = () => {
    actions.getIncompleteCases()
  }

  // eslint-disable-next-line
  useEffect(fetchIncompleteCases, [])

  useEffect(fetchRxRenewalCaseCount,
    // eslint-disable-next-line
    [rxRenewalCaseCount]);

  const fetchOtherPhysiciansCases = () => {
    physicianTags?.includes(PhysicianTagEnum.LEAD_PHYSICIAN) && actions.getOtherPhysiciansCaseCount()
  }

  useEffect(fetchOtherPhysiciansCases,
    // eslint-disable-next-line
    [physicianDetails])

  const caseNumberDisplay = (length, children) => {
    return (
      <h2>
        There {length === 1 ? "is" : "are"}{" "}
        <b style={{ color: "#7BAD7E" }}>
          {length} {length === 1 ? "case" : "cases"}
        </b>
        {children}
      </h2>
    );
  };

  const updatedCaseNumberDisplay = (length) => {
    return (
      <p className="physcian-dashboard-card-heading-def margin-top-20">
        Updated cases/responses from the patient:
        <b style={{ color: "#FF4D4F" }}>&nbsp;{length}</b>
      </p>
    );
  };

  return (
    <>
      <h1> Welcome, {physicianCredential || ''} <b className="uppercase" >{phyicianName && phyicianName} </b> </h1>
      <div className="physician-dashboard">
          <Card className="physcian-dashboard-part-card-of-cases">
            <Spin
              spinning={caseCountLoading}
              size="large"
              indicator={<LoadingOutlined spin />}
            >
            <div>
              <div>
                <h4>
                  <b>Async</b>
                </h4>
                <div className="physcian-dashborad-card-heading">
                  {caseNumberDisplay(pendingPrescriptionCaseCount, ' pending')}
                  <p className="physcian-dashboard-card-heading-def margin-top-20">
                    List of async consults that are assigned to you,
                  </p>
                  <p className="physcian-dashboard-card-heading-def">
                    which are in{" "}
                    <b className="primary-font-color">Pending Prescription</b>{" "}
                    Status
                  </p>
                </div>

              </div>
            </div>
            <div className="physcian-dashborad-card-button">
              <Link to={`${url}/${userConstants.USER_PHYSICIAN}/caselist`}>
                <Button type="primary">
                  <b>View All</b>
                </Button>
              </Link>
            </div>
          </Spin>
        </Card>

        <Card className="physcian-dashboard-part-card-of-cases">
          <Spin
            spinning={scheduledCaseCountLoading}
            size="large"
            indicator={<LoadingOutlined spin />}
          >
            <div>
              <div>
                <h4>
                  <b>Sync</b>
                </h4>
                <div className="physcian-dashborad-card-heading">
                  {caseNumberDisplay(scheduledCaseCount)}
                  <p className="physcian-dashboard-card-heading-def margin-top-20">
                    List of sync consults that are assigned to you,
                  </p>
                  <p className="physcian-dashboard-card-heading-def">
                    which requires a{" "}
                    <b className="primary-font-color">Audio/ Video Consult</b> to be
                    completed
                  </p>
                </div>
              </div>
            </div>
            <div className="physcian-dashborad-card-button">
              <Link to={`${url}/${userConstants.USER_PHYSICIAN}/sync-cases`}>
                <Button type="primary">
                  <b>View All</b>
                </Button>
              </Link>
            </div>
          </Spin>
        </Card>
        <Card className="physcian-dashboard-part-card-of-cases">
          <Spin
            spinning={rxChangeCaseCountLoading}
            size="large"
            indicator={<LoadingOutlined spin />}
          >
            <div>
              <div className="physcian-dashborad-card-heading">
                {caseNumberDisplay(rxChangeCaseCount)}
                <h2>for Rx Change review</h2>

                <p className="physcian-dashboard-card-heading-def margin-top-20">
                  Rx Change requests
                </p>
              </div>

            </div>
            <div className="physcian-dashborad-card-button">
              <Link
                to={`${url}/${userConstants.USER_PHYSICIAN}/updateprescribe`}
              >
                <Button type="primary">
                  <b>Go to Rx Change requests</b>
                </Button>
              </Link>
            </div>
          </Spin>
        </Card>
        <Card className="physcian-dashboard-part-card-of-cases">
          <Spin
            spinning={rxRenewalCaseCountLoading}
            size="large"
            indicator={<LoadingOutlined spin />}
          >
            <div>
              <div className="physcian-dashborad-card-heading">
                {caseNumberDisplay(rxRenewalCaseCount)}
                <h2>for Rx Renewal review</h2>

                <p className="physcian-dashboard-card-heading-def margin-top-20">
                  Rx Renewal requests
                </p>
              </div>

            </div>
            <div className="physcian-dashborad-card-button">
              <Link
                to={`${url}/${userConstants.USER_PHYSICIAN}/rx-change-requests`}
              >
                <Button type="primary">
                  <b>Go to Rx Renewal Requests</b>
                </Button>
              </Link>
            </div>
          </Spin>
        </Card>

        <Card className="physcian-dashboard-part-card-of-cases">
          <Spin
            spinning={
              additionalInfoCaseCountLoading ||
              additionalInfoCaseUpdateCountLoading
            }
            size="large"
            indicator={<LoadingOutlined spin />}
          >
            <div>
              <div>
                <div className="physcian-dashborad-card-heading">
                  <section>
                    {caseNumberDisplay(additionalInfoCaseCount)}
                    <h2>pending a patient's response</h2>

                    <p className="physcian-dashboard-card-heading-def margin-top-20">
                      List of cases that are assigned to you,
                    </p>
                  </section>
                  <section>
                    {updatedCaseNumberDisplay(additionalInfoCaseUpdateCount)}
                  </section>
                </div>
              </div>

            </div>
            <div className="physcian-dashborad-card-button">
              <Link
                to={`${url}/${userConstants.USER_PHYSICIAN}/additional-info-required`}
              >
                <Button type="primary">
                  <b>Go to Additional Info Required</b>
                </Button>
              </Link>
            </div>
          </Spin>
        </Card>

        { physicianTags?.includes(PhysicianTagEnum.LEAD_PHYSICIAN) && (
          <Card className="physcian-dashboard-part-card-of-cases">
            <Spin
              spinning={ otherPhysiciansCaseListLoading }
              size="large"
              indicator={<LoadingOutlined spin />}
            >
              <div>
                <div>
                  <div className="physcian-dashborad-card-heading">
                    <section>
                      {caseNumberDisplay(otherPhysiciansCaseCount)}
                      <h2>assigned to other physicians</h2>

                      <p className="physcian-dashboard-card-heading-def margin-top-20">
                        List of incomplete cases that are assigned to other physicians, ordered by first created
                      </p>
                    </section>
                  </div>
                </div>

              </div>
              <div className="physcian-dashborad-card-button">
                <Link
                  to={`${url}/${userConstants.USER_PHYSICIAN}/other-physicians-cases`}
                >
                  <Button type="primary">
                    <b>Go to Other Physicians Queue</b>
                  </Button>
                </Link>
              </div>
            </Spin>
          </Card>
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  const {
    caseCountLoading,
    pendingPrescriptionCaseCount,
  } = state.pendingPrescription;
  const {
    scheduledCaseCount,
    scheduledCaseCountLoading,
  } = state.scheduledCases;
  const {
    additionalInfoCaseCountLoading,
    additionalInfoCaseUpdateCountLoading,
    additionalInfoCaseCount,
    additionalInfoCaseUpdateCount,
  } = state.additionalInfoRequired;
  const {
    pendingPrescriptionCaseNotification,
    audioVideoCaseNotification,
    rxChangeCaseNotification,
    unassignedCaseNotification,
  } = state.notifications;
  const { rxChangeCaseCountLoading, rxChangeCaseCount } = state.rxChange;
  const { rxRenewalCaseCountLoading, rxRenewalCaseCount } = state.rxRenewal;
  const { otherPhysiciansCaseListLoading, otherPhysiciansCaseCount } = state.otherPhysiciansCases
  const { physicianDetails } = state.physicianDetails

  return {
    caseCountLoading,
    pendingPrescriptionCaseCount,
    scheduledCaseCount,
    scheduledCaseCountLoading,
    additionalInfoCaseCountLoading,
    additionalInfoCaseUpdateCountLoading,
    additionalInfoCaseCount,
    additionalInfoCaseUpdateCount,
    rxChangeCaseCountLoading,
    rxChangeCaseCount,
    rxRenewalCaseCountLoading,
    rxRenewalCaseCount,
    pendingPrescriptionCaseNotification,
    audioVideoCaseNotification,
    rxChangeCaseNotification,
    unassignedCaseNotification,
    otherPhysiciansCaseListLoading,
    otherPhysiciansCaseCount,
    physicianDetails
  };
};

const mapDispatchToProps = (dispatch) => {
  const { getPendingPrescriptionCaseCount } = pendingPrescription.actions;
  const { getScheduledCaseCount } = scheduledCases.actions;
  const { getAdditionalInfoCaseCount } = additionalInfoRequired.actions;
  const { getRxChangeCaseCount } = rxChange.actions;
  const { getRxRenewalCaseCount } = rxRenewal.actions;
  const { getIncompleteCases } = incompleteCases.actions
  const { getOtherPhysiciansCaseCount } = otherPhysiciansCases.actions

  return {
    actions: bindActionCreators(
      {
        getPendingPrescriptionCaseCount,
        getScheduledCaseCount,
        getAdditionalInfoCaseCount,
        getRxChangeCaseCount,
        getRxRenewalCaseCount,
        getIncompleteCases,
        getOtherPhysiciansCaseCount
      },
      dispatch
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PhysicianDashboard);

import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import Video from "twilio-video";
import Participant from "./Participant";
import "../VideoCall.css";
import {
  PhoneFilled,
  AudioOutlined,
  AudioMutedOutlined,
  FileTextOutlined
} from "@ant-design/icons";
import { Modal, Tooltip, Form, Radio, message, Checkbox, Spin } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import CaseDetailsDraw from '../../Messages/CaseDetailsDraw';
import PhysciainApi from "../../../axios/physician/PhysicianApi";

// import common functions
import { history } from "../../../helpers";
import isWheel from "../../../utils/isWheelPhysician";

const Room = ({
  roomName,
  token,
  handleLogout,
  consulationType,
  physicianName,
  patientName,
  clearRoomData
}) => {
  const [room, setRoom] = useState(null);
  const [participants, setParticipants] = useState([]);
  const [mute, setMuted] = useState();
  const [internalMute, setInternalMute] = useState(false);
  const [visible, setVisible] = useState(false);
  const [loading, setloading] = useState(false);
  const [caseDetailsDrawerOpen, setCaseDetailsDrawerOpen] = useState(false);

  const [localname, setLocalName] = useState("");
  const [remotename, setRemoteName] = useState("");
  const [form] = Form.useForm();
  const { id: caseId } = useParams();
  const roomRef = useRef(null);

  // TODO come up with more reliable way to detect if patient
  const isPatient = window.localStorage.getItem("caseId") !== null;

  useEffect(() => { roomRef.current = room }, [room])

  useEffect(() => {
    if (window.localStorage.getItem("type") === "physician") {
      setLocalName(physicianName);
      setRemoteName(patientName);
    }

    if (window.localStorage.getItem("caseId") !== null) {
      setLocalName(patientName);
      setRemoteName(physicianName);
    }
  }, [patientName, physicianName]);

  useEffect(() => {
    const participantConnected = (participant) => {
      setParticipants((prevParticipants) => [...prevParticipants, participant]);
    };

    const participantDisconnected = (participant) => {
      setParticipants((prevParticipants) =>
        prevParticipants.filter((p) => p.sid !== participant.sid)
      );
    };

    const participantUpdated = (_, participant) => {
      setParticipants((prevParticipants) => prevParticipants.map(p => {
        if (p.sid === participant.sid) return participant;
        else return p;
      }))
    }

    Video.connect(token, { name: roomName }).then((newRoom) => {
      newRoom.on("participantConnected", participantConnected);
      newRoom.on("participantDisconnected", participantDisconnected);
      newRoom.on('participantReconnected', participantUpdated)
      newRoom.on('trackDisabled', participantUpdated);
      newRoom.on('trackEnabled', participantUpdated);
      newRoom.participants.forEach(participantConnected);
      consulationType === "audio" &&
        newRoom.localParticipant.videoTracks.forEach((publication) => {
          publication.track.stop();
          publication.unpublish();
        })
      setRoom(newRoom);
    });

    return () => {
      if (roomRef.current?.localParticipant.state === "connected") {
        roomRef.current.localParticipant.tracks.forEach(trackPublication => trackPublication.track.stop());
        roomRef.current.disconnect();
        roomRef.current = null;
      }
      else {
        setRoom(roomRef.current);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roomName, token]);

  const onOkClick = () => {
    const formData = form.getFieldsValue();

    if (formData.confirm === "yes") {
      setloading(true);
      const status = { status: "pending prescription" };
      message.success("Successfully updated case status");
      handleLogout();

      setVisible(false);

      PhysciainApi.updateCaseStatus(roomName, status)
        .then((res) => {
          if (res.data.success === true) {
            message.success("Successfully updated case status");
            handleLogout();
            setVisible(false);
            setloading(false);
            window.localStorage.removeItem("roomToken");
            window.localStorage.removeItem("roomName");
            window.localStorage.removeItem("roomPatientName");
            window.localStorage.removeItem("roomPhyscianName");
            window.localStorage.removeItem("roomConsultationType");
            history.push(`/prescribe-medicine/${roomName}`);
          }
        })
        .catch((error) => {
          const { response } = error;
          setloading(false);
          handleLogout();
          window.localStorage.removeItem("roomToken");
          window.localStorage.removeItem("roomName");
          window.localStorage.removeItem("roomPatientName");
          window.localStorage.removeItem("roomPhyscianName");
          window.localStorage.removeItem("roomConsultationType");
          if (response) {
            const { data } = response;
            if (data && data.message) {
              message.error(data.message);
            } else {
              message.error("Unable to accept your confirmation");
            }
          } else {
            message.error("Unable to accept your confirmation");
          }
          history.push(`/dashboard/physician/caselist`);
        });
    }

    if (formData.confirm === "no") {
      if (formData.isMissed) {
        const data = {
          caseId: roomName,
          isWheel: isWheel(),
        };
        setloading(true);
        PhysciainApi.rescheduleConsultation(data)
          .then((res) => {
            if (res.data.success === true) {
              message.success("Successfully updated case status");
              handleLogout();
              window.localStorage.removeItem("roomToken");
              window.localStorage.removeItem("roomName");
              window.localStorage.removeItem("roomPatientName");
              window.localStorage.removeItem("roomPhyscianName");
              window.localStorage.removeItem("roomConsultationType");
              setVisible(false);
              setloading(false);
              history.push(`/dashboard/physician/caselist`);
            }
          })
          .catch((error) => {
            const { response } = error;
            setloading(false);
            handleLogout();
            window.localStorage.removeItem("roomToken");
            window.localStorage.removeItem("roomName");
            window.localStorage.removeItem("roomPatientName");
            window.localStorage.removeItem("roomPhyscianName");
            window.localStorage.removeItem("roomConsultationType");
            if (response) {
              const { data } = response;
              if (data && data.message) {
                message.error(data.message);
              } else {
                message.error("Unable to accept your confirmation");
              }
            } else {
              message.error("Unable to accept your confirmation");
            }
            history.push(`/dashboard/physician/caselist`);
          });
      }
      else {
        clearRoomData();
        history.push('/dashboard/physician/caselist');
      }
    }

    if (formData.confirm === undefined) {
      if (window.localStorage.getItem("caseId") !== null) {
        handleLogout();
        window.localStorage.removeItem("roomToken");
        window.localStorage.removeItem("roomName");
        window.localStorage.removeItem("roomPatientName");
        window.localStorage.removeItem("roomPhyscianName");
        window.localStorage.removeItem("roomConsultationType");
        history.push(`/case/scheduled-call/${roomName}`);
      } else {
        message.info("please select the options");
      }
    }
  };

  function handleconfirm() {
    setVisible(true);
  }

  const remoteParticipants = participants.map(
    (participant, index) =>
      index === 0 && (
        <Participant
          key={participant.sid}
          participant={participant}
          mute={false}
          type={consulationType}
          name={remotename}
        />
      )
  );

  return (
    <div className="room">
      <div className="room-video-part">
        <div className="remote-participant">{remoteParticipants}</div>
        <div className="local-participant">
          {room ? (
            <Participant
              key={room.localParticipant.sid}
              participant={room.localParticipant}
              mute={mute}
              type={consulationType}
              name={localname}
            />
          ) : (
            ""
          )}
        </div>
      </div>
      <div className="room-video-button-part">
        <Tooltip title="Leave Room">
          <PhoneFilled
            className="room-leave-room"
            rotate={225}
            onClick={() => {
              handleconfirm();
            }}
          />
        </Tooltip>
        <Modal
          visible={visible}
          onCancel={() => {
            setVisible(false);
          }}
          okText={"Leave Room"}
          onOk={onOkClick}
        >
          <Spin spinning={loading}>
            <h3>
              <ExclamationCircleOutlined /> Are You sure to leave Room?
            </h3>
            {window.localStorage.getItem("caseId") === null && (
              <Form
                layout="vertical"
                form={form}
                size="large"
                style={{ width: "60%" }}
              >
                <Form.Item
                  name="confirm"
                  label="Completed your Consultation"
                  className="form-confirm-radio"
                >
                  <Radio.Group>
                    <Radio.Button value="yes">Yes</Radio.Button>
                    <Radio.Button value="no">No</Radio.Button>
                  </Radio.Group>
                </Form.Item>
                <Form.Item name='isMissed' valuePropName='checked'>
                  <Checkbox>Missed Schedule?</Checkbox>
                </Form.Item>
              </Form>
            )}
          </Spin>
        </Modal>
        {internalMute ? (
          <Tooltip title="Unmute">
            <AudioMutedOutlined
              className="room-audio-cut"
              onClick={() => {
                setMuted("unmute");
                setInternalMute(false);
              }}
            />
          </Tooltip>
        ) : (
          <Tooltip title="Mute">
            <AudioOutlined
              className="room-audio-open"
              onClick={() => {
                setMuted("mute");
                setInternalMute(true);
              }}
            />
          </Tooltip>
        )}

        {window.localStorage.getItem("caseId") === null && (
          <Tooltip title="Case Details">
            <FileTextOutlined
              className="room-case-details"
              onClick={() => setCaseDetailsDrawerOpen(true)}
            />
          </Tooltip>
        )}
      </div>

      {!isPatient && <CaseDetailsDraw caseId={caseId} caseDetailsDrawCollapse={caseDetailsDrawerOpen} handleCaseDetailsDrawClose={() => setCaseDetailsDrawerOpen(false)} />}
    </div>
  );
};

export default Room;

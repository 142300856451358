import dayjs from 'dayjs'
import localeData from 'dayjs/plugin/localeData'
import weekday from 'dayjs/plugin/weekday'

dayjs.extend(weekday)
dayjs.extend(localeData)

export const DEFAULT_PRESCRIPTION = {
    startDate: dayjs(),
    substitutions: false,
    isUrgent: false,
    valid: false
};

export const RESET_VALUES = {
    drugName: null,
    strength: null,
    refills: 0,
    daysSupply: null,
    quantity: 0,
    sig: null,
    pharmacyNotes: null,
    providePharmacyNotes: false,
    noSubstitutions: false,
    isUrgent: false,
    valid: false
}
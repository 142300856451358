import React, { createContext, useContext, useRef, useEffect } from 'react';
import markerSDK from '@marker.io/browser';
import config from '../config';

export const MarkerContext = createContext(null);

export const useMarkerWidget = () => {
  return useContext(MarkerContext);
}

export const MarkerProvider = ({ children }) => {
  const markerWidgetRef = useRef(null);

  const getMarkerWidget = () => markerWidgetRef.current;
  const setMarkerMetadata = (metadata={}) => markerWidgetRef.current?.setCustomData({ currentSHA: config.LATEST_SHA, ...metadata });

  useEffect(() => {
    if (!markerWidgetRef.current && config.MARKER_PROJECT_ID) {
      markerSDK.loadWidget({ 
        project: config.MARKER_PROJECT_ID,
        customData: {
          currentSHA: config.LATEST_SHA
        }
      })
        .then((widget) => markerWidgetRef.current = widget);
    }
  }, []);

  return (
    <MarkerContext.Provider value={{ getMarkerWidget, setMarkerMetadata }}>
      {children}
    </MarkerContext.Provider>
  )
}
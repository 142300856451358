//display the case allocated to physician

import React from "react";
import { Button, Form, Input, Modal } from "antd";
import { FilterFilled, CloseOutlined } from "@ant-design/icons";
import FilterForm from "./FilterForm"
import "./SearchAndFilterCases.css";

const { Search } = Input;

const SearchAndFilterCases = (props) => {
  const [showClear, setShowClear] = React.useState(false);
  const [form] = Form.useForm();
  const {
    searchCase,
    showModal,
    setShowModal,
    handleCancelCross,
    handleCancel,
    handleOk,
    telemedicines,
    states,
    hideFields,
  } = props;

  const handleFilter = () => {
    const data = form.getFieldValue();
    handleOk(data);
    setShowClear(true);
  };

  const handleClear = () => {
    form.resetFields();
    handleCancel();
    setShowClear(false);
  };

  return (
    <div>
      <p className="search-filter-title">Search & Filter</p>
      <div className="search-filter-row">
        <Search
          placeholder="Search case by name"
          size="large"
          allowClear
          onSearch={searchCase}
          style={{ width: 350 }}
        />
        {showClear &&
          <Button type="secondary" style={{ height: "40px" }} onClick={handleClear}>
            <CloseOutlined />
          </Button>
        }
        <Button type="primary" style={{ height: "40px" }} onClick={() => setShowModal(true)}>
          <FilterFilled />
        </Button>

        <Modal
          width="50%"
          visible={showModal}
          onOk={handleOk}
          onCancel={handleCancelCross}
          title="Filter By"
          footer={[
            <Button key="back" onClick={handleClear}>
              Clear
            </Button>,
            <Button key="submit" type="primary" onClick={handleFilter}>
              Filter
            </Button>,
          ]}
        >
          <FilterForm
            form={form}
            telemedicines={telemedicines}
            states={states}
            hideFields={hideFields}
          />
        </Modal>
      </div>
    </div>
  );
};

export default SearchAndFilterCases;

import API from '../../axios/prescription/PrescriptionsApi'
import types from './types'

const setRxRequests = payload => ({
    type: types.SET_RX_REQUESTS,
    payload
})

const setRxErrors = payload => ({
    type: types.SET_RX_ERRORS,
    payload
})

const setIsLoading = payload => ({
    type: types.SET_IS_LOADING,
    payload
})

function getRxRequestsAndErrors() {
    return async dispatch => {
        dispatch(setIsLoading(true))
        try {
            const rxRequestResponse = await API.getRxRequests()
            dispatch(setRxRequests(rxRequestResponse?.data?.requests))
            const rxErrorResponse = await API.getRxErrors()
            dispatch(setRxErrors(rxErrorResponse?.data?.errors))
        } catch (e) {
            console.log(e)
        } finally {
            dispatch(setIsLoading(false))
        }
    };
}

const actions = {
    getRxRequestsAndErrors
};

export default actions;
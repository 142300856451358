/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { Button, Col, message, Row, Tooltip } from "antd";
import { PaperClipOutlined, PlusOutlined, SendOutlined } from "@ant-design/icons";
import { AntTinyWrapper } from '../../components/AntTinyWrapper';
import moment from "moment";
import styled from 'styled-components'

//import components
import MessageComponent from "../MessageComponent/MessageComponent";
import MessageTemplateButton from "./MessageTemplateButton";
import PhysicianAPI from '../../axios/physician/PhysicianApi'
import { useRequestWithDisclaimer } from "../../hooks/useRequestWithDisclaimer";

const ButtonGroup = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`

const MessageListingComponent = ({
  roomId,
  authorId,
  sendMessage,
  messages,
  allowImageUpload,
  handleImageUpload,
  handleMessageView,
  isPatient,
  isDifferentPlatform,
  className,
  handleLoadMore,
  pageNumber,
  caseId
}) => {
  const [messageEntered, setMessageEntered] = useState("");
  const [physicianDetails, setPhysicianDetails] = useState({});
  const [caseDetails, setCaseDetails] = useState({});
  const [scrollHeight, setScrollHeight] = useState();
  const messageListingWrapperRef = useRef(null);

  // handling message sending
  const handleMessageSendButtonClick = (event) => {
    event.stopPropagation();
    if (messageEntered) {
      const data = {};
      data.chatRoomId = roomId;
      data.author = authorId;
      data.isPatient = isPatient;
      data.isWheel = isDifferentPlatform;
      data.message = messageEntered;
      data.type = "text";
      data.key = null;
      sendMessage(data);
      setMessageEntered("");
    } else {
      return false;
    }
  };

  const handleMessageClick = (imageUrl) => {
    window.open(imageUrl, "_blank");
  };
  const handleMessageCreatedInDays = (value) => {
    if (value) {
      var createdDate = moment(
        moment(value).format("DD.MM.YYYY"),
        "DD.MM.YYYY"
      );
      var todaysDate = moment(moment().format("DD.MM.YYYY"), "DD.MM.YYYY");

      var result = todaysDate.diff(createdDate, "days");
      if (result === 0) {
        return "Today";
      } else if (result === 1) {
        return result +" day ago";
      } else return result +" days ago";
    } else {
      return "";
    }
  };
  const handleTextAreaChange = (value) => {
    setMessageEntered(value);
  };

  const handleTextAreaFocus = (value) => {
    handleMessageView(value);
  };

  const handleAttachmentButtonClick = (event) => {
    event.stopPropagation();
    handleImageUpload();
  };
  const scrollToBottom = () => {
    messageListingWrapperRef.current.scrollTop =
      messageListingWrapperRef.current.scrollHeight;
    setScrollHeight(messageListingWrapperRef.current.scrollHeight);
  };
  const scrollToTopSection = () => {
    messageListingWrapperRef.current.scrollTop =
      messageListingWrapperRef.current.scrollHeight - scrollHeight;
    setScrollHeight(messageListingWrapperRef.current.scrollHeight);
  };

  const fetchCaseDetails = useRequestWithDisclaimer({
    initiateRequest: () => PhysicianAPI.getCaseDetailsById(caseId),
    onSuccess: (res) => setCaseDetails({ _id: caseId, ...res.data.payload }),
    onError: (err) => message.error('Unable to fetch case data'),
    checkNeedsDisclaimer: () => false
  })

  useEffect(() => {
    if (!pageNumber || pageNumber === 1) {
      scrollToBottom();
    } else {
      scrollToTopSection();
    }
  }, [messages]);

  useEffect(() => {
    PhysicianAPI.physicianData()
      .then(res => setPhysicianDetails(res.data.payload))
      .catch(() => message.error('Unable to fetch physician data'));

    fetchCaseDetails();
  }, [])

  return (
    <>
      <div
        ref={messageListingWrapperRef}
        className={`message-list-messaging ${className}`}
      >
        { messages?.totalCount > messages.messages?.length && (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button
              type="primary"
              size="large"
              style={{
                padding: "8px",
                display: "block",
                cursor: "pointer",
              }}
              onClick={handleLoadMore}
            >
              <PlusOutlined /> Load More Messages
            </Button>
          </div>
        )}
        {!!messages.messages?.length ? (
          <>
            {messages.messages.map((eachMessage) => (
              <MessageComponent
                key={eachMessage._id}
                isPatient={eachMessage.isPatient}
                isAuthor={eachMessage.author === physicianDetails._id}
                avatarUrl={
                  messages.imageRefrence[eachMessage.author]
                    ? messages.imageRefrence[eachMessage.author]
                    : null
                }
                name={
                  eachMessage.author === physicianDetails._id
                    ? "You"
                    : messages.nameRefrence[eachMessage.author]
                }
                time={
                  eachMessage.created
                    ? moment(eachMessage.created).format("LLL")
                    : moment().format("LLL")
                }
                messageSendInDays={handleMessageCreatedInDays(
                  eachMessage.created
                )}
                messageText={eachMessage.message}
                imageUrl={eachMessage.key ? eachMessage.key : null}
                handleMessageClick={handleMessageClick}
              />
            ))}
          </>
        ) : (
          <div className="no-message-section">
            <span>No messages yet</span>
          </div>
        )}
      </div>
        <>
          <Row>
            <Col span={24}>
              <AntTinyWrapper
                value={messageEntered}
                onChange={handleTextAreaChange}
                onFocus={handleTextAreaFocus}
                onKeyDown={event => {
                  if (!event.shiftKey && event.key === 'Enter') {
                    event.preventDefault();
                    handleMessageSendButtonClick(event)
                  }
                }}
              />
            </Col>
          </Row>
          <Row>
            <ButtonGroup>
              <div>
                <MessageTemplateButton 
                  caseDetails={caseDetails} 
                  physicianDetails={physicianDetails}
                  setText={setMessageEntered} 
                    />
                {allowImageUpload && (
                    <Tooltip placement="bottomLeft" title="Attach Image">
                      <Button
                        type="secondary"
                        className="chat-message-button"
                        onClick={handleAttachmentButtonClick}
                        icon={<PaperClipOutlined />}
                      >Attachment</Button>
                    </Tooltip>
                )}
              </div>
              <Tooltip placement="topLeft" title="Send Message">
                <Button
                  type="primary"
                  className="chat-message-button"
                  onClick={handleMessageSendButtonClick}
                  icon={<SendOutlined />}
                >Send</Button>
              </Tooltip>
            </ButtonGroup>
          </Row>
        </>
    </>
  );
};
/*
*/

export default MessageListingComponent;

import React from 'react'
import { Form } from 'antd'

import {
    CheckCircleOutlined,
    CloseCircleOutlined,
    QuestionCircleOutlined,
    ClearOutlined
} from '@ant-design/icons'

import { VeritasSpinner } from '../../../../../../components/VeritasSpinner'
import { showVariableList } from './showVariableList'
import { StyledButton } from '../../../Button';

import {
    StyledModal,
    ModalTitle,
} from './styles'

import {
    ModalHeaderContainer,
    ChartingEntryFormItem,
    Title,
    StyledSelect,
    StyledTextArea,
    CompiledChartingEntry,
    ButtonGroup,
    ButtonGroupRight
} from './ChartingEntry.Modal.styles'

export const ChartingEntryModal = ({
    selectedItem,
    modalMode,
    onCancel,
    chartingState,
    selectTemplate,
    selectPrescription,
    resetChartingEntry,
    handleTextChange,
    editChartingEntry,
    addChartingEntry
}) => {
    const [form] = Form.useForm()

    const handleSubmit = async () => {
        const submitFn = !!selectedItem
            ? editChartingEntry
            : addChartingEntry

        const payload = { note: chartingState.compiledEntry }

        if (!!selectedItem) {
            payload._id = selectedItem._id
        }

        submitFn(payload)
    }

    const handleResetChartingEntry = () => {
        selectTemplate(null)
        resetChartingEntry()
    }

    const handleCancel = () => {
        handleResetChartingEntry()
        onCancel()
    }

    const queuedPrescriptionOptions = Object.values(chartingState.queuedPrescriptions).map(prescription => ({
        value: prescription._id,
        label: prescription.name
    }))

    const ModalHeader = () => {
        if (!modalMode) return null

        return (
            <ModalHeaderContainer>
                <ModalTitle>
                    {modalMode === 'add'
                        ? 'Add Charting Entry'
                        : 'Edit Charting Entry'
                    }
                </ModalTitle>
                <StyledButton
                    type="button"
                    size="medium"
                    Icon={ClearOutlined}
                    tooltip="Clear Note"
                    onClick={resetChartingEntry}
                />
            </ModalHeaderContainer>
        )
    }

    return (
        <StyledModal
            centered
            width={800}
            visible={!!modalMode}
            title={<ModalHeader />}
            closable={false}
            footer={null}
        >
            <VeritasSpinner spinning={chartingState.loading}>
                <Form
                    form={form}
                    onFinish={handleSubmit}
                >
                    <ChartingEntryFormItem>
                        <Title>Selected Prescription</Title>
                        <StyledSelect
                            placeholder="Select a prescription to templatize"
                            options={queuedPrescriptionOptions}
                            value={chartingState.selectedPrescription}
                            onSelect={selectPrescription}
                        />
                    </ChartingEntryFormItem>

                    <ChartingEntryFormItem>
                        <Title>Template Name</Title>
                        <StyledSelect
                            placeholder="Select a template"
                            options={chartingState.templates.map(template => ({
                                value: template.message,
                                label: template.title
                            }))}
                            value={chartingState.selectedTemplate}
                            onSelect={selectTemplate}
                        />
                    </ChartingEntryFormItem>

                    <ChartingEntryFormItem>
                        <Title>Template</Title>
                        <StyledTextArea 
                            autoSize={{ minRows: 3 }}
                            value={chartingState.rawEntry} 
                            onChange={handleTextChange} 
                        />
                    </ChartingEntryFormItem>

                    <ChartingEntryFormItem>
                        <Title>Rendered message</Title>
                        <CompiledChartingEntry>
                            {chartingState.compiledEntry}
                        </CompiledChartingEntry>
                    </ChartingEntryFormItem>

                    <ButtonGroup>
                        <StyledButton
                            type="button"
                            size="medium"
                            Icon={QuestionCircleOutlined}
                            tooltip="Template Variables"
                            onClick={showVariableList}
                        />
                        <ButtonGroupRight>
                            <StyledButton
                                type="button"
                                size="medium"
                                Icon={CloseCircleOutlined}
                                tooltip="Cancel"
                                color="red"
                                onClick={handleCancel}
                            />
                            <StyledButton
                                type="submit"
                                size="medium"
                                Icon={CheckCircleOutlined}
                                tooltip="Submit"
                            />
                        </ButtonGroupRight>
                    </ButtonGroup>
                </Form>
            </VeritasSpinner>
        </StyledModal>
    )
}
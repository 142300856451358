import React from "react";

const DashBoardIcon = () => {
  return (
    <span style={{ marginRight: 10 }} className="icon-div">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1rem"
        height="1rem"
        viewBox="0 0 24.46 24.46"
      >
        <defs></defs>
        <title>Asset 8</title>
        <g>
          <path d="M9.43,8.15H1.78A1.79,1.79,0,0,1,0,6.37V1.78A1.79,1.79,0,0,1,1.78,0H9.43a1.79,1.79,0,0,1,1.78,1.78V6.37A1.79,1.79,0,0,1,9.43,8.15ZM1.78,1.53a.25.25,0,0,0-.25.25V6.37a.26.26,0,0,0,.25.25H9.43a.26.26,0,0,0,.25-.25V1.78a.26.26,0,0,0-.25-.25Z" />
          <path d="M9.43,24.46H1.78A1.79,1.79,0,0,1,0,22.68V12a1.79,1.79,0,0,1,1.78-1.78H9.43A1.79,1.79,0,0,1,11.21,12v10.7A1.79,1.79,0,0,1,9.43,24.46ZM1.78,11.72a.26.26,0,0,0-.25.25v10.7a.25.25,0,0,0,.25.25H9.43a.26.26,0,0,0,.25-.25V12a.26.26,0,0,0-.25-.25Z" />
          <path d="M22.68,24.46H15a1.79,1.79,0,0,1-1.78-1.78V18.09A1.79,1.79,0,0,1,15,16.31h7.64a1.79,1.79,0,0,1,1.78,1.78v4.59A1.79,1.79,0,0,1,22.68,24.46ZM15,17.84a.26.26,0,0,0-.25.25v4.59a.26.26,0,0,0,.25.25h7.64a.25.25,0,0,0,.25-.25V18.09a.26.26,0,0,0-.25-.25Z" />
          <path d="M22.68,14.27H15a1.79,1.79,0,0,1-1.78-1.78V1.78A1.79,1.79,0,0,1,15,0h7.64a1.79,1.79,0,0,1,1.78,1.78v10.7A1.79,1.79,0,0,1,22.68,14.27ZM15,1.53a.26.26,0,0,0-.25.25v10.7a.26.26,0,0,0,.25.25h7.64a.26.26,0,0,0,.25-.25V1.78a.25.25,0,0,0-.25-.25Z" />
        </g>
      </svg>
    </span>
  );
};

export default DashBoardIcon;

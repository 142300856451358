import types from "./types"

const initState = {
    MessageCount: 0,
    chatCaseId: "",
};

export default function message(state = initState, action) {
    switch (action.type) {
        case types.SET_MESSAGE_COUNT:
            return {
                ...state,
                MessageCount: action.payload,
            };

        case types.SET_CHAT_CASE_ID:
            return {
                ...state,
                chatCaseId: action.id,
            };
        default:
            return state;
    }
};


import React from 'react'

import { Modal } from 'antd'

import { TreatmentForm } from './TreatmentForm'

import { TreatmentFormContainer } from './Treatment.Modal.styles'

export const TreatmentModal = ({
    deselectPrescription,
    mode
}) => {

    const formTitle = mode === 'add' ? 'Add New Treatment' : 'Edit Treatment'
    
    return (
       <Modal
            centered
            title={formTitle}
            visible={!!mode}
            onCancel={deselectPrescription}
            footer={null}
       >

            <TreatmentFormContainer>
                <TreatmentForm 
                    mode={mode}
                    deselectPrescription={deselectPrescription}
                />
            </TreatmentFormContainer>
       </Modal>
    )
}
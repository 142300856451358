import types from "./types";
import handleAPIErrorResponse from "../../utils/handleAPIResponseError";
import API from "../../axios/physician/PhysicianApi";

import { message } from "antd";

const setScheduledCaseCountLoading = (status) => ({
    type: types.SET_SCHEDULED_CASE_COUNT_LOADING,
    data: status,
});

const setScheduledCaseListLoading = (status) => ({
    type: types.SET_SCHEDULED_CASE_LIST_LOADING,
    data: status,
});

const setScheduledCaseList = (payload) => ({
    type: types.SET_SCHEDULED_CASE_LIST,
    payload
})

const setScheduledCaseCount = (payload) => ({
    type: types.SET_SCHEDULED_CASE_COUNT,
    payload
})

function getScheduledCaseList() {
    return (dispatch) => {
        dispatch(setScheduledCaseListLoading(true));
        API.getScheduledList().then(
            (res) => {
                const { data } = res;
                if (data && data.success) {
                    const { payload } = data;
                    if (payload) {
                        dispatch(setScheduledCaseList(payload))
                    }
                    message.success("Scheduled Cases List has been updated");
                } else {
                    message.warning("Unable to update scheduled cases");
                }
                dispatch(setScheduledCaseListLoading(false));
            }).catch(
                (error) => {
                    handleAPIErrorResponse(error, "Error occured while updating scheduled cases");
                    dispatch(setScheduledCaseListLoading(false));
                });
    };
}

function getScheduledCaseCount(status) {
    return (dispatch) => {
        dispatch(setScheduledCaseCountLoading(true));
        API.getCaseCount(status).then(
            (res) => {
                const { data } = res;
                if (data && data.success) {
                    const { payload } = data;
                    if (payload) {
                        const { caseCount } = payload;
                        dispatch(setScheduledCaseCount(caseCount))
                    }
                } else {
                    message.warning("Unable to update scheduled cases");
                }
                dispatch(setScheduledCaseCountLoading(false));
            }).catch(
                (error) => {
                    handleAPIErrorResponse(error, "Error occured while updating scheduled cases");
                    dispatch(setScheduledCaseCountLoading(false));
                });
    };
}

const actions ={
    setScheduledCaseCountLoading,
    setScheduledCaseListLoading,
    setScheduledCaseList,
    setScheduledCaseCount,
    getScheduledCaseList,
    getScheduledCaseCount,
};

export default actions; 
import React from 'react'
import { Button } from 'antd'
import { UndoOutlined } from '@ant-design/icons';

const ClearPrefilledForm = ({ handleClick }) => (
    <div style={{ display: 'flex', justifyContent: 'space-between', marginLeft: 'auto' }}>
        <Button
            size='small'
            style={{ display: 'inline', marginLeft: 'auto', marginBottom: 'none' }}
            icon={<UndoOutlined />}
            onClick={handleClick}
        >
            Clear
        </Button>
    </div>
)

export default ClearPrefilledForm
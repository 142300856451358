import types from "./types";
import API from "../../axios/apiHelper";
import { history } from "../../helpers";
import { message } from "antd";

function setRegisterRequest(user) {
    return { type: types.REGISTER_REQUEST, user };
}
function setRegisterSuccess(user) {
    return { type: types.REGISTER_SUCCESS, user };
}
function setRegisterFailure(error) {
    return { type: types.REGISTER_FAILURE, error };
}

function physicianRegister(user) {
    return (dispatch) => {
        dispatch(setRegisterRequest(user));

        API.physicianSignUp(user).then(
            (user) => {
                const { data } = user;
                if (data && data.status) {
                    dispatch(setRegisterSuccess());
                    history.push("/");
                    message.success("Registration completed successfully");
                } else {
                    if (data && data.message) {
                        message.error(data.message);
                    } else {
                        message.error("Physician Registration Failed. Please try again!");
                    }
                }
            },
            (error) => {
                const { response } = error;
                if (response && response.status) {
                    if (response.status === 409) {
                        message.error(response.data.message);
                    } else {
                        const { data } = response;
                        if (data && data.message) {
                            message.error(data.message);
                        } else {
                            message.error("Physician Registration Failed. Please try again!");
                        }
                    }
                } else {
                    message.error("Physician Registration Failed. Please try again!");
                }
                dispatch(setRegisterFailure(error));
            }
        )
    };
}

const actions = {
    physicianRegister,
    setRegisterSuccess,
    setRegisterRequest,
    setRegisterFailure,
};

export default actions;
import { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { message } from 'antd'

import TicketAPI from '../../../../../axios/TicketApi'
import caseTickets from '../../../../../redux/caseTickets'

export const useTickets = ({
    caseDetails,
    setMessages
}) => {
    const dispatch = useDispatch()
    const [adminMessages, setAdminMessages] = useState({
        list: [],
        count: 0,
    });

    const fetchMessages = useCallback(async () => {
        if (!caseDetails?.caseId) return

        setAdminMessages({
            list: [],
            count: 0
        })

        try {
            const { data } = await TicketAPI.getTicketChat({
                caseId: caseDetails.caseId
            })

            if (!data.success || !data.payload) {
                throw new Error('Error fetching admin support chat')
            }

            const { payload } = data;

            const unreadCount = payload.data?.filter(message => !message.readBy?.includes('physician')).length
            const timestampMessageMapping = payload.data.map(message => ({ ...message, created: message.createAt }))
            const list = timestampMessageMapping.reverse()

            setAdminMessages({
                list,
                count: unreadCount
            })
            return list
        } catch (e) {
            message.error(e.response?.data?.message || e.message)
        }
    }, [caseDetails?.caseId])

    const sendAdminChatMessage = async message => {

        if (!caseDetails) return

        try {
            const payload = {
                caseId: caseDetails.caseId,
                message,
            }

            const { data } = await TicketAPI.sendChatMessage(payload)

            if (!data.success) {
                throw new Error("Error sending new admin support message")
            }
            fetchMessages().then((list) => {
                list && setMessages({
                    list: list,
                    loading: false
                })
            })

            dispatch(caseTickets.actions.getCaseTicketNotificationCount())
        } catch (e) {
            message.error(e.reponse?.data?.message || e.message)
        }
    }

    useEffect(() => {
        fetchMessages()
    }, [caseDetails?.status, fetchMessages])

    return {
        adminMessages,
        fetchMessages,
        sendAdminChatMessage
    }
}

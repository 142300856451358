import React, { useState } from "react";
import { bindActionCreators } from 'redux'
import { connect } from "react-redux";

import referrals from "../../../../redux/referrals";

import { AntTinyWrapper } from "../../../../components/AntTinyWrapper";

import { Modal, message } from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";

const CaseReviewRejectionModal = ({
    caseId,
    fetchCaseDetails,
    caseReviewModalVisible,
    actions
}) => {

    const [reviewNotes, setReviewNotes] = useState('')

    const handleRejectReview = async () => {
        try {
          await actions.rejectReferralCase(caseId, reviewNotes)
          fetchCaseDetails()
        } catch (e) {
          message.error(e.message);
        }
      }

    return (
        <Modal
            title={<><CloseCircleOutlined style={{ color: 'red', marginRight: '5px'}}/>Return Case To Physician</>}
            visible={caseReviewModalVisible}
            onOk={handleRejectReview}
            okText="Return to Physician"
            okType="danger"
            okButtonProps={{
                disabled: reviewNotes.length === 0
            }}
            onCancel={() => actions.setCaseReviewModalVisible(false)}
            >
            <AntTinyWrapper
                value={reviewNotes}
                onChange={setReviewNotes}
                placeholder="Reasoning as to why this case should be Rx'd..."
            />
        </Modal>     
    )
}

const mapStateToProps = state => {
    const {
        caseReviewModalVisible
    } = state.referrals

    return {
        caseReviewModalVisible
    }
}

const mapDispatchToProps = dispatch => {
    const {
        approveReferralCase,
        rejectReferralCase,
        setCaseReviewModalVisible
    } = referrals.actions


    return {
        actions: bindActionCreators(
            {
                approveReferralCase,
                rejectReferralCase,
                setCaseReviewModalVisible
            },
            dispatch
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CaseReviewRejectionModal);
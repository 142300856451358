import types from "./types"

const initState = {
    rxChangeCaseListLoading: false,
    rxChangeCaseCountLoading: false,
    rxChangeCaseList: [],
    rxChangeCaseCount: 0,
};

export default function rxChange(
    state = initState,
    action,
) {
    switch (action.type) {
        case types.SET_RX_CHANGE_CASE_LIST_LOADING:
            return {
                ...state,
                rxChangeCaseListLoading: action.data,
            };
        case types.SET_RX_CHANGE_CASE_COUNT_LOADING:
            return {
                ...state,
                rxChangeCaseCountLoading: action.data,
            };
        case types.SET_RX_CHANGE_CASE_LIST:
            return {
                ...state,
                rxChangeCaseList: action.payload,
            };
        case types.SET_RX_CHANGE_CASE_COUNT:
            return {
                ...state,
                rxChangeCaseCount: action.payload,
            };
        default:
            return state;
    }
}
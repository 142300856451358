//display the case allocated to physician

import React, { useEffect } from "react";
import { Card, Button, Spin, Typography, Row, Col, Form } from "antd";
import {
  VideoCameraFilled,
  AudioFilled,
  LoadingOutlined,
} from "@ant-design/icons";

import { connect } from "react-redux";
import moment from "moment";

//import CSS
import "./WaitingRoom.css";

//import function
import { history } from "../../helpers";
import { userConstants } from "../../constants";
import { dateTimeFormatter, timeFormatter } from "../../utils/dateFormatter";
import actions from '../../redux/waitingRoom/actions';

const { Title, Text } = Typography;

const WaitingRoom = (props) => {
  const { joinedWaitingRoomCaseList, loading, handleCallStart, getWaitingRoomCaseList } = props;

  const onStartCallClick = (event, caseId) => {
    event.stopPropagation();
    handleCallStart(caseId);
  };

  const handleCaseDetailsClick = (event, caseId) => {
    event.stopPropagation();
    history.push(
      `/dashboard/${userConstants.USER_PHYSICIAN}/caseview/${caseId}`
    );
  };

  useEffect(() => {
    getWaitingRoomCaseList();
    // eslint-disable-next-line
  }, [])

  return (
    <div className="waiting-room-case-list">
      <Title level={1}>Waiting Room</Title>
      <Spin
        size="large"
        spinning={loading}
        indicator={<LoadingOutlined spin />}
      >
        <Row>
          {joinedWaitingRoomCaseList.length ? (
            joinedWaitingRoomCaseList.map((item, index) => {
              return (
                <Col
                  className="waiting-room-case-list-card-wrapper"
                  span={24}
                  key={`caes-col-${item.caseId}-${index}`}
                >
                  <Card
                    className="waiting-room-case-list-card"
                    key={`case-card-${item.caseId}-${index}`}
                  >
                    <Form name="waiting-case-details">
                      <Row>
                        <Col span={24}>
                          <Title
                            level={4}
                          >{`Patient : ${item.patientName}`}</Title>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={24} xl={12}>
                          <Form.Item label="Case Id">
                            <Text strong>{item.caseId}</Text>
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={24} xl={12}>
                          <Form.Item label="Created At">
                            <Text strong>
                              {moment(item.createdDate).format("LLLL")}
                            </Text>
                          </Form.Item>{" "}
                        </Col>
                        <Col span={24}>
                          <Form.Item label="Scheduled Time">
                            <Text strong>
                              {item.consultationType === "audio" ? (
                                <AudioFilled />
                              ) : (
                                <VideoCameraFilled />
                              )}{" "}
                              {item.startTime
                                ? `${dateTimeFormatter(
                                    item.startTime
                                  )} - ${timeFormatter(item.startTime)}`
                                : ""}{" "}
                              -{item.endTime ? timeFormatter(item.endTime) : ""}
                            </Text>
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row justify="end">
                        <Col>
                          <Button
                            type="primary"
                            size="large"
                            className="waiting-room-case-list-card-button"
                            onClick={(event) =>
                              handleCaseDetailsClick(event, item.caseId)
                            }
                          >
                            View Details
                          </Button>
                        </Col>
                        <Col>
                          <Button
                            type="primary"
                            size="large"
                            className="waiting-room-case-list-card-button"
                            onClick={(event) =>
                              onStartCallClick(event, item.caseId)
                            }
                          >
                            Start Call
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                  </Card>
                </Col>
              );
            })
          ) : (
            <Col className="align-text-center" span={24}>
              <Text strong>No cases in waiting room</Text>
            </Col>
          )}
        </Row>
      </Spin>
    </div>
  );
};

function mapStateToProps(state) {
  const { caseListloading, joinedWaitingRoomCaseList } = state.waitingRoom;
  return { loading: caseListloading, joinedWaitingRoomCaseList };
}

function mapDispatchToProps(dispatch) {
  return {
    getWaitingRoomCaseList: () => dispatch(actions.getWaitingRoomCaseList({ status: 'waiting room' }))
  } 
}

export default connect(mapStateToProps, mapDispatchToProps)(WaitingRoom);

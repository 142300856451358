/* eslint-disable no-template-curly-in-string */
/* eslint-disable react-hooks/exhaustive-deps */
//import packages
import React from "react";
import { Typography, Row, Col } from "antd";

import "./CasePhotoAndIdViewComponent.css";

//import APIs and Functions
import isValidUrl from "../../utils/isValidURL";

const { Text } = Typography;

const CasePhotoAndIdViewComponent = ({ title, facePhotoUrl, photoIdUrl }) => {
  return (
    <section className="image-view">
      <h3 className="sub-title-text-color">
        <b>{title}</b>
      </h3>
      <Row>
        <Col className="image-wrapper-col">
          {isValidUrl(facePhotoUrl) === true ? (
            <img
              src={facePhotoUrl}
              alt="patient face"
              className="image-photo height-auto"
            />
          ) : (
            <Text strong className="no-image-text">
              No photo Available
            </Text>
          )}
        </Col>
        <Col className="image-wrapper-col">
          {isValidUrl(photoIdUrl) === true ? (
            <Col>
              <img
                src={photoIdUrl}
                alt="patient id"
                className="image-photo-id height-auto"
              />
            </Col>
          ) : (
            <Text strong className="no-image-text">
              No Id Proof Available
            </Text>
          )}
        </Col>
      </Row>
    </section>
  );
};

export default CasePhotoAndIdViewComponent;

import React from 'react';
import styled from 'styled-components';
import { Spin } from 'antd'

export const StyledTableWrapper = styled.div`
  background-color: #ffffff;
  min-height: 80vh;
  height: 100%;
  margin: 24px auto;
  padding: 24px 28px;
  @media (max-width: 1200px) {
    max-width: 720px;
  }
  @media (max-width: 788px), (max-width: 922px) {
    max-width: 580px;
  }
  @media (max-width: 720px) {
    max-width: 320px;
  }
`;

export const StyledFiltersSection = styled.section`
  display: flex;
  justify-content: space-between; 
`;

const StyledLabel = styled.label`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 15px;

  &.span {
    margin-right: 20px;
  }
`;

export const Label = ({ label, children, ...props }) => {
  return (
    <StyledLabel {...props}>
      <span>{label}</span>
      {children}
    </StyledLabel>
  )
}

export const StyledSpin = styled(Spin)`
  height: 100%;
`

import React, { memo } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { Form, Select } from 'antd'

import prescribeMedicine from '../../../../../../redux/prescribeMedicine'
import { useDispenseUnitSelector } from './useDispenseUnitSelector'
import { usePrescriptionFormContext } from '../../context/prescriptionFormContext'

const DispenseUnitSelector = ({
    prescription,
    selectedStrength,
    dispenseUnits,
    handleFieldsChange,
    actions
}) => {
    const { form } = usePrescriptionFormContext()
    const { options } = useDispenseUnitSelector({
        form,
        prescription,
        dispenseUnits,
        selectedStrength,
        ...actions
    })

    const handleChange = quantityUnits => {
        if (prescription._id) {
            const dispenseUnit = dispenseUnits.find(unit => unit.StandardDispenseUnitTypeID.toString() === quantityUnits.toString())
            actions.saveQueuedPrescription({ _id: prescription._id, quantityUnits, doseForm: dispenseUnit.Name, dispenseUnitId: dispenseUnit.StandardDispenseUnitTypeID })
            handleFieldsChange()
        }
    }

    return (
        <Form.Item
            required
            label="Dispense Unit"
            name="quantityUnits"
            style={{ display: 'inline-block', width: 'calc(50% - 12px)' }}
            rules={[{ required: true, message: 'Required' }]}
        >
            <Select
                showSearch
                placeholder='Dispense unit'
                options={options}
                onSelect={handleChange}
            />
        </Form.Item>
    )
}

const mapDispatchToProps = (dispatch) => {
    const { saveQueuedPrescription } = prescribeMedicine.actions;
  
    return {
      actions: bindActionCreators(
        { saveQueuedPrescription },
        dispatch
      ),
    };
  };

  const MemoizedDispenseUnitSelector = memo(DispenseUnitSelector)

  export default connect(null, mapDispatchToProps)(MemoizedDispenseUnitSelector)
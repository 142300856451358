import Axios from "../Axios";


export default class CaseRegisterApi {

  static getToken() {
    const token = window.localStorage.getItem("token");
    return token
  }

  static getQuestion(caseId, isRoot = false, questionId) {
    const token = this.getToken();
    return Axios({
      url: `/qa/question?caseId=${caseId}&isRoot=${isRoot}&questionId=${questionId}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
  }

  static submitAsweredQuestions(caseId, data) {
    const token = this.getToken();
    return Axios({
      url: `/case/mif/${caseId}`,
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      data
    });
  }

  static getCaseDetails(caseId) {
    const token = this.getToken();
    return Axios({
      url: `/case/case/${caseId}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
  }
}
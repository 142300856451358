import React from 'react'

import { Form, Select } from 'antd'

import { useDispenseUnitSelector } from './useDispenseUnitSelector'

export const DispenseUnits = ({ dispenseUnits }) => {
    
    const { options } = useDispenseUnitSelector({ dispenseUnits })

    return (
        <Form.Item
            required
            label="Dispense Unit"
            name="quantityUnits"
            style={{ display: 'inline-block', width: 'calc(50% - 12px)' }}
            rules={[{ required: true, message: 'Required' }]}
        >
            <Select
                showSearch
                placeholder='Dispense unit'
                options={options}
            />
        </Form.Item>
    )
}

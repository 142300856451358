import types from "./types";
import API from "../../axios/physician/PhysicianApi";
import handleAPIErrorResponse from "../../utils/handleAPIResponseError";
import config from "../../config";
import moment from "moment-timezone";

import { message } from "antd";

const setIncompleteCasesLoading = (payload) => ({
  type: types.SET_INCOMPLETE_CASES_LOADING,
  payload,
});

const setIncompleteCaseCount = (payload) => ({
  type: types.SET_INCOMPLETE_CASES_COUNT,
  payload,
});

const setIncompleteCases = (payload) => ({
  type: types.SET_INCOMPLETE_CASES,
  payload,
});

function getIncompleteCases(tablePreferences) {
  return (dispatch) => {
    dispatch(setIncompleteCasesLoading(true));
    API.getCaseList({
      status: "pending prescription",
      "additional-status":
        "pending video consult,pending audio consult,rx change,rx renewal",
      "consultation-start": true,
      "last-updated": moment()
        .subtract(config.completedCaseCheckDeadlineInHours, "hours")
        .toISOString(),
      ...tablePreferences,
    })
      .then((res) => {
        if (res.data) {
          const { payload } = res.data;
          const { count, data } = payload;
          dispatch(setIncompleteCaseCount(count));
          dispatch(setIncompleteCases(data || []));
          dispatch(setIncompleteCasesLoading(false));
        } else {
          message.warning("Unable to retrieve incomplete cases");
        }
      })
      .catch((error) => {
        console.log(error);
        handleAPIErrorResponse(
          error,
          "Error occurred while trying to load case count for Rx Renewals"
        );
      });
  };
}

const actions = {
  getIncompleteCases,
};

export default actions;

import React from 'react'
import { Modal, Button, Typography, message } from 'antd'
import { formatTenDigitPhoneNumber } from '../../../utils/phoneFormatter'
import moment from 'moment-timezone'

import PrescriptionsAPI from '../../../axios/prescription/PrescriptionsApi'

const { Text, Title } = Typography

const Detail = ({ header, children }) => (
    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
        {header && <Text style={{ marginRight: '10px', fontSize: '.75rem', textTransform: 'capitalize' }}>{header}</Text>}
        {children}
    </div>
)

export const CancelPrescriptionModal = ({
    prescription,
    patient,
    pharmacy,
    deselectRx,
}) => {

    const handleCancelPrescription = async () => {
        try {
            const { responseCode } = await PrescriptionsAPI.cancelPrescription(prescription.prescriptionId)
            if (!responseCode === '200') throw new Error('Error cancelling rx request prescription')
            deselectRx(true)
        } catch (e) {
            console.error(e)
            message.error('Error cancelling rx request prescription')
        }
    }
    return (
            <Modal
                title="Cancel Prescription?"
                visible={prescription}
                width={800}
                onCancel={deselectRx}
                footer={[
                    <Button key="submit" type="danger" onClick={() => handleCancelPrescription(prescription._id)}>Cancel Rx</Button>
                ]}
            >
                <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    {patient &&
                        <div style={{ width: '33.33%', display: 'flex', flexDirection: 'column', gap: '5px' }}>
                            <Title level={4}>Patient</Title>
                            <Detail header={'name'}>
                                <div style={{ fontWeight: 'bolder', fontSize: '.75rem' }}>{`${patient.firstName} ${patient.lastName}`}</div>
                            </Detail>
                            {patient.address1 && (
                                <Detail header='Address'>
                                    <div style={{ fontWeight: 'bolder', fontSize: '.75rem' }}>{patient.address1}</div>
                                    {patient.address2 && <div style={{ maxWidth: '180px', fontWeight: 'bolder', fontSize: '.75rem' }}>{patient.address2}</div>}
                                    <div style={{ fontWeight: 'bolder', fontSize: '.75rem' }}> {`${patient.city}, ${patient.state?.name} ${patient.zipCode}`}</div>
                                </Detail>
                            )}
                            {(patient.phone || patient.email) && (
                                <Detail header='Contact Info'>
                                    {patient.phone && <div style={{ fontWeight: 'bolder', fontSize: '.75rem' }}>{`${formatTenDigitPhoneNumber(patient.phone?.replace(/\D/g, ''))}`}</div>}
                                    {patient.email && <div style={{ fontWeight: 'bolder', fontSize: '.75rem' }}> {patient.email}</div>}
                                </Detail>
                            )}
                            {
                                patient.gender && (
                                    <Detail header='Gender/DOB'>
                                        <div style={{ fontWeight: 'bolder', fontSize: '.75rem' }}>{`${patient.gender.toUpperCase()},  ${moment(patient.dob).utc().format('MM-DD-YYYY')}`}</div>
                                    </Detail>
                                )
                            }
                        </div>
                    }

                    {pharmacy &&
                        <div style={{ width: '33.33%', display: 'flex', flexDirection: 'column', gap: '5px' }}>
                            <Title level={4}>Pharmacy</Title>
                            <Detail header={'name'}>
                                <div style={{ fontWeight: 'bolder', fontSize: '.75rem' }}>{pharmacy.name}</div>
                            </Detail>
                            {pharmacy.address1 && (
                                <Detail header='Address'>
                                    <div style={{ fontWeight: 'bolder', fontSize: '.75rem' }}>{pharmacy.address1}</div>
                                    {pharmacy.address2 && <div style={{ maxWidth: '180px', fontWeight: 'bolder', fontSize: '.75rem' }}>{pharmacy.address2}</div>}
                                    <div style={{ fontWeight: 'bolder', fontSize: '.75rem' }}> {`${pharmacy.city}, ${pharmacy.state} ${pharmacy.zipCode}`}</div>
                                </Detail>
                            )}
                            {(pharmacy.phoneOrFax || pharmacy.email) && (
                                <Detail header='Contact Info'>
                                    {pharmacy.phoneOrFax && <div style={{ fontWeight: 'bolder', fontSize: '.75rem' }}>{`${formatTenDigitPhoneNumber(pharmacy.phoneOrFax?.replace(/\D/g, ''))}`}</div>}
                                    {pharmacy.email && <div style={{ fontWeight: 'bolder', fontSize: '.75rem' }}> {pharmacy.email}</div>}
                                </Detail>
                            )}
                        </div>
                    }
                    {prescription &&
                        <div style={{ width: '33.33%', display: 'flex', flexDirection: 'column', gap: '5px' }}>
                            <Title level={4}>Prescription</Title>
                            <Detail header='Drug Name'>
                                <div style={{ fontWeight: 'bolder', fontSize: '.75rem' }}>{prescription.name}</div>
                            </Detail>
                            <Detail header='Strength'>
                                <div style={{ fontWeight: 'bolder', fontSize: '.75rem' }}>{prescription.strength || 'See above'}</div>
                            </Detail>
                            <Detail header='Quantity'>
                                <div style={{ fontWeight: 'bolder', fontSize: '.75rem' }}>{prescription.quantity}</div>
                            </Detail>
                            <Detail header='Refills'>
                                <div style={{ fontWeight: 'bolder', fontSize: '.75rem' }}>{prescription.refills}</div>
                            </Detail>
                            <Detail header='Days Supply'>
                                <div style={{ fontWeight: 'bolder', fontSize: '.75rem' }}>{prescription.daysSupply}</div>
                            </Detail>
                            <Detail header='Directions'>
                                <div style={{ fontWeight: 'bolder', fontSize: '.75rem' }}>{prescription.directions}</div>
                            </Detail>
                        </div>
                    }
                </div>
            </Modal>
    )
}
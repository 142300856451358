import types from "./types"

const initState = {
    caseListLoading: false,
    caseCountLoading: false,
    pendingPrescriptionCaseCount: 0,
    pendingPrescriptionCaseList: [],
};

export default function pendingPrescription(
    state = initState,
    action,
) {
    switch (action.type) {
        case types.SET_CASE_LIST_LOADING:
            return {
                ...state,
                caseListLoading: action.data,
            };
        case types.SET_CASE_COUNT_LOADING:
            return {
                ...state,
                caseCountLoading: action.data,
            };
        case types.SET_PENDING_PRESCRIPTION_CASE_LIST:
            return {
                ...state,
                pendingPrescriptionCaseList: action.payload,
            };
        case types.SET_PENDING_PRESCRIPTION_CASE_COUNT:
            return {
                ...state,
                pendingPrescriptionCaseCount: action.payload,
            };
        default:
            return state;
    }
}
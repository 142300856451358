import Axios from "./Axios";
import config from "../config";

const { api } = config;


export default class API {

  static getToken() {
    const token = window.localStorage.getItem("token");
    return token;
  }

  static physicianSignUp(model) {
    var bodyFormData = new FormData();
    for (var key in model) {
      bodyFormData.append(key, model[key]);
    }
    return Axios({
      url: `${api.baseUrl}/physician/physician`,
      method: "POST",
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: bodyFormData,
    });
  }
  static login(data) {
    return Axios({
      url: `/api/user/login`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    });
  }

  static fetchLookUp(lookup) {
    return Axios({
      url: `/lookup/${lookup}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  static fetchLookUpDetails(lookup, id) {
    return Axios({
      url: `/lookup/${lookup}/${id}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  static LoginCase(data) {
    return Axios({
      url: `/auth/case/login/`,
      method: "POST",
      data: data,
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  static verifyPhysician(npi, firstname, lastname) {
    return Axios({
      url: `/physician/physician/verify-npi`,
      method: "GET",
      params: {
        npi: npi,
        firstName: firstname,
        lastName: lastname,
      },
    });
  }

  static verfiyAuthenticationCode(data){ 
    return Axios({
      url:`${api.baseUrl}/physician/verify/otp`,
      method:"PUT",
      data:data,
    })
  }

  static resendOtpCode(data){
    return Axios({
      url:`${api.baseUrl}/api/resend/otp`,
      method:"POST",
      data:data,
    })
  }

  static logOut(){
    const token = this.getToken();
    return Axios({
      url:`${api.baseUrl}/physician/logout`,
      method:"POST",     
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
  }
}

import React, { useState } from "react";
import { Button, Modal, Form, Input, Spin, AutoComplete, Tag, message } from "antd";
import { EditFilled, LoadingOutlined } from "@ant-design/icons";
import _ from "lodash";
import PhysicianApi from "../../axios/physician/PhysicianApi";
import { useAllergies } from "./hooks/useAllergies";
import styled from 'styled-components'

const { TextArea } = Input;

const StyledSpin = styled(Spin)`
  i {
    background-color: #7bad7b;
  }
`

const CaseMedicalInformationEdit = ({ getPatientAllergies = [], patientAllergies = [], caseDetails = {}, fetchCaseDetails }) => {
  const [form] = Form.useForm();
  const [intialValues, setIntialValues] = useState();
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const {
    setAllergySearchTerm,
    addAllergyToQueue,
    removeAllergyFromQueue,
    resetAllergies,
    allergySearchResultsLoading,
    allergySearchTerm,
    allergySearchResults,
    newAllergyQueue
  } = useAllergies(caseDetails?.patientId)

  const onEditButtonClick = (caseDetails) => {
    setEditModalVisible(true);
    const formData = {
      "blood-pressure": caseDetails.bloodPressure,
      medications: caseDetails.medications,
    };
    setIntialValues(formData);
    form.setFieldsValue(formData);
  };

  const onSubmitMedicalInformation = () => {
    const value = form.getFieldsValue()
    value.newAllergyQueue = newAllergyQueue

    if (!_.isEqual(value, intialValues)) {
      setLoading(true);
      PhysicianApi.updateMedicalInformation(value, caseDetails.id)
        .then((res) => {
          const { data } = res;
          if (data.success) {
            message.success("Medical information updated successfully !");
            form.resetFields();
            setEditModalVisible(false);
            setLoading(false);
            resetAllergies()
            getPatientAllergies(caseDetails.patientId)
            fetchCaseDetails();
          } else {
            message.error(data.payload);
            setLoading(false);
          }
        })
        .catch((error) => {
          const { response } = error;
          setLoading(false);
          if (response) {
            const { data } = response;
            if (data && data.message) {
              message.error(data.message);
            } else {
              message.error(
                "Unable to update Medical information, Please try again!"
              );
            }
          } else {
            message.error(
              "Unable to update Medical information, Please try again!"
            );
          }
        });
    } else {
      message.info("Please update value before you submit!");
    }
  };

  const handleEnqueueAllergy = allergyDisplayName => {
    const allergy = allergySearchResults.find(allergy => allergy.displayName === allergyDisplayName)
    if (allergy) {      
      addAllergyToQueue(allergy)
      form.setFieldsValue({ allergies: "" })
    }
  }

  const handleDequeueAllergy = allergy => removeAllergyFromQueue(allergy)

  const handleCancel = () => {
    resetAllergies()
    setEditModalVisible(false)
  }

  const AllergyTag = ({ allergy, closable }) => {
    return (
      <Tag
        key={allergy.name}
        size="large"
        style={{ 
          margin: '4px', 
          backgroundColor: 'rgb(123, 173, 126)', 
          color: '#fff',
          fontSize: '1.05rem',
          padding: '3px 8px'
        }}
        closable={closable}
        onClose={(e) => {
          e.preventDefault();
          handleDequeueAllergy(allergy);
        }}
    >
      {allergy.name}
    </Tag>
    )
  }

  return (
    <div className="patient-edit-button">
      <Button
        type="primary"
        icon={<EditFilled />}
        onClick={() => onEditButtonClick(caseDetails)}
      >
        Edit Medical Information
      </Button>
      <Modal
        title={"Edit Medical Information"}
        visible={editModalVisible}
        closable={false}
        onOk={onSubmitMedicalInformation}
        onCancel={handleCancel}
      >
        <StyledSpin spinning={loading} indicator={<LoadingOutlined />}>
          <Form
            form={form}
            layout={"vertical"}
          >
            <Form.Item
              label="Blood Pressure"
              name="blood-pressure"
            >
              <TextArea />
            </Form.Item>

            <Form.Item
              label="Medications"
              name="medications"
            >
              <TextArea />
            </Form.Item>
            <Form.Item
              label="Allergies"
              name="allergies"
            >
              <AutoComplete
                allowClear
                options={allergySearchResults
                  .map(allergy => ({ value: allergy.displayName, label: allergy.displayName }))
                  .filter(allergy => !patientAllergies.map(patientAllergy => patientAllergy.name).includes(allergy.value))
                }
                onSelect={handleEnqueueAllergy}
                onSearch={setAllergySearchTerm}
                value={null}
                placeholder="Search for allergy"
                notFoundContent={allergySearchResultsLoading
                  ? <StyledSpin />
                  : allergySearchTerm?.length ? "No matches" : "Search term" }
              />
            </Form.Item>
            <div style={{ margin: '10px 0'}}>
              { patientAllergies?.map(allergy => <AllergyTag allergy={allergy} closable={false} />) }
              { newAllergyQueue.map(allergy => <AllergyTag allergy={{...allergy, name: allergy.displayName }} closable={true} /> ) }
            </div>
          </Form>
        </StyledSpin>
      </Modal>
    </div>
  );
};

export default CaseMedicalInformationEdit;

import React, { useCallback, useContext, useEffect, useState, useMemo } from "react";
import { message, Modal, Spin, Table, Typography } from "antd";
import { BellOutlined, LoadingOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { useSelector } from 'react-redux';
import PhysicianApi from "../../axios/physician/PhysicianApi";
import { TablePreferencesContext } from '../../context/TablePreferencesProvider';
import SearchAndFilterCases from "../../components/SearchAndFilterCases/SearchAndFilterCases";
import { getTextFieldSorter, getDateFieldSorter, getSortOrder } from '../../utils/sorters';
import { dateTimeFormatter } from "../../utils/dateFormatter";
import { createCaseFilterQueryParamMap, createTableQueryParamMap, createTableSortByMap } from "../../utils/createCaseFilterQueryParamMap";
import { CaseFilterMap, CaseQueryParamMap } from "../../constants/CaseFilter"
import { userConstants } from "../../constants";

const { Text } = Typography;

const TABLE_NAME = 'rx renewals'

const hideFields = Object.freeze([
    CaseFilterMap.PHYSICIAN_ID,
    CaseFilterMap.STATUS,
    CaseFilterMap.CASE_TAGS,
]);

const TableDataMap = {
    CASE_ID: CaseFilterMap.CASE_ID,
    PATIENT_NAME: `${CaseFilterMap.PATIENT_FIRST_NAME} ${CaseFilterMap.PATIENT_LAST_NAME}`,
    SUBMITTED_AT: 'submittedAt',
    STATE_ID: CaseFilterMap.STATE_ID,
    CONSULTATION_START: 'consultationStart',
    UNREAD_CHAT_NOTIFICATION: 'unreadChatNotification',
    TELEMEDICINE_CATEGORY_ID: CaseFilterMap.TELEMEDICINE_CATEGORY_ID,
    CREATED_DATE: CaseFilterMap.CREATED_DATE,
}

const TableSortByMap = {
    ...createTableSortByMap(TableDataMap),
    [TableDataMap.TELEMEDICINE_CATEGORY_ID]: 'category',
}

const getTableColumns = (sortOrder, sortBy) => {
    const columns = [
        {
            title: "Case No",
            key: TableDataMap.CASE_ID,
        },
        {
            title: "Patient Name",
            key: TableDataMap.PATIENT_NAME,
        },
        {
            title: "Renewal Submitted",
            key: TableDataMap.SUBMITTED_AT,
        },
        {
            title: "State",
            key: TableDataMap.STATE_ID,
        },
        {
            title: "Started",
            key: TableDataMap.CONSULTATION_START,
        },
        {
            title: <BellOutlined />,
            key: TableDataMap.UNREAD_CHAT_NOTIFICATION,
        },
        {
            title: "Category",
            key: TableDataMap.TELEMEDICINE_CATEGORY_ID,
        },
        {
            title: "Case Created",
            key: TableDataMap.CREATED_DATE,
        },
    ];

    columns.forEach((c) => {
        c.dataIndex = c.key;
        if ([TableDataMap.CASE_ID].includes(c.key)) {
          return
        }
        c.sortOrder = getSortOrder(sortOrder, sortBy, c.key);
        const isDateField = [TableDataMap.CREATED_DATE, TableDataMap.SUBMITTED_AT].includes(c.key);
        c.sorter = isDateField ? getDateFieldSorter(c.key) : getTextFieldSorter(c.key);
    });

    return columns;
}

const defaultPreferences = { currentPage: 1, tablePageSize: 100, sortOrder: 'ascend', sortBy: 'createdDate' };

const InternalRxRequests = () => {
    const history = useHistory();
    const { statesList, telemedicineCategoriesList } = useSelector(state => state.entities)
    const [consultStartConfirmationModal, setConsultStartConfirmationModal] = useState(false);
    const [caseForConsultationStart, setCaseForConsultationStart] = useState({});
    const [modalButtonLoading, setModalButtonLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [searchValue, setSearchValue] = useState(null);
    const [filters, setFilters] = useState({});
    const [rxRenewalRequests, setRxRenewalRequests] = useState([]);
    const [recordCount, setRecordCount] = useState(0);
    const [showModal, setShowModal] = useState(false);
    const { getTablePreferences, updateTablePreferences } = useContext(TablePreferencesContext);
    const { currentPage, tablePageSize, sortOrder, sortBy } = getTablePreferences(TABLE_NAME, defaultPreferences);

    const columns = useMemo(() => getTableColumns(sortOrder, sortBy), [sortOrder, sortBy]);

    const fetchCases = useCallback(() => {
        const newTablePreferences = {
            currentPage,
            tablePageSize,
            sortOrder,
            sortBy: TableSortByMap[sortBy] || sortBy,
        };
        const tableFilter = createTableQueryParamMap(newTablePreferences);
        const caseFilterMap = createCaseFilterQueryParamMap(filters, hideFields);
        if (searchValue !== null) {
          caseFilterMap[CaseQueryParamMap.SEARCH_TERM] = searchValue;
        }

        setLoading(true);
        PhysicianApi.getRxRenewalRequests({ ...caseFilterMap, ...tableFilter })
            .then(res => {
                setRecordCount(res.data.payload.count);
                setRxRenewalRequests(res.data.payload.data)
            })
            .catch(err => {
                message.error("Error occured while fetching Rx Renewal Requests");
                console.log("Error occured while fetching Rx Renewal Requests: ", err);
            })
            .finally(() => setLoading(false));
    }, [searchValue, filters, currentPage, tablePageSize, sortOrder, sortBy]);

    useEffect(fetchCases, [fetchCases]);

    const handleConsultStartCancellation = (event) => {
        event.stopPropagation();
        setCaseForConsultationStart({});
        setConsultStartConfirmationModal(false);
        message.warn("Please start consultation to move to the follow-up case details");
    };

    const handleConsultationStartError = (messageString) => {
        setModalButtonLoading(false);
        setConsultStartConfirmationModal(false);
        message.error(messageString);
    };

    const handleConsultStartAccept = (event) => {
        event.stopPropagation();
        const data = {
          caseId: caseForConsultationStart.caseId,
          consultationStart: true,
        };
        setModalButtonLoading(true);
        PhysicianApi
          .startConsultation(data)
          .then((res) => {
            if (res.data.success) {
              pushRouteCaseDetails(caseForConsultationStart.caseId);
            } else {
              const errorMessage = res.data?.message || "Unable to Start Consult. Please try again later!";
              handleConsultationStartError(errorMessage);
            }
          })
          .catch((err) => {
            const errorMessage = err?.response?.data?.message || "Error occured while Starting Consultation. Please try again later!";
            handleConsultationStartError(errorMessage);
          });
    };

    const pushRouteCaseDetails = (caseId) => {
        history.push(`/dashboard/${userConstants.USER_PHYSICIAN}/caseview/${caseId}`,
        { from: history.location.pathname.split('/').pop() });
    };

    const handleCaseListClick = (event, record) => {
        event.stopPropagation();
        if (!record.consultationStart) {
            setCaseForConsultationStart(record);
            setConsultStartConfirmationModal(true);
        } else {
            pushRouteCaseDetails(record.caseId);
        }
    };

    const handleOk = (filterData) => {
        setFilters(filterData);
        setShowModal(false);
    };

    const handleCancel = () => {
        setFilters({});
        setShowModal(false);
    };

    const handleCancelCross = () => {
        setShowModal(false);
    };

    const handleSearch = (value) => {
        setSearchValue(value);
    }

    const handleShowModal = () => {
        setShowModal(true);
    }

    const onChangePage = (page) => {
        updateTablePreferences(TABLE_NAME, { currentPage: page })
      };

    const onPageSizeChange = (_, size) => {
      updateTablePreferences(TABLE_NAME, { currentPage: 1, tablePageSize: size })
    };

    const onChangeOrder = (_pagination, _filters, sorter) => {
        updateTablePreferences(TABLE_NAME, {
          sortOrder: sorter.order || 'ascend',
          sortBy: sorter.field,
        });
    }

    const casesForTable = rxRenewalRequests.map((c) => {
        return {
          key: c.caseId,
          rowKey: c.caseId,
          [TableDataMap.CASE_ID]: c.caseId,
          [TableDataMap.PATIENT_NAME]: c.patientName,
          [TableDataMap.SUBMITTED_AT]: c.submittedAt ? dateTimeFormatter(c.submittedAt) : '',
          [TableDataMap.STATE_ID]: c.state,
          [TableDataMap.CONSULTATION_START]: c.consultationStart ? 'yes' : 'no',
          [TableDataMap.UNREAD_CHAT_NOTIFICATION]: c.unreadChatNotification,
          [TableDataMap.TELEMEDICINE_CATEGORY_ID]: c.category,
          [TableDataMap.CREATED_DATE]: dateTimeFormatter(c.createdDate),
        }
    })

    return (
        <div className="consulted-case">
            <div className="waiting-room-case-list-part-heading">
                <h1>Rx Renewal Requests</h1>

                <SearchAndFilterCases
                    searchCase={handleSearch}
                    showModal={showModal}
                    setShowModal={setShowModal}
                    handleCancelCross={handleCancelCross}
                    handleCancel={handleCancel}
                    handleOk={handleOk}
                    handleShowModal={handleShowModal}
                    telemedicines={telemedicineCategoriesList}
                    states={statesList}
                    hideFields={hideFields}
                />
            </div>
            <div className="consulted-case-no-of-patient-list-data">
                <Spin
                    size="large"
                    spinning={loading}
                    indicator={<LoadingOutlined spin />}
                >
                    <div>
                        <Table
                            className="wating-room-table"
                            columns={columns}
                            dataSource={casesForTable}
                            onChange={onChangeOrder}
                            pagination={{
                                total: recordCount,
                                showTotal: (total) => `Total ${total} Rx Requests`,
                                pageSizeOptions: ["5", "10", "20", "50", "100"],
                                showSizeChanger: true,
                                current: currentPage,
                                pageSize: tablePageSize,
                                onChange: onChangePage,
                                onShowSizeChange: onPageSizeChange,
                            }}
                            scroll={{ x: 900 }}
                            onRow={(record) => ({ onClick: event => handleCaseListClick(event, record) })}
                        />
                    </div>
                </Spin>
                <Modal
                    title="Please confirm Consultation Start"
                    okText="Start Consultation"
                    closable={false}
                    maskClosable={false}
                    centered
                    visible={consultStartConfirmationModal}
                    okButtonProps={{ loading: modalButtonLoading }}
                    cancelButtonProps={{ loading: modalButtonLoading }}
                    onOk={(event) => handleConsultStartAccept(event)}
                    onCancel={(event) => handleConsultStartCancellation(event)}
                >
                    <Text strong>
                        You haven't started this case for an Rx update yet. Please
                        confirm that you are starting the follow-up consult for this case
                    </Text>
                </Modal>
            </div>
        </div>
    );
};

export default InternalRxRequests;

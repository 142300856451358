import React, { useEffect } from "react";
import "../Articles.css";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useParams, useHistory } from "react-router-dom";
import { Form, Button, Spin, Typography } from "antd";
import { LeftOutlined, LoadingOutlined } from "@ant-design/icons";
import { userConstants } from "../../../constants";
import { Editor } from "@tinymce/tinymce-react";
import articles from "../../../redux/articles";
import config from "../../../config";

const { Title } = Typography;

const ArticleView = ({ actions, viewArticleLoading, viewArticle }) => {
  const { id } = useParams();
  const {apiKeys}=config;
  let history = useHistory();

  useEffect(() => {
    articleView();
    // eslint-disable-next-line
  }, []);

  const articleView = () => {
    actions.getViewArticle(id);
  };

  return (
    <>
      <Spin
        size="large"
        spinning={viewArticleLoading}
        indicator={<LoadingOutlined spin />}
      >
        <div className="article-view-wrapper">
          <Button
            className="article-view-button"
            size="large"
            onClick={() =>
              history.push(
                `/dashboard/${userConstants.USER_PHYSICIAN}/articles`
              )
            }
          >
            <LeftOutlined />
            Back to Articles
          </Button>
          <div className="article-view-content-wrapper">
            <Form name="article-view">
              {viewArticle && (
                <>
                  <Title level={2}>Title: {viewArticle.title}</Title>
                  <div className="article-view-category-wrapper">
                    <Form.Item label="Article Category:">
                      <p className="article-view-text">
                        {viewArticle.articleCategoryName}
                      </p>
                    </Form.Item>
                    {viewArticle.articleCategoryName ===
                      "Clinical Protocol" && (
                      <Form.Item label="Telemedicine Category:">
                        <p className="article-view-text">
                          {viewArticle.teleMedicineCategoryName}
                        </p>
                      </Form.Item>
                    )}
                  </div>
                  <Form.Item>
                    <Title level={4}>
                      Short Description: {viewArticle.description}
                    </Title>
                  </Form.Item>
                </>
              )}
            </Form>
            <Editor
              apiKey={apiKeys.tinyEditorKey}
              initialValue={viewArticle ? viewArticle.editorHtmlData : ""}
              disabled={true}
              init={{
                height: 600,
                readonly: true,
                menubar: "false",
                plugin: "false",
                statusbar: false,
                toolbar: false,
              }}
            />
          </div>
        </div>
      </Spin>
    </>
  );
};

const mapStateToProps = (state) => {
  const { viewArticleLoading, viewArticle } = state.articles;

  return {
    viewArticleLoading,
    viewArticle,
  };
};

const mapDispatchToProps = (dispatch) => {
  const { getViewArticle } = articles.actions;
  return {
    actions: bindActionCreators(
      {
        getViewArticle,
      },
      dispatch
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ArticleView);

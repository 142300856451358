import React, { useState } from 'react'
import { Typography, Button, Spin, Input } from 'antd'

const { Text } = Typography

export const LegalAgreement = ({ 
    validation,
    handleSubmit,
    handleCancel
}) => {
    const [legalAgreementStep, setLegalAgreementStep] = useState(0)
    const [signature, setSignature] = useState('')

    const handleNext = async () => {
        await handleSubmit({ 
            agreementId: validation.legalAgreement[legalAgreementStep].agreementId,
            nameSigned: signature
        })
        setSignature('')
        if (legalAgreementStep === 0) setLegalAgreementStep(1)
    }

    return (
        <Spin spinning={validation.loading}>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                <Text><strong>Clinician Legal Agreement</strong></Text>
                {!!validation.legalAgreement && (
                    <div style={{ margin: '10px 0'}}>
                        <Text><strong>{validation.legalAgreement[legalAgreementStep].agreementText}</strong></Text>
                    </div>
                )}
                <Input placeholder='Please sign here' value={signature} onChange={e => setSignature(e.target.value)}/>
                { legalAgreementStep === 0 && <Button onClick={handleCancel}>Cancel</Button> }
                <Button 
                    type="primary" 
                    disabled={signature.length < 1}
                    onClick={handleNext}>{legalAgreementStep === 0 ? 'Next' : 'Accept'}
                </Button>
            </div>
        </Spin>
    )
}
import React, { useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from 'moment-timezone'
import { capitalize } from "lodash";
import caseQueueActions from "../../redux/caseQueue/actions"
import { StyledTable } from "./StyledComponents";
import StatusIcon from "./StatusIcon";
import { dateTimeFormatter } from "../../utils/dateFormatter";
import { getDateFieldSorter } from "../../utils/sorters";

const createTableColumns = (TableDataMap) => {
  const columns = [
    {
      colSpan: 1,
      key: TableDataMap.STATUS_ICON,
      width: 60,
      render: (status) => <StatusIcon status={status}/>,
    },
    {
      title: "Case No",
      colSpan: 1,
      key: TableDataMap.CASE_ID,
      width: 230,
    },
    {
      title: "Patient Name",
      key: TableDataMap.PATIENT_NAME,
      width: 180,
    },
    {
      title: "Category Name",
      key: TableDataMap.TELEMEDICINE_CATEGORY_ID,
      width: 250,
    },
    {
      title: "State",
      key: TableDataMap.STATE_ID,
      width: 140,
    },
    {
      title: "Created Date",
      key: TableDataMap.CREATED_DATE,
      width: 230,
      render: val => (
        <div>
          <div>{dateTimeFormatter(val)}</div>
          <div>({moment(val).fromNow()})</div>
        </div>
      ),
      sorter: getDateFieldSorter(TableDataMap.CREATED_DATE),
    },
    {
      title: "Status",
      key: TableDataMap.STATUS,
      render: (status) => status.split(' ').map(capitalize).join(' '),
    }
  ];

  columns.forEach((c) => (c.dataIndex = c.key));
  return columns;
};

const QueueTable = ({ TableDataMap, setCaseForConsultationStart, setOpenModal }) => {
    const caseQueue = useSelector(state => state.caseQueue.caseQueue);
    const dispatch = useDispatch();

    const onChange = (_, __, sorter) => {
        dispatch(caseQueueActions.setSortDirection(sorter.order || 'ascend'));
    }

    const columns = useMemo(() => createTableColumns(TableDataMap), [TableDataMap]);

    const handleCaseListClick = (event, record) => {
      event.stopPropagation();
      setCaseForConsultationStart(record);
      setOpenModal(true);
    };

    const casesForTable = caseQueue.map((c) => {
      return {
        key: c._id,
        rowKey: c._id,
        [TableDataMap.STATUS_ICON]: c.status,
        [TableDataMap.CASE_ID]: c._id,
        [TableDataMap.PATIENT_NAME]: c.patient?.name,
        [TableDataMap.TELEMEDICINE_CATEGORY_ID]: c.category?.name,
        [TableDataMap.STATE_ID]: c.state,
        [TableDataMap.CREATED_DATE]: c.createdAt,
        [TableDataMap.STATUS]: c.status,
        [TableDataMap.REQUEST_ID]: c.request?._id,
      }
    })

    return (
        <StyledTable
          className="case-queue-table"
          columns={columns}
          dataSource={casesForTable}
          scroll={{ x: 900 }}
          onChange={onChange}
          pagination={{
            hideOnSinglePage: true,
            pageSize: 50
          }}
          onRow={(record) => ({
            onClick: (event) => handleCaseListClick(event, record),
          })}
        />
    )
}

export default QueueTable;

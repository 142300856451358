import { message } from "antd";
const handleAPIErrorResponse = (error, errorMessage) => {
    try {
        const { response } = error;
        console.log("======================== response ========================", response, error);
        if (response) {
            const { data, status } = response;
            if (status !== 401 && status !== 403) {
                if (data && data.message) {
                    const messageType = data.message === 'availability has not been set'
                        ? 'warning'
                        : 'error';
                    message[messageType](data.message);
                } else {
                    message.error(errorMessage);
                }
            } else {
                console.log("======================== API Error Response:: Unathorized ========================", response);
            }
        } else {
            message.error(errorMessage);
        }
    } catch (err) {
        console.log("============= API response handling error =================", err);
        message.error(errorMessage);
    }
}

export default handleAPIErrorResponse;
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { ChatItem } from "react-chat-elements";

//import CSS
import "react-chat-elements/dist/main.css";

const ChatList = ({ chatList, caseDetails, chatSelection }) => {
  const [chatListItems, setChatListItem] = useState([]);

  useEffect(() => {
    setChatListItem(chatList);
  }, [chatList]);

  const handleChatListClick = (chatDetails) => {
    chatSelection(chatDetails);
  };

  return (
    <div className='chatlist-item-wrapper'>
      {chatListItems.map((eachChat) => (
        <ChatItem
          alt={eachChat.name}
          avatar={eachChat.photo ? eachChat.photo : null}
          title={eachChat.categoryName}
          className={
            eachChat.caseId === caseDetails.caseId ? "message-single-chat" : ""
          }
          key={eachChat.caseId}
          subtitle={eachChat.name}
          unread={eachChat.count}
          date={new Date(eachChat.date)}
          onClick={() => {
            handleChatListClick(eachChat);
          }}
        />
      ))}
    </div>
  );
};

export default ChatList;

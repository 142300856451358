import types from "./types";
import API from "../../axios/physician/PhysicianApi";
import handleAPIErrorResponse from "../../utils/handleAPIResponseError";
import { CaseStatusEnum } from "../../types/enums";

import { message } from "antd";

const setReferralCasesLoading = (payload) => ({
  type: types.SET_REFERRAL_CASES_LOADING,
  payload,
});

const setReferralCaseCount = (payload) => ({
  type: types.SET_REFERRAL_CASES_COUNT,
  payload,
});

const setReferralCases = (payload) => ({
  type: types.SET_REFERRAL_CASES,
  payload,
});

const setCaseReviewModalVisible = payload => ({
  type: types.SET_CASE_REVIEW_MODAL_VISIBLE,
  payload
});

function getReferralCases() {
  return (dispatch) => {
    dispatch(setReferralCasesLoading(true));


    API.getReferralCases()
      .then((res) => {
        if (res.data) {
          const { payload } = res.data;
          const { count, data } = payload;
          dispatch(setReferralCaseCount(count));
          dispatch(setReferralCases(data || []));
          dispatch(setReferralCasesLoading(false));
        } else {
          message.warning("Unable to retrieve referral cases");
        }
      })
      .catch((error) => {
        console.log(error);
        handleAPIErrorResponse(
          error,
          "Error occurred while trying to load case count for referrals"
        );
      });
  };
}

function approveReferralCase(caseId) {
  return async dispatch => {
    try {
      const response = await API.updateCaseStatus(caseId, { status: CaseStatusEnum.CONSULT_COMPLETE_NO_RX, sendToPatient: true })
      const { data } = response;
      if (!data.success) throw new Error(data?.message)
      dispatch(getReferralCases())
      dispatch(setCaseReviewModalVisible(false))
    } catch (e) {
      throw new Error(e.response?.data?.message || e.message)
    }
  }
}

function rejectReferralCase(caseId, reason) {
  return async dispatch => {
    try {
      const response = await API.rejectCaseReview({ caseId, reason })
      const { data } = response;
      if (!data.success) throw new Error(data?.message)
      dispatch(getReferralCases())
      dispatch(setCaseReviewModalVisible(false));
    } catch (e) {
      throw new Error(e.response?.data?.message || e.message)
    }
  }
}

const actions = {
  getReferralCases,
  approveReferralCase,
  rejectReferralCase,
  setCaseReviewModalVisible
};

export default actions;

import React from 'react'
import { Form, Radio, Typography, Button } from 'antd'

const { Text, Title } = Typography

const ChallengeQuestion = ({ n, question, answers }) => {
    return (
        <div style={{ margin: '20px 0'}}>
            <Text>{question}</Text>
            <Form.Item style={{ marginTop: '5px'}} name={n} rules={[{ required: true }]}>
                <Radio.Group style={{ display: 'flex', flexDirection: 'column'}} options={answers.map(answer => ({ label: answer, value: answer }))}/>
            </Form.Item>
        </div>
        
    )
}

export const ChallengeQuestions = ({ 
    validation,
    challengeQuestionsAndAnswers,
    handleSubmit
}) => {
    return (
        <Form 
            onFinish={handleSubmit}
        >
            <Title level={3}>Challenge Questions</Title>
            <Text><strong>To comply with DEA regulations for the Electronic Prescribing of Controlled Substances, please answer the following questions:</strong></Text>
            { challengeQuestionsAndAnswers?.questions?.map((question, i) => (
                <ChallengeQuestion 
                    key={JSON.stringify(question)} 
                    n={i} 
                    question={question}
                    answers={challengeQuestionsAndAnswers?.answers?.[i]}
                />)
            )}
            <div style={{ display: 'flex', justifyContent: 'center'}}>
                <Form.Item>
                    <Button type="primary" htmlType="submit">Next</Button>
                </Form.Item>    
            </div>  
        </Form>
    )
}
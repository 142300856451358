const types = {
  SET_PHYSICIAN_MONTHLY_REPORT_LOADING: "SET_PHYSICIAN_MONTHLY_REPORT_LOADING",

  SET_IS_LOADING_CASE_REPORT: "SET_IS_LOADING_CASE_REPORT",
  SET_IS_LOADING_CONSULT_REPORT: "SET_IS_LOADING_CONSULT_REPORT",
  SET_IS_LOADIN_COMPLETION_COUNTS: "SET_IS_LOADIN_COMPLETION_COUNTS",

  SET_MONTHLY_REPORT_DATE: "SET_MONTHLY_REPORT_DATE",
  SET_MONTHLY_CASE_REPORT: "SET_MONTHLY_CASE_REPORT",
  SET_OPEN_CASE_REPORT: "SET_OPEN_CASE_REPORT",
  SET_MONTHLY_CONSULT_REPORT: "SET_MONTHLY_CONSULT_REPORT",
  SET_MONTHLY_RX_RENEWALS_COUNT: "SET_MONTHLY_RX_RENEWALS_COUNT",
  SET_PHYSICIAN_MONTHLY_REPORT: "SET_PHYSICIAN_MONTHLY_REPORT",
  SET_PHYSICIAN_REPORT_MONTH: "SET_PHYSICIAN_REPORT_MONTH",
  SET_PHYSICIAN_REPORT_YEAR: "SET_PHYSICIAN_REPORT_YEAR",
  SET_CASE_COMPLETION_COUNTS: "SET_CASE_COMPLETION_COUNTS",

  INCREMENT_CASE_REPORT_REQUEST_ID: "INCREMENT_CASE_REPORT_REQUEST_ID",
  INCREMENT_CONSULT_REPORT_REQUEST_ID: "INCREMENT_CONSULT_REPORT_REQUEST_ID",
  INCREMENT_COMPLETION_COUNTS_REQUEST_ID: "INCREMENT_COMPLETION_COUNTS_REQUEST_ID",
};

export default types;

import React, { createContext, useContext } from 'react';

const CaseContext = createContext();

export const useCaseContext = () => {
    const context = useContext(CaseContext);
    if (!context) {
        throw new Error("useCaseContext must be used within a CaseProvider");
    }
    return context;
}

export const CaseProvider = ({ children, value }) => {
    return (
        <CaseContext.Provider value={value}>
            {children}
        </CaseContext.Provider>
    );
};

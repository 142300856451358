import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useMarkerWidget } from '../context/MarkerProvider';

export const useUpdateMarkerMetadataWithUrlParams = () => {
  const params = useParams();
  const { setMarkerMetadata } = useMarkerWidget();

  useEffect(() => {
    setMarkerMetadata({ ...params });
  // eslint-disable-next-line
  }, [params])
}
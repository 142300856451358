import { useState, useEffect  } from 'react'

const getConfigFromLocalStorage = () => {
    const config = localStorage.getItem('userConfig');
    return config ? JSON.parse(config) : { 
        useExperimentalFeatures: false,
        useLegacyFeatures: false 
    };
};

const setConfigInLocalStorage = (config) => {
    localStorage.setItem('userConfig', JSON.stringify(config));
};

export const useUserConfig = () => {
    const [config, setConfig] = useState(getConfigFromLocalStorage());

    useEffect(() => {
        setConfigInLocalStorage(config);
    }, [config]);

    const toggleUseExperimentalFeatures = () => {
        setConfig((prevConfig) => ({
            ...prevConfig,
            useExperimentalFeatures: !prevConfig.useExperimentalFeatures,
        }));
        window.location.reload()
    };

    const toggleUseLegacyFeatures = () => {
        setConfig((prevConfig) => ({
            ...prevConfig,
            useLegacyFeatures: !prevConfig.useLegacyFeatures
        }));
        window.location.reload()
    };

    return [config, { toggleUseExperimentalFeatures, toggleUseLegacyFeatures }];
};
import types from "./types";
import API from "../../axios/physician/PhysicianApi";
import handleAPIErrorResponse from "../../utils/handleAPIResponseError";
import { dateTimeFormatter } from "../../utils/dateFormatter";

import { message } from "antd";

const setAdditionalInfoCaseCountLoading = (status) => ({
  type: types.SET_ADDITIONAL_INFO_CASE_COUNT_LOADING,
  data: status,
});

const setAdditionalInfoCaseUpdateCountLoading = (status) => ({
  type: types.SET_ADDITIONAL_INFO_CASE_UPDATE_COUNT_LOADING,
  data: status,
});

const setAdditionalInfoCaseListLoading = (status) => ({
  type: types.SET_ADDITIONAL_INFO_CASE_LIST_LOADING,
  data: status,
});

const setAdditionalInfoCaseCount = (payload) => ({
  type: types.SET_ADDITIONAL_INFO_CASE_COUNT,
  payload,
});

const setAdditionalInfoCaseUpdateCount = (payload) => ({
  type: types.SET_ADDITIONAL_INFO_CASE_UPDATE_COUNT,
  payload,
});

const setAdditionalInfoCaseList = (payload, count) => ({
  type: types.SET_ADDITIONAL_INFO_CASE_LIST,
  payload,
  count,
});

const setAdditionalInfoChatCount = (payload) => ({
  type: types.SET_ADDITIONAL_INFO_CHAT_COUNT,
  payload,
});

function getAdditionalInfoCaseList(status) {
  return (dispatch) => {
    dispatch(setAdditionalInfoCaseListLoading(true));
    API.getCaseList(status)
      .then((res) => {
        const { data } = res;
        if (data && data.success) {
          const { payload } = data;
          if (payload.data) {
            const mappedData = payload.data.map((item) => {
              return {
                ...item,
                key: item._id,
                name: item.patientName,
                caseno: item.caseId,
                categoryname: item.category,
                createddate: item.createdDate
                  ? dateTimeFormatter(item.createdDate)
                  : "",
                lastMessageToPatientAtDisplay: item.lastMessageToPatientAt
                  ? dateTimeFormatter(item.lastMessageToPatientAt)
                  : "",
                status: item.status,
                statusDisplayName: item.statusDisplayName,
                consultationType: item.consultationType
                  ? item.consultationType
                  : null,
                consultationStart: item.consultationStart
                  ? item.consultationStart
                  : null,
              };
            });
            const mappedCount = payload.count;
            dispatch(setAdditionalInfoCaseList(mappedData, mappedCount));
          }
        } else {
          message.warning(
            "Unable to update cases which required Additional Info"
          );
        }
        dispatch(setAdditionalInfoCaseListLoading(false));
      })
      .catch((error) => {
        handleAPIErrorResponse(
          error,
          "Error occured while updating cases which required Additional Info"
        );
        dispatch(setAdditionalInfoCaseListLoading(false));
      });
  };
}

function getAdditionalInfoCaseCount(status) {
  return (dispatch) => {
    dispatch(setAdditionalInfoCaseCountLoading(true));
    API.getCaseCount(status)
      .then((res) => {
        const { data } = res;
        if (data) {
          const { payload } = data;
          if (payload) {
            const { caseCount, chatCount } = payload;
            dispatch(setAdditionalInfoCaseCount(caseCount));
            dispatch(setAdditionalInfoChatCount(chatCount));
          }
        } else {
          message.warning("Unable to update Additional Info Requied Cases");
        }
        dispatch(setAdditionalInfoCaseCountLoading(false));
      })
      .catch((error) => {
        handleAPIErrorResponse(
          error,
          "Error occured while updating Additional Info Requied Cases"
        );
        dispatch(setAdditionalInfoCaseCountLoading(false));
      });
  };
}

function getAdditionalInfoCaseUpdateCount(status) {
  return (dispatch) => {
    dispatch(setAdditionalInfoCaseUpdateCountLoading(true));
    API.getCaseNotification(status)
      .then((res) => {
        const { data } = res;
        if (data && data.success) {
          const { payload } = data;
          dispatch(setAdditionalInfoCaseUpdateCount(payload));
        } else {
          message.warning(
            "Unable to fetch updates on Additional Info Required Cases"
          );
        }
        dispatch(setAdditionalInfoCaseUpdateCountLoading(false));
      })
      .catch((error) => {
        handleAPIErrorResponse(
          error,
          "Error occurred while fetching updates on Additional Info Required Cases"
        );
        dispatch(setAdditionalInfoCaseUpdateCountLoading(false));
      });
  };
}

const actions = {
  setAdditionalInfoCaseCountLoading,
  setAdditionalInfoCaseUpdateCountLoading,
  setAdditionalInfoCaseListLoading,
  setAdditionalInfoCaseCount,
  setAdditionalInfoCaseUpdateCount,
  setAdditionalInfoCaseList,
  getAdditionalInfoCaseList,
  getAdditionalInfoCaseCount,
  getAdditionalInfoCaseUpdateCount,
};

export default actions;

import React from "react";
import { Modal, Form, Input, InputNumber, AutoComplete } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

import {
  CheckCircleOutlined,
  CloseCircleOutlined,
} from '@ant-design/icons'

import { useAllergies } from "./hooks/useAllergies";

import { StyledButton } from "../../../Button";
import { VeritasSpinner } from "../../../../../../components/VeritasSpinner";
import { AllergyTag } from "./AllergyTag";

import {
  FooterContainer,
  TagsContainer
} from "./PatientBiometrics.Modal.styles";

import { useBiometrics } from "./hooks/useBiometrics";

const { TextArea } = Input;

export const PatientBiometricsModal = ({
  caseDetails,
  editModalVisible,
  setEditModalVisible,
  setRecordedAllergies,
}) => {

  const {
    setAllergySearchTerm,
    addAllergyToQueue,
    removeAllergyFromQueue,
    resetAllergies,
    allergySearchResultsLoading,
    allergySearchTerm,
    allergySearchResults,
    newAllergyQueue,
    patientAllergies,
    getPatientAllergies
  } = useAllergies({
    patientId: caseDetails?.patientId,
    setRecordedAllergies,
  })

  const {
    form,
    loading,
    handleCancel,
    onSubmitMedicalInformation
  } = useBiometrics({
    resetAllergies,
    newAllergyQueue,
    getPatientAllergies,
    setEditModalVisible
  })

  const handleAddAllergyToQueue = (allergy) => {
    addAllergyToQueue(allergy)
    form.setFieldsValue({ 'recorded-allergies': '' })
  }

  return (
    <Modal
      title={"Edit Medical Information"}
      visible={editModalVisible}
      centered
      closable={false}
      footer={[
        <FooterContainer>
          <StyledButton
            type="button"
            Icon={CloseCircleOutlined}
            onClick={handleCancel}
            tooltip="Cancel"
            color="red"
          />
          <StyledButton
            type="submit"
            Icon={CheckCircleOutlined}
            onClick={onSubmitMedicalInformation}
            tooltip="Submit"
            style={{ marginLeft: '0' }}
          />
        </FooterContainer>
      ]}
    >
      <VeritasSpinner spinning={loading} indicator={<LoadingOutlined />}>
        <Form
          form={form}
          layout={"vertical"}
        >
          <Form.Item
            label="Height (in)"
            name="height"
          >
            <InputNumber min={0}/>
          </Form.Item>
          <Form.Item
            label="Weight (lbs)"
            name="weight"
          >
            <InputNumber min={0}/>
          </Form.Item>
          <Form.Item
            label="Blood Pressure"
            name="blood-pressure"
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Medications"
            name="medications"
          >
            <TextArea 
              autoSize={{ minRows: 4 }}
            />
          </Form.Item>
          <Form.Item
            label="Patient-Reported Allergies"
            name="allergies"
          >
            <TextArea 
                autoSize={{ minRows: 4 }}
            />
          </Form.Item>
          <Form.Item
            label="Recorded Allergies"
            name="recorded-allergies"
          >
            <AutoComplete
              allowClear
              options={allergySearchResults
                .map(allergy => ({ value: allergy.displayName, label: allergy.displayName }))
                .filter(allergy => !patientAllergies.map(patientAllergy => patientAllergy.name).includes(allergy.value))
              }
              onSelect={handleAddAllergyToQueue}
              onSearch={setAllergySearchTerm}
              value={null}
              placeholder="Search for allergy"
              notFoundContent={allergySearchResultsLoading
                ? <VeritasSpinner />
                : allergySearchTerm?.length ? "No matches" : "Search term"}
            />
          </Form.Item>
          <TagsContainer>
            {
              patientAllergies?.map(allergy => (
                <AllergyTag
                  allergy={allergy}
                  closable={false}
                  handleDequeueAllergy={removeAllergyFromQueue}
                />
              ))
            }
            {
              newAllergyQueue.map(allergy => (
                <AllergyTag
                  allergy={{ ...allergy, name: allergy.displayName }}
                  closable={true}
                  handleDequeueAllergy={removeAllergyFromQueue}
                />
              ))
            }
          </TagsContainer>
        </Form>
      </VeritasSpinner>
    </Modal>
  )
};
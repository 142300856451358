import types from "./types"

const initState = {
    otherPhysiciansCaseCountLoading: false,
    otherPhysiciansCaseListLoading: false,
    otherPhysiciansCaseCount: 0,
    otherPhysiciansCaseList: [],
};

export default function otherPhysiciansCases(
    state = initState,
    action,
) {
    switch (action.type) {
        case types.SET_OTHER_PHYSICIANS_CASE_LIST_LOADING:
            return {
                ...state,
                otherPhysiciansCaseListLoading: action.data,
            };
        case types.SET_OTHER_PHYSIANS_CASE_COUNT_LOADING:
            return {
                ...state,
                otherPhysiciansCaseCountLoading: action.data,
            };
        case types.SET_OTHER_PHYSICIANS_CASE_LIST:
            return {
                ...state,
                otherPhysiciansCaseList: action.data
            };
        case types.SET_OTHER_PHYSICIANS_CASE_COUNT:
            return {
                ...state,
                otherPhysiciansCaseCount: action.data,
            };
        case types.REMOVE_CASE_FROM_OPC:
            return {
                ...state,
                otherPhysiciansCaseList: state.otherPhysiciansCaseList?.filter(_case => _case._id !== action.data)
            }
        default:
            return state;
    }
}
import React, { useState, useEffect } from "react";
import PhysicianAPI from '../../axios/physician/PhysicianApi'
import { Table, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import moment from 'moment-timezone'

// commons
import { userConstants } from "../../constants";
import { history } from "../../helpers";

const History = () => {
  const [caseHistoryLoading, setCaseHistoryLoading] = useState(false)
  const [caseHistory, setCaseHistory] = useState([])
  
  const columns = [
    {
      title: "Case No",
      dataIndex: "caseId",
      key: "caseno",
    },
    {
      title: "Assigned Provider",
      dataIndex: "assignedPhysician",
      key: "assignedProvider"
    },
    {
      title: "Patient",
      dataIndex: "patientName",
      key: "name",
    },
    {
      title: "Category",
      dataIndex: "categoryName",
      key: "categoryName",
    },
    {
      title: "Last Viewed",
      dataIndex: "lastViewed",
      key: "lastViewed",
      render: (_, caseDetails) => moment(caseDetails.lastViewed).from(new Date()),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    }
  ];

  const getViewedCaseHistory = async () => {
    setCaseHistoryLoading(true)
    try {
      const { data } = await PhysicianAPI.getViewedCaseHistory()
      setCaseHistory(data.cases)
    } catch (e) {
      console.log(e)
    } finally {
      setCaseHistoryLoading(false)
    }
  }

  
  const pushRouteCaseDetails = (caseId) => {
    history.push(
      `/dashboard/${userConstants.USER_PHYSICIAN}/caseview/${caseId}`, {
        from: 'history'
      }
    );
  };

  const handleCaseListClick = (event, record) => {
    event.stopPropagation();
    pushRouteCaseDetails(record.caseId);
  };

  useEffect(() => {
    getViewedCaseHistory()
  }, [])

  return (
    <div className="consulted-case">
      <div className="waiting-room-case-list-part-heading">
        <div>
          <h1 style={{ marginBottom: "0" }}>History</h1>
          <h4>Last 50 cases viewed</h4>
        </div>
      </div>
      <div className="consulted-case-no-of-patient-list-data">
        <Spin
          size="large"
          spinning={caseHistoryLoading}
          indicator={<LoadingOutlined spin />}
        >
          <div>
            <Table
              className="wating-room-table"
              columns={columns}
              dataSource={caseHistory}
              pagination={false}
              onRow={(record, rowIndex) => {
                return {
                  onClick: (event) =>
                    handleCaseListClick(event, record, rowIndex),
                };
              }}
            />
          </div>
        </Spin>
      </div>
    </div>
  );
};

export default History

import { Table, Spin } from "antd";
import styled, { keyframes } from 'styled-components'
import { VideoCameraOutlined } from "@ant-design/icons";

export const StyledTable = styled(Table)`
  .animate * {
    animation-name: ${keyframes`
    0% { background: #7bad7b; }
    100% { background: #fff; }
  `};
    animation-duration: 2s;
    animation-iteration-count: 1;
  }
`

export const StyledSpin = styled(Spin)`
  margin-left: 14px;
  .ant-spin-dot {
    font-size: 25px;
  }
  i {
    background-color: #7bad7b;
    height: 10px;
    width: 10px;
  }
`

export const StyledSmallSpin = styled(Spin)`
  i {
    background-color: #7bad7b;
  }
`

export const StyledVideoIcon = styled(VideoCameraOutlined)`
  color: rgba(124, 124, 124) !important;
`

import types from './types';

const initialState = {
    pendingPrescriptionCaseNotification: 0,
    audioVideoCaseNotification: 0,
    additionlInfoCaseUpdateNotification: 0,
    rxChangeCaseNotification: 0,
    caseMessageNotification: 0,
    unassignedCaseNotification: 0,
    rxRequestNotifications: []
};

export default function notifications(state = initialState, action) {
    switch (action.type) {
        case types.SET_PENDING_PRESCRIPTION_CASE_NOTIFICATION:
            return {
                ...state,
                pendingPrescriptionCaseNotification: action.data,
            };
        case types.SET_AUDIO_VIDEO_CASE_NOTIFICATION:
            return {
                ...state,
                audioVideoCaseNotification: action.data,
            };
        case types.SET_ADDITIONAL_INFO_CASE_UPDATE_NOTIFICATION:
            return {
                ...state,
                additionlInfoCaseUpdateNotification: action.data,
            };
        case types.SET_RX_CHANGE_CASE_NOTIFICATION:
            return {
                ...state,
                rxChangeCaseNotification: action.data,
            };
        case types.SET_CASE_MESSAGE_NOTIFICATION:
            return {
                ...state,
                caseMessageNotification: action.data,
            };
        case types.SET_UNASSIGNED_CASE_NOTIFICATION:
            return {
                ...state,
                unassignedCaseNotification: action.data,
            };
        case types.SET_RX_REQUEST_NOTIFICATION:
            return {
                ...state,
                rxRequestNotifications: Array.from(new Set(...state.rxRequestNotifications, action.data.type))
            }
        case types.CLEAR_RX_REQUEST_NOTIFICATION:
            return {
                ...state,
                rxRequestNotifications: []
            }
        default:
            return state;
    }
}

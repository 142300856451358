import actions from './actions';
import reducer from './reducer';

const caseQueue = {
    actions,
    reducer,
    name: 'caseQueue',
};

export default caseQueue;

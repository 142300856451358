import React from 'react'
import dayjs from 'dayjs'
import { Typography, Input } from 'antd'
import { PrescriptionItem } from '../../PrescribeMedicine/components/PrescriptionItem'

const { Text } = Typography

const CompoundIngredient = ({ ingredient }) => {
    return (
        <div style={{ display: 'flex', flexDirection: 'row', gap: '10px', textTransform: 'capitalize' }} key={ingredient.freeText || ingredient.name}>
            <div>{ingredient.freeText || ingredient.name},</div>
            <div>{ingredient.quantity} {ingredient.dispenseUnit}</div>
        </div>
    )
}

const renderCompoundIngredients = ingredients => {
    const ingredientComponents = ingredients.map(ingredient => <CompoundIngredient key={ingredient._id} ingredient={ingredient} />)
    return <PrescriptionItem style={{ gridColumnStart: 1, gridColumnEnd: 3, textTransform: 'none' }} label='Ingredients' value={ingredientComponents} />
}

export const Prescription = ({ 
    prescription, 
    title, 
    isEditable, 
    editRequest, 
    isPriorAuth,
    priorAuthNumber,
    setPriorAuthNumber,
    children
}) => {

    return (
        <div style={{ width: '50%', display: 'flex', flexDirection: 'column' }}>
            <Text style={{ fontSize: '1.05rem', fontWeight: 'bolder' }}>{title}</Text>
            { children }

            <div style={{ marginTop: '10px', display: 'flex', flexDirection: 'column', gap: '6px' }}>
                <PrescriptionItem  style={{ flexDirection: 'row' }} label='Medication:' value={prescription?.name || 'Unavailable'} isEditable={isEditable} editRequest={editRequest} />
                { prescription?.strength && <PrescriptionItem  style={{ flexDirection: 'row' }} label='Dosage:' value={prescription?.strength} /> }
                {prescription?.ingredients && renderCompoundIngredients(prescription?.ingredients || 'Unavailable')}
                <PrescriptionItem  style={{ flexDirection: 'row' }} label='Quantity:' value={prescription?.quantity || 'Unavailable'} />
                <PrescriptionItem  style={{ flexDirection: 'row' }} label='Days Supply:' value={prescription?.daysSupply || 'Unavailable'} />
                <PrescriptionItem  style={{ flexDirection: 'row' }} label='Refills' value={prescription?.refills || 'Unavailable'} />
                <PrescriptionItem  style={{ flexDirection: 'row' }} label='Effective Date:' value={dayjs(prescription?.startDate || new Date()).format('MM/DD/YYYY')} />
                <PrescriptionItem  style={{ gridColumnStart: 1, gridColumnEnd: 3, textTransform: 'none' }} label='Directions:' value={prescription?.directions || 'Unavailable'} />
                <PrescriptionItem style={{ flexDirection: 'row', textTransform: 'none' }} label="Pharmacy Notes:" value={prescription?.pharmacyNotes || 'none'} />
                <PrescriptionItem  style={{ flexDirection: 'row' }} label='Substitutions Allowed?' value={prescription?.noSubstitutions ? 'No' : 'Yes'} />
                { isPriorAuth && 
                    <div style={{ marginTop: '10px', textTransform: 'capitalize', display: 'flex', flexDirection: 'column', gap: '10px', gridColumn: `span 1` }}>
                        <div style={{ color: 'rgba(0,0,0,.65)', fontWeight: '400' }}>Prior Authorization Number</div>
                        <Input style={{ width: '50%'}} type="text" maxLength={35} value={priorAuthNumber} onChange={e => setPriorAuthNumber(e.target.value)}/>
                    </div>
                }
            </div>
        </div>
    )
}

import types from "./types";
import handleAPIErrorResponse from "../../utils/handleAPIResponseError";
import API from "../../axios/physician/PhysicianApi";

import { message } from "antd";

const setLoading = (status) => ({
    type: types.SET_LOADING,
    payload: status,
});

const setCaseList = (payload) => ({
    type: types.SET_WAITING_ROOM_CASE_LIST,
    payload
})

function getWaitingRoomCaseList(status) {
    return (dispatch) => {
        dispatch(setLoading(true));
        API.getCaseList(status).then(
            (res) => {
                if (res.data.success && res.data.payload) {
                    dispatch(setCaseList(res.data.payload))
                    dispatch(setLoading(false));
                } else {
                    dispatch(setLoading(false));
                    message.warning("Unable to update cases in waiting room");
                }
            }).catch(
                (error) => {
                    handleAPIErrorResponse(error, "Error occured while updaing cases in waiting room");
                    dispatch(setLoading(false));
                });
    };
}

const actions = {
    setLoading,
    setCaseList,
    getWaitingRoomCaseList,
};

export default actions;
import React from 'react'
import { Button, Typography } from 'antd'

export const IdentityVerificationResult = ({ stepForward }) => {
    return (
            <div style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: '10px'
            }}>
                <Typography.Text>Please follow the instructions sent to you via SMS for facial recognition identity verification. When complete, please click "Continue".</Typography.Text>
                <Button type="primary" style={{ width: '100px'}} onClick={stepForward}>Continue</Button>
            </div>
    )
}
import React from 'react'
import { Typography } from 'antd'
const { Title } = Typography

export const PrimaryTitle = () => (
    <>
        <Title level={4}>Patient Reconciliation</Title>
        <p style={{ fontSize: '.8rem', color: 'grey'}}>
            A patient match was unable to be made given the patient information supplied by the pharmacy.
            <br/>
            Select a matching LifeMD patient, if possible, otherwise click "No Match"
        </p>
    </>
  )

  export const SecondaryTitle = () => (
    <>
        <Title level={4}>Patient Reconciliation</Title>
        <p style={{ fontSize: '.8rem', color: 'grey'}}>
            <strong>Below are patient properties where our records differ fromt those of our prescriber service.</strong><br/>
            We've automatically defaulted to the values we have on file for our patient.<br />
            Toggle the entire patient, or specific values using the arrow buttons below. You'll be able to confirm all the patient's details on the following screen.
        </p>
    </>
  )

  export const TirtiaryTitle = () => (
    <>
        <Title level={4}>Patient Reconciliation</Title>
        <p style={{ fontSize: '.8rem', color: 'grey'}}>
            Confirm your selection.
        </p>
    </>
  )
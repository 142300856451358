import React from 'react'
import { DiffSpace } from './styles'
import { DiffItemContainer } from './styles'
import { DiffItem } from './components/DiffItem'

import { Button, Typography } from 'antd'

import {
    ArrowLeftOutlined,
    ArrowRightOutlined
} from "@ant-design/icons";

const { Text } = Typography

export const DiffContainer = ({ 
    localPatient, 
    prescriberPatient,
    mergedPatient,
    handleReconcilePatientProperty,
    selectedMergeSource,
    selectMergeSource
}) => {
    
    const comparableVals = [
        { title: 'First Name', property: 'firstName'},
        { title: 'Middle Name', property: 'middleName'},
        { title: 'Last Name', property: 'lastName'},
        { title: 'Date of Birth', property: 'dob'},
        { title: 'Street Address', property: 'address1'},
        { title: 'Unit/PO Box', property: 'address2'},
        { title: 'City', property: 'city'},
        { title: 'State', property: 'state'},
        { title: 'Postal Code', property: 'zip'},
        { title: 'Email Address', property: 'email'},
        { title: 'Primary Phone', property: 'primaryPhone'},
        { title: 'Gender', property: 'gender'},
        { title: 'Weight (lb)', property: 'weight'},
        { title: 'Height (in)', property: 'height'},
    ]

    const handleToggleMergeSource = () => {
        const newMergeDirection = selectedMergeSource === 'rtl' ? 'ltr' : 'rtl'
        const newSource = newMergeDirection === 'rtl' ? localPatient : prescriberPatient
        selectMergeSource(newMergeDirection)
        handleReconcilePatientProperty(newSource)
    }

    const localSelected = selectedMergeSource === 'rtl'
    
    return (
        <DiffSpace>
            <DiffItemContainer 
                style={{ 
                    fontSize: '1.3rem',
                    padding: '15px',
                    background: 'rgba(0,0,0,.1)'
                }}
            >
                <Text style={{ textAlign: 'right', ...localSelected && { fontWeight: 700 }}}>Veritas</Text>
                <div style={{ 
                    display: 'flex', 
                    flexDirection: 'column', 
                    alignItems: 'center', 
                    justifyContent: 'flex-start',
                    gap: '3px'
                }}>
                    Source
                    <Button type='primary' onClick={handleToggleMergeSource}>
                        { selectedMergeSource === 'rtl' ? <ArrowLeftOutlined/> : <ArrowRightOutlined /> }
                    </Button>
                </div>
                <Text style={ { ...!localSelected && { fontWeight: 700 }}}>Prescriber Service</Text>
            </DiffItemContainer>
            {
                comparableVals.map(val => {
                    if (
                        localPatient?.[val.property]?.toString() !== prescriberPatient?.[val.property]?.toString()
                    )
                        return (
                            <DiffItem 
                                valName={val.title}
                                propName={val.property}
                                localVal={localPatient?.[val.property]}
                                prescriberVal={prescriberPatient?.[val.property]}
                                reconciledVal={mergedPatient[val.property]}
                                handleReconcilePatientProperty={handleReconcilePatientProperty}
                                style={{ fontSize: '.9rem' }}
                            />
                        )
                    return <></>
                })
            }
        </DiffSpace>
    )
}
import React from 'react'
import { Typography, Button } from 'antd'
import { DiffItemContainer } from '../styles'
import moment from 'moment-timezone'

import {
    ArrowLeftOutlined,
    ArrowRightOutlined
} from "@ant-design/icons";

const { Text } = Typography

export const DiffItem = ({ 
    valName, 
    propName,
    localVal, 
    prescriberVal, 
    reconciledVal,
    handleReconcilePatientProperty,
    style={} 
}) => {
    
    const localSelected = localVal === reconciledVal
    const isDate = valName === 'Date of Birth'

    const toggleVal = val => {
        handleReconcilePatientProperty({ [propName]: val})
    }

    return (
        <DiffItemContainer style={style}>
            <Text style={{ textAlign: 'right', ...localSelected && { fontWeight: 700 }}}>{isDate ? moment(localVal).utc().format('MM/DD/YYYY') : localVal}</Text>
            <div style={{ 
                display: 'flex', 
                flexDirection: 'column', 
                alignItems: 'center', 
                justifyContent: 'flex-start',
                gap: '3px'
            }}>
                { valName }
                <Button type='primary' onClick={() => toggleVal(localSelected ? prescriberVal : localVal)}>
                    { localSelected ? <ArrowLeftOutlined/> : <ArrowRightOutlined /> }
                </Button>
            </div>
            <Text style={ { ...!localSelected && { fontWeight: 700 }}}>{isDate ? moment(prescriberVal).utc().format('MM/DD/YYYY') : prescriberVal}</Text>
        </DiffItemContainer>
    )
}
import { useState, useRef, useMemo, useCallback } from 'react';
import { debounce } from 'lodash'
import { message } from 'antd'

import PrescriptionsAPI from '../axios/prescription/PrescriptionsApi'

export const useSearchMedications = ({
    handleFieldsChange,
    form
}) => {
    const [medicationSearchMatches, setMedicationSearchMatches] = useState([])
    const [medicationSearchLoading, setMedicationSearchLoading] = useState(false)

    const [{ selectedMedication, selectedStrength }, setMedicationState] = useState({
        selectedMedication: null,
        selectedStrength: null
    })

    const abortController = useRef(null)

    const handleResetMedication = () => {
        setMedicationState({
            selectedMedication: null,
            selectedStrength: null
        })
    }

    const fetchMedications = useCallback(async searchStr => {
        form.resetFields(['ndc'])
        abortController.current?.abort()
        abortController.current = new AbortController()
        setMedicationSearchLoading(true)
        setMedicationSearchMatches([])

        if (searchStr?.length <= 2) {
            return setMedicationSearchLoading(false)
        }

        const abbreviatedSearch = searchStr.split(' ')[0]

        try {
            const { data: { medications } } = await PrescriptionsAPI.searchMedications(abbreviatedSearch, abortController.current?.signal)

            if (abortController.current?.signal.aborted) return

            setMedicationSearchMatches(medications)
           
            setMedicationState({
                selectedMedication: null,
                selectedStrength: null
            })

            return medications
        } catch (e) {
            console.error(e);
            message.error('Error fetching medication matches from prescription service');
        } finally {
            setMedicationSearchLoading(false);
        }
    }, [])

    const handleSelectMedication = medicationName => {
        const medicationMatch = medicationSearchMatches.find(({ name }) => name === medicationName);
        
        if (!medicationMatch) {
            return handleResetMedication()
        }

        const isCompound = medicationMatch.ingredients?.length
        const selectedStrength = isCompound
            ? { ...medicationMatch, ...medicationMatch.prescribingDefaults }
            : medicationMatch.strengths?.[0] || medicationMatch

        form.resetFields([
            'ndc',
            'ingredients',
            'dispenseableDrugId',
            'dispenseUnit',
            'quantityUnits'
        ])
        
        if (isCompound) {
            form.setFieldsValue({ ingredients: selectedStrength.ingredients })
        }

        const strength = selectedStrength?.strength ||  'Compound'

        setMedicationState({
            selectedMedication: medicationMatch,
            selectedStrength
        })

        form.setFieldsValue({ 
            strength, 
            ndc: selectedStrength.ndc, 
            dispensableDrugId: selectedStrength?.dispensableDrugId,
            dispenseUnitId: selectedStrength.dispenseUnitId,
            quantityUnits: selectedStrength?.doseForm
        })
        
        handleFieldsChange()
    };


    const selectStrength = strength => {
        const selectedStrength = selectedMedication?.strengths?.find(medStrength => medStrength.strength === strength)
         
        setMedicationState(prev => ({
            ...prev,
            selectedStrength
        }))

    }

    const debounceFetchMedications = useMemo(() => debounce(fetchMedications, 500), []);

    return { 
        fetchMedications: debounceFetchMedications, 
        medicationSearchMatches, 
        medicationSearchLoading,
        selectedMedication, 
        selectedStrength,
        handleSelectMedication,
        selectStrength
    }
}
import React from "react";
import { Card, Row, Col, Button } from "antd";
import { Redirect, useHistory } from "react-router-dom";

// import CSS
import "../LoginSelection.css";

// import components
import Logo from "../../../assets/images/svg/logo-one.png";
import Doctoractive from "../../../assets/images/svg/PhysicianActive.svg";
import SafeConnection from "../../../components/SafeConnection";
import LoginPhysician from "./components/LoginPhysician";

const PhysicianLogin = () => {
  const history = useHistory();

  const onUserLinkClick = () => {
    history.push("./login-case");
  };

  return window.localStorage.getItem("token") !== null &&
    window.localStorage.getItem("type") === "physician" ? (
    <Redirect to="/dashboard" />
  ) : window.localStorage.getItem("token") !== null &&
    window.localStorage.getItem("caseId") !== null ? (
    <Redirect
      to={`/case/scheduled-call/${window.localStorage.getItem("caseId")}`}
    />
  ) : (
    <div className="loginselection-part">
      <Card className="login-selection-part-card">
        <Row className="login-section-row-wrapper" gutter={[16, 16]}>
          <Col
            className="wrapper-text-align"
            xl={12}
            lg={12}
            md={24}
            sm={24}
            xs={24}
          >
            <img
              src={Doctoractive}
              alt="logo"
              className="logo-active-inactive-selection"
            ></img>

            <p>
              <b>I'm a</b>
              <span style={{ color: "#7BAD7E" }}>
                <b>{` Physician`}</b>
              </span>
            </p>
          </Col>
          <Col xl={12} lg={12} md={24} sm={24} xs={24}>
            <LoginPhysician />
            <div className="wrapper-text-align">
              <Button
                onClick={onUserLinkClick}
                className="user-button-link"
                type="link"
              >
                Are you a Patient?
              </Button>
            </div>
            <hr></hr>
            <SafeConnection />
          </Col>
        </Row>
      </Card>

      <div className="login-selection-part-one">
        <img
          src={Logo}
          alt="logo"
          className="login-selection-part-one-image"
        ></img>
      </div>
      <div className="login-selection-part-two" />
    </div>
  );
};

export default PhysicianLogin;

import Axios from "../Axios"
import config from "../../config";

const { api } = config;

export default class API {
	static getToken() {
        const token = window.localStorage.getItem("token");
        return token;
    }

	static create(type, data) {
		const token = this.getToken();
		return Axios({
            url: `${api.baseUrl}/templates`,
            method: "POST",
			data,
			params: { type },
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

	}

	static getMany(params) {
		const token = this.getToken();
		return Axios({
            url: `${api.baseUrl}/templates`,
            method: "GET",
			params,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
	}

	static getOne(id) {
		const token = this.getToken();
		return Axios({
            url: `${api.baseUrl}/templates/${id}`,
            method: "GET",
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
	}

	static deleteOne(id) {
		const token = this.getToken();
		return Axios({
            url: `${api.baseUrl}/templates/${id}`,
            method: "DELETE",
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
	}

	static update(id, data) {
		const token = this.getToken();
		return Axios({
            url: `${api.baseUrl}/templates/${id}`,
            method: "PUT",
			data,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
	}
}
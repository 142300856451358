import styled from 'styled-components'
import { Button } from 'antd'

export const SelectMergePatientContainer = styled.div`
    display: flex;
    flex-direction: column;
`

export const LocalPatientRecord = styled.div`
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;
`

export const StyledBackButton = styled(Button)`
    align-self: flex-start;
`

export const StyledConfirmButton = styled(Button)`
    align-self: flex-end;
`
import { useEffect, useState, useMemo } from "react";
import PhysicianAPI from "../axios/physician/PhysicianApi";
import { TemplateTypeEnum, FILTER } from '../types/enums'
import decodeToken from "../utils/decodeToken";
import { sorters } from "../utils/sorters";

export const useGetFilteredTemplates = ({ caseCategoryId, caseStatus }) => {
    const [commonCaseTemplates, setCommonCaseTemplates] = useState([]);
    const [commonExtraMessageTemplates, setCommonExtraMessageTemplates] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const [templateFilter, setTemplateFilter] = useState(FILTER.MY);

    const getTemplateMessages = (status, telemedicineCategoryId) => {
        const filters = {
          categoryIds: [telemedicineCategoryId],
          status,
        };
        setIsLoading(true);
        Promise.all([
          PhysicianAPI.getTemplates(TemplateTypeEnum.CASE, { filters })
            .then((res) => setCommonCaseTemplates(res.data.templates.sort(sorters.favorite))),
          PhysicianAPI.getTemplates(TemplateTypeEnum.MESSAGE, { filters })
            .then((res) => setCommonExtraMessageTemplates(res.data.templates.sort(sorters.favorite)))
        ])
        .catch((error) => console.log( "================ template message fetch error ==================", error))
        .finally(() => {
          setIsLoading(false);
        });
      }

      const userId = useMemo(() => {
        const decodedToken = decodeToken(localStorage.getItem('token'));
        return decodedToken?.user?._id;
      }, []);

      useEffect(() => {
        if (caseCategoryId && caseStatus)  {
            getTemplateMessages(caseStatus, caseCategoryId);
        }
      }, [caseCategoryId, caseStatus]);

      const filterMap = {
        [FILTER.SHARED]: template => template.ownerId !== userId,
        [FILTER.ADMIN]: template => !template.ownerId,
        [FILTER.MY]: template => template.ownerId === userId,
        [FILTER.ALL]: () => true,
      }

      const filteredCaseTemplates = commonCaseTemplates.filter(filterMap[templateFilter] || (() => true))
      const filteredExtraMessageTemplates = commonExtraMessageTemplates.filter(filterMap[templateFilter] || (() => true))

  return {
    isLoading,
    templateFilter,
    setTemplateFilter,
    caseTemplates: commonCaseTemplates,
    extraMessageTemplates: commonExtraMessageTemplates,
    filteredCaseTemplates,
    filteredExtraMessageTemplates,
  }
}

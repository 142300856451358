import types from "./types";
import moment from "moment-timezone";

const initState = {
  monthlyReportsLoading: false,

  isLoadingCaseReport: false,
  isLoadingConsultReport: false,
  isLoadingCompletionCounts: false,

  caseReportRequestId: 0,
  consultReportRequestId: 0,
  completionCountsRequestId: 0,

  monthlyCaseReport: null,
  consultReport: null,
  completionCounts: null,
  reportDate: new Date(),
  reportMonth: moment().month(),
  reportYear: moment().year(),
};

export default function reports(state = initState, action) {
  switch (action.type) {
    case types.SET_PHYSICIAN_MONTHLY_REPORT_LOADING:
      return {
        ...state,
        monthlyReportsLoading: action.payload,
      };

    case types.SET_IS_LOADING_CASE_REPORT:
      return {
        ...state,
        isLoadingCaseReport: action.payload,
      };
    case types.SET_IS_LOADING_CONSULT_REPORT:
      return {
        ...state,
        isLoadingConsultReport: action.payload,
      };

    case types.SET_IS_LOADIN_COMPLETION_COUNTS:
      return {
        ...state,
        isLoadingCompletionCounts: action.payload,
      };

    case types.SET_PHYSICIAN_REPORT_MONTH:
      return {
        ...state,
        reportMonth: action.payload,
      };
    case types.SET_PHYSICIAN_REPORT_YEAR:
      return {
        ...state,
        reportYear: action.payload,
      };
    case types.SET_MONTHLY_REPORT_DATE:
      return {
        ...state,
        reportDate: action.payload,
      };
    case types.SET_MONTHLY_CASE_REPORT:
      return {
        ...state,
        monthlyCaseReport: action.payload,
      };
    case types.SET_MONTHLY_CONSULT_REPORT:
      return {
        ...state,
        consultReport: action.payload
      }
    case types.SET_PHYSICIAN_MONTHLY_REPORT:
      return {
        ...state,
        monthlyReport: action.payload,
      };

    case types.SET_CASE_COMPLETION_COUNTS:
      return {
        ...state,
        completionCounts: action.payload
      }
    case types.INCREMENT_CASE_REPORT_REQUEST_ID:
      return {
        ...state,
        caseReportRequestId: state.caseReportRequestId + 1
      }
    case types.INCREMENT_CONSULT_REPORT_REQUEST_ID:
      return {
        ...state,
        consultReportRequestId: state.consultReportRequestId + 1
      }
    case types.INCREMENT_COMPLETION_COUNTS_REQUEST_ID:
      return {
        ...state,
        completionCountsRequestId: state.completionCountsRequestId + 1
      }
    default:
      return state;
  }
}

import React from 'react'
import { Form } from 'antd'

const RequiredFormField = ({ label, name, rules = [], style, children }) => {
    return (
        <Form.Item
            required
            label={label}
            name={name}
            style={style || { display: 'inline-block', width: 'calc(50% - 12px)' }}
            rules={[...rules, { required: true }]}
        >
            {children}
        </Form.Item>
    )
}

export default RequiredFormField
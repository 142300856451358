import React, { useState } from 'react'
import { Skeleton } from 'antd'
import { EditOutlined } from '@ant-design/icons'

import { SectionHeader } from '../../../SectionHeader'
import { StyledButton } from '../../../Button'
import { Item } from "../../../Item"
import { PatientBiometricsModal } from './PatientBiometrics.Modal'

import { Container } from "../../../../Styles"

import { useCaseViewContext } from '../../../../context/CaseViewContext'
import { VTooltip } from "../../../../../../shared/ui/Tooltip/Tooltip";

export const Biometrics = () => {
    const [editModalVisible, setEditModalVisible] = useState(false);
    const [recordedAllergies, setRecordedAllergies] = useState([]);
    const { caseDetails, fetchCaseDetails } = useCaseViewContext()

    function calculateBMI(weight, height) {
        const bmi = (weight / (height ** 2)) * 703;
        return Math.round(bmi);
    }

    return (
        <Container>
            <SectionHeader title="Biometrics">
                <StyledButton onClick={() => setEditModalVisible(true)}>
                  <VTooltip title='Edit Biometric Information'>
                    <EditOutlined />
                  </VTooltip>
                </StyledButton>
            </SectionHeader>
            <Skeleton loading={!caseDetails} paragraph={{ rows: 6 }}>
                {caseDetails?.heightFt > 0 && <Item textTransform='none' label="Height" value={`${caseDetails?.heightFt * 12 + (Number(caseDetails.heightIn) || 0)} in`} />}
                {caseDetails?.weight > 0 && <Item textTransform='none' label="Weight" value={`${caseDetails.weight} lbs`} />}
                {caseDetails?.weight > 0 && caseDetails?.heightFt > 0 && <Item label="BMI" value={calculateBMI(caseDetails?.weight, (caseDetails?.heightFt * 12 + Number(caseDetails?.heightIn)))} />}
                <Item label="Blood Pressure" value={caseDetails?.bloodPressure} />
                <Item 
                    label="Reported Meds" 
                    value={caseDetails?.medications?.split(',').join(' ') || 'None'} 
                    emphasize
                    emphasizeExceptions={["none", "no"]}
                />
                <Item label="Patient-reported Allergies" value={caseDetails?.allergies} />
                <Item 
                    label="Recorded Allergies" 
                    value={recordedAllergies.length ? recordedAllergies : 'None'} 
                />
            </Skeleton>
            <PatientBiometricsModal
                caseDetails={caseDetails}
                fetchCaseDetails={fetchCaseDetails}
                editModalVisible={editModalVisible}
                setEditModalVisible={setEditModalVisible}
                setRecordedAllergies={setRecordedAllergies}
            />
        </Container>
    )
}
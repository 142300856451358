/* eslint-disable no-template-curly-in-string */
/* eslint-disable react-hooks/exhaustive-deps */
//import packages
import React, { useState, useEffect } from "react";
import { Modal, Spin, Row, Col } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

//import CSS
import "./CaseUpdateComparisonComponent.css";

//import components
import CasePhotoAndIdViewComponent from "../CasePhotoAndIdViewComponent/CasePhotoAndIdViewComponent";
import GeneralUpdateViewComponent from "../GeneralUpdateViewComponent/GeneralUpdateViewComponent";
import MIFView from "../../pages/caselist/MIFView";

//import APIs and Functions
import physicianApi from "../../axios/physician/PhysicianApi";

const CaseUpdateComparisonComponent = ({
  modalVisible,
  caseId,
  setModalVisible,
  clearCaseNotificationCallback,
}) => {
  const [loading, setLoading] = useState(false);
  const [mifUpdatesComparisonDetails, setMIFUpdateComparisonDetails] = useState(
    null
  );
  const [
    imageUpdateComparisonDetails,
    setImageUpdateComparisonDetails,
  ] = useState(null);
  const [
    addressUpdateComparisonDetails,
    setAddressUpdateComparisonDetails,
  ] = useState(null);
  const initNetworkRequest = () => {
    if (caseId) {
      setLoading(true);
      physicianApi
        .getCaseDetailsById(caseId)
        .then((res) => {
          const { data } = res;
          if (data && data.success === true) {
            const { payload } = data;
            console.log(
              "======================= payload from Data ===================",
              payload
            );
            const { notifications } = payload;
            let imagesNotificationData = null;
            let mifNotificationData = null;
            let addressNotificationData = {};
            let generalUpdateOldData = {},
              generalUpdateNewData = {};
            if (notifications && notifications.length) {
              notifications.forEach((eachNotifications) => {
                const { data } = eachNotifications;
                if (data && data.event === "case images update") {
                  const {
                    newData: responsenewData,
                    oldData: responseOldData,
                  } = data;
                  imagesNotificationData = {};
                  let oldData = {},
                    newData = {};
                  oldData = { ...responseOldData };
                  newData = { ...responsenewData };
                  imagesNotificationData.oldData = { ...oldData };
                  imagesNotificationData.newData = { ...newData };
                }
                if (data && data.event === "case mif update") {
                  const {
                    newData: responsenewData,
                    oldData: responseOldData,
                  } = data;
                  mifNotificationData = {};
                  let oldData = [],
                    newData = [];
                  try {
                    oldData = JSON.parse(
                      responseOldData.mif
                        ? responseOldData.mif
                        : JSON.stringify([])
                    );
                    newData = JSON.parse(
                      responsenewData.mif
                        ? responsenewData.mif
                        : JSON.stringify([])
                    );
                  } catch (err) {
                    console.error(
                      "::::::::::::::::: MIF old and new data parsing error:::::::::::::::::::",
                      err
                    );
                  }
                  mifNotificationData.oldData = [...oldData];
                  mifNotificationData.newData = [...newData];
                }
                if (data && data.event === "case general update") {
                  const {
                    newData: responseNewData,
                    oldData: responseOldData,
                  } = data;

                  generalUpdateOldData = {
                    ...generalUpdateOldData,
                    ...responseOldData,
                  };
                  generalUpdateNewData = {
                    ...generalUpdateNewData,
                    ...responseNewData,
                  };
                }
              });
              addressNotificationData = {};
              addressNotificationData.keys =
                generalUpdateOldData && Object.keys(generalUpdateOldData)
                  ? Object.keys(generalUpdateOldData)
                  : [];
              addressNotificationData.oldData = { ...generalUpdateOldData };
              addressNotificationData.newData = { ...generalUpdateNewData };
            }
            console.log(
              ":::::::::::::::  address notification ::::::::::::::::",
              addressNotificationData
            );
            setImageUpdateComparisonDetails(imagesNotificationData);
            setMIFUpdateComparisonDetails(mifNotificationData);
            setAddressUpdateComparisonDetails(addressNotificationData);
          }
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };

  useEffect(initNetworkRequest, [caseId]);

  const handleModalCancel = () => {
    setMIFUpdateComparisonDetails(null);
    setImageUpdateComparisonDetails(null);
    setAddressUpdateComparisonDetails(null);
    setModalVisible();
  };

  const handleClearCaseNotification = () => {
    setLoading(true);
    physicianApi
      .clearCaseNotification(caseId)
      .then((res) => {
        const { data } = res;
        if (data && data.success === true) {
          if (
            clearCaseNotificationCallback &&
            typeof clearCaseNotificationCallback === "function"
          ) {
            setMIFUpdateComparisonDetails(null);
            setImageUpdateComparisonDetails(null);
            setAddressUpdateComparisonDetails(null);
            clearCaseNotificationCallback();
          }
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  return (
    <Modal
      visible={modalVisible}
      title="Compare updated details of Case"
      maskClosable={false}
      closable={false}
      centered
      destroyOnClose
      okText="Clear notification"
      cancelText="Close Details"
      onOk={handleClearCaseNotification}
      onCancel={handleModalCancel}
      okButtonProps={{
        loading: loading,
      }}
      cancelButtonProps={{
        loading: loading,
      }}
      width={"80%"}
      bodyStyle={{ height: "80vh", overflow: "auto" }}
    >
      <Spin
        spinning={loading}
        size="large"
        wrapperClassName="spin-wrapper"
        indicator={<LoadingOutlined />}
      >
        <article className="case-comparison">
          <section>
            {addressUpdateComparisonDetails &&
            Object.keys(addressUpdateComparisonDetails).length &&
            addressUpdateComparisonDetails.oldData &&
            addressUpdateComparisonDetails.newData &&
            Object.keys(addressUpdateComparisonDetails.oldData).length &&
            Object.keys(addressUpdateComparisonDetails.newData).length ? (
              <>
                <h2 className="title-color">
                  <b>Compare Previous and Current Patient Details</b>
                </h2>
                <Row className="wrapper-row">
                  <Col xs={24} sm={24} md={12} lg={12} xl={12} className="updates-col-wrapper">
                    <GeneralUpdateViewComponent
                      title="Previous Patient Details"
                      properties={addressUpdateComparisonDetails.keys}
                      data={addressUpdateComparisonDetails.oldData}
                    />
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12} className="updates-col-wrapper">
                    <GeneralUpdateViewComponent
                      title="Current Patient Details"
                      properties={addressUpdateComparisonDetails.keys}
                      data={addressUpdateComparisonDetails.newData}
                    />
                  </Col>
                </Row>
                <hr className="hr-separation" />
              </>
            ) : (
              <></>
            )}
          </section>
          <section>
            {imageUpdateComparisonDetails &&
              Object.keys(imageUpdateComparisonDetails).length && (
                <>
                  <h2 className="title-color">
                    <b>Compare Previous and Current Photos</b>
                  </h2>
                  <Row className="wrapper-row">
                    <Col xs={24} sm={24} md={12} lg={12} xl={12} className="updates-col-wrapper">
                      <CasePhotoAndIdViewComponent
                        title="Previous Photo and Id"
                        facePhotoUrl={
                          imageUpdateComparisonDetails.oldData &&
                          imageUpdateComparisonDetails.oldData.photo
                            ? imageUpdateComparisonDetails.oldData.photo
                            : ""
                        }
                        photoIdUrl={
                          imageUpdateComparisonDetails.oldData &&
                          imageUpdateComparisonDetails.oldData.idProof
                            ? imageUpdateComparisonDetails.oldData.idProof
                            : ""
                        }
                      />
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12} className="updates-col-wrapper">
                      <CasePhotoAndIdViewComponent
                        title="Current Photo and Id"
                        facePhotoUrl={
                          imageUpdateComparisonDetails.newData &&
                          imageUpdateComparisonDetails.newData.photo
                            ? imageUpdateComparisonDetails.newData.photo
                            : ""
                        }
                        photoIdUrl={
                          imageUpdateComparisonDetails.newData &&
                          imageUpdateComparisonDetails.newData.idProof
                            ? imageUpdateComparisonDetails.newData.idProof
                            : ""
                        }
                      />
                    </Col>
                  </Row>
                </>
              )}
          </section>
          <section>
            {mifUpdatesComparisonDetails &&
              Object.keys(mifUpdatesComparisonDetails).length && (
                <>
                  <hr className="hr-separation" />
                  <h2 className="title-color">
                    <b>Compare Previous and Current MIF</b>
                  </h2>
                  <Row className="mif-wrapper-row wrapper-row">
                    <Col xs={24} sm={24} md={12} lg={12} xl={12} className="updates-col-wrapper">
                      <MIFView
                        title="Previous Health Analysis"
                        answeredQuestions={
                          mifUpdatesComparisonDetails.oldData
                            ? mifUpdatesComparisonDetails.oldData
                            : []
                        }
                      />
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12} className="updates-col-wrapper">
                      <MIFView
                        title="Current Health Analysis"
                        answeredQuestions={
                          mifUpdatesComparisonDetails.newData
                            ? mifUpdatesComparisonDetails.newData
                            : []
                        }
                      />
                    </Col>
                  </Row>
                </>
              )}
          </section>
        </article>
      </Spin>
    </Modal>
  );
};

export default CaseUpdateComparisonComponent;

import React from 'react'
import { Table, Row, Typography } from 'antd'
import HTMLReactParser from 'html-react-parser';

export const CaseVisitNotes = ({ caseDetails }) => {

    const visitNotes = (caseDetails.requests || [])
        .filter(request => !!request.visitNote)
        .map(request => ({
            completedAt: request.completedAt,
            completedBy: `${request.completedBy?.firstName} ${request.completedBy?.lastName}`,
            visitNote: request.visitNote
        }))

    const columns = [
        { 
            dataIndex: 'visitNote', 
            title: 'Visit Note', 
            render: str => HTMLReactParser(str),
        },
        {
            dataIndex: 'completedBy',
            title: 'Provider',
        },
        { 
            dataIndex: 'completedAt', 
            title: 'Creation Date',  
            align: 'right',
            render: date => new Date(date).toLocaleDateString()
        }
    ]

    return (
        <Row className="case-details-row-wrapper">
            <Typography.Title level={4} className="sub-content-color">Visit Notes</Typography.Title>
            <Table
                style={{ width: '100%'}}
                columns={columns}
                pagination={false}
                dataSource={visitNotes}
            />
        </Row>
    )
}
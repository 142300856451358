import React from "react";
import { Select } from "antd";
import { StyledVideoIcon } from "./StyledComponents";
import { useSelector, useDispatch } from "react-redux";
import caseQueue from "../../redux/caseQueue"

import {
  ReloadOutlined,
  PhoneOutlined,
  MedicineBoxOutlined,
  SwapOutlined,
  ExperimentOutlined
} from "@ant-design/icons";
import { CaseStatusEnum } from "../../types/enums"

const { Option } = Select;

const SelectStatus = () => {
    const caseQueueLoading = useSelector(state => state.caseQueue.loading);
    const dispatch = useDispatch();
    const onChange = (value) => {
        dispatch(caseQueue.actions.setCaseQueueStatusFilter(value));
    }

    const style = { width: '200px', textTransform: 'capitalize', margin: '10px 0 20px 0' }

    return (
      <Select style={style} defaultValue='All' onChange={onChange} disabled={caseQueueLoading}>
        <Option value={null}>All</Option>
        <Option value={CaseStatusEnum.PENDING_PRESCRIPTION}><MedicineBoxOutlined style={{ marginRight: '4px'}}/> New Async Cases</Option>
        <Option value={CaseStatusEnum.RX_RENEWAL}><ReloadOutlined style={{ marginRight: '8px'}}/>Rx Renewals</Option>
        <Option value={CaseStatusEnum.RX_CHANGE}><SwapOutlined style={{ marginRight: '8px'}}/>Rx Changes</Option>
        <Option value={CaseStatusEnum.PENDING_SCHEDULE}><PhoneOutlined/> / <StyledVideoIcon style={{ fontSize: '12px', marginRight: '8px'}} /> Sync Cases</Option>
        <Option value={CaseStatusEnum.PENDING_LABS}><ExperimentOutlined style={{ marginRight: '8px'}}/>Labs</Option>
      </Select>
    )
}

export default SelectStatus;

import React, { useState } from "react";
import { Button, Form, Input, message } from "antd";
import PhysicianApi from "../../../../axios/physician/PhysicianApi";
import { useHistory } from "react-router-dom";

import "./PhysicianPasswordChange.css";
import { PasswordChecker, isValidPassword } from "../../../../components/PasswordChecker";

const PhysicianPasswordChange = () => {
  const [form] = Form.useForm();
  const history = useHistory();
  const [newPassword, setNewPassword] = useState('');
  const [loading, setLoading] = useState(false);

  const updatePassword = (value) => {
    const data = {
      oldPassword: value.oldPassword,
      newPassword: value.confirm,
    };
    setLoading(true);

    PhysicianApi.changePassword(data)
      .then((res) => {
        const { data } = res;
        if (data && data.success) {
          message.success(res.data.message);
          setLoading(false);
          window.localStorage.clear();
          message.info("You will be redirected to login page shortly");
          setTimeout(() => {
            history.push("/login-physician");
          }, 2000);
        } else {
          message.error(data.message);
          setLoading(false);
        }
      })
      .catch((error) => {
        const { response } = error;
        if (response) {
          const { data } = response;
          if (data && data.message) {
            message.error(data.message);
            setLoading(false);
          } else {
            message.error("Unable to Change Password , Please Try Again!");
            setLoading(false);
          }
        }
      });
  };

  return (
    <>
      <div className="password-change-physician">
        <h1>Change Password</h1>
        <PasswordChecker password={newPassword} />
        <Form
          layout="vertical"
          form={form}
          size="large"
          onFinish={updatePassword}
        >
          <Form.Item
            name="oldPassword"
            label="Current Password"
            rules={[
              {
                required: true,
                message: "Please input your Current Password!",
                whitespace: true,
              },
            ]}
          >
            <Input.Password placeholder="Enter Current Password" />
          </Form.Item>

          <Form.Item
            name="password"
            label="New Password"
            rules={[
              {
                required: true,
                message: "Please input your New Password!",
                whitespace: true,
              },
            ]}
            hasFeedback
          >
            <Input.Password placeholder="Enter New Password" onChange={(e) => {
              setNewPassword(e.target.value);
            }} />
          </Form.Item>
          <Form.Item
            name="confirm"
            label="Confirm Password"
            dependencies={["password"]}
            hasFeedback
            rules={[
              {
                required: true,
                message: "Please confirm your password!",
                whitespace: true,
              },
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }

                  return Promise.reject(
                    "The two passwords that you entered do not match!"
                  );
                },
              }),
            ]}
          >
            <Input.Password placeholder="Confirm New Password" />
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              size="large"
              className="password-change-save-button"
              htmlType="submit"
              loading={loading}
              disabled={!isValidPassword(newPassword)}
            >
              Change Password
            </Button>
          </Form.Item>
        </Form>
      </div>
    </>
  );
};

export default PhysicianPasswordChange;

import React, { useState, useEffect } from 'react';
import { Button, Modal, message } from 'antd';
import axios from 'axios';
import config from '../config';


export const useVersionUpdater = () => {
  const [updateFound, setUpdateFound] = useState(false);
  const [triggerUpdateCheckAt, setTriggerUpdateCheckAt] = useState(null);

  const updateApp = async () => {
    return axios
      .get(`${config.api.baseUrl}/versions`, { params: { app: 'provider' } })
      .then(({ data }) => {
        if (config.LATEST_SHA === data.sha) return;
        setUpdateFound(true);

        Modal.info({
          title: 'Veritas App Update Found!',
          content: 'Click Update to refresh the page and get the latest version',
          mask: false,
          okText: 'Update',
          cancelButtonProps: { style: { hidden: true } },
          maskClosable: true,
          onOk: () => window.location.reload(),
          onCancel: () => message.info({ 
            duration: null, 
            content: (
              <span>
                <span style={{ marginRight: 10 }}>Outdated versions can cause unexpected behaviors. Click Update to refresh the page and get the latest version</span>
                <Button type='default' onClick={() => window.location.reload()}>Update</Button> 
              </span>
            )
          })
        })
      })
  }


  useEffect(() => {
    if (!updateFound && !!config.LATEST_SHA) {
      let checkUpdateInTenMins;
      updateApp()
        .finally(() => {
          checkUpdateInTenMins = setTimeout(() => setTriggerUpdateCheckAt(Date.now()), 1000 * 60 * 10) // 10 mins
        });

      return () => clearTimeout(checkUpdateInTenMins);
    }
    // eslint-disable-next-line
  }, [triggerUpdateCheckAt]);
}
import React, { useState, useEffect } from "react";
import { Tabs, Typography } from "antd";
import { connect } from "react-redux";
import ArticleList from "./components/ArticleList";

const { TabPane } = Tabs;
const { Title } = Typography;

const Articles = ({ articleCategoryList }) => {
  const [articleType, setArticleType] = useState("protocol");
  const [clinicalId, setClinicalId] = useState();
  const [trainingId, setTrainingId] = useState();

  const articleCategory = () => {
    articleCategoryList.forEach((item) => {
      if (item.name === "Clinical Protocol") {
        setClinicalId(item._id);
      }
      if (item.name === "Training") {
        setTrainingId(item._id);
      }
    });
  };

  useEffect(() => {
    if (articleCategoryList) {
      articleCategory();
    }
    // eslint-disable-next-line
  }, [articleCategoryList]);

  const ArticleCategoryListId = (value) => {
    setArticleType(value);
  };

  return (
    <div className="article-wrapper">
      <Title level={1}>Articles</Title>
      <Tabs
        defaultActiveKey="protocol"
        type="card"
        centered
        onChange={ArticleCategoryListId}
      >
        <TabPane tab="Clinical Protocol" key="protocol">
          <ArticleList type={articleType} valueClinical={clinicalId} />
        </TabPane>
        <TabPane tab="Training" key="training">
          <ArticleList type={articleType} valueTraining={trainingId} />
        </TabPane>
      </Tabs>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { articleCategoryList } = state.entities;

  return {
    articleCategoryList,
  };
};

export default connect(mapStateToProps)(Articles);

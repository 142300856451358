import types from './types';

const setPendingPrescriptionCaseNotification = data => ({
    type: types.SET_PENDING_PRESCRIPTION_CASE_NOTIFICATION,
    data,
});


const setAudioVideoCaseNotification = data => ({
    type: types.SET_AUDIO_VIDEO_CASE_NOTIFICATION,
    data,
});

const setAdditionalInfoCaseUpdateNotification = data => ({
    type: types.SET_ADDITIONAL_INFO_CASE_UPDATE_NOTIFICATION,
    data,
});

const setRxChangeCaseNotification = data => ({
    type: types.SET_RX_CHANGE_CASE_NOTIFICATION,
    data,
});

const setCaseMessageNotification = data => ({
    type: types.SET_CASE_MESSAGE_NOTIFICATION,
    data,
});

const setUnassignedCaseNotification = data => ({
    type: types.SET_UNASSIGNED_CASE_NOTIFICATION,
    data,
});
const setRxRequestNotification = data => ({
    type: types.SET_RX_REQUEST_NOTIFICATION,
    data
})

function pendingPrescriptionCaseNotification() {
    return (dispatch, getState) => {
        const data = getState().notifications.pendingPrescriptionCaseNotification + 1;
        dispatch(setPendingPrescriptionCaseNotification(data));
    }
};

function audioVideoCaseNotification() {
    return (dispatch, getState) => {
        const data = getState().notifications.audioVideoCaseNotification + 1;
        dispatch(setAudioVideoCaseNotification(data));
    }
};

function additionalInfoCaseUpdateNotification() {
    return (dispatch, getState) => {
        const data = getState().notifications.additionalInfoCaseUpdateNotification + 1;
        dispatch(setAdditionalInfoCaseUpdateNotification(data));
    }
};

function rxChangeCaseNotification() {
    return (dispatch, getState) => {
        const data = getState().notifications.rxChangeCaseNotification + 1;
        dispatch(setRxChangeCaseNotification(data));
    }
};

function caseMessageNotification() {
    return (dispatch, getState) => {
        const data = getState().notifications.caseMessageNotification + 1;
        dispatch(setCaseMessageNotification(data));
    }
};

function unassignedCaseNotification() {
    return (dispatch, getState) => {
        const data = getState().notifications.unassignedCaseNotification + 1;
        dispatch(setUnassignedCaseNotification(data));
    }
};

function rxRequestNotification(data) {
    return dispatch => {
        dispatch(setRxRequestNotification(data));
    }
}

function clearRxRequestNotifications() {
    return dispatch => {
        dispatch({
            type: types.CLEAR_RX_REQUEST_NOTIFICATION,
        })
    }
}

const actions = {
    setPendingPrescriptionCaseNotification,
    setAudioVideoCaseNotification,
    setAdditionalInfoCaseUpdateNotification,
    setRxChangeCaseNotification,
    setCaseMessageNotification,
    setUnassignedCaseNotification,
    pendingPrescriptionCaseNotification,
    audioVideoCaseNotification,
    additionalInfoCaseUpdateNotification,
    rxChangeCaseNotification,
    caseMessageNotification,
    unassignedCaseNotification,
    rxRequestNotification,
    clearRxRequestNotifications
};

export default actions; 
import React, { useState } from 'react'
import { Button, Typography, Tooltip, Modal } from 'antd'
import { LeftOutlined, EyeOutlined } from '@ant-design/icons';

import { useProviderContext } from '../context/ProviderContext';
import { useCaseContext } from '../context/CaseContext';
import CaseDetailsAccordion from '../../../components/CaseDetailsAccordion'

import { history } from "../../../helpers";

const { Text, Title } = Typography

export const Header = () => {
    const { _case } = useCaseContext()
    const { provider } = useProviderContext()
    const [caseDetailsModalIsVisible, setCaseDetailsModalIsVisible] = useState(false);

    return (
        <div style={{ display: 'flex', flexDirection: 'column', width: '100%', justifyContent: 'space-between' }}>
            <Button
                style={{ marginBottom: '20px', marginLeft: 'auto', width: '200px' }}
                onClick={() => history.push(`/dashboard/physician/caseview/${_case?._id}`)}
            >
                <LeftOutlined />
                Back to Case Details
            </Button>
            <div style={{ display: 'flex', gap: '10px', height: '45px', marginBottom: '20px'}}>
                <Tooltip placement="right" title="View MIF">
                    <Button
                        type="primary"
                        size="large"
                        style={{ height: '100%'}}
                        icon={<EyeOutlined />}
                        onClick={() => setCaseDetailsModalIsVisible(true)}
                    />
                </Tooltip>
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                    <Title level={4} style={{ fontWeight: '800', marginBottom: '-5px', display: 'inline-block' }}>Provide Prescription</Title>
                    <Text>Provider: {provider?.username}</Text>
                </div>
            </div>
            <Modal 
                width='40vw'
                footer={null} 
                closable={false}
                visible={caseDetailsModalIsVisible} 
                onCancel={() => setCaseDetailsModalIsVisible(false)} 
                bodyStyle={{ overflowY: 'scroll', height: '80vh' }}
            >
                <CaseDetailsAccordion caseId={_case?._id} defaultActiveKey='1' />
            </Modal>
        </div>
    )
}

import React, { useState } from 'react'
import PrescriptionsAPI from '../../axios/prescription/PrescriptionsApi'
import styled from 'styled-components'
import moment from 'moment-timezone'

import {
    Card,
    Button,
    Typography,
    message
} from 'antd'

const { Text } = Typography

const StyledCard = styled(Card)`
    background: #fff;
    text-transform: capitalize;

    & .ant-card-bordered {
        border: 1px solid #fff;
    }

    & .ant-card-head-wrapper {
        width: 100%;
    }

    & .ant-card-head {
        background: #7BAD7E;
        border-bottom: 1px solid #E1E6ED;
        color: #848C94;
        font-weight: 500;
        padding: 0 10px;
        width: 100%;
    }

    & .ant-card-head-title {
        font-weight: 700;
        font-size: 1.5 rem;
        width: 100%;
        display: flex;
        color: #fff;
    }

    & .ant-card-body {
        padding: 12px;
        min-height: 0;
    }

    & p {
        margin: 0;
    }
`

const StyledText = styled(Text)`
  color: #7eae80;
`;

function splitOnCapitalized(str) {
    return str.replace(/([a-z])([A-Z])/g, '$1 $2');
}

export const PrescriptionCard = ({ 
    script,
    prescriptionHistory = [],
    setPrescriptionHistory
}) => {
    const [cancelled, setCancelled] = useState(false)

    const handleCancelPrescription = async (prescriptionId, setCancelled) => {
        try {
            const { responseCode } = await PrescriptionsAPI.cancelPrescription(prescriptionId)
            if (!responseCode === '200') throw new Error('Error cancelling rx request prescription')
            setCancelled(true)
            const clonedRxState = [ ...prescriptionHistory ]
            const cancelledRx = clonedRxState.find(rx => rx.prescriptionId.toString() === prescriptionId.toString())
            
            if (cancelledRx) {
                cancelledRx.prescriberStatus = 'Cancel Pending'
                setPrescriptionHistory(clonedRxState)
            }
        } catch (e) {
            console.error(e)
            message.error('Error cancelling rx request prescription')
        }
    }
    
    const { 
        prescriptionId,
        name,
        strength,
        quantity,
        refills,
        directions,
        daysSupply,
        medicationStatus,
        pharmacyStatus,
        comment,
        startDate,
        signedAt
    } = script

    const formattedWrittenDate = moment(startDate || signedAt).tz(moment.tz.guess()).format('MM/DD/YYYY HH:mm:ss A');

    return (
        <StyledCard 
            size="small" 
            title={
                <>
                    {`${formattedWrittenDate} - ${name}`}
                    { 
                        medicationStatus?.toLowerCase() === "active" &&
                        pharmacyStatus === "PharmacyVerified" && 
                            <Button 
                                size="small"
                                style={{ marginLeft: '15px' }} 
                                type="danger"
                                onClick={() => handleCancelPrescription(prescriptionId, setCancelled)}
                            >
                                Cancel
                            </Button>
                    }
                </>
            }>
            { strength && <p><StyledText>Strength:</StyledText> {strength}</p> }
            { quantity && <p><StyledText>Quantity:</StyledText> {quantity}</p> }
            { daysSupply && <p><StyledText>Days Supply:</StyledText> {daysSupply}</p> }
            { refills && <p><StyledText>Refills:</StyledText> {refills}</p> }
            { directions && <p><StyledText>Directions:</StyledText> {directions}</p> }
            { medicationStatus && <p><StyledText>Medication Status:</StyledText> {splitOnCapitalized(medicationStatus)} </p> }
            { pharmacyStatus && <p><StyledText>Pharmacy Status:</StyledText> {splitOnCapitalized(pharmacyStatus)} </p> }
            { comment && <p><StyledText>Comment:</StyledText> {comment} </p> }
        </StyledCard>
    )
}

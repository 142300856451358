//import packages
import React, { useState, useMemo } from "react";
import {
  Layout,
  Button,
  Modal,
  Tabs,
  message,
  Typography
} from "antd";
import { PlusCircleOutlined } from '@ant-design/icons'
//import other files and API function
import { StyledTableWrapper } from './styled';
import TemplatesTable from "./TemplatesTable";
import TemplateForm from './TemplateForm';
import { PrescriptionTemplates } from './PrescriptionTemplates'
import { PrescriptionTemplateForm } from "./PrescriptionTemplates/components/PrescriptionTemplateForm";
import { useTemplates } from "./hooks/useTemplates"
import decodeToken from '../../utils/decodeToken';

const { Content } = Layout;
const { Title } = Typography

const Templates = () => {
  const [templateType, setTemplateType] = useState('case');
  const [templateModalIsOpen, setTemplateModalIsOpen] = useState(false);

  const user = useMemo(() => {
    const decodedToken = decodeToken(localStorage.getItem('token'));
    return decodedToken?.user;
  }, []);

  const {
    selectTemplate,
    setTemplates,
    createTemplate,
    editTemplate,
    deleteTemplate,
    setTemplateFilters,
    searchTemplates,
    telemedicineCategories,
    caseStatuses,
    selectedTemplate,
    loading,
    templates,
    templateFilters
  } = useTemplates(templateType, user)

  const handleCreateTemplate = async template => {
    try {
        await createTemplate(template)
        handleFinish()
    } catch (e) {
        message.error(e)
    }
  }

  const handleFinish = () => {
    setTemplateModalIsOpen(false)
    selectTemplate(null)
  };

  const handleChangeTemplateType = templateType => {
    setTemplates([])
    setTemplateType(templateType)
  }

  const _searchTemplates = () => {
    searchTemplates({ type: templateType, ...templateFilters })
  }

  return (
    <Content>
      <StyledTableWrapper>
        <Tabs
          style={{ display: 'block', height: '100%' }}
          activeKey={templateType}
          onChange={handleChangeTemplateType}
          tabBarExtraContent={
            <Button
              type='primary'
              icon={<PlusCircleOutlined />}
              onClick={() => setTemplateModalIsOpen(true)}
            >
              Add
            </Button>
          }
        >
          <Tabs.TabPane tab='Case Templates' key='case'>
            <TemplatesTable 
              loading={loading}
              type='case' 
              user={user}
              templates={templates}
              templateFilters={templateFilters}
              telemedicineCategories={telemedicineCategories}
              caseStatuses={caseStatuses}
              setTemplateFilters={setTemplateFilters}
              handleFinish={handleFinish}
              searchTemplates={searchTemplates}
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab='Message Templates' key='message' >
            <TemplatesTable 
              loading={loading}
              type='message' 
              user={user}
              templates={templates}
              templateFilters={templateFilters}
              telemedicineCategories={telemedicineCategories}
              caseStatuses={caseStatuses}
              setTemplateFilters={setTemplateFilters}
              handleFinish={handleFinish}
              searchTemplates={searchTemplates}
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab='Prescription Templates' key='prescription'>
            <PrescriptionTemplates
                user={user}
                templates={templates}
                telemedicineCategories={telemedicineCategories}
                selectedTemplate={selectedTemplate}
                templateFilters={templateFilters}
                loading={loading}
                selectTemplate={selectTemplate}
                editTemplate={editTemplate}
                deleteTemplate={deleteTemplate}
                handleFinish={handleFinish}
                setTemplateFilters={setTemplateFilters} 
              />
          </Tabs.TabPane>
        </Tabs>
      </StyledTableWrapper>
      <Modal
        title={templateType === 'prescription' && <Title level={4} style={{ color: '#7BAD7E' }}>Add New Prescription Template</Title> }
        maskClosable
        centered
        closable={false}
        width='50vw'
        footer={null}
        visible={templateModalIsOpen}
        onCancel={() => setTemplateModalIsOpen(false)}
      >
        {
          templateType === 'prescription'
            ? <PrescriptionTemplateForm telemedicineCategories={telemedicineCategories} handleFinish={handleFinish} selectTemplate={selectTemplate} submit={handleCreateTemplate} />
            : <TemplateForm mode='Add' type={templateType} handleFinish={handleFinish} searchTemplates={_searchTemplates} loading={loading}/>
        }
      </Modal>
    </Content>
  );
};

export default Templates;


import types from "./types";
import API from "../../axios/physician/PhysicianApi";
import handleAPIErrorResponse from "../../utils/handleAPIResponseError";

const setCaseQueueLoading = (status) => ({
    type: types.SET_CASE_QUEUE_LIST_LOADING,
    data: status,
});

const setCaseQueueCountLoading = (status) => ({
    type: types.SET_CASE_QUEUE_COUNT_LOADING,
    data: status,
})

const setCaseQueue = cases => ({
    type: types.SET_CASE_QUEUE_LIST,
    data: cases
})

const setCaseQueueCount = count => ({
    type: types.SET_CASE_QUEUE_COUNT,
    data: count
})

const removeCaseFromQueue = caseId => ({
    type: types.REMOVE_CASE_FROM_QUEUE,
    data: caseId
})

const setSortDirection = sortDirection => ({
    type: types.SET_SORT_DIRECTION,
    data: sortDirection
})

const setCaseQueueStatusFilter = status => ({
    type: types.SET_CASE_QUEUE_STATUS_FILTER,
    data: status
})

const incrementQueueCount = () => ({
    type: types.INCREMENT_QUEUE_COUNT
})

const setSendSMSNotifications = sendSMSNotifications => ({
    type: types.SET_SEND_SMS_NOTIFICATIONS,
    data: sendSMSNotifications
})

function getCaseQueue(params, sendSMSNotifications) {
    return (dispatch) => {
        dispatch(setCaseQueue({ cases: [] }))
        dispatch(setSendSMSNotifications(sendSMSNotifications))
        dispatch(setCaseQueueLoading(true));
        API.getCaseQueue(params)
            .then((res) => {
                if (!res.data?.success) throw Error()
                dispatch(setCaseQueue({ cases: res.data.cases }))
            })
            .catch(error => handleAPIErrorResponse(error, "Unable to fetch case queue"))
            .finally(() => dispatch(setCaseQueueLoading(false)))
    };
}

function getCaseQueueCount(type) {
    return (dispatch) => {
        dispatch(setCaseQueueCountLoading(true));
        API.getCaseQueue({ count: type })
            .then((res) => {
                if (!res.data?.success) throw Error()
                dispatch(setCaseQueueCount(res.data.count))
            })
            .catch(error => handleAPIErrorResponse(error, "Unable to fetch case queue count"))
            .finally(() => dispatch(setCaseQueueCountLoading(false)))
    };
}

function addCaseToQueue(physician, _case) {
    return dispatch => {
        if (
            physician.states?.map(state => state.toLowerCase()).includes(_case.state?.toLowerCase()) &&
            physician.clients?.map(client => client.toLowerCase()).includes(_case.client?.toLowerCase()) &&
            physician.teleMedicineCategories?.map(category => category.toLowerCase()).includes(_case.telemedicineCategory?.toLowerCase())) {

            const mappedCase = caseMapper(_case)

            dispatch({
                type: types.ADD_CASE_TO_QUEUE,
                data: mappedCase
            })
        } else {
            incrementQueueCount()
        }
    }
}

function caseMapper(_case) {
    return {
        ..._case,
        _id: _case.caseId,
        client: { name: _case.client },
        patient: { name: _case.patient },
        category: { name: _case.telemedicineCategory },
    }
}

const actions = {
    getCaseQueue,
    removeCaseFromQueue,
    getCaseQueueCount,
    setSortDirection,
    addCaseToQueue,
    setCaseQueueStatusFilter,
    incrementQueueCount
};

export default actions;
import React from 'react'
import { Form, Button, Input, Checkbox, Typography } from 'antd'

const REQUIRED = {
    required: true,
    message: 'Required'
}

export const DUOMobileActivationRequest = ({
    validation,
    validate,
    handleSubmit
}) => {
    const [form] = Form.useForm()

    return (
        <div>
             <Typography.Title level={3}>DUO Mobile Step 1</Typography.Title>
            <Typography.Text>
                You have been enabled for DUO mobile activation. Please follow the instructions and refrain from refreshing your browser or digressing from the workflow.
            </Typography.Text>

            <Form
                form={form}
                onFinish={handleSubmit}
                layout="vertical"
            >
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: '10px' }}>
                    <Typography.Text>
                        First enter the mobile phone number that you will be using for the DUO app.
                    </Typography.Text>
                    <Form.Item
                        label="Mobile Phone Number for DUO Activation"
                        name='mobilePhoneNumber'
                        rules={[REQUIRED]}
                    >
                        <Input type='text' />
                    </Form.Item>
                    <Typography.Text>
                        Upon clicking the "Send Me DUO Activation Texts" button, you will receive two texts. One is for downloading the DUO app. The other is to link you to the DoseSpot system.
                    </Typography.Text>
                    <div style={{ margin: '10px 0'}}>
                        <Form.Item
                            rules={[REQUIRED]}
                        >
                            <Checkbox
                                style={{ marginRight: '10px' }}
                                checked={validation.duoSMSAccepted}
                                onClick={e => validate(e.target.checked)}
                            />
                             <Typography.Text>
                            <strong>
                                I agree to receive text messages via SMS and accept any fees from my mobile provider.
                            </strong>
                        </Typography.Text>
                        </Form.Item>
                       
                       
                    </div>
                    <Form.Item>
                        <Button type="primary" htmlType='submit'>Send Me DUO Activation Texts</Button>
                    </Form.Item>
                </div>
            </Form>
        </div>
    )
}
import React from 'react'

export const Item = ({ 
    label, 
    value, 
    emphasize, 
    emphasizeExceptions = [],
    style 
}) => {
    const _emphasize = emphasize && !emphasizeExceptions.includes(value.trim().toLowerCase())
    const valueColor = _emphasize ? 'red' : "#000";

    return (
        <div style={{ width: '100%', display: 'flex', flexDirection: 'column', marginBottom: '10px', textTransform: 'capitalize', ...style }}>
            <div style={{ color: "#999", width: "200px" }}>{label}</div>
            <div style={{ color: valueColor }}>{value}</div>
        </div>
    )
}
import React from 'react'
import { connect } from 'react-redux'
import { AutoComplete  } from 'antd'

import {  useProviderContext } from '../../../context/ProviderContext'
import { required, isMismatch, isControlledSubstance } from '../validation'
import { StyledFormItem } from '../styles'

const MedicationSearch = ({
    form,
    APMedicineDictionary,
    fetchMedications,
    handleSelectMedication,
    medicationSearchMatches,
    telemedicineCategoryId,
    prescription,
    selectedStrength
}) => {
    const { provider } = useProviderContext()

    const handleSearch = (e) => {
        e.stopPropagation()
        fetchMedications(e.target.value)
    }
    
    const validateProviderCanRxControlledSubstances = {
        validator: async () => {
            const schedule = form.getFieldValue('schedule');
            if (!!schedule && schedule.toString() !== "0") {
                if (!provider.idVerified) {
                    return Promise.reject(new Error("Provider not authorized to prescribe controlled substances."))
                }
                if (!provider.tfaActivated) {
                    return Promise.reject(new Error("Please activatete TFA before continuing to prescribe controlled substances."))
                }
                if (!provider?.deaNumber) {
                    return Promise.reject(new Error("Please set up a DEA Number within your profile before continuing to prescribe controlled substances."))
                }
            }
            
            return Promise.resolve()
        }   
    };

    return (
        <StyledFormItem
            label="Medication Name"
            name="name"
            required
            rules={[
                required,
                isMismatch(APMedicineDictionary, prescription?.requestedMedication, telemedicineCategoryId), 
                validateProviderCanRxControlledSubstances,
                isControlledSubstance(selectedStrength)
            ]}
            onChange={handleSearch}
        >
            <AutoComplete
                onSelect={handleSelectMedication}
                placeholder="Search medication name"
                options={medicationSearchMatches?.map(match => ({ label: match.name, value: match.name }))}
            />
        </StyledFormItem>
    )
}

const mapStateToProps = (state) => {
    const {
        APMedicineDictionary
    } = state.prescribeMedicine;
    return {
        APMedicineDictionary
    };
};

export default connect(mapStateToProps)(MedicationSearch)
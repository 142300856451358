import React from 'react'
import { Form, Button, Input, Typography } from 'antd'

const REQUIRED = {
    required: true,
    message: 'Required'
}

export const DUOMobileActivation = ({ handleSubmit, stepBack }) => {
    const [form] = Form.useForm()

    return (
        <Form
            form={form}
            onFinish={handleSubmit}
            layout="vertical"
        >
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', gap: '10px' }}>
                <Typography.Title level={3}>DUO Mobile Step 2</Typography.Title>
                <Typography.Text>
                    Once you have the DUO app downloaded and you click the link to connect to DoseSpot, you will be able to generate a Two Factor Authentication (TFA) code
                </Typography.Text>
                <Form.Item
                    style={{ width: '200px', alignSelf: 'center'}}
                    label="TFA Code"
                    name='tfaCode'
                    rules={[REQUIRED]}
                >
                    <Input type='text' />
                </Form.Item>
                <Typography.Text>
                    After entering your PIN and clicking the "Save" button, you should see a "Success" message. Immediately sync your token by clicking the shield icon near the Welcome Greeting.
                </Typography.Text>
                <Form.Item
                    style={{ width: '200px', alignSelf: 'center'}}
                    label="PIN"
                    name='PIN'
                    rules={[REQUIRED]}
                >
                    <Input type='password' maxLength={4} />
                </Form.Item>
                <Form.Item style={{ alignSelf: 'center'}}>
                    <Button type="primary" onClick={stepBack}>Back</Button>
                    <Button type="primary" htmlType='submit'>Next</Button>
                </Form.Item>
            </div>
        </Form>
    )
}
import React from "react";
import PropTypes from "prop-types";
import { Button, Badge } from "antd";
import { MessageOutlined } from "@ant-design/icons";

const MessagesNavigator = (props) => {
  return (
    <>
      {props.caseId ? (
        <Badge count={props.count}>
          <Button
            shape="circle"
            icon={<MessageOutlined />}
            onClick={(event) => {
              event.stopPropagation();
              props.navigateToMessages(props.caseId);
            }}
          />
        </Badge>
      ) : ''}
    </>
  )
}

MessagesNavigator.propTypes = {
    caseId: PropTypes.number,
    count: PropTypes.number,
    navigateToMessages: PropTypes.func
}

export default MessagesNavigator;

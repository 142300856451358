import React, { useState, useEffect, useContext, useMemo } from "react";
import { useSelector } from 'react-redux';
import { Table, Spin, message } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { TablePreferencesContext } from '../../context/TablePreferencesProvider';
import { history } from "../../helpers";
import { userConstants } from "../../constants";
import PhysicianAPI from '../../axios/physician/PhysicianApi'
import SearchAndFilterCases from "../../components/SearchAndFilterCases/SearchAndFilterCases";
import { getTextFieldSorter, getDateFieldSorter, getSortOrder } from '../../utils/sorters';
import { dateTimeFormatter } from "../../utils/dateFormatter";
import { createCaseFilterQueryParamMap, createTableQueryParamMap, createTableSortByMap } from "../../utils/createCaseFilterQueryParamMap";
import { CaseTagEnum } from "../../types/enums";
import { CaseFilterMap, CaseQueryParamMap } from "../../constants/CaseFilter"
import { capitalize } from "lodash";

const hideFields = Object.freeze([CaseFilterMap.PHYSICIAN_ID]);

const TableDataMap = {
  CASE_ID: CaseFilterMap.CASE_ID,
  PATIENT_NAME: `${CaseFilterMap.PATIENT_FIRST_NAME} ${CaseFilterMap.PATIENT_LAST_NAME}`,
  TELEMEDICINE_CATEGORY_ID: CaseFilterMap.TELEMEDICINE_CATEGORY_ID,
  CREATED_DATE: CaseFilterMap.CREATED_DATE,
  COMPLETED_AT: 'completedAt',
  STATUS: CaseFilterMap.STATUS,
}

const TableSortByMap = createTableSortByMap(TableDataMap);

const getTableColumns = (sortOrder, sortBy) => {
  const columns = [
    {
      title: "Case No",
      key: TableDataMap.CASE_ID,
    },
    {
      title: "Patient Name",
      key: TableDataMap.PATIENT_NAME,
    },
    {
      title: "Category Name",
      key: TableDataMap.TELEMEDICINE_CATEGORY_ID,
    },
    {
      title: "Created Date",
      key: TableDataMap.CREATED_DATE,
    },
    {
      title: "Completed Date",
      key: TableDataMap.COMPLETED_AT,
    },
    {
      title: "Status",
      key: TableDataMap.STATUS,
    },
  ];

  columns.forEach((c) => {
    c.dataIndex = c.key;
    if ([TableDataMap.CASE_ID].includes(c.key)) {
      return
    }
    c.sortOrder = getSortOrder(sortOrder, sortBy, c.key);
    const isDateField = [TableDataMap.CREATED_DATE, TableDataMap.COMPLETED_AT].includes(c.key);
    c.sorter = isDateField ? getDateFieldSorter(c.key) : getTextFieldSorter(c.key);
  });

  return columns;
}

const defaultPreferences = { currentPage: 1, tablePageSize: 100, sortOrder: 'descend' }

const Reassigned = (props) => {
  const { title, type } = props;
  const [showModal, setShowModal] = useState(false)
  const [cases, setCases] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [isFetching, setIsFetching] = useState(false);
  const [searchValue, setSearchValue] = useState(null);
  const [caseListFilter, setCaseListFilter] = useState({});
  const { getTablePreferences, updateTablePreferences } = useContext(TablePreferencesContext);
  const { currentPage, tablePageSize, sortOrder, sortBy } = getTablePreferences(type, defaultPreferences);
  const { statesList, telemedicineCategoriesList } = useSelector(state => state.entities)

  const columns = useMemo(() => getTableColumns(sortOrder, sortBy), [sortOrder, sortBy]);

  const fetchCases = useMemo(() => () => {
    const newTablePreferences = {
      currentPage,
      tablePageSize,
      sortOrder,
      sortBy: TableSortByMap[sortBy] || sortBy,
    };
    const tableFilter = createTableQueryParamMap(newTablePreferences);
    const caseFilterMap = createCaseFilterQueryParamMap(caseListFilter, hideFields);
    if (searchValue !== null) {
      caseFilterMap[CaseQueryParamMap.SEARCH_TERM] = searchValue;
    }

    if (Array.isArray(caseFilterMap.tags)) {
      caseFilterMap.tags.push(CaseTagEnum.REASSIGNED);
    }
    else {
      caseFilterMap.tags = [CaseTagEnum.REASSIGNED];
    }

    setIsFetching(true);
    PhysicianAPI
      .getCaseList({ ...tableFilter, ...caseFilterMap })
      .then(res => {
        setCases(res.data.payload.data);
        setTotalCount(res.data.payload.count);
      })
      .catch((err) => {
        message.error('Unable to fetch cases');
        console.log('Unable to fetch cases ERROR: ', err);
      })
      .finally(() => setIsFetching(false));
  }, [currentPage, tablePageSize, caseListFilter, searchValue, sortOrder, sortBy]);

  useEffect(() => {
    fetchCases();
  }, [fetchCases])

  const handleIntialTableCount = () => {
    updateTablePreferences(type, { currentPage: 1, tablePageSize: 100 })
  };

  const handleOk = (filterData) => {
    setCaseListFilter(filterData);
    setShowModal(false);
    handleIntialTableCount();
  };

  const searchCase = (value) => {
    setSearchValue(value);
    handleIntialTableCount();
  };

  const handleCancel = () => {
    setCaseListFilter({});
    setShowModal(false);
    handleIntialTableCount();
  };

  const handleCancelCross = () => {
    setShowModal(false);
  };

  const onChangePage = (page) => {
    updateTablePreferences(type, { currentPage: page })
  };

  const onPageSizeChange = (_, size) => {
    updateTablePreferences(type, { currentPage: 1, tablePageSize: size })
  };

  const onChangeOrder = (_pagination, _filters, sorter) => {
    updateTablePreferences(type, {
      sortOrder: sorter.order || 'ascend',
      sortBy: sorter.field,
    });
  }

  const casesForTable = cases.map((c) => {
    return {
      key: c.caseId,
      rowKey: c.caseId,
      [TableDataMap.CASE_ID]: c.caseId,
      [TableDataMap.PATIENT_NAME]: c.patientName,
      [TableDataMap.TELEMEDICINE_CATEGORY_ID]: c.category,
      [TableDataMap.CREATED_DATE]: dateTimeFormatter(c.createdDate),
      [TableDataMap.COMPLETED_AT]: c.completedAt ? dateTimeFormatter(c.completedAt) : '',
      [TableDataMap.STATUS]: c.status.split(' ').map(capitalize).join(' '),
    }
  })

  return (
    <div className="consulted-case">
      <div className="waiting-room-case-list-part-heading">
        <h1>Reassigned Cases</h1>
        <SearchAndFilterCases
          searchCase={searchCase}
          showModal={showModal}
          setShowModal={setShowModal}
          handleCancelCross={handleCancelCross}
          handleCancel={handleCancel}
          handleOk={handleOk}
          telemedicines={telemedicineCategoriesList}
          states={statesList}
          hideFields={hideFields}
        />
      </div>

      <div className="consulted-case-no-of-patient-list-data">
        <Spin
          size="large"
          spinning={isFetching}
          indicator={<LoadingOutlined spin />}
        >
          <div>
            <Table
              columns={columns}
              dataSource={casesForTable}
              onChange={onChangeOrder}
              pagination={{
                total: totalCount,
                showTotal: () => `Total ${totalCount} cases`,
                current: currentPage,
                pageSize: tablePageSize,
                pageSizeOptions: ["5", "10", "20", "50", "100"],
                showSizeChanger: true,
                onChange: onChangePage,
                onShowSizeChange: onPageSizeChange,
              }}
              scroll={{ x: 900 }}
              size="large"
              onRow={(record) => {
                return {
                  onClick: () => {
                    history.push(
                      `/dashboard/${userConstants.USER_PHYSICIAN}/caseview/${record.caseId}`,
                      { from: title }
                    );
                  },
                };
              }}
            />
          </div>
        </Spin>
      </div>
    </div>
  );
};

export default Reassigned;

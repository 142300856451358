import React, { useState, useEffect } from "react";
import { Typography, Collapse, Card, message, Spin, Button } from "antd";
import PrescriptionsAPI from  "../../../axios/prescription/PrescriptionsApi"
import PatientsAPI from "../../../axios/patient/PatientApi"
import styled from 'styled-components'
import moment from 'moment-timezone'
import { PatientFieldEnum } from '../../../types/enums'
import { CancelPrescriptionModal } from './CancelPrescriptionModal'



const { Title, Text } = Typography;
const { Panel } = Collapse;

const StyledCollapse = styled(Collapse)`

    & .ant-collapse-content {
        color: #7C7C7C;
        background-color: #f4f4f4;
        border-top: 1px solid #E1E6ED;
    }
`

const StyledText = styled(Text)`
  color: #7eae80;
`;

const PanelTitle = styled(Title)`
    margin-bottom: 0 !important;
`

const ItemContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 10px;
    overflow: scroll;
`

const StyledCard = styled(Card)`
    background: #fff;
    text-transform: capitalize;

    & .ant-card-bordered {
        border: 1px solid #fff;
    }

    & .ant-card-head-wrapper {
        min-width: 100%;
    }

    & .ant-card-head {
        background: #7BAD7E;
        border-bottom: 1px solid #E1E6ED;
        color: #848C94;
        font-weight: 500;
        padding: 0 10px;
        width: 100%;
    }

    & .ant-card-head-title {
        font-weight: 700;
        font-size: 1.5 rem;
        width: 100%;
        display: flex;
        color: #fff;
    }

    & .ant-card-body {
        padding: 12px;
        min-height: 0;
    }

    & p {
        margin: 0;
    }
`

const TreatmentTitle = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
`

const Logo = styled.img`
    height: 20px;
`

const PrescriptionCard = ({ script, selectPrescription }) => {        
    const { 
        prescriptionId,
        name,
        strength,
        quantity,
        refills,
        directions,
        daysSupply,
        medicationStatus,
        pharmacyStatus,
        comment,
        effectiveDate,
        signedAt
    } = script

    const formattedEffectiveDate = moment(effectiveDate || signedAt).tz(moment.tz.guess()).format('MM/DD/YYYY HH:mm:ss A')

    return (
        <StyledCard 
            size="small" 
            title={`${formattedEffectiveDate} - ${name}`}   
        >
            { prescriptionId && <p><StyledText>Prescription Id:</StyledText> {prescriptionId}</p> }
            { strength && <p><StyledText>Strength:</StyledText> {strength}</p> }
            { !!quantity && <p><StyledText>Quantity:</StyledText> {quantity}</p> }
            { !!daysSupply && <p><StyledText>Days Supply:</StyledText> {daysSupply}</p> }
            <p><StyledText>Refills:</StyledText> {refills || 0}</p>
            { directions && <p><StyledText>Directions:</StyledText> {directions}</p> }
            { medicationStatus && <p><StyledText>Medication Status:</StyledText> {medicationStatus} </p> }
            { pharmacyStatus && <p><StyledText>Pharmacy Status:</StyledText> {pharmacyStatus} </p> }
            { comment && <p><StyledText>Comment:</StyledText> {comment} </p> }
            { 
                !!prescriptionId && 
                !medicationStatus?.toLowerCase().includes("cancel") &&
                pharmacyStatus === "PharmacyVerified" &&
                <Button 
                    size="small"
                    style={{ alignSelf: 'flex-end' }} 
                    type="danger"
                    onClick={() => selectPrescription(script)}
                >   
                    Cancel
                </Button>
            }
        </StyledCard>
    )
}

const TreatmentCard = ({ treatment }) => {
    const { createdDate, consultationType, prescriptionStatus, followUp, teleMedicineCategory, status, logoUrl } = treatment
    const formattedCreatedDate = moment(createdDate).tz(moment.tz.guess()).format('MM/DD/YYYY')

    return (
        <StyledCard 
            size="small" 
            title={
                <TreatmentTitle>
                    {formattedCreatedDate} 
                     <Logo src={logoUrl}/>
                </TreatmentTitle>
            }
        >
            { teleMedicineCategory && <p><StyledText>Category: </StyledText>{teleMedicineCategory.name}</p> }
            { consultationType && <p><StyledText>Consult: </StyledText>{consultationType}</p> }
            { status && <p><StyledText>Case Status: </StyledText>{status}</p> }
            { followUp !== undefined && <p><StyledText>Follow Up: </StyledText>{followUp.toString()}</p> }
            { prescriptionStatus && <p><StyledText>Perscription Status: </StyledText>{prescriptionStatus}</p> }
        </StyledCard>
    )
}

const ProfileUpdateCard = ({ eventGroup }) => {
    const updateDate = new Date(eventGroup.createdAt).toLocaleDateString()
    const updateTime = new Date(eventGroup.createdAt).toLocaleTimeString()

    return (
        <StyledCard size="small" title={`${updateDate} ${updateTime}`}>
            { eventGroup?.updates 
                ? Object.entries(eventGroup.updates).map(([k, v], i) => <p key={`${eventGroup.createdAt}${i}`}><StyledText>{PatientFieldEnum[k]}:</StyledText> {v}</p>) 
                : <p><StyledText>None</StyledText></p>
            }
        </StyledCard>
    )
}

const CasePatientHistory = ({ currentCaseId, patientId  }) => {
    const [selectedPrescription, selectPrescription] = useState(null)
    const [patient, setPatient] = useState(null)
    const [pharmacy, setPharmacy] = useState(null)
    const [patientHistory, setPatientHistory] = useState([])
    const [prescriptionHistory, setPrescriptionHistory] = useState(null)
    const [caseHistory, setCaseHistory] = useState(null)
    const [rxLoading, setRxLoading] = useState(false)
    const [patientHistoryLoading, setPatientHistoryLoading] = useState(false)
    const [caseHistoryLoading, setCaseHistoryLoading] = useState(false)

    const getPatientDetails = async () => {
        try {
            const { data }  = await PatientsAPI.getPatientDetails(patientId)
            setPatient(data.patient)
        } catch (e) {
            message.error('Error fetching patient details')
            console.log(e)
        }
    }

    const getPatientPrescriptionHistory = async () => {
        setRxLoading(true)
        try {
            const { data: { prescriptions } } = await PrescriptionsAPI.getPatientPrescriptionHistory(patientId)
            const activePrescriptions = prescriptions.filter(rx => rx.prescriberStatus?.toLowerCase() !== 'deleted')
            setPrescriptionHistory(activePrescriptions)
        } catch (e) {
            message.error('Error fetching patient Rx history')
            console.log(e)
        } finally {
            setRxLoading(false)
        }
    }

    const getPatientHistory = async () => {
        setPatientHistoryLoading(true)
        try {
            const { data }  = await PatientsAPI.getPatientDetails(patientId)
            const { history } = data.patient
            setPatientHistory(history)
        } catch (e) {
            console.log(e)
            message.error("Unable to load Patient details. Please try again!");
        } finally {
            setPatientHistoryLoading(false)
        }
    }

    const getCaseHistory = async () => {
        setCaseHistoryLoading(true)
        try {
            const response = await PatientsAPI.getCaseHistory(patientId)
            const { patientCaseHistory } = response.data
            const otherCases = patientCaseHistory.filter(patientCase => patientCase._id !== currentCaseId)
            return setCaseHistory(otherCases)
        } catch (e) {
            console.log(e)
            message.error('Unable to load patient case history')
        } finally {
            setCaseHistoryLoading(false)
        }
    }

    const handleDeselectPrescription = async (cancelled = false) => {
        if (cancelled) {
            const clonedRxHistory = [ ...prescriptionHistory ]
            const cancelledRx = clonedRxHistory.find(rx => rx.prescriptionId.toString() === selectedPrescription.prescriptionId.toString())
            cancelledRx.medicationStatus = 'Cancel Pending'
            setPrescriptionHistory(clonedRxHistory)
        }
        setPharmacy(null)
        selectPrescription(null)
    }

    const getPharmacyDetails = async (pharmacyId) => {
        try {
            const { data } = await PrescriptionsAPI.getPharmacybyId(pharmacyId)
            setPharmacy(data.pharmacy)
        } catch (e) {
            message.error('Error fetching pharmacy details')
            console.log(e)
        }
    }

    useEffect(() => {
        if (patientId) {
            getPatientDetails()
            getPatientHistory()
            getCaseHistory()
            getPatientPrescriptionHistory()
        }
    // eslint-disable-next-line
    }, [currentCaseId])

    useEffect(() => {
        if (selectedPrescription) {
            getPharmacyDetails(selectedPrescription.pharmacyId)
        } else {
            setPharmacy(null)
            getPatientPrescriptionHistory()
        }
    }, [selectedPrescription])
    
    return (
        <StyledCollapse defaultActiveKey={['1', '2', '3']}>
            <Panel header={<PanelTitle level={3}>Prescriptions</PanelTitle>} key="1">
                
             <ItemContainer>
                { rxLoading && <Spin style={{ margin: '0 auto' }}/> }
                { !rxLoading &&
                    ( prescriptionHistory?.length 
                        ? prescriptionHistory
                            .sort((a, b) => new Date(b.signedAt) - new Date(a.signedAt))
                            .map(script => <PrescriptionCard key={script.prescriptionId} script={script} selectPrescription={selectPrescription} />) 
                        : 'No Prescription History'  )
                }
            </ItemContainer> 
            </Panel>
            <Panel header={<PanelTitle level={3}>Other Treatments</PanelTitle>} key="2">
                <ItemContainer>
                    { !caseHistoryLoading && caseHistory?.length 
                        ? caseHistory.map(_case => <TreatmentCard key={_case._id} treatment={_case}/>)
                        : 'No Past Treatments' }
                </ItemContainer>
            </Panel>
            <Panel header={<PanelTitle level={3}>Patient Profile Changes</PanelTitle>} key="3">
                <ItemContainer>
                    { !patientHistoryLoading && patientHistory?.length 
                        ? patientHistory.map(eventGroup => <ProfileUpdateCard key={eventGroup.createdAt} eventGroup={eventGroup} />)
                        : 'None'
                    }
                </ItemContainer>
            </Panel>

            { selectedPrescription && 
                <CancelPrescriptionModal
                    prescription={selectedPrescription}
                    patient={patient}
                    pharmacy={pharmacy}
                    deselectRx={handleDeselectPrescription}
                />
            }
        </StyledCollapse>
    )
};

export default CasePatientHistory;
import types from "./types";
import handleAPIErrorResponse from "../../utils/handleAPIResponseError";
import API from "../../axios/physician/PhysicianApi";

import { message } from "antd";

const setPhysicianDetailsLoading = (status) => ({
    type: types.SET_PHYSICIAN_DETAILS_LOADING,
    data: status,
});

const setPhysicianDetails = (payload) => ({
    type: types.SET_PHYSICIAN_DETAILS,
    payload
})

const setPhysicianCaseIds = (payload) => ({
    type: types.SET_PHYSICIAN_BOOKMARKED_CASES,
    payload
})

function getPhysicianDetails() {
    return (dispatch) => {
        dispatch(setPhysicianDetailsLoading(true));
        API.physicianData().then(
            (res) => {
                const { data } = res;
                if (data && data.success) {
                    const { payload } = data;
                    if (payload && Object.keys(payload).length) {
                        const mappedData = mapPhysicianDetails(payload)
                        dispatch(setPhysicianDetails(mappedData))
                    }
                } else {
                    message.warning("Unable to fetch Physician Details");
                }
                dispatch(setPhysicianDetailsLoading(false));
            }).catch(
                (error) => {
                    handleAPIErrorResponse(error,"Error occured while fetching prescription details");
                    dispatch(setPhysicianDetailsLoading(false));
            });
    };
}

function mapAndSetPhysicianDetails(physician) {
    const mappedData = mapPhysicianDetails(physician)
    return dispatch => {
        dispatch(setPhysicianDetails(mappedData))
    }
}

function mapPhysicianDetails(physician) {

    const {
        _id,
        firstName,
        lastName,
        isWheel,
        profilePic1,
        profilePic2,
        email,
        tags,
        employmentStatus,
        states,
        clients,
        teleMedicineCategories,
        preferences,
        idVerified,
        tfaActivated,
        pinSet,
        useRxTemplates, 
        controlledSubstanceAuthorized,
        deaNumber,
        address1, 
        address2,
        caseIds,
        city,
        state,
        zipCode,
        meetingLink
    } = physician

    return {
        username: `${firstName} ${lastName}`,
        isWheel,
        profilePic: profilePic1 || profilePic2,
        userId: _id,
        userEmail: email,
        userType: "physician",
        tags: tags,
        employmentStatus,
        states,
        clients,
        teleMedicineCategories,
        preferences,
        idVerified,
        tfaActivated,
        pinSet,
        useRxTemplates,
        controlledSubstanceAuthorized,
        deaNumber,
        address1,
        address2,
        caseIds: caseIds || [],
        city,
        state,
        zipCode,
        meetingLink
    }
}

const actions  = {
    setPhysicianDetails,
    setPhysicianDetailsLoading,
    getPhysicianDetails,
    mapAndSetPhysicianDetails,
    setPhysicianCaseIds
};

export default actions; 
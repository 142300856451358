export const CaseStatusEnum = {
  CONSULT_COMPLETE: "consult complete",
  CONSULT_COMPLETE_NO_RX: "consult complete no rx",
  PENDING_PRESCRIPTION: "pending prescription",
  CASE_CREATED: "case created",
  RX_CHANGE: "rx change",
  ADDITIONAL_INFO_REQUIRED: "additional info required",
  INVALID_FACE_PHOTO: "invalid face photo",
  INVALID_PHOTO_ID: "invalid photo id",
  PENDING_SCHEDULE: "pending schedule",
  PENDING_VIDEO_CONSULT: "pending video consult",
  PENDING_AUDIO_CONSULT: "pending audio consult",
  ARCHIVE: "archive",
  CANCELLED: "cancelled",
  ASSIGNED: "assigned",
  RX_RENEWAL: "rx renewal",
  REFER: "refer",
  WAITING_ROOM: "waiting room",
  PENDING_LABS: "pending labs"
};

export const EXTRA_MESSAGE_CASE_STATUS_NAMES = Object.freeze([
  CaseStatusEnum.CONSULT_COMPLETE,
  CaseStatusEnum.CONSULT_COMPLETE_NO_RX,
  CaseStatusEnum.ADDITIONAL_INFO_REQUIRED,
  CaseStatusEnum.INVALID_FACE_PHOTO,
  CaseStatusEnum.INVALID_PHOTO_ID,
]);

export const REQUIRE_INFO_STATUS_NAMES = Object.freeze([
  CaseStatusEnum.ADDITIONAL_INFO_REQUIRED,
  CaseStatusEnum.INVALID_FACE_PHOTO,
  CaseStatusEnum.INVALID_PHOTO_ID,
]);

export const CaseStatusColorCodeEnum = {
	'consult complete': '#de0365',
	'consult complete no rx': '#293191',
	'pending prescription': '#27adbf',
	'rx change': '#b4048a',
	'rx renewal': '#b44a2e',
	'rx renewal cancelled': '#fa30ce',
	'additional info required': '#0b19fe',
	'ivalid face photo': '#2cef59',
	'invalid photo id': '#0a9ba9',
	'case created': '#f10ba6',
	'pending schedule': '#5da7a3',
	'cancelled': '#fa30ce',
	'assigned': 'green',
	'pending video consult': '#f95424',
	'pending audio consult': '#fa7cdc',
	'refer': '#335CFF'
}

export const TagColorCodeEnum = {
  '*RxRenewal_to_RxChange': 'red'
}

export const CaseTagEnum = {
  NEEDS_REVIEW: '*needs review',
  REVIEWED: '*reviewed',
  REASSIGNED: '*reassigned',
}

export const ConsultTypeEnum = {
  STORE_AND_FORWARD: "store and forward",
  VIDEO: "video",
  AUDIO: "audio",
};

export const PrescriptionStatusEnum = {
  ERRORS: "errors",
  PRESCRIBED_WITH_ERRORS: "prescribed with errors",
  WAITING: "waiting",
  SUCCESS: "success",
  PENDING_PRESCRIPTION: "pending prescription",
  PRESCRIBED: "prescribed"
}

export const PatientFieldEnum = {
  firstName: "First Name",
  lastName: "Last Name",
  dob: "Date of Birth",
  email: "Email",
  phone: "Phone Number",
  gender: "Gender",
  address1: "Address 1",
  address2: "Address 2",
  city: "City",
  state: "State",
  zipCode: "Postal Code",
  heightIn: "Height (in)",
  heightFt: "Height (ft)",
  weight: "Weight",
  age: "Age"
};

export const PhysicianTagEnum = {
	NEEDS_REVIEW: 'needs review',
	REVIEWER: 'reviewer',
  LEAD_PHYSICIAN: 'lead physician'
};

export const PhysicianEmploymentStatusEnum = {
  INTERNAL_PHYSICIAN: 'internal physicians'
}

export const RxRenewalStatusEnum = {
	PENDING: 'PENDING',
	COMPLETED: 'COMPLETED',
	CANCELED: 'CANCELED',
}

export const RxErrorType = {
  TRANSMISSION_ERROR: 'TRANSMISSION ERROR',
  RX_CREATION_ERROR: 'RX CREATION ERROR',
  RX_SEND_ERROR: 'RX SEND ERROR',
}

export const TelemedicineCategoryEnum = {
  TESTOSTERONE: 'testosterone',
}

export const ClientIdEnum = {
  CLEARED: "62388f86a83ca843e4060e46"
}

export const RequestStatusEnum = {
  ...RxRenewalStatusEnum,
  COMPLETED_NO_RX: 'COMPLETED NO RX',
  PENDING_LAB_ORDER: 'PENDING LAB ORDER',
  PENDING_LAB_RESULTS: 'PENDING LAB RESULTS'
}

export const LabOrderEventEnum = {
  REPORT_RECEIVED: 'REPORT_RECEIVED',
	ORDER_PLACED: 'ORDER_PLACED',
}

export const TemplateTypeEnum = {
  CASE: 'case',
  MESSAGE: 'message',
  PRESCRIPTION: 'prescription',
  VISIT: 'visit'
}

export const MODE = {
  VIEW: 'View',
  ADD: 'Add',
  EDIT: 'Edit',
}

export const FILTER = {
  ALL: "all",
  MY: "my",
  SHARED: "shared",
  ADMIN: "admin",
}

export const LabResourceTypeEnum = {
  REPORT: 'report',
  ORDER: 'order'
}

export const SocketEventEnum = {
	GET_CONSULT_TYPE_REPORT: "GetConsultTypeReport",
	GET_COMPLETION_COUNTS_REPORT: "GetCompletionCountsReport",
	GET_CASE_REPORT: "GetCaseReport",
}

export const REPORT_TYPE = {
  PHYSICIAN_MONTHLY: 'PHYSICIAN_MONTHLY',
}

import actions from "./actions";
import reducer from "./reducer";

const referrals = {
  actions,
  reducer,
  name: "referrals",
};

export default referrals;
